import React from "react";
import classes from "./Disclaimer.module.css";

interface Props {
  label?: string;
  content?: string;
}
export const Disclaimer = ({ label, content }: Props) => {
  return (
    <div className={classes.Container}>
      {label && <p className={classes.Label}>{label}</p>}
      <p className={classes.Content}>{content}</p>
    </div>
  );
};
