import React from "react";
import classes from "./AddIdType.module.css";
import { FormHelperText, Grid } from "@material-ui/core";
import {
  FileSelector,
  InputLabel,
  Separator,
  Button,
  SelectInput,
  IconGenerator,
} from "../../Common";

import { IdTypes } from "../../../Config/Constants/SelectValues";
import { IDs_Payload } from "../../../Types";
import { Field, FieldProps } from "formik";

interface Props {
  className?: string;
  values?: IDs_Payload[];
  onAddId?: (value: IDs_Payload) => void;
  onRemoveId?: (index: number) => boolean | undefined;
}

export const AddMemberIdType = (props: Props) => {
  const { className, values, onAddId, onRemoveId } = props;
  return (
    <div className={className}>
      <div className={classes.Root}>
        <Grid container spacing={1}>
          {values &&
            values?.map((ids, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      onClick={() => {
                        if (onRemoveId) onRemoveId(index);
                      }}
                      freeHeight
                      variant="default"
                      className={classes.removeButton}
                    >
                      <IconGenerator iconName="close-box" />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel>
                      <span>Id Type*</span>
                    </InputLabel>
                    <Field name={`ids.${index}.id_types`}>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: formik, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      }: FieldProps) => (
                        <SelectInput
                          variant={"outlined"}
                          className={classes.inputFields}
                          options={IdTypes}
                          value={field.value}
                          handleChange={(e) =>
                            formik.setFieldValue(
                              `ids.${index}.id_types`,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field name={`ids.${index}.file`}>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { setFieldValue, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      }: FieldProps) => {
                        return (
                          <>
                            <FileSelector
                              limitSize={2000000}
                              value={field.value}
                              btnText={
                                field.value
                                  ? "Replace the File"
                                  : "Choose an File"
                              }
                              onFileChoose={(file: any) =>
                                setFieldValue(`ids.${index}.file`, file)
                              }
                              onClearClick={() =>
                                setFieldValue(`ids.${index}.file`, null)
                              }
                            />
                            {errors.ids &&
                              //@ts-ignore
                              errors.ids[index] &&
                              //@ts-ignore
                              errors.ids[index].file && (
                                <FormHelperText error={true}>
                                  {
                                    //@ts-ignore
                                    errors.ids[index].file
                                  }
                                </FormHelperText>
                              )}
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Separator className={classes.SeparatorClass} />
                  </Grid>
                </>
              );
            })}
        </Grid>

        <Button
          onClick={() => {
            if (values && values.length < 3) {
              if (onAddId)
                onAddId({
                  file: null,
                  id_types: "",
                });
            }
          }}
          variant="link"
        >
          <span>{`+ Add Another ID (${values?.length}/3)`}</span>
        </Button>
      </div>
    </div>
  );
};
