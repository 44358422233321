import { Chip, Grid } from "@material-ui/core";
import { de } from "date-fns/locale";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import {
  closeGlobalDialog,
  openGlobalDialog,
  openSnackBar,
} from "../../../Redux/Actions/appStatusActions";
import { getStudentsDetailsById } from "../../../Redux/Actions/corporateActions";
import { StudentActions } from "../../../Redux/Actions/studentActions";
import { useTypedSelector } from "../../../Redux/Reducers";
import profilePicTmpImage from "../../../Resources/Images/1.jpg";
import {
  LanguageVariantType,
  LevelTestType,
  PackageType,
  StudentDetailType,
  studentInitialValues,
  StudentType,
} from "../../../Types";
import {
  Alert,
  Button,
  CheckboxWithLabel,
  FormikTextField,
  TextInput,
} from "../../Common";
import { DateTimePicker } from "../../Common/DateTimePicker/DateTimePicker";
import { IOSSwitch } from "../../Common/IOSSwitch/IOSSwitch";
import { useDashboardPageLocalData } from "../../Hooks";
import { ConfirmationModal } from "../../menus";
import { ScoresLayout } from "../ScoresLayout/ScoresLayout";
import { ExpandMenu } from "./../../Common/ExpandMenu/ExpandMenu";
import { InputLabel } from "./../../Common/InputLabel/InputLabel";
import { ProfileBaseInfo } from "./../../Common/ProfileBaseInfo/ProfileBaseInfo";
import classes from "./UserDetail.module.css";

const validationSchema = yup.object({});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
}

export const UsersDetail = ({ lang, setSelectedLanguage }: Props) => {
  const dispatch = useDispatch();
  const [{ idToEdit }] = useDashboardPageLocalData();
  const studentDetail = useTypedSelector(
    (state) => state.student.studentDetails
  );
  const matchmakingQuestions = useTypedSelector(
    (state) => state.student.studentMatchmakingQuestions
  );

  const freePackages = useTypedSelector((state) => state.packages.freePackages);
  const allPackages = useTypedSelector((state) => state.packages.userPackages);

  const [initialValues, setInitialValues] =
    useState<StudentDetailType>(studentInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [selectedPackage, setSelectedPackage] = useState<string>("");
  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);
  const invitationAll =
    studentDetail?.invite_friends?.friend_invitation_count_overall;
  const invitationSignedUp =
    studentDetail?.invite_friends?.friend_invitation_count_signup;
  const invitationPaid =
    studentDetail?.invite_friends?.friend_invitation_count_paid;

  useEffect(() => {
    if (studentDetail) {
      setInitialValues({
        ...studentDetail,
        phone_number: studentDetail.phone_number,
        created_at: moment(studentDetail?.created_at).format("MMM Do, YYYY"),
      });
    }
  }, [studentDetail]);

  useEffect(() => {
    if (idToEdit && !studentDetail && isSupervisor) {
      dispatch(getStudentsDetailsById(idToEdit));
    } else if (idToEdit && !studentDetail && !isSupervisor) {
      dispatch(StudentActions.getStudentDetails(idToEdit));
    }
  }, [idToEdit]);

  const onCancelClicked = () => {
    dispatch(closeGlobalDialog());
    setSelectedPackage("");
  };

  const onAddPageClicked = () => {
    if (idToEdit) {
      dispatch(
        StudentActions.unlockFreePackage(
          selectedPackage,
          studentDetail?.email ? studentDetail?.email : "",
          idToEdit
        )
      );
      onCancelClicked();
    }
  };

  const updatePackage = (
    classes_remaining: number,
    validity_date: string,
    id: string
  ) => {
    if (studentDetail) {
      dispatch(
        StudentActions.updatePackage(
          id,
          studentDetail.email,
          studentDetail._id,
          classes_remaining,
          validity_date
        )
      );
    }
  };

  const addPackage = () => {
    if (selectedPackage !== "") {
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <ConfirmationModal
              onContainedButtonClicked={onAddPageClicked}
              onOutlineButtonClicked={onCancelClicked}
              text={
                <span>
                  Are you sure you want to add this package to this student
                </span>
              }
              outlineBtnText="Cancel"
              containedButtonText="Yes"
            />
          ),
        })
      );
    } else
      dispatch(
        openSnackBar({
          open: true,
          variant: "info",
          message: "Please select a package first",
        })
      );
  };

  const updateStudent = (id?: string, data?: Partial<StudentDetailType>) => {
    if (id && data) dispatch(StudentActions.updateStudent(id, data));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        let updatedValues = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
          additional_data: {
            back_up_email: values.additional_data?.back_up_email,
            job_position: values.additional_data?.job_position,
            company_name: values.additional_data?.company_name,
            company_code: values.additional_data?.company_code,
          },
          ageGroup: values.ageGroup,
          level_test: values.level_test,
          post_level: values.post_level,
          level_test_detail: values.level_test_detail,
          post_level_test_detail: values.post_level_test_detail,
          level_test_status: values.level_test_detail ? "completed" : undefined,
          post_level_test_status: values.post_level_test_detail ? "completed" : undefined,
        };

        updateStudent(values._id, {
          ...updatedValues,
        });
      }}
    >
      {({ values, handleSubmit, errors, setFieldValue }) => {
        return (
          <Form>
            <div>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ProfileBaseInfo
                        profilePicture={
                          values.avatar_url
                            ? values.avatar_url
                            : profilePicTmpImage
                        }
                        className={classes.inputFields}
                        isSupervisor={isSupervisor}
                      />
                    </Grid>

                    {values.email && (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormikTextField
                          label="Email Address"
                          placeholder={"Noooooooo@gmail.com..."}
                          className={classes.inputFields}
                          name="email"
                          readonly
                        />
                      </Grid>
                    )}
                    {values.phone_number && (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormikTextField
                          label="Phone Number"
                          placeholder={"Noooooooo@gmail.com..."}
                          className={classes.inputFields}
                          name="phone_number"
                          readonly={isSupervisor}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Bakcup Email address"
                        placeholder={"Noooooooo@gmail.com..."}
                        className={classes.inputFields}
                        name="additional_data.back_up_email"
                        readonly={isSupervisor}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Age Group"
                        placeholder={"Age Group"}
                        className={classes.inputFields}
                        name="ageGroup"
                        readonly={isSupervisor}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Job Position"
                        placeholder={"Job Position"}
                        className={classes.inputFields}
                        name="additional_data.job_position"
                        readonly={isSupervisor}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Company Name"
                        placeholder={"Company Name"}
                        className={classes.inputFields}
                        name="additional_data.company_name"
                        readonly={isSupervisor}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Company Code"
                        placeholder={"Company Code"}
                        className={classes.inputFields}
                        name="additional_data.company_code"
                        readonly={isSupervisor}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Level Test"
                        placeholder="Level Test"
                        className={classes.inputFields}
                        name="level_test"
                        readonly={isSupervisor}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Post Level Test"
                        placeholder="Post Level Test"
                        className={classes.inputFields}
                        name="post_level"
                        readonly={isSupervisor}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Registration Date"
                        placeholder={"created_at"}
                        className={classes.inputFields}
                        name="created_at"
                        readonly
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Student ID"
                        placeholder={"Student Id"}
                        className={classes.inputFields}
                        name="_id"
                        readonly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{ marginBottom: "2rem" }}
                        className={classes.inputFields}
                      >
                        <InputLabel>
                          <span>Class State</span>
                        </InputLabel>
                        {matchmakingQuestions &&
                          matchmakingQuestions.length &&
                          matchmakingQuestions.map((question) => {
                            let answers =
                              studentDetail &&
                              studentDetail.matchmakingAnswers &&
                              studentDetail.matchmakingAnswers.length
                                ? studentDetail.matchmakingAnswers.filter(
                                    (answer) =>
                                      answer.questionId === question._id
                                  )[0].answer
                                : [];
                            return (
                              <ExpandMenu
                                className={classes.expandRoot}
                                header={<p>{question.title}</p>}
                                content={
                                  <div className={classes.expandContent}>
                                    {answers && answers.length ? (
                                      answers.map((ans: any) => (
                                        <CheckboxWithLabel
                                          checked={true}
                                          label={ans.label}
                                        />
                                      ))
                                    ) : (
                                      <Alert hideButton>
                                        <span>Not answered yet</span>
                                      </Alert>
                                    )}
                                  </div>
                                }
                              />
                            );
                          })}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{ marginBottom: "2rem" }}
                        className={classes.inputFields}
                      >
                        <InputLabel>
                          <span>Chosen Tags</span>
                        </InputLabel>
                        <div>
                          {studentDetail?.ideal_tutor_tags.length ? (
                            studentDetail?.ideal_tutor_tags.map((tag) => (
                              <Chip
                                color="primary"
                                label={tag.name && tag.name.en}
                                icon={<FaCheck />}
                              />
                            ))
                          ) : (
                            <p className={classes.NoResult}>
                              No tags was choosen
                            </p>
                          )}
                        </div>
                      </div>
                    </Grid>

                    {!values?.corporatePackages.length && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div
                          style={{ marginBottom: "2rem" }}
                          className={classes.inputFields}
                        >
                          <InputLabel>
                            <span>Current Packages</span>
                          </InputLabel>
                          <div className={classes.FreePackage}>
                            <ExpandMenu
                              className={classes.expandRootPackage}
                              header={<p>Select a package</p>}
                              content={allPackages
                                .filter((pa) => pa.name?.en !== "Level Test")
                                .map((pack) => (
                                  <div className={classes.expandContent}>
                                    <CheckboxWithLabel
                                      label={`${
                                        pack.classes_count === 1
                                          ? "Single Class"
                                          : pack.name?.en
                                      } : ${pack.class_duration_number}Min ${
                                        pack.classes_count === 1
                                          ? ``
                                          : `${pack.classes_count} Credits`
                                      }`}
                                      checked={selectedPackage === pack._id}
                                      onChange={() => {
                                        setSelectedPackage(pack._id);
                                      }}
                                    />
                                  </div>
                                ))}
                            />
                            <Button
                              className={classes.AddPackageButton}
                              color="primary"
                              variant="contained"
                              onClick={addPackage}
                            >
                              <span>Add</span>
                            </Button>
                          </div>
                          {values?.currentPackages.length ? (
                            values?.currentPackages.map((pac) => {
                              return (
                                <div className={classes.FreePackage}>
                                  <ExpandMenu
                                    className={classes.expandRootPackage}
                                    header={
                                      <p>
                                        {pac.class_duration_number}Min{" "}
                                        {pac.is_single_class
                                          ? "Free"
                                          : pac.classes_remaining === 1
                                          ? "Single Class"
                                          : pac.package_name}
                                      </p>
                                    }
                                    content={
                                      <div className={classes.expandContent}>
                                        <div className={classes.firstRow}>
                                          <CheckboxWithLabel
                                            label={`${pac.classes_remaining}/${pac.total_classes} Credits Remaining`}
                                            checked={true}
                                          />
                                          <div
                                            className={classes.ActionButtons}
                                          >
                                            <Button
                                              className={
                                                classes.AddCreditButton
                                              }
                                              color="primary"
                                              variant="contained"
                                              onClick={() => {
                                                let updated = [
                                                  ...values?.currentPackages,
                                                ];
                                                let index = updated.findIndex(
                                                  (u) => u._id === pac._id
                                                );
                                                if (
                                                  index > -1 &&
                                                  updated[index]
                                                    .classes_remaining <
                                                    updated[index].total_classes
                                                ) {
                                                  let packageToChange = {
                                                    ...updated[index],
                                                  };
                                                  packageToChange.classes_remaining += 1;
                                                  updated[index] =
                                                    packageToChange;

                                                  setFieldValue(
                                                    "currentPackages",
                                                    updated
                                                  );
                                                }
                                              }}
                                            >
                                              <span>+</span>
                                            </Button>
                                            <Button
                                              className={
                                                classes.RemoveCreditButton
                                              }
                                              color="primary"
                                              variant="contained"
                                              onClick={() => {
                                                let updated = [
                                                  ...values?.currentPackages,
                                                ];
                                                let index = updated.findIndex(
                                                  (u) => u._id === pac._id
                                                );
                                                if (
                                                  index > -1 &&
                                                  updated[index]
                                                    .classes_remaining > 0
                                                ) {
                                                  let packageToChange = {
                                                    ...updated[index],
                                                  };
                                                  packageToChange.classes_remaining -= 1;
                                                  updated[index] =
                                                    packageToChange;

                                                  setFieldValue(
                                                    "currentPackages",
                                                    updated
                                                  );
                                                }
                                              }}
                                            >
                                              <span>-</span>
                                            </Button>
                                          </div>
                                        </div>
                                        <div className={classes.BottomRow}>
                                          <p>Validity Date</p>
                                          <DateTimePicker
                                            value={pac.validity_date}
                                            className={classes.TimePicker}
                                            setFieldValue={(date) => {
                                              let updated = [
                                                ...values?.currentPackages,
                                              ];
                                              let index = updated.findIndex(
                                                (u) => u._id === pac._id
                                              );
                                              if (index > -1) {
                                                let packageToChange = {
                                                  ...updated[index],
                                                };
                                                packageToChange.validity_date =
                                                  moment(date).toISOString(
                                                    true
                                                  );
                                                updated[index] =
                                                  packageToChange;

                                                setFieldValue(
                                                  "currentPackages",
                                                  updated
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    }
                                  />
                                  <Button
                                    className={classes.AddPackageButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      updatePackage(
                                        pac.classes_remaining,
                                        pac.validity_date,
                                        pac._id
                                      )
                                    }
                                  >
                                    <span>Save</span>
                                  </Button>
                                </div>
                              );
                            })
                          ) : (
                            <p className={classes.NoResult}>
                              No package was bought by this student
                            </p>
                          )}
                        </div>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{ marginBottom: "2rem" }}
                        className={classes.inputFields}
                      >
                        <InputLabel>
                          <span>Corporate Packages</span>
                        </InputLabel>

                        {values?.corporatePackages.length ? (
                          values?.corporatePackages.map((pac) => {
                            return (
                              <div className={classes.FreePackage}>
                                <ExpandMenu
                                  className={classes.expandRootPackage}
                                  header={
                                    <p>
                                      {pac.class_duration_number}Min{" "}
                                      {pac.is_single_class
                                        ? "Free"
                                        : pac.classes_remaining === 1
                                        ? "Single Class"
                                        : pac.package_name}
                                    </p>
                                  }
                                  content={
                                    <div className={classes.expandContent}>
                                      <div className={classes.firstRow}>
                                        <CheckboxWithLabel
                                          label={`${pac.classes_remaining}/${pac.total_classes} Credits Remaining`}
                                          checked={true}
                                        />
                                        <div className={classes.ActionButtons}>
                                          <Button
                                            className={classes.AddCreditButton}
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                              let updated = [
                                                ...values?.corporatePackages,
                                              ];
                                              let index = updated.findIndex(
                                                (u) => u._id === pac._id
                                              );
                                              if (
                                                index > -1 &&
                                                updated[index]
                                                  .classes_remaining <
                                                  updated[index].total_classes
                                              ) {
                                                let packageToChange = {
                                                  ...updated[index],
                                                };
                                                packageToChange.classes_remaining += 1;
                                                updated[index] =
                                                  packageToChange;

                                                setFieldValue(
                                                  "corporatePackages",
                                                  updated
                                                );
                                              }
                                            }}
                                          >
                                            <span>+</span>
                                          </Button>
                                          <Button
                                            className={
                                              classes.RemoveCreditButton
                                            }
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                              let updated = [
                                                ...values?.corporatePackages,
                                              ];
                                              let index = updated.findIndex(
                                                (u) => u._id === pac._id
                                              );
                                              if (
                                                index > -1 &&
                                                updated[index]
                                                  .classes_remaining > 0
                                              ) {
                                                let packageToChange = {
                                                  ...updated[index],
                                                };
                                                packageToChange.classes_remaining -= 1;
                                                updated[index] =
                                                  packageToChange;

                                                setFieldValue(
                                                  "corporatePackages",
                                                  updated
                                                );
                                              }
                                            }}
                                          >
                                            <span>-</span>
                                          </Button>
                                        </div>
                                      </div>
                                      <div className={classes.BottomRow}>
                                        <p>Validity Date</p>
                                        <DateTimePicker
                                          value={pac.validity_date}
                                          className={classes.TimePicker}
                                          setFieldValue={(date) => {
                                            let updated = [
                                              ...values?.corporatePackages,
                                            ];
                                            let index = updated.findIndex(
                                              (u) => u._id === pac._id
                                            );
                                            if (index > -1) {
                                              let packageToChange = {
                                                ...updated[index],
                                              };
                                              packageToChange.validity_date =
                                                moment(date).toISOString(true);
                                              updated[index] = packageToChange;

                                              setFieldValue(
                                                "corporatePackages",
                                                updated
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  }
                                />
                                <Button
                                  className={classes.AddPackageButton}
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    updatePackage(
                                      pac.classes_remaining,
                                      pac.validity_date,
                                      pac._id
                                    )
                                  }
                                >
                                  <span>Save</span>
                                </Button>
                              </div>
                            );
                          })
                        ) : (
                          <p className={classes.NoResult}>
                            Not corporate student
                          </p>
                        )}
                      </div>
                    </Grid>

                    {!isSupervisor && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div
                          style={{ marginBottom: "2rem" }}
                          className={classes.inputFields}
                        >
                          <InputLabel>
                            <span>Add Free Package</span>
                          </InputLabel>
                          <div className={classes.FreePackage}>
                            <ExpandMenu
                              className={classes.expandRootPackage}
                              header={<p>Select a free package</p>}
                              content={freePackages.map((pack) => (
                                <div
                                  className={[
                                    classes.firstRow,
                                    classes.expandContent,
                                  ].join(" ")}
                                >
                                  <CheckboxWithLabel
                                    label={`${pack.class_duration_number}Min free ${pack.classes_count}Credits`}
                                    checked={selectedPackage === pack._id}
                                    onChange={() => {
                                      setSelectedPackage(pack._id);
                                    }}
                                  />
                                </div>
                              ))}
                            />
                            <Button
                              className={classes.AddPackageButton}
                              color="primary"
                              variant="contained"
                              onClick={addPackage}
                            >
                              <span>Add</span>
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={classes.ScoresRoot}>
                        <div className={classes.ScoresHeader}>
                          <h2>Average Skill Scores</h2>
                          <h2>{studentDetail?.classCount} Classes</h2>
                        </div>
                        <div className={classes.ScoresInfoContainer}>
                          <div className={classes.ScoresInfoItem}>
                            <p>Fluency</p>
                            <h4>
                              {studentDetail?.current_result_average
                                ?.speaking || 0}
                            </h4>
                          </div>
                          <div className={classes.ScoresInfoItem}>
                            <p>Grammar</p>
                            <h4>
                              {studentDetail?.current_result_average?.grammar ||
                                0}
                            </h4>
                          </div>
                          <div className={classes.ScoresInfoItem}>
                            <p>Listening</p>
                            <h4>
                              {studentDetail?.current_result_average
                                ?.listening || 0}
                            </h4>
                          </div>
                          <div className={classes.ScoresInfoItem}>
                            <p>Pronunciation</p>
                            <h4>
                              {studentDetail?.current_result_average
                                ?.pronunciation || 0}
                            </h4>
                          </div>
                          <div className={classes.ScoresInfoItem}>
                            <p>Vocabulary</p>
                            <h4>
                              {studentDetail?.current_result_average
                                ?.vocabulary || 0}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {studentDetail &&
                        studentDetail?.corporate &&
                        (studentDetail.level_test_detail ||
                          studentDetail.post_level_test_detail) && (
                          <InputLabel
                            labelButton={
                              <Button
                                variant="contained"
                                padding="0 2.28rem"
                                type="button"
                                onClick={() => {
                                  if (editMode) {
                                    setFieldValue(
                                      "level_test_detail",
                                      studentDetail.level_test_detail
                                    );
                                    setFieldValue(
                                      "post_level_test_detail",
                                      studentDetail.post_level_test_detail
                                    );
                                  }
                                  setEditMode(!editMode);
                                }}
                              >
                                <span>
                                  {editMode ? "Reset Changes" : "Edit Mode"}
                                </span>
                              </Button>
                            }
                          >
                            <span>Level Test Score</span>
                          </InputLabel>
                        )}
                      {studentDetail?.corporate && (
                        <ScoresLayout
                          editMode={
                            editMode ||
                            (!studentDetail.level_test_detail &&
                              !studentDetail.post_level_test_detail)
                          }
                          title="Pre Test Score"
                          secondTitle="Class Details"
                          setFieldValue={(field, value) => {
                            setFieldValue(`level_test_detail.${field}`, value);
                          }}
                          data={values?.level_test_detail}
                        />
                      )}
                    </Grid>

                    {studentDetail?.corporate && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ScoresLayout
                          editMode={
                            editMode ||
                            (!studentDetail.level_test_detail &&
                              !studentDetail.post_level_test_detail)
                          }
                          title="Post Test Score"
                          secondTitle="Class Details"
                          setFieldValue={(field, value) => {
                            setFieldValue(
                              `post_level_test_detail.${field}`,
                              value
                            );
                          }}
                          data={values?.post_level_test_detail}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>
                          {values.level_test_status === "no_level_test"
                            ? "No Level Test"
                            : "With Level Test"}
                        </span>
                      </InputLabel>
                      <IOSSwitch
                        checked={
                          values.level_test_status === "no_level_test"
                            ? false
                            : true
                        }
                        onChange={(e, value) => {
                          setFieldValue(
                            "level_test_status",
                            value ? "initiated" : "no_level_test"
                          );
                          dispatch(
                            StudentActions.updateStudent(values._id, {
                              level_test_status: value
                                ? "initiated"
                                : "no_level_test",
                              post_level_test_status: value
                                ? "initiated"
                                : "no_level_test",
                            })
                          );
                        }}
                      />
                    </Grid>
                    {!isSupervisor && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div
                          style={{ marginBottom: "2rem" }}
                          className={classes.inputFields}
                        >
                          <InputLabel>
                            <span>Invitations</span>
                          </InputLabel>
                          <div className={classes.infoBox}>
                            <p>{invitationAll ? invitationAll : 0} People</p>
                            <p>
                              {invitationSignedUp ? invitationSignedUp : 0}{" "}
                              Signed up
                            </p>
                            <p>{invitationPaid ? invitationPaid : 0} Paid</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!isSupervisor && (
                  <Grid
                    justify="flex-end"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Button
                      className={classes.AddLevelTest}
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <span>Update Student Detail</span>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
