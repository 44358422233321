import React from "react";
import classes from "./AddAnnouncements.module.css";
import { Grid } from "@material-ui/core";

import {
  ImagePicker,
  FormikTextField,
  FormikTextArea,
  Separator,
  InputLabel,
  DatePicker,
  LanguageFormControls,
  Button,
} from "../../Common";
import { Formik, Form } from "formik";
import {
  LanguageVariantType,
  eventInitialState,
  EventType_Payload,
  EventType,
} from "../../../Types";
import * as yup from "yup";
import moment from "moment";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../../Redux/Actions/appStatusActions";
import useFormAdderTools from "../../Hooks/useFormAdderTools";
import SelectInput from "../../Common/SelectInput";

const validationSchema = yup.object({
  roomLink: yup.string().url("Invalid URL"),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  eventToEdit: {
    [key: string]: EventType;
  };
  onSubmitForm: (eventData: EventType_Payload) => void;
}

export const AddAnnouncements = (props: Props) => {
  const { lang, setSelectedLanguage, eventToEdit, onSubmitForm } = props;
  const dispatch = useDispatch();
  const [initialValues, onResetForm] = useFormAdderTools<EventType_Payload>({
    itemToEdit: eventToEdit,
    lang,
    defaultValues: eventInitialState as EventType_Payload,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        const startDate = moment(values.startDate);
        const endDate = moment(values.endDate);
        const startHour = values.startTime.split(":")[0];
        const startMinute = values.startTime.split(":")[1];
        const endHour = values.endTime.split(":")[0];
        const endMinute = values.endTime.split(":")[1];
        startDate.hours(parseInt(startHour)).minutes(parseInt(startMinute));
        endDate.hours(parseInt(endHour)).minutes(parseInt(endMinute));

        const ifOnTheSameDate = endDate.diff(startDate) <= 0;
        if (ifOnTheSameDate) {
          dispatch(
            openSnackBar({
              open: true,
              message: "invalid Start and End date",
              variant: "error",
            })
          );
        } else {
          onSubmitForm(values);
        }
      }}
    >
      {({ values, setFieldValue, errors }) => {
        const addButtonDisabled =
          Object.keys(errors).length > 0 ||
          JSON.stringify(values) === JSON.stringify(eventToEdit[lang]);

        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ImagePicker
                    hints={[
                      "* Image shouldn’t be larger than1000 x 1000 pixels.",
                      "* Make sure to use appropriate and relevant imagery",
                    ]}
                    className={classes.inputFields}
                    value={values.image || undefined}
                    fileSizeLimit={700000}
                    onFileChoose={(file) => setFieldValue("image", file)}
                    onCleanFile={() => setFieldValue("image", null)}
                  />

                  <FormikTextField
                    label="Announcement Title*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="eventTitle"
                  />
                  <div className={classes.inputFields}>
                    <FormikTextArea
                      label="Announcement Description HTML*"
                      rows={10}
                      placeholder={"Html..."}
                      name="roomLink"
                    />
                    <div className="d-flex mt-2">
                      <Button variant="outlined">
                        <span className="px-5">Preview</span>
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Category*</span>
                      </InputLabel>
                      <SelectInput
                        options={[]}
                        label={""}
                        handleChange={() => {}}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Target Users*</span>
                      </InputLabel>
                      <SelectInput
                        options={[]}
                        label={""}
                        handleChange={() => {}}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Start Date*</span>
                      </InputLabel>
                      <DatePicker
                        minDate={new Date()}
                        maxDate={
                          values.endDate ? new Date(values.endDate) : undefined
                        }
                        selectedDate={
                          values.startDate && values.startDate !== ""
                            ? new Date(values.startDate)
                            : new Date()
                        }
                        onChange={(newDate) => {
                          setFieldValue("startDate", newDate?.toISOString());
                        }}
                        className={classes.inputFields}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>End Date*</span>
                      </InputLabel>
                      <DatePicker
                        minDate={
                          values.startDate
                            ? new Date(values.startDate)
                            : new Date()
                        }
                        selectedDate={
                          values.endDate && values.endDate !== ""
                            ? new Date(values.endDate)
                            : new Date()
                        }
                        onChange={(newDate) => {
                          setFieldValue("endDate", newDate?.toISOString());
                        }}
                        className={classes.inputFields}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Separator />
            <LanguageFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              englishChecked={eventToEdit["en"] !== undefined}
              koreanChecked={eventToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                eventToEdit[lang] !== undefined ? "Edit Event" : "Add Event"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
