import React from "react";
//import classes from "./LearningMaterialScreen.module.css";
import { ColumnDescription } from "react-bootstrap-table-next";
import { DashboardPageState, Member, Role } from "../../Types";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
//import AudioSelector from "./../../Components/Common/AudioSelector/AudioSelector";
import { AddMember } from "./../../Components/Members/AddMember/AddMember";
import { Member_Payload } from "../../Types/UserAndRoles";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  memberList: Member[];
  roleList: Role[];
  onSubmit: (data: Member_Payload) => void;
  memberToEdit: Member | null;
  onEditMemberClicked: (data: Member) => void;
}

export const MembersScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    memberList,
    roleList,
    onSubmit,
    memberToEdit,
    onEditMemberClicked,
  } = props;

  const RenderAddFlow = () => {
    return (
      <>
        <AddMember
          allRoles={roleList}
          onSubmit={onSubmit}
          dataToEdit={memberToEdit}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a New Member";
      case "edit":
        return "Member Info";
      default:
        return "The Wonder Team";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      EditFlowContent={() => RenderAddFlow()}
      data={memberList}
      onEditCell={onEditMemberClicked}
    />
  );
};
