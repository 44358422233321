import { MainRouteType } from "./../../../Types/ConfigTypes";
export const UsersRoute: MainRouteType = {
  key: "Users",
  title: "User",
  path: "/users/students",
  subRoutes: [
    {
      key: "Users",
      title: "User",
      path: "/users/students",
    },
    {
      key: "PreferredTags",
      title: "Preferred Tag",
      path: "/users/tags",
    },
    {
      key: "MatchmakingQuestions",
      title: "Matchmaking Question",
      path: "/users/matchmaking",
    },
  ],
};
