import BaseApi from "../BaseAPI";

class CommonService extends BaseApi {
  constructor() {
    super({ suffix: "account/common" });
  }
  uploadFormData(data: FormData) {
    return this.httpService.post(`upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        responseType: "blob",
      },
    });
  }

  getGalleryData = () => {
    return this.httpService.get("gallery");
  };

  getMatchmakingQuestions = (userType: string) => {
    return this.httpService.get(`questions?user_type=${userType}`);
  };
}

const CommonServiceInstance = new CommonService();
export default CommonServiceInstance;
