import React, { ReactNode } from "react";

import classes from "./Button.module.css";

//! when full height is true the button height will be fit to the container

interface Props {
  children: ReactNode;
  variant?: "contained" | "outlined" | "default" | "link";
  color?: "primary" | "danger";
  disabled?: boolean;
  freeHeight?: boolean;
  className?: string;
  onClick?: (event: any, place?: string) => void;
  type?: "button" | "submit" | "reset";
  height?: string;
  padding?: string;
}

export const Button = (props: Props) => {
  const {
    className,
    children,
    disabled,
    freeHeight,
    variant,
    onClick,
    type,
    color,
    height,
    padding
  } = props;
  const buttonClassName = [
    classes[`button_${variant}`],
    freeHeight ? classes[`freeHeightButton`] : "",
    classes[`color_${variant}_${color}`],
  ].join(" ");

  return (
    <div className={className}>
      <button
        type={type}
        style={{ height: height && height, padding: padding && padding }}
        onClick={(e) => onClick && onClick(e)}
        className={buttonClassName}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
};

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  type: "button",
};
