import { DashboardPageState } from "../../../Types";
import { Button } from "../../Common";
import classes from "./MemberFormControl.module.css";
import React from "react";

interface Props {
  pageState: DashboardPageState;
  className?: string;
  onClearButtonClicked?: () => void;
  isSubmitDisable?: boolean;
}

export const MemberFormControl = ({
  pageState,
  className,
  onClearButtonClicked,
  isSubmitDisable,
}: Props) => {
  if (pageState === "add" || pageState === "edit") {
    return (
      <div className={className}>
        <div className={classes.Root}>
          <Button
            onClick={onClearButtonClicked}
            className={classes.inputs}
            variant="outlined"
          >
            <span>Clear</span>
          </Button>
          <Button disabled={isSubmitDisable} type="submit" variant="contained">
            <span>{pageState === "add" ? "Add Member" : "Edit Member"}</span>
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
