import React, { useState, useEffect } from "react";
import classes from "./StudentReviewCard.module.css";
import { Button } from "@material-ui/core";
import StarRate from "@material-ui/lab/Rating";
import { useFormik } from "formik";
import { ClassType, StudentReviewType } from "../../../Types";
import { IconGenerator, TextInput } from "../../Common";
import { Disclaimer } from "../../Common/Disclaimer/Disclaimer";

export interface Props {
  open?: boolean;
  onClose: () => void;
  classData: ClassType;
}
export const StudentReviewCard = ({ open, onClose, classData }: Props) => {
  const [initialValues, setInitialValues] = useState<StudentReviewType>({
    classRate: 0,
    tutorRate: 0,
    learningMaterialRate: 0,
    description: "",
    haveProblem: false,
    problem: null,
    problemDescription: "",
  });

  const formik = useFormik<StudentReviewType>({
    initialValues: initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values } = formik;

  const onCloseTestimonial = () => {
    onClose();
  };

  useEffect(() => {
    if (classData && classData.reviewedByStudent) {
      setInitialValues(classData.reviewedByStudent);
    }
  }, [classData]);

  if (classData && !classData.reviewedByStudent) {
    return null;
  }

  const _renderClassReview = () => {
    return (
      <>
        <div className={classes.StarRateRoot}>
          <p>Class Rating</p>
          <StarRate
            value={values.classRate}
            emptyIcon={
              <IconGenerator iconName="star" className={classes.EmptyStar} />
            }
            icon={
              <IconGenerator iconName="star" className={classes.FullStar} />
            }
            readOnly
          />
        </div>
        <div style={{ minHeight: "3.57rem" }} />
        <div className={classes.StarRateRoot}>
          <p>Tutor Rating</p>
          <StarRate
            value={values.tutorRate}
            emptyIcon={
              <IconGenerator iconName="star" className={classes.EmptyStar} />
            }
            icon={
              <IconGenerator iconName="star" className={classes.FullStar} />
            }
            readOnly
          />
        </div>
        <div style={{ minHeight: "3.57rem" }} />
        <div className={classes.StarRateRoot}>
          <p>Material Rating</p>
          <StarRate
            value={values.learningMaterialRate}
            emptyIcon={
              <IconGenerator iconName="star" className={classes.EmptyStar} />
            }
            icon={
              <IconGenerator iconName="star" className={classes.FullStar} />
            }
            readOnly
          />
        </div>
        {values.description && (
          <Disclaimer label="Description" content={values.description} />
        )}

        {values.problem ? (
          <div style={{ marginTop: "4.28rem" }}>
            <div className={classes.IssueReport}>
              <IconGenerator iconName="error" />
              <p>{values.problem}</p>
            </div>
            {values.problemDescription ? (
              <Disclaimer
                label="Description"
                content={values.problemDescription}
              />
            ) : null}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className={classes.FormRoot}>
      <div className={classes.HeaderContainer}>
        <h2>Student Review</h2>
        <div className="d-flex align-center">
          <Button className={classes.ExitBtn} onClick={onCloseTestimonial}>
            <IconGenerator iconName="close-box" className={classes.CloseIcon} />
          </Button>
        </div>
      </div>
      <form className={classes.FormContainer}>
        <div className={classes.ReviewListContainer}>
          {_renderClassReview()}
        </div>
      </form>
    </div>
  );
};
