import React from "react";
import classes from "./LanguageFormControll.module.css";
import { BorderedCheckbox, Button } from "../../Common";
import { LanguageVariantType } from "../../../Types";

export interface Props {
  onAddClick?: () => void;
  onClearClick?: () => void;
  addButtonDisabled?: boolean;
  clearButtonDisabled?: boolean;
  englishChecked?: boolean;
  koreanChecked?: boolean;
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (type: LanguageVariantType) => void;
  className?: string;
  submitButtonText: string;
  clearButtonText: string;
  notMultiLang?: boolean;
}

export const LanguageFormControls = (props: Props) => {
  const {
    className,
    onAddClick,
    onClearClick,
    addButtonDisabled,
    clearButtonDisabled,
    englishChecked,
    koreanChecked,
    selectedLanguage,
    setSelectedLanguage,
    submitButtonText,
    clearButtonText,
    notMultiLang,
  } = props;
  return (
    <div className={className}>
      <div className={classes.Root}>
        {!notMultiLang && (
          <>
            <BorderedCheckbox
              className={classes.checkboxItem}
              label="English"
              checked={englishChecked}
              isSelected={selectedLanguage === "en"}
              onClick={() => setSelectedLanguage("en")}
            />
            <BorderedCheckbox
              className={classes.checkboxItem}
              label="Korean"
              checked={koreanChecked}
              isSelected={selectedLanguage === "ko"}
              onClick={() => setSelectedLanguage("ko")}
            />
          </>
        )}
        <Button
          disabled={clearButtonDisabled}
          onClick={onClearClick}
          className={[classes.checkboxItem, classes.buttonClass].join(" ")}
          variant="outlined"
          type="reset"
        >
          <span>{clearButtonText}</span>
        </Button>
        <Button
          disabled={addButtonDisabled}
          onClick={onAddClick}
          className={classes.buttonClass}
          variant="contained"
          type="submit"
        >
          <span>{submitButtonText}</span>
        </Button>
      </div>
    </div>
  );
};
