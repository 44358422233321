import React from "react";
import Store from "./Redux/Store";

import { Provider as ReduxProvider } from "react-redux";

import AppContainer from "./AppContainer";
import "./Styles/main.scss";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ReduxProvider store={Store}>
        <AppContainer />
      </ReduxProvider>
    </BrowserRouter>
  );
}

export default App;
