import React from "react";
import { useDashboardPageLocalData } from "./../../Components/Hooks";

import { ColumnDescription } from "react-bootstrap-table-next";
import { FeatureBannerScreen } from "./FeatureBannerScreen";
import { FeatureBannerType_Payload } from "../../Types";
import { useTypedSelector } from "../../Redux/Reducers/index";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "image",
    text: "Image",
  },
  {
    dataField: "title",
    text: "Title",
  },
  {
    dataField: "subTitle",
    text: "Description",
  },
  {
    dataField: "category",
    text: "Upload Date",
  },
];

export const FeatureBannerScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();
  const featureBannerToEdit = useTypedSelector(
    (state) => state.featureBanner.featureBannerToEdit
  );

  const onSubmitForm = (values: FeatureBannerType_Payload) => {
    if (pageState === "add") {
    } else if (pageState === "edit") {
    }
  };

  return (
    <FeatureBannerScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      onSubmitForm={onSubmitForm}
      featureBannerToEdit={featureBannerToEdit}
    />
  );
};
