import { InterviewRejection_Payload, TutorDetailType } from "../../../Types";
import { AcceptInterview_Payload, ChooseInterviewer_Payload, QualifyTutor_Payload } from "../../../Types/interview";
import BaseApi from "../BaseAPI";

class TutorServices extends BaseApi {
  constructor() {
    super({ suffix: "" });
  }

  getAllTutors = () => {
    return this.httpService.get("admin/tutors");
  };
  getTutorDetails = (id: string) => {
    return this.httpService.get("admin/tutors/" + id);
  };
  banUser = (id: string, duration: number) => {
    return this.httpService.patch(`ban/${id}`, { duration });
  };
  unBanUser = (id: string) => {
    return this.httpService.patch(`unban/${id}`);
  };

  getTutorInterviewApplications = () => {
    return this.httpService.get("admin/get_all_interview_applications");
  };
  getTutorApplicationDetail = (id: string) => {
    return this.httpService.get("admin/tutors/" + id);
  };

  getRejectionReasons = () => {
    return this.httpService.get("");
  };

  rejectTutorApplication = (data: InterviewRejection_Payload) => {
    return this.httpService.patch("admin/edit_book_interview", data);
  };

  acceptTutorApplicationRequest = (data: AcceptInterview_Payload) => {
    return this.httpService.patch("admin/edit_book_interview", data);
  };

  chooseApplicationInterviewer = (
    id: string,
    data: ChooseInterviewer_Payload
  ) => {
    return this.httpService.patch(id, data);
  };

  changeTutorInterviewStatus = (data: QualifyTutor_Payload) => {
    return this.httpService.patch("admin/edit_book_interview", data);
  };

  getTutorRates = (id: string) => {
    return this.httpService.get(id);
  };

  toggleTutor = (id: string) => {
    return this.httpService.get(`admin/tutor/show/${id}`);
  };

  updateTutorDetail = (id: string, dataToUpdate: Partial<TutorDetailType>) => {
    return this.httpService.patch(`admin/tutor/edit/${id}`, { ...dataToUpdate });
  };
}

const TutorServicesInstance = new TutorServices();
export default TutorServicesInstance;
