import React from "react";
import classes from "./InterviewState.module.css";
import { Button } from "../../Common";
import { InterviewStatusType, InterviewType } from "../../../Types";
import moment from "moment-timezone";

interface Props {
  className?: string;
  state: InterviewStatusType;
  onRejectRequest?: () => void;
  onAcceptRequest?: () => void;
  onQualifyTutor?: () => void;
  onRejectQualify?: () => void;
  onSetTimeClicked?: () => void;
  onSelectInterviewer?: () => void;
  onRejectInterviewTime?: () => void;
  onInterviewDone?: () => void;
  interviewData: InterviewType;
}

export const InterviewState = (props: Props) => {
  const {
    className,
    state,
    onAcceptRequest,
    onRejectQualify,
    onQualifyTutor,
    onRejectRequest,
    onSetTimeClicked,
    onSelectInterviewer,
    onInterviewDone,
    onRejectInterviewTime,

    interviewData,
  } = props;
  const titleText = () => {
    switch (state) {
      case "CONFIRMED":
        return "Interview Done";
      case "QUALIFY":
        return "User Qualified";
      case "REJECTED":
        return "Interview Rejected";
      case "COMPLETED":
        return "Interview is accepted";
      default:
        return "Interview Request";
    }
  };

  const mainText = () => {
    switch (state) {
      case "COMPLETED":
        return (
          <span className={classes.Text}>
            The Interview is going to be for
            <span>
              {" "}
              {moment(interviewData.date).format("MMM Do, YYYY")} at{" "}
              {interviewData.startTime}
            </span>
            .
          </span>
        );
      case "CONFIRMED":
        return (
          <span className={classes.Text}>
            The Interview was held in
            <span>
              {" "}
              {moment(interviewData.date).format("MMM Do, YYYY")} at{" "}
              {moment(interviewData.startTime).format("h:mm A")}
            </span>
            .Do you qualify this user?
          </span>
        );
      case "QUALIFY":
        return (
          <span className={classes.Text}>
            The Interview was held on{" "}
            <span>
              {moment(interviewData.date).format("MMM Do, YYYY")} at{" "}
              {moment(interviewData.startTime).format("h:mm A")}{" "}
            </span>
            .User was qualified on {" "}
            <span>
              {moment(interviewData.qualifiedTime).format("MMM Do, YYYY")} at{" "}
              {moment(interviewData.qualifiedTime).format("h:mm A")}{" "}
            </span>
          </span>
        );
      case "REJECTED":
      case "NOT_QUALIFY":
        return (
          <>
            <span className={classes.Text}>
              The interview Request is rejected.
            </span>
            <p className={classes.Text}>
              {`Rejection Reason :`}{" "}
              <span>{interviewData.rejectionReason}</span>
            </p>
            <p className={classes.Text}>
              {`message :`} <span>{interviewData.message}</span>
            </p>
          </>
        );
      default:
        return (
          <span className={classes.Text}>
            This user submited this Interview Request for an interview
            <span> {interviewData.preferredDate}</span>, Preferably Between{" "}
            <span>{interviewData.preferredTime}</span>.
          </span>
        );
    }
  };

  const renderButtons = () => {
    switch (state) {
      case "COMPLETED":
        return (
          <div className="d-flex justify-content-end mt-5">
            <Button
              onClick={onRejectInterviewTime}
              variant="outlined"
              className="mr-3"
            >
              <div className="p-4">
                <span>Cancel Interview</span>
              </div>
            </Button>
            <Button onClick={onInterviewDone} variant="contained">
              <div className="p-5">
                <span>Interview is Done</span>
              </div>
            </Button>
          </div>
        );
      case "CONFIRMED":
        return (
          <div className="d-flex justify-content-end mt-5">
            <Button
              onClick={onRejectQualify}
              variant="outlined"
              className="mr-3"
            >
              <div className="p-4">
                <span>Reject</span>
              </div>
            </Button>
            <Button onClick={onQualifyTutor} variant="contained">
              <div className="p-5">
                <span>Qualify</span>
              </div>
            </Button>
          </div>
        );
      case "QUALIFY":
        return null;
      case "PENDING":
        return (
          <div className="d-flex justify-content-end mt-5">
            <Button
              onClick={onRejectRequest}
              variant="outlined"
              className="mr-3"
            >
              <div className="p-4">
                <span>Reject</span>
              </div>
            </Button>
            <Button onClick={onSetTimeClicked} variant="contained">
              <div className="p-5">
                <span>Set A Time</span>
              </div>
            </Button>
          </div>
        );
      case "REJECTED":
      case "NOT_QUALIFY":
        return (
          <div className="d-flex justify-content-end mt-5">
            <Button onClick={onSetTimeClicked} variant="contained">
              <div className="p-5">
                <span>Accept Application and Set A Time</span>
              </div>
            </Button>
          </div>
        );

      case "ACCEPTED":
        return (
          <div className="mt-5 d-flex justify-content-between align-items-center">
            <div>
              <h2 className={classes.Title}>{"Request Accepted by Tooti"}</h2>
              <span className={classes.Text}>
                the Interview will be conducted on
                <span> Feb 18th, 2021 at 18:00PM</span> by{" "}
                <span>Catherine Hong</span>.
              </span>
            </div>
            <Button
              onClick={onSelectInterviewer}
              variant="outlined"
              className="mr-3"
            >
              <div className="p-4">
                <span>Change Interviewer</span>
              </div>
            </Button>
          </div>
        );
      default:
        return (
          <div className="d-flex justify-content-end mt-5">
            <Button
              onClick={onRejectRequest}
              variant="outlined"
              className="mr-3"
            >
              <div className="p-4">
                <span>Reject</span>
              </div>
            </Button>
            <Button onClick={onAcceptRequest} variant="contained">
              <div className="p-5">
                <span>Accept</span>
              </div>
            </Button>
          </div>
        );
    }
  };
  return (
    <div className={className}>
      <div
        style={
          state === "REJECTED" || state === "NOT_QUALIFY"
            ? { backgroundColor: " #F9C744" }
            : undefined
        }
        className={
          state === "QUALIFY"
            ? [classes.Root, classes.QualifiedRoot].join(" ")
            : classes.Root
        }
      >
        <h2 className={classes.Title}>{titleText()}</h2>
        {mainText()}
        {renderButtons()}
      </div>
    </div>
  );
};
