import classes from "./AddMember.module.css";
import { FormHelperText, Grid } from "@material-ui/core";
import React, { useState } from "react";
import {
  ImagePicker,
  FormikTextField,
  Separator,
  FileSelector,
} from "../../Common";
import { Formik, Form, FieldArray } from "formik";
import {
  Member_Payload,
  memberInitialState,
  Role,
  Member,
} from "../../../Types";
import * as yup from "yup";
import { DatePicker } from "./../../Common/DatePicker/DatePicker";
import { InputLabel } from "./../../Common/InputLabel/InputLabel";
import { AddMemberIdType } from "../AddIdType/AddIdType";
import { MemberFormControl } from "../MemberFormControl/MemberFormControl";
import SelectInput from "./../../Common/SelectInput";
import {
  NationalityOptions,
  allGenderOptions,
  EmploymentTypeOptions,
} from "./../../../Config/Constants/SelectValues";
import { getMyFormikProps } from "../../../utils";
import { Alert } from "../../Common/Alert/Alert";

const validationSchema = yup.object({
  isEditMode: yup.boolean(),
  address: yup.string().required("address is require"),
  contract: yup.string().required("please select contract file"),
  dob: yup.string().required("please select birth date"),
  email: yup
    .string()
    .email("invalid email address")
    .required("please enter email address"),
  first_name: yup.string().required("name is required"),
  last_name: yup.string().required("last name is required"),
  gender: yup.string().required("please select gender"),
  nationality: yup.string().required("please select nationality"),
  phone_number: yup.string().required("Phone number is required"),
  profile_image: yup.string().required("please select an profile image"),
  role: yup.string().required("please select a role"),
  username: yup.string().required("nickname is required"),
  password: yup.mixed().when("isEditMode", {
    is: true,
    then: yup.string().min(8, "password should be more than 8 characters"),
    otherwise: yup
      .string()
      .required("password is required")
      .min(8, "password should be more than 8 characters"),
  }),

  ids: yup.array(
    yup.object().shape({
      id_types: yup.string().required("please enter an id Type"),
      file: yup.mixed().required("please select a file"),
    })
  ),
});

interface Props {
  dataToEdit: Member | null;
  allRoles: Role[];
  onSubmit: (data: Member_Payload) => void;
}

export const AddMember = ({ allRoles, onSubmit, dataToEdit }: Props) => {
  const [initialValues, setInitialValues] =
    useState<Member_Payload>(memberInitialState);

  const roleOptions = allRoles.map((role) => {
    return {
      value: role._id,
      label: role.title,
    };
  });

  React.useEffect(() => {
    if (dataToEdit) {
      setInitialValues({ ...dataToEdit, role: dataToEdit.role._id });
    } else {
      setInitialValues(memberInitialState);
    }
  }, [dataToEdit]);
  return (
    <Formik
      initialValues={{ ...initialValues, isEditMode: dataToEdit !== null }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { values, setFieldValue, touched, errors, resetForm } = formik;
        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ImagePicker
                        hints={[
                          "* Image shouldn’t be larger than 500 x 500 pixels.",
                          "* Maximum image size is 200kBs.",
                          "* Make sure to use appropriate and relevant imagery",
                        ]}
                        className={"mb-5"}
                        value={values.profile_image}
                        onFileChoose={(file) =>
                          setFieldValue("profile_image", file)
                        }
                        onCleanFile={() => setFieldValue("profile_image", null)}
                      />
                      {touched.profile_image &&
                        errors.profile_image !== undefined && (
                          <FormHelperText error={true}>
                            {errors.profile_image}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormikTextField
                        label="Nickname*"
                        placeholder={"nickname..."}
                        className={classes.inputFields}
                        name="username"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="First Name*"
                        placeholder={"First Name"}
                        className={classes.inputFields}
                        name="first_name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Last Name*"
                        placeholder={"Last Name"}
                        className={classes.inputFields}
                        name="last_name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Phone Number*"
                        placeholder={"(000) 11 222 3333..."}
                        className={classes.inputFields}
                        name="phone_number"
                        type="tel"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Email Address*"
                        placeholder={"example@example.com..."}
                        className={classes.inputFields}
                        name="email"
                        type="email"
                        readonly={dataToEdit !== null}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormikTextField
                        label="Address*"
                        placeholder={"Home, Work,..."}
                        className={classes.inputFields}
                        name="address"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Date of Birth*</span>
                      </InputLabel>
                      <DatePicker
                        selectedDate={values.dob || new Date()}
                        {...getMyFormikProps("dob", formik)}
                        className={classes.inputFields}
                        onChange={(newDate) => {
                          setFieldValue("dob", newDate?.toISOString());
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Gender*</span>
                      </InputLabel>
                      <SelectInput
                        value={values.gender}
                        variant={"outlined"}
                        handleChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                        options={allGenderOptions}
                        {...getMyFormikProps("gender", formik)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Employment Type*</span>
                      </InputLabel>
                      <SelectInput
                        value={values.employment_type}
                        variant={"outlined"}
                        className={classes.inputFields}
                        handleChange={(e) =>
                          setFieldValue("employment_type", e.target.value)
                        }
                        options={EmploymentTypeOptions}
                        {...getMyFormikProps("employment_type", formik)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Nationality*</span>
                      </InputLabel>
                      <SelectInput
                        value={values.nationality}
                        className={classes.inputFields}
                        variant={"outlined"}
                        handleChange={(e) =>
                          setFieldValue("nationality", e.target.value)
                        }
                        options={NationalityOptions}
                        {...getMyFormikProps("nationality", formik)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel>
                      <span>Role*</span>
                    </InputLabel>
                    <SelectInput
                      value={values.role}
                      variant={"outlined"}
                      className={classes.inputFields}
                      handleChange={(e) =>
                        setFieldValue("role", e.target.value)
                      }
                      options={roleOptions}
                      {...getMyFormikProps("role", formik)}
                    />
                    {/*<InterviewTimeSetter
                      isInterviewAssigned={values.isInterviewAssigned}
                      onAssignInterview={() => {
                        setFieldValue(
                          "isInterviewAssigned",
                          !values.isInterviewAssigned
                        );
                      }}
                    />*/}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileSelector
                      btnText="Add a File"
                      label="Contract"
                      limitSize={2000000}
                      className="mt-5"
                      value={values.contract}
                      onFileChoose={(file) => setFieldValue("contract", file)}
                      onClearClick={() => setFieldValue("contract", null)}
                    />
                    {touched.contract && errors.contract !== undefined && (
                      <FormHelperText
                        className={classes.inputFields}
                        error={true}
                      >
                        {errors.contract}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    className="mt-5"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FieldArray name="ids">
                      {({ remove, push }) => (
                        <AddMemberIdType
                          values={values.ids}
                          className={classes.inputFields}
                          onAddId={push}
                          onRemoveId={remove}
                        />
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {dataToEdit && (
                      <Alert hideButton className="mb-2">
                        <span>
                          Fill This only if you want to change password
                        </span>
                      </Alert>
                    )}
                    <FormikTextField
                      label={dataToEdit ? "New Password" : "Password*"}
                      placeholder={
                        dataToEdit ? "new password..." : "password..."
                      }
                      name="password"
                      type="password"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Separator className="mt-5" />
            <MemberFormControl
              className={classes.formControlBlock}
              pageState={dataToEdit ? "edit" : "add"}
              isSubmitDisable={Object.keys(errors).length > 0}
              onClearButtonClicked={() => resetForm()}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
