import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";

import ExtraTableFilters from "../../Components/Classes/ExtraTableFilters/ExtraTableFilters";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { StudentBrief } from "../../Components/Users/StudentBrief/StudentBrief";
import { ClassType, DashboardPageState, LanguageVariantType } from "../../Types";
import { ClassDetails } from "./../../Components/Classes/ClassesDetails/ClassesDetails";

interface Props {
  onChangePageState: (
    newState: DashboardPageState,
    idToEdit?: string,
    params?: string
  ) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  statusChangeHandler: (val: string) => void;
  classDurationChangeHandler: (val: "20" | "40") => void;
  selectedDuration: "20" | "40";
  selectedClassState: string;
  allClasses: ClassType[];
  selectedStartDate?: MaterialUiPickersDate;
  selectedEndDate?: MaterialUiPickersDate;
  changeDateSearchHandler: (
    type: string,
    newDate: MaterialUiPickersDate
  ) => void;
  onClearInputs: () => void;
  onViewCellClick?: ((cellData: any, cellIndex: number) => void) | undefined;
  params?: string;
  getSearchValueOnChange?: (value: string) => void;
  studentId?: string | null;
  isSupervisor?: boolean;
  classesWithoutFilter: ClassType[];
}

export const ClassesScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    statusChangeHandler,
    selectedClassState,
    allClasses,
    selectedStartDate,
    selectedEndDate,
    changeDateSearchHandler,
    onClearInputs,
    onViewCellClick,
    getSearchValueOnChange,
    studentId,
    isSupervisor,
    classesWithoutFilter,
    selectedDuration,
    classDurationChangeHandler,
  } = props;

  const RenderDetailFlow = () => {
    return (
      <>
        <ClassDetails
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      </>
    );
  };

  const RenderExtraTableFilters = () => {
    return (
      <ExtraTableFilters
        selectedClassState={selectedClassState}
        statusChangeHandler={statusChangeHandler}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        changeDateSearchHandler={changeDateSearchHandler}
        onClearInputs={onClearInputs}
        selectedDuration={selectedDuration}
        classDurationChangeHandler={classDurationChangeHandler}
      />
    );
  };

  const RenderPageHeader = () => {
    //TODO
    return (
      <StudentBrief
        numberOfClasses={18}
        classesLength={classesWithoutFilter.length}
        completedClassesLength={
          classesWithoutFilter.filter((cla) => cla.status === "completed")
            .length
        }
      />
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a Event";
      case "edit":
        return "Class Details";
      case "supervisor":
        return "supervisor";

      default:
        return "Manage Class";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      onViewCellClick={onViewCellClick}
      EditFlowContent={() => RenderDetailFlow()}
      ExtraTableFilters={() => RenderExtraTableFilters()}
      PageHeaderComponent={
        studentId && isSupervisor ? () => RenderPageHeader() : undefined
      }
      haveBackButton={isSupervisor}
      haveAddButton={false}
      data={allClasses}
      getSearchValueOnChange={getSearchValueOnChange}
    />
  );
};
