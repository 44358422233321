import React from "react";
import classes from "./PreferredTagsModal.module.css";
import { CardModal } from "../../menus";
import { Chip, Grid } from "@material-ui/core";
import { TextInput, InputLabel } from "../../Common";
import { FaCheck } from "react-icons/fa";
import { useFormik } from "formik";
import { TagType_Payload } from "../../../Types";
import { getMyFormikProps } from "../../../utils";

interface Props {
  tag: any | null;
  onClose: () => void;
  onResponseClicked: () => void;
}
export const PreferredTagsModal = (_props: Props) => {
  const { onClose } = _props;
  const formik = useFormik<TagType_Payload>({
    initialValues: {
      matchingTags: [],
      titleEn: "",
      titleKo: "",
    },
    onSubmit: () => {
      console.log("test");
    },
  });
  return (
    <CardModal
      haveFromButtons
      containedButtonText="Submit"
      outlineBtnText="Cancel"
      onOutlineButtonClicked={onClose}
      onContainedButtonClicked={formik.handleSubmit}
      head={<span>Preferred Tag</span>}
      onClose={onClose}
    >
      <div className={classes.Root}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              notFormikInput
              {...getMyFormikProps("titleEn", formik)}
              label="English Title"
              placeholder="name"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              label="Korean Title"
              notFormikInput
              {...getMyFormikProps("titleKo", formik)}
              placeholder="name"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mb-5">
            <div className={classes.MatchingTagsContainer}>
              <div className="d-flex justify-content-between w-100">
                <InputLabel>
                  <span>Matching Tags</span>
                </InputLabel>
                <InputLabel>
                  <span>{`${formik.values.matchingTags.length}/${5}`}</span>
                </InputLabel>
              </div>
              <div className="d-flex flex-wrap">
                {[
                  "1000000",
                  "2000000",
                  "3000000",
                  "4000000",
                  "5000000",
                  "6000000",
                  "7000000",
                  "8000000",
                ].map((chip, index) => {
                  const isSelected =
                    formik.values.matchingTags.find((i) => i === chip) !==
                    undefined;
                  return (
                    <div
                      key={index}
                      onClick={() => {

                        if (isSelected) {
                          formik.setFieldValue(
                            "matchingTags",
                            formik.values.matchingTags.filter((i) => i !== chip)
                          );
                        } else {
                          if(formik.values.matchingTags.length>=5){
                            return;
                          }
                          formik.setFieldValue(
                            "matchingTags",
                            [...formik.values.matchingTags,chip]
                          );
                        }
                      }}
                    >
                      <Chip
                        clickable={true}
                        color={isSelected ? "primary" : "default"}
                        className="mr-2 mb-2"
                        label={chip}
                        icon={isSelected ? <FaCheck /> : undefined}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </CardModal>
  );
};
