import axios, { AxiosInstance } from "axios";

import { ENV } from "../../env/base";
import { openSnackBar } from "../../Redux/Actions/appStatusActions";
//import config from "../../Config/AppConfig";
import Store from "../../Redux/Store";

// const { BASE_API_URL, TOKEN_STORAGE_KEY } = config;

const { BASE_API_URL, TOKEN_STORAGE_KEY, SERVER_ADDRESS } = ENV;

export interface BaseApiType {
  httpService: AxiosInstance;
  requestInterceptors: () => void;
  responseInterceptors: () => void;
}

export interface BaseApiConstructor {
  suffix: string;
  baseUrl?: string;
}

class BaseApi implements BaseApiType {
  httpService: AxiosInstance = axios.create({});

  constructor({ suffix, baseUrl = BASE_API_URL }: BaseApiConstructor) {
    this.httpService = axios.create({
      baseURL: `${baseUrl}/${suffix}`,
      
    });

    this.requestInterceptors();
    this.responseInterceptors();
  }

  requestInterceptors(): void {
    this.httpService.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(TOKEN_STORAGE_KEY);

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },

      (error) => Promise.reject(error)
    );
  }

  responseInterceptors(): void {
    this.httpService.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response === undefined) {
          Store.dispatch(
            openSnackBar({
              message: "Please check your internet connection",
              variant: "error",
              open: true,
            })
          );
          return Promise.reject({
            network: "Please check your internet connection",
          });
        } else {
          if (error.response.data) {
            Store.dispatch(
              openSnackBar({
                message: error.response.data.message,
                variant: "error",
                open: true,
              })
            );
          }
        }
        return Promise.reject(error.response);
      }
    );
  }
}

export default BaseApi;
