import classes from "./AddFeatureBanner.module.css";
import { Grid } from "@material-ui/core";
import React from "react";
import {
  ImagePicker,
  FormikTextField,
  FormikTextArea,
  Separator,
  CheckboxWithLabel,
} from "../../Common";

import { Formik, Form } from "formik";
import { DashboardPageState, LanguageVariantType } from "../../../Types";
import * as yup from "yup";
import { InputLabel } from "./../../Common/InputLabel/InputLabel";
import {
  featureBannerPayloadInitialState,
  FeatureBannerType_Payload,
} from "../../../Types/feature";
import SelectInput from "../../Common/SelectInput";
import { MaterialFormControls } from "../../MaterialLearnings";

const validationSchema = yup.object({
  title: yup.string().required("title is require"),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  onSubmitForm: (materialData: FeatureBannerType_Payload) => void;
  featureBannerToEdit: {
    [key: string]: FeatureBannerType_Payload;
  };
  pageState: DashboardPageState;
}

export const AddFeatureBanner = ({
  lang,
  setSelectedLanguage,
  onSubmitForm,
  featureBannerToEdit,
}: Props) => {
  const [initialValues, setInitialValues] =
    React.useState<FeatureBannerType_Payload>(featureBannerPayloadInitialState);

  React.useEffect(() => {
    if (featureBannerToEdit[lang] !== undefined) {
      setInitialValues(featureBannerToEdit[lang]);
    } else {
      const otherLang = featureBannerToEdit[lang === "en" ? "ko" : "en"];
      if (otherLang !== undefined) {
        setInitialValues(otherLang);
      } else {
        setInitialValues(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const onResetForm = () => {
    setInitialValues(initialValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmitForm(values);
      }}
    >
      {({ values, touched, errors, setFieldValue }) => {
        const addButtonDisabled = Object.keys(errors).length > 0;
        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ImagePicker
                    hints={[
                      "* Image shouldn’t be larger than1200 x 1200 pixels.",
                      "* Maximum image size is 700kBs.",
                      "* Make sure to use appropriate and relevant imagery",
                    ]}
                    className={classes.inputFields}
                  />
                  <FormikTextField
                    label="Feature Title"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="title"
                    id="title"
                    value={values.title}
                    error={touched.title && errors.title !== undefined}
                    helperText={touched.title ? errors.title : undefined}
                  />
                  <FormikTextArea
                    className={classes.inputFields}
                    label="Feature Description"
                    limit={500}
                    placeholder={"Maximum 500 characters..."}
                    rows={6}
                    name="description"
                    id="description"
                    value={values.description}
                    error={
                      touched.description && errors.description !== undefined
                    }
                    helperText={
                      touched.description ? errors.description : undefined
                    }
                  />
                  <div className={classes.inputFields}>
                    <InputLabel>
                      <span>Type*</span>
                    </InputLabel>
                    <SelectInput
                      value={values.type}
                      variant={"outlined"}
                      handleChange={(e) =>
                        setFieldValue("type", e.target.value)
                      }
                      options={[].map((item) => ({
                        label: "",
                        value: "",
                      }))}
                      label={"Type"}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={classes.checkboxRoot}>
                    <CheckboxWithLabel
                      label="More Details"
                      labelPlacement="end"
                      checked={values.haveMoreDetails}
                      onChange={(e) =>
                        setFieldValue(
                          "haveMoreDetails",
                          !values.haveMoreDetails
                        )
                      }
                    />
                  </div>
                  <FormikTextArea
                    className={classes.inputFields}
                    label="Details - Html*"
                    placeholder={"Maximum 2000 characters..."}
                    limit={2000}
                    rows={10}
                    disabled={!values.haveMoreDetails}
                    name="detailHtml"
                    id="detailHtml"
                    value={values.detailHtml}
                    error={
                      touched.detailHtml && errors.detailHtml !== undefined
                    }
                    helperText={
                      touched.detailHtml ? errors.detailHtml : undefined
                    }
                  />

                  <FormikTextField
                    label="Link Url"
                    placeholder={"Some External link..."}
                    className={classes.inputFields}
                    disabled={!values.haveMoreDetails}
                    name="linkUrl"
                    id="linkUrl"
                    value={values.linkUrl}
                    error={touched.linkUrl && errors.linkUrl !== undefined}
                    helperText={touched.linkUrl ? errors.linkUrl : undefined}
                  />
                </Grid>
              </Grid>
            </div>
            <Separator />
            <MaterialFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              englishChecked={featureBannerToEdit["en"] !== undefined}
              koreanChecked={featureBannerToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                featureBannerToEdit[lang] !== undefined
                  ? "Edit FeatureBanner"
                  : "Add FeatureBanner"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
