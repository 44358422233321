import { createMuiTheme, Theme } from "@material-ui/core";
import { faIR, enUS } from "@material-ui/core/locale";

const myPallet = {
  BG: "#f9fbe7",
  primary: "#272727",
  secondary: "#eb7c43",
  info: "#307fb8",
};

function getTheme(dir: "rtl" | "ltr"): Theme {
  return createMuiTheme(
    {
      direction: dir,
      overrides: {
        MuiMenuItem: {
          root: {
            display: "flex",
            justifyContent: "center",
            fontSize:"2.28rem"
          },
        },
        MuiStepper: {
          root: {
            flip: false,
          },
          horizontal: {
            flip: false,
          },
        },
        MuiStep: {
          root: {
            flip: false,
          },
        },
        MuiSlider: {
          root: {
            direction: "ltr",
            flip: false,
          },
        },
        MuiSvgIcon: {
          root: {
            fontSize: "2.85rem !important",
          },
        },
        MuiButton: {
          root: {
            "& svg": {
              width: "1.7rem !important",
              height: "1.71rem !important",
            },
            "& span+svg": {
              marginLeft: ".2rem !important",
            },
          },
          containedPrimary: {},
          containedSecondary: {
            color: "#fff",
          },
        },
        MuiInputBase:{
          root:{
            height: "8rem !important",
          }
        },
        MuiTextField: {
          root: {
            padding: "0 !important",
            height: "8rem !important",
            "& label": {},
            "& input": {
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "2.28rem",
              lineHeight: "150%",

              color: "#292929",
              padding: "0 !important",
              height: "100% !important",
            },
            "& input:placeholder": {
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "2.28rem",
              lineHeight: "150%",

              color: "#858585",
            },
            "& textarea": {
              textAlign: "left",
            },
            "& svg": {
              fontSize: "1.2rem",
            },
            borderRadius: "20px",
          },
          
        },
        MuiFormHelperText:{
          root:{
            fontSize:"2rem"
          }
        },
        MuiInput: {
          root: {
            "& svg": {
              fontSize: "1.2rem",
            },

          },
        
        },
        MuiFormControl: {
          root: {
            "& svg": {
              fontSize: "1.2rem",
            },
          },
        },
        MuiInputLabel: {
          root: {
            fontSize:"2.28rem"
          },
        },
        MuiTypography: {
          root: {
            fontSize:"2.28rem !important"
          },
          
          body1: {},
        },
      
        MuiSelect: {
          root: {
            textAlign: "left",
            fontSize:"2.28rem"
          },
          
        },
        MuiChip:{
          root:{
            fontSize:"2rem"
          }
        }
      },
      palette: {
        primary: {
          main: myPallet.primary,
        },
        secondary: {
          main: myPallet.secondary,
        },
        info: {
          main: myPallet.info,
        },
        background: {
          default: myPallet.BG,
        },
      },
    },
    dir === "rtl" ? faIR : enUS
  );
}
export default getTheme;
