import { ClassesRoute } from "./ClassesRoute";
import { ContentRoute } from "./ContentRoute";
import { CorporateRequestRoute } from "./CorporateRequestRoute";
import { NotificationRoute } from "./NotificationRoute";
import { OwnerRoute } from "./OwnersRoute";
import { PackagesRoute } from "./PackagesRoute";
import { ProgramRoute } from "./ProgramRoute";
import { ReviewsRoute } from "./ReviewsRoute";
import { TutorsRoute } from "./TutorsRoute";
import { UsersRoute } from "./UsersRoute";

export const allAppRoutes = [
  ContentRoute,
  OwnerRoute,
  ClassesRoute,
  ReviewsRoute,
  TutorsRoute,
  UsersRoute,
  NotificationRoute,
  PackagesRoute,
  ProgramRoute,
  CorporateRequestRoute,
];

export * from "./ContentRoute";
export * from "./OwnersRoute";
export * from "./ClassesRoute";
export * from "./ReviewsRoute";
export * from "./TutorsRoute";
export * from "./UsersRoute";
export * from "./NotificationRoute";
export * from "./PackagesRoute";
export * from "./ProgramRoute";
