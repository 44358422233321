import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";

import { useDashboardPageLocalData } from "../../Components/Hooks";
import { TestimonialsScreen } from "./TestimonialsScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "Name",
    text: "Name",
  },
  {
    dataField: "Rating",
    text: "Rating",
  },
  {
    dataField: "Title",
    text: "Title",
  },
  {
    dataField: "Description",
    text: "Description",
  },
  {
    dataField: "Homepage",
    text: "Homepage",
  },
  {
    dataField: "Submission Date",
    text: "Submission Date",
  },
];

export const TestimonialsScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();
  return (
    <TestimonialsScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
    />
  );
};
