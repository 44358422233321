import { MainRouteType } from "../../../Types";

export const ContentRoute: MainRouteType = {
  path: "/content/featureBanner",
  key: "Content",
  title: "Content",
  subRoutes: [
    {
      key: "FeatureBanner",
      title: "Feature Banner",
      path: "/content/featureBanner",
    },
    {
      key: "EventsBanner",
      title: "Events Banner",
      path: "/content/eventsBanner",
    },
    {
      key: "LearningMaterials",
      title: "Learning Material",
      path: "/content/learningMaterials",
    },
    {
      key: "Gallery",
      title: "Gallery",
      path: "/content/gallery",
    },
  ],
};
