import { FormHelperText } from "@material-ui/core";
//import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { HTMLAttributes } from "react";
import { IconType } from "react-icons/lib";

/*
audio: null
image: (binary)
insight: <p>Data visualization is a form of visual art that grabs our interest and keeps our eyes on the message. When we see a chart, we quickly see trends and outliers.</p>
<p>If we can see something, we internalize it quickly. If you&rsquo;ve ever stared at a massive spreadsheet of data and couldn&rsquo;t see a trend, you know how much more effective a visualization can be.</p>
key_expression_example_audios: null
category: 5fcb65cf800a142e58f422c7
main_category: 5f
*/

export interface OptionObj {
  label: string;
  value: string | number;
}

interface Props {
  value?: unknown;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  variant?: "outlined" | "standard" | "filled" | undefined;
  label?: string | number;
  options: Array<OptionObj>;
  LabelIcon?: IconType;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onFocus?: any;
  disabled?: boolean;
}

export const SelectInput = ({
  value,
  handleChange,
  variant,
  label,
  options,
  placeholder,
  className,
  style,
  error,
  helperText,
  onFocus,
  disabled,
}: Props & HTMLAttributes<HTMLDivElement>) => {
  return (
    <FormControl
      style={style}
      className={className}
      fullWidth
      variant={variant}
    >
      {label && (
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      )}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        //label={label}
        labelWidth={90}
        error={error}
        onFocus={onFocus}
        disabled={disabled ? disabled : false}
      >
        {options.map((op, key) => {
          return (
            <MenuItem key={key} value={op.value}>
              {op.label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
