import React, { ChangeEvent, useRef } from "react";
import { Button, IconGenerator } from "../";
import classes from "./ImagePicker.module.css";
import noImagePic from "../../../Resources/Images/noImage.jpg";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../../Redux/Actions/appStatusActions";

interface Props {
  className?: string;
  hints?: string[];
  imageSize?: string | number;
  fileSizeLimit?: number;
  onBigFileError?: () => void;
  onFileChoose?: (file: File | null) => void;
  onCleanFile?: () => void;
  value?: File | string;
}

export const ImagePicker = (props: Props) => {
  const {
    className,
    imageSize,
    hints,
    onFileChoose,
    onCleanFile,
    fileSizeLimit,
    onBigFileError,
    value,
  } = props;
  const picSize = imageSize
    ? { width: imageSize, height: imageSize }
    : undefined;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  const onInputButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  let maxFileSizeLimitTxt = "";
  if (fileSizeLimit) {
    const maxFileSizeToMB = fileSizeLimit / 1000000;
    const maxFileSizeToKB = fileSizeLimit / 1000;
    maxFileSizeLimitTxt = `*Maximum file size is ${
      maxFileSizeToMB >= 1 ? maxFileSizeToMB : maxFileSizeToKB
    } ${maxFileSizeToMB >= 1 ? "MBs" : "KBs"}`;
  }

  const hintsToRender = hints
    ? maxFileSizeLimitTxt !== ""
      ? [maxFileSizeLimitTxt, ...hints]
      : hints
    : [];

  const _onFileChoose = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (files[0]) {
        
        if (fileSizeLimit && files[0].size > fileSizeLimit) {
          dispatch(
            openSnackBar({
              open: true,
              message: "Your File is bigger than limitation",
              variant: "error",
            })
          );
          if (onBigFileError) {
            onBigFileError();
          }
        } else {
          if (onFileChoose) {
            onFileChoose(files[0]);
          }
        }
      }
    } else {
      e.target.files = null;
      if (onFileChoose) {
        onFileChoose(null);
      }
    }
    e.target.value="";
  };
  return (
    <div className={className}>
      <input
        type="file"
        ref={inputRef}
        className={classes.fileInputClassName}
        accept="image/*"
        onChange={_onFileChoose}
      />
      <div className={classes.Root}>
        <div className={classes.imageContainer} style={picSize}>
          <img
            src={
              value
                ? typeof value === "string"
                  ? value
                  : URL.createObjectURL(value)
                : noImagePic
            }
            alt="tooti picker"
          />
        </div>
        <div className={classes.controlsContainer}>
          <div className={classes.ButtonsContainer}>
            <Button onClick={onInputButtonClick} className={classes.Buttons}>
              <IconGenerator iconName="plus-box" />
              <span>{value?"Edit Image":"Add an Image"}</span>
            </Button>
            {value && (
              <Button onClick={onCleanFile} className={classes.Buttons}>
                <IconGenerator iconName="close-box" />
                <span>Remove Cover Image</span>
              </Button>
            )}
          </div>
          <div className={classes.hintsContainer}>
            {hintsToRender.map((h, key) => (
              <p key={key}>{h}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ImagePicker.defaultProps = {
  imageSize: "30rem",
  hints: [],
};
