import { classDetailInitialState } from "../../Types";
import { ClassActionTypes, ClassReducerType, GET_ALL_CLASSES, GET_CLASS_DETAILS, GET_STUDENT_CLASSES } from "../ActionTypes";

const initialState: ClassReducerType = {
  allClasses: [],
  classDetails: null,
  studentClasses: [],
};

export default function ClassReducer(
  state = initialState,
  action: ClassActionTypes
): ClassReducerType {
  switch (action.type) {
    case GET_STUDENT_CLASSES:
      return {
        ...state,
        studentClasses: action.payload,
      };
    case GET_ALL_CLASSES:
      return {
        ...state,
        allClasses: action.payload,
      };
    case GET_CLASS_DETAILS:
      console.log(action.payload);
      return {
        ...state,
        classDetails: action.payload,
      };
    default:
      return state;
  }
}
