import { MainRouteType } from "../../../Types";

export const PackagesRoute: MainRouteType = {
  path: "/packages/list",
  key: "Packages",
  title: "Package",
  subRoutes: [
    {
      key: "Packages",
      title: "Package",
      path: "/packages/list",
    },
    {
      key: "Coupons",
      title: "Coupons",
      path: "/packages/coupons",
    },
  ],
};
