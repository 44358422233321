import React from "react";

import { ColumnDescription } from "react-bootstrap-table-next";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { ApplicationDetails } from "../../Components/Application";
import {
  DashboardPageState,
  InterviewType,
  LanguageVariantType,
  Member,
} from "../../Types";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  interviewers: Member[];
  interviewList: InterviewType[];
}

export const ApplicationScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    interviewers,
    interviewList,
  } = props;

  const RenderDetailFlow = () => {
    return (
      <>
        <ApplicationDetails
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          interviewers={interviewers}
          tutorDetail={null}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a Event";
      case "edit":
        return "Application Detail";
      default:
        return "Application";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      EditFlowContent={() => RenderDetailFlow()}
      haveAddButton={false}
      onViewCellClick={() => onChangePageState("edit")}
      data={interviewList}
    />
  );
};
