export interface Notification_Payload {
  title: string;
  category: string;
  targetUser: string;
  startDate: string;
  endDate: string;
}

export const notificationInitialState: Notification_Payload = {
  title: "",
  category: "",
  targetUser: "",
  startDate: new Date()?.toISOString(),
  endDate: new Date()?.toISOString(),
};

export interface Announcements_Payload {
  image: string | null | File;
  title: string;
  descriptionHtml: string;
  category: string;
  targetUser: string;
  startDate: string;
  endDate: string;
}

export const announcementInitialState: Announcements_Payload = {
  image: null,
  title: "",
  descriptionHtml: "",
  category: "",
  targetUser: "",
  startDate: new Date()?.toISOString(),
  endDate: new Date()?.toISOString(),
};
