import React from "react";
import classes from "./AddNotification.module.css";
import { Grid } from "@material-ui/core";

import {
  FormikTextField,
  Separator,
  InputLabel,
  DatePicker,
  LanguageFormControls,
  SelectInput,
} from "../../Common";
import { Formik, Form } from "formik";
import {
  LanguageVariantType,
  eventInitialState,
  EventType_Payload,
  EventType,
} from "../../../Types";
import * as yup from "yup";
import moment from "moment";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../../Redux/Actions/appStatusActions";
import useFormAdderTools from "../../Hooks/useFormAdderTools";

const validationSchema = yup.object({
  roomLink: yup.string().url("Invalid URL"),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  eventToEdit: {
    [key: string]: EventType;
  };
  onSubmitForm: (eventData: EventType_Payload) => void;
}

export const AddNotification = (props: Props) => {
  const { lang, setSelectedLanguage, eventToEdit, onSubmitForm } = props;
  const dispatch = useDispatch();
  const [initialValues, onResetForm] = useFormAdderTools<EventType_Payload>({
    itemToEdit: eventToEdit,
    lang,
    defaultValues: eventInitialState as EventType_Payload,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        const startDate = moment(values.startDate);
        const endDate = moment(values.endDate);
        const startHour = values.startTime.split(":")[0];
        const startMinute = values.startTime.split(":")[1];
        const endHour = values.endTime.split(":")[0];
        const endMinute = values.endTime.split(":")[1];
        startDate.hours(parseInt(startHour)).minutes(parseInt(startMinute));
        endDate.hours(parseInt(endHour)).minutes(parseInt(endMinute));

        const ifOnTheSameDate = endDate.diff(startDate) <= 0;
        if (ifOnTheSameDate) {
          dispatch(
            openSnackBar({
              open: true,
              message: "invalid Start and End date",
              variant: "error",
            })
          );
        } else {
          onSubmitForm(values);
        }
      }}
    >
      {({ values, setFieldValue, errors }) => {
        const addButtonDisabled =
          Object.keys(errors).length > 0 ||
          JSON.stringify(values) === JSON.stringify(eventToEdit[lang]);

        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField
                    label="Event Title*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="eventTitle"
                  />

                  <SelectInput
                    handleChange={() => {}}
                    className={classes.inputFields}
                    variant="outlined"
                    options={[]}
                    label="Category*"
                  />

                  <SelectInput
                    handleChange={() => {}}
                    className={"mt-5 mb-5"}
                    variant="outlined"
                    options={[]}
                    label="Target User*"
                  />

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Start Date*</span>
                      </InputLabel>
                      <DatePicker
                        minDate={new Date()}
                        maxDate={
                          values.endDate ? new Date(values.endDate) : undefined
                        }
                        selectedDate={
                          values.startDate && values.startDate !== ""
                            ? new Date(values.startDate)
                            : new Date()
                        }
                        onChange={(newDate) => {
                          setFieldValue("startDate", newDate?.toISOString());
                        }}
                        className={classes.inputFields}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>End Date*</span>
                      </InputLabel>
                      <DatePicker
                        minDate={
                          values.startDate
                            ? new Date(values.startDate)
                            : new Date()
                        }
                        selectedDate={
                          values.endDate && values.endDate !== ""
                            ? new Date(values.endDate)
                            : new Date()
                        }
                        onChange={(newDate) => {
                          setFieldValue("endDate", newDate?.toISOString());
                        }}
                        className={classes.inputFields}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Separator />
            <LanguageFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              englishChecked={eventToEdit["en"] !== undefined}
              koreanChecked={eventToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                eventToEdit[lang] !== undefined ? "Edit Event" : "Add Event"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
