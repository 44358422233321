import BaseApi from "../BaseAPI";

class CouponService extends BaseApi {
  constructor() {
    super({ suffix: "admin" });
  }

  getAllCoupons = () => {
    return this.httpService.get("/voucher/get_all");
  };

  updateCoupon = (id: string, data: any) => {
    return this.httpService.patch(`/voucher/update/${id}`, { data });
  };

  createCoupon = (data: any) => {
    return this.httpService.post(`/voucher/add`, { data });
  };

  deleteCoupon = (id: string) => {
    return this.httpService.delete(`/voucher/delete/${id}`);
  };
}

const CouponsServiceInstance = new CouponService();
export default CouponsServiceInstance;
