import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { StudentType, TutorType } from "./";
import { PackageType } from "./";
import { LearningMaterialType } from "./materialLearning";

export type StatusType =
  | "request_pending"
  | "confirmed"
  | "cancelled_by_tutor"
  | "cancelled_by_student"
  | "rejected"
  | "cancelled_by_admin"
  | "completed"
  | "in_complete"
  | "auto_reject"
  | string;

export interface ClassType {
  student?: StudentType | null;
  disabled?: boolean;
  reviewedByStudent?: StudentReviewType;
  classPreferences?: {
    skipped: boolean;
    introduction?: string;
  };

  classCantCancel: boolean;

  classCantEdit: boolean;

  inCompleteReason: string;

  requestedIssueReports: {
    url: string;
    includeAttendance: boolean;
    includeExpense: boolean;
  }[];

  reviewedByTutor: TutorReviewType;
  tutor?: TutorType | null;
  startTime: string;
  endTime: Date;

  learning_material?: LearningMaterialType | null;
  status_select?: {
    label: StatusType;
    value: StatusType;
  };
  status: StatusType;
  status_reason: string;
  class_duration_type: "20 minutes" | "40 minutes";
  subscription?: PackageType | null;
  docs: string | null;
  meeting: string | null;

  class_history: {
    tutor_socket: string;
    student_socket: string;
    has_tutor_joined: boolean;
    has_student_joined: boolean;
    tutor_join_timings?: string | null;

    student_join_timings?: string | null;
    student_connection_status: "not_initiated" | "connected" | "disconnected";
    tutor_connection_status: "not_initiated" | "connected" | "disconnected";
    events?: string[];
    t?: string;
  };
  _id: string;
  created_at: string;
  company_name?: string;
  company_code?: string;
  booked_for?: string;
}

export interface SearchClassType {
  startTime?: MaterialUiPickersDate;
  endTime?: MaterialUiPickersDate;
  search_term: string;
  sort_based_start_time?: number;
  type: string | null;
  register_time?: number;
  sort_based_update_time?: number;
  duration?: '20' | '40'
}

export interface TutorReviewType {
  listening: ReviewPartType;
  pronunciation: ReviewPartType;
  speaking: ReviewPartType;
  vocabulary: ReviewPartType;
  grammar: ReviewPartType;
  problem: string;
  problemDescription: string;
  otherOpinion: string;
}

export interface StudentReviewType {
  classRate: number;
  tutorRate: number;
  description: string;
  haveProblem: boolean;
  learningMaterialRate: number;
  problem: string | null;
  problemDescription: string;
}

export const tutorReviewInitialValues = {
  pronunciation: {
    rate: 0,
    haveDescription: false,
    description: "",
  },
  speaking: {
    rate: 0,
    haveDescription: false,
    description: "",
  },
  vocabulary: {
    rate: 0,
    haveDescription: false,
    description: "",
  },
  listening: {
    rate: 0,
    haveDescription: false,
    description: "",
  },
  grammar: {
    rate: 0,
    haveDescription: false,
    description: "",
  },
  problem: "",
  problemDescription: "",
  otherOpinion: "",
};

export interface ReviewPartType {
  rate: number;
  haveDescription: boolean;
  description: string;
}

export interface EditClassData {
  class_time: Date;
  learningMaterial: string;
  tutorId: string;
  class_id: string;
  studentId: string;
  status: string;
  redirectionUrl?: string;
  in_completion_reason?: string;
}
