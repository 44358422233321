import moment from "moment";
import React from "react";
import classes from "./BootstrapTableCellRenderer.module.css";

interface Props {
  value: any;
  type: "image" | "text" | "date" | "dateTime" | "boolean";
  customDateRender?: string;
  imageAlt?: string;
}

export const BootstrapTableCellRenderer = (props: Props) => {
  const { value, type, customDateRender, imageAlt } = props;
  switch (type) {
    case "text":
      return <p className={classes.Text}>{value}</p>;
    case "image":
      return <img className={classes.Image} src={value} alt={imageAlt || ""} />;
    case "date":
      return (
        <p className={classes.Text}>
          {moment(value).format(customDateRender || "YYYY.MM.DD")}
        </p>
      );
    case "dateTime":
      return (
        <p className={classes.Text}>
          {moment(value).format(customDateRender || "YYYY.MM.DD")}
        </p>
      );
    case "boolean":
      return (
        <p className={classes.Text} style={{ color: value ? "green" : "red" }}>
          {value ? "Yes" : "No"}
        </p>
      );

    default:
      return <p>{value}</p>;
  }
};
