import React from "react";
import classes from "./GalleryEditorModal.module.css";
import { CardModal } from "../../menus";
import { Grid } from "@material-ui/core";
import { TextInput } from "../../Common";
import { useFormik } from "formik";
import { Gallery_Payload } from "../../../Types";
import { ImagePicker } from "../../Common/ImagePicker/ImagePicker";
import { getMyFormikProps } from "../../../utils";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  title: yup.string(),
  image: yup.mixed().required("image is required"),
});

interface Props {
  selectedGallery: any;
  onClose: () => void;
  onSendImage: (val: Gallery_Payload) => void;
}
export const GalleryEditorModal = (_props: Props) => {
  const { onClose, onSendImage, selectedGallery } = _props;

  const formik = useFormik<Gallery_Payload>({
    initialValues: {
      title: "",
      image: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      onSendImage(values);
    },
  });

  React.useEffect(() => {
    if (selectedGallery) {
      formik.setValues({
        image: selectedGallery.url,
        title: selectedGallery.title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGallery]);
  return (
    <CardModal
      head={<span>Gallery</span>}
      onClose={onClose}
      haveFromButtons={!selectedGallery}
      containedButtonText="Submit"
      outlineBtnText="Cancel"
      onContainedButtonClicked={formik.handleSubmit}
      onOutlineButtonClicked={onClose}
    >
      <div className={classes.Root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!selectedGallery && (
              <ImagePicker
                fileSizeLimit={1000000}
                value={formik.values.image}
                onFileChoose={(f) => formik.setFieldValue("image", f)}
                onCleanFile={() => formik.setFieldValue("image", undefined)}
                hints={["* Make sure to use appropriate and relevant imagery"]}
              />
            )}
            {selectedGallery && (
              <img src={selectedGallery.url} className="w-100" alt="" />
            )}
          </Grid>
          <Grid className="mb-5" item xs={12} sm={12} md={12} lg={12} xl={12}>
            {selectedGallery && (
              <TextInput
                readonly={selectedGallery}
                notFormikInput
                label="Title*"
                placeholder="Desribe the image..."
                {...getMyFormikProps("title", formik)}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </CardModal>
  );
};
