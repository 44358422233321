import { Member_Payload } from "../../Types";

/*
  profile_image: string | File | undefined;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  email: string;
  username: string;
  address: string;
  dob: string;
  gender: string;
  employment_type: string;
  nationality: string;
  role: Role | null;
  contract: string;
  ids: IDs_Payload[];
  account_status: "active";

*/

export const getMemberFormData = (data: Member_Payload): FormData => {
  const formData = new FormData();
  if (data.profile_image) {
    formData.append("profile_image", data.profile_image as Blob);
  }
  formData.append("contract", data.contract as Blob);
  data.ids.forEach((id) => {
    if (id.file !== null && typeof id.file !== "string") {
      formData.append("id_files", id.file as Blob);
    }
  });

  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("phone_number", data.phone_number);
  formData.append("username", data.username);
  formData.append("address", data.address);
  formData.append("dob", data.dob);
  formData.append("gender", data.gender);
  formData.append("employment_type", data.employment_type);
  formData.append("nationality", data.nationality);
  if (data.role) formData.append("role", data.role);

  formData.append("email", data.email);
  formData.append("password", data.password);
  if (data.ids && data.ids.length > 0)
    formData.append(
      "ids",
      JSON.stringify(
        data.ids.map((id) => {
          return {
            ...id,
            file:
              typeof id.file === "string"
                ? id.file !== "false" && id.file !== "true"
                  ? id.file
                  : null
                : id.file !== null
                ? true
                : null,
          };
        })
      )
    );

  return formData;
};
