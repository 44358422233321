import React from "react";
import Lottie from "lottie-react-web";
import animation from "../../Resources/Other/loading2.json";
import { Backdrop } from "@material-ui/core";
import { useTypedSelector } from "./../../Redux/Reducers/index";

interface Props {
  width?: string | number;
  height?: string | number;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
  isOverlay?: boolean;
  showOverLay?: boolean;
}

export const LottieLoading = ({
  width,
  height,
  containerStyle,
  containerClassName,
  isOverlay,
}: Props) => {
  const reduxLoading = useTypedSelector((state) => state.status.reduxLoading);
  const uploadProgress = useTypedSelector(
    (state) => state.status.uploadProgress
  );
  //console.log(uploadProgress);
  if (isOverlay) {
    return (
      <Backdrop
        open={reduxLoading}
        className={containerClassName}
        style={{ zIndex: 1401 }}
      >
        <div>
          <Lottie
            width={"200px"}
            height={"200px"}
            speed={1}
            options={{
              animationData: animation,
            }}
          />
          <progress
            style={{ position: "fixed", bottom: "50", width: "200px" }}
            value={uploadProgress}
            max={100}
          >
            {uploadProgress}
          </progress>
        </div>
      </Backdrop>
    );
  }
  return (
    <div style={containerStyle} className={containerClassName}>
      <Lottie
        width={width}
        height={height}
        speed={1}
        options={{
          animationData: animation,
        }}
      />
    </div>
  );
};

export default LottieLoading;
