import { DialogProps } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ThunkAction } from "redux-thunk";

import { Action } from "redux";

import { CorporateDetail } from "../Types/corporate";
import {
  ClassType,
  EventType,
  FeatureBannerType,
  Interviewer,
  InterviewRejection_Payload,
  InterviewType,
  IssueType,
  LearningMaterialCategory,
  LearningMaterialMainCategory,
  LearningMaterialType,
  MatchmakingDataType,
  Member,
  PackageType,
  Role,
  StudentDetailType,
  StudentType,
  TestimonialType,
  TutorDetailType,
  TutorRateData,
  TutorType,
  TypeOfFeatureBanners,
} from "./../Types";
import { RootState } from "./Reducers";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//--------------------------------------------------------------------------------------

//?AppStatus Types

//--------------------------------------------------------------------------------------

export const GET_ERRORS = "GET_ERRORS",
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  OPEN_DIALOG = "OPEN_DIALOG",
  CLOSE_DIALOG = "CLOSE_DIALOG",
  OPEN_SNACKBAR = "OPEN_SNACKBAR",
  CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
  TOGGLE_REDUX_LOADING = "TOGGLE_REDUX_LOADING",
  SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS",
  OPEN_GLOBAL_DIALOG = "OPEN_GLOBAL_DIALOG",
  CLOSE_GLOBAL_DIALOG = "CLOSE_GLOBAL_DIALOG";

export interface Errors {
  [key: string]: string;
}

export interface Modal {
  visible: boolean;
  title?: string;
  description: string;
  Icon: undefined;
  btnText: string;
  onBtnClick: Function;
  closeOnClick: boolean;
  secondaryBtnText?: Function;
  onSecondaryBtnClick?: Function;
}

export interface SnackBar {
  open: boolean;
  message: string;
  variant: "info" | "error" | "success";
  onClose?: Function;
}

interface GetErrorsAction {
  type: typeof GET_ERRORS;
  payload: Errors;
}

interface OpenSnackbarAction {
  type: typeof OPEN_SNACKBAR;
  payload: SnackBar;
}

interface CloseSnackbarAction {
  type: typeof CLOSE_SNACKBAR;
  payload?: undefined;
}

interface OpenModalAction {
  type: typeof OPEN_MODAL;
  payload: Modal;
}

interface CloseModalAction {
  type: typeof CLOSE_MODAL;
  payload?: undefined;
}

interface ChangeLanguageAction {
  type: typeof CHANGE_LANGUAGE;
  payload: "ko" | "en";
}

interface ToggleReduxLoadingAction {
  type: typeof TOGGLE_REDUX_LOADING;
  payload: boolean;
}

interface SetUploadProgressAction {
  type: typeof SET_UPLOAD_PROGRESS;
  payload: number;
}

interface openDialogModalActionType {
  type: typeof OPEN_GLOBAL_DIALOG;
  payload: DialogProps;
}

interface closeDialogModalActionType {
  type: typeof CLOSE_GLOBAL_DIALOG;
  payload: undefined;
}

export type AppStatusActionTypes =
  | GetErrorsAction
  | OpenModalAction
  | CloseModalAction
  | OpenSnackbarAction
  | CloseSnackbarAction
  | ChangeLanguageAction
  | ToggleReduxLoadingAction
  | SetUploadProgressAction
  | openDialogModalActionType
  | closeDialogModalActionType;

export interface AppStatusState {
  errors: Errors;
  modal: Modal;
  snackBar: SnackBar;
  lang: "ko" | "en" | string;
  reduxLoading: boolean;
  uploadProgress: number;
  dialog: DialogProps;
}

//--------------------------------------------------------------------------------------

//?Common Types

//--------------------------------------------------------------------------------------
export const GET_GALLERY_DATA = "GET_GALLERY_DATA";

interface getGalleryDataActionType {
  type: typeof GET_GALLERY_DATA;
  payload: any[];
}

export type commonActionTypes = getGalleryDataActionType;

export interface CommonState {
  gallery: any[];
}

//--------------------------------------------------------------------------------------

//?Auth Types

//--------------------------------------------------------------------------------------

export const USER_FOUND = "AUTH_USER_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  GET_USER_PROJECT_ACCESS = "GET_USER_PROJECT_ACCESS",
  GET_ALL_ISSUES = "GET_ALL_ISSUES",
  //MAIN_VERIFICATION_CODE_SENT = "MAIN_VERIFICATION_CODE_SENT",
  LOGOUT_USER = "LOGOUT_USER",
  TOGGLE_IS_AUTHENTICATING = "TOGGLE_IS_AUTHENTICATING",
  TOGGLE_SUPERVISOR = "TOGGLE_SUPERVISOR";

interface UserFoundAction {
  type: typeof USER_FOUND;
  payload: Member;
}

interface UserNotFoundAction {
  type: typeof USER_NOT_FOUND;
  payload: undefined;
}

interface toggleAuthenticating {
  type: typeof TOGGLE_IS_AUTHENTICATING;
  payload: boolean;
}
interface LogoutAction {
  type: typeof LOGOUT_USER;
  payload: undefined;
}

interface ToggleSupervisor {
  type: typeof TOGGLE_SUPERVISOR;
  payload: boolean;
}

export type AuthActionTypes =
  | UserFoundAction
  | UserNotFoundAction
  | LogoutAction
  | toggleAuthenticating
  | ToggleSupervisor;

export interface AuthState {
  isAuth: boolean;
  isAuthenticating: boolean;
  user?: Member;
  isSupervisor?: boolean;
}

//--------------------------------------------------------------------------------------

//?Learning Material Types

//--------------------------------------------------------------------------------------

export const GET_ALL_LEARNING_MATERIALS = "GET_ALL_LEARNING_MATERIALS",
  GET_ALL_LEARNING_MATERIALS_BY_MATERIALID =
    "GET_ALL_LEARNING_MATERIALS_BY_MATERIALID",
  ADD_LEARNING_MATERIAL_BY_MATERIALID = "ADD_LEARNING_MATERIAL_BY_MATERIALID",
  GET_LEARNING_MATERIALS_CATEGORIES = "GET_LEARNING_MATERIALS_CATEGORIES",
  GET_LEARNING_MATERIALS_MAIN_CATEGORIES =
    "GET_LEARNING_MATERIALS_MAIN_CATEGORIES",
  TOGGLE_LM_DISPLAY_ON_HOME_SCREEN = "TOGGLE_LM_DISPLAY_ON_HOME_SCREEN";

interface GetAllLearningMaterials {
  type: typeof GET_ALL_LEARNING_MATERIALS;
  payload: LearningMaterialType[];
}
interface GetAllLearningMaterialByMaterialId {
  type: typeof GET_ALL_LEARNING_MATERIALS_BY_MATERIALID;
  payload: {
    [key: string]: LearningMaterialType;
  };
}
interface AddMaterialByMaterialIdActionType {
  type: typeof ADD_LEARNING_MATERIAL_BY_MATERIALID;
  payload: {
    [key: string]: LearningMaterialType;
  };
}
interface GetLearningMaterialCategoriesActionType {
  type: typeof GET_LEARNING_MATERIALS_CATEGORIES;
  payload: LearningMaterialCategory[];
}

interface GetLearningMaterialMainCategoriesActionType {
  type: typeof GET_LEARNING_MATERIALS_MAIN_CATEGORIES;
  payload: LearningMaterialMainCategory[];
}

interface ToggleLmDisplayOnHomeScreenActionType {
  type: typeof TOGGLE_LM_DISPLAY_ON_HOME_SCREEN;
  payload: string;
}

export type learningMaterialActionTypes =
  | GetLearningMaterialCategoriesActionType
  | GetLearningMaterialMainCategoriesActionType
  | GetAllLearningMaterials
  | GetAllLearningMaterialByMaterialId
  | AddMaterialByMaterialIdActionType
  | ToggleLmDisplayOnHomeScreenActionType;

export interface LearningMaterialState {
  categories: LearningMaterialCategory[];
  mainCategories: LearningMaterialMainCategory[];
  allLearningMaterials: LearningMaterialType[];
  learningMaterialToEdit: {
    [key: string]: LearningMaterialType;
  };
}

//--------------------------------------------------------------------------------------

//?Member & Roles Types

//--------------------------------------------------------------------------------------

export const GET_ALL_ROLES = "GET_ALL_ROLES",
  GET_ALL_MEMBERS = "GET_All_USERS",
  GET_MEMBER = "GET_MEMBER";

interface getAllRolesActionType {
  type: typeof GET_ALL_ROLES;
  payload: Role[];
}

interface getAllUsersActionType {
  type: typeof GET_ALL_MEMBERS;
  payload: Member[];
}
interface getMember {
  type: typeof GET_MEMBER;
  payload: Member | null;
}

export type userAndRoleActionTypes =
  | getAllRolesActionType
  | getAllUsersActionType
  | getMember;

export interface UserAndRoleState {
  members: Member[];
  roles: Role[];
  member: Member | null;
}

//--------------------------------------------------------------------------------------

//?Event Types

//--------------------------------------------------------------------------------------
export const GET_ALL_EVENTS = "GET_ALL_EVENTS",
  GET_EVENT_DETAIL = "GET_EVENT_DETAIL";

interface getAllEventActionType {
  type: typeof GET_ALL_EVENTS;
  payload: EventType[];
}
interface getEventDetailActionType {
  type: typeof GET_EVENT_DETAIL;
  payload: string;
}

export type eventsActionTypes =
  | getAllEventActionType
  | getEventDetailActionType;

export interface EventState {
  events: EventType[];
  eventToEdit: {
    [key: string]: EventType;
  };
}

//--------------------------------------------------------------------------------------

//?feature banner Types

//--------------------------------------------------------------------------------------
export const GET_ALL_FEATURE_BANNERS = "GET_ALL_FEATURE_BANNERS",
  GET_FEATURE_BANNER_TYPES = "GET_FEATURE_BANNER_TYPES",
  SET_FEATURE_BANNER_TO_EDIT = "SET_FEATURE_BANNER_TO_EDIT";

interface getFeatureBannersActionType {
  type: typeof GET_ALL_FEATURE_BANNERS;
  payload: FeatureBannerType[];
}

interface getFeatureBannersTypesActionType {
  type: typeof GET_FEATURE_BANNER_TYPES;
  payload: TypeOfFeatureBanners[];
}

interface setFeatureBannerToEditActionType {
  type: typeof SET_FEATURE_BANNER_TO_EDIT;
  payload: {
    [key: string]: FeatureBannerType;
  };
}

export type featureBannerActionTypes =
  | getFeatureBannersActionType
  | getFeatureBannersTypesActionType
  | setFeatureBannerToEditActionType;

export interface FeatureBannerState {
  featureBanners: FeatureBannerType[];
  featureBannerTypes: TypeOfFeatureBanners[];
  featureBannerToEdit: {
    [key: string]: FeatureBannerType;
  };
}

//--------------------------------------------------------------------------------------

//? Classes

//--------------------------------------------------------------------------------------
export const GET_ALL_CLASSES = "GET_ALL_CLASSES",
  GET_CLASS_DETAILS = "GET_CLASS_DETAILS",
  GET_STUDENT_CLASSES = "GET_STUDENT_CLASSES";

interface getAllClasses {
  type: typeof GET_ALL_CLASSES;
  payload: ClassType[];
}

interface getStudentClasses {
  type: typeof GET_STUDENT_CLASSES;
  payload: ClassType[];
}

interface getClassDetails {
  type: typeof GET_CLASS_DETAILS;
  payload: ClassType;
}

export type ClassActionTypes =
  | getAllClasses
  | getClassDetails
  | getStudentClasses;

export interface ClassReducerType {
  allClasses: ClassType[];
  classDetails: ClassType | null;
  studentClasses: ClassType[];
}

//--------------------------------------------------------------------------------------

//? Student

//--------------------------------------------------------------------------------------
export const GET_ALL_STUDENTS = "GET_ALL_STUDENTS",
  GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL",
  GET_STUDENT_MATCHMAKING_QUESTIONS = "GET_TUTOR_MATCHMAKING_QUESTIONS";

interface getStudentMatchmakingQuestions {
  type: typeof GET_STUDENT_MATCHMAKING_QUESTIONS;
  payload: MatchmakingDataType[];
}

interface getAllStudents {
  type: typeof GET_ALL_STUDENTS;
  payload: StudentDetailType[];
}

interface getStudentDetails {
  type: typeof GET_STUDENT_DETAIL;
  payload: StudentDetailType;
}

export type StudentActionType =
  | getAllStudents
  | getStudentDetails
  | getStudentMatchmakingQuestions;

export interface StudentReducerType {
  allStudents: StudentDetailType[];
  studentDetails: StudentDetailType | null;
  studentMatchmakingQuestions: MatchmakingDataType[] | null;
}

//--------------------------------------------------------------------------------------

//? Tutor

//--------------------------------------------------------------------------------------

export const GET_ALL_TUTORS = "GET_ALL_TUTORS",
  GET_TUTOR_DETAIL = "GET_TUTOR_DETAIL",
  GET_TUTOR_APPLICATIONS = "GET_TUTOR_APPLICATIONS",
  GET_TUTOR_APPLICATION_DETAILS = "GET_TUTOR_APPLICATION_DETAILS",
  GET_APPLICATION_REJECTION_REASONS = "GET_APPLICATION_REJECTION_REASONS",
  GET_TUTOR_RATES = "GET_TUTOR_RATES",
  GET_TUTOR_MATCHMAKING_QUESTIONS = "GET_TUTOR_MATCHMAKING_QUESTIONS";

interface getTutorMatchmakingQuestions {
  type: typeof GET_TUTOR_MATCHMAKING_QUESTIONS;
  payload: MatchmakingDataType[];
}

interface getAllTutors {
  type: typeof GET_ALL_TUTORS;
  payload: TutorType[];
}

interface getTutorDetails {
  type: typeof GET_TUTOR_DETAIL;
  payload: TutorDetailType;
}

interface getTutorInterviewApplications {
  type: typeof GET_TUTOR_APPLICATIONS;
  payload: InterviewType[];
}

interface getTutorApplicationDetails {
  type: typeof GET_TUTOR_APPLICATION_DETAILS;
  payload: InterviewType | null;
}

interface getTutorApplicationRejectionReason {
  type: typeof GET_APPLICATION_REJECTION_REASONS;
  payload: InterviewRejection_Payload[];
}
interface getTutorRatesActionType {
  type: typeof GET_TUTOR_RATES;
  payload: TutorRateData | null;
}

export type TutorActionType =
  | getAllTutors
  | getTutorDetails
  | getTutorApplicationDetails
  | getTutorInterviewApplications
  | getTutorApplicationRejectionReason
  | getTutorRatesActionType
  | getTutorMatchmakingQuestions;

export interface TutorReducerType {
  allTutors: TutorType[];
  tutorDetails: TutorDetailType | null;
  tutorApplications: InterviewType[];
  applicationDetails: InterviewType | null;
  applicationRejectionReasons: InterviewRejection_Payload[];
  tutorRates: TutorRateData | null;
  tutorMatchmakingQuestions: MatchmakingDataType[] | null;
}

//--------------------------------------------------------------------------------------

//?Reviews Types

//--------------------------------------------------------------------------------------

export const GET_ALL_TESTIMONIALS = "GET_ALL_TESTIMONIALS",
  GET_TESTIMONIAL_DETAIL = "GET_TESTIMONIAL_DETAIL";

interface getAllTestimonialsActionType {
  type: typeof GET_ALL_TESTIMONIALS;
  payload: TestimonialType[];
}

interface getAllTestimonialDetailsActionType {
  type: typeof GET_TESTIMONIAL_DETAIL;
  payload: string | null;
}

interface getAllIssuesActionType {
  type: typeof GET_ALL_ISSUES;
  payload: IssueType[];
}

export type ReviewsActionTypes =
  | getAllTestimonialsActionType
  | getAllTestimonialDetailsActionType
  | getAllIssuesActionType;

export interface ReviewsReducerType {
  allTestimonials: TestimonialType[];
  testimonial: TestimonialType | null;
  issues: IssueType[];
}

//--------------------------------------------------------------------------------------

//?Interview Types

//--------------------------------------------------------------------------------------

export const GET_INTERVIEWER = "GET_INTERVIEWER";

type getInterviewerActionType = {
  type: typeof GET_INTERVIEWER;
  payload: Interviewer | null;
};

export type InterviewersActionTypes = getInterviewerActionType;

export interface InterviewerReducerType {
  interviewer: Interviewer | null;
}

//-----------------------------------------------------------------------------------------------------------------

export const GET_FREE_PACKAGES = "GET_FREE_PACKAGES",
  GET_USER_PACKAGES = "GET_USER_PACKAGES",
  GET_ALL_COUPONS = "GET_ALL_COUPONS";

interface getFreePackages {
  type: typeof GET_FREE_PACKAGES;
  payload: PackageType[];
}

interface getAllCoupons {
  type: typeof GET_ALL_COUPONS;
  payload: any[];
}
interface getUserPackages {
  type: typeof GET_USER_PACKAGES;
  payload: PackageType[];
}

export type PackagesActions = getFreePackages | getUserPackages | getAllCoupons;

export interface PackagesReducerType {
  freePackages: PackageType[];
  userPackages: PackageType[];
  coupons: any[];
}

//........................

export const GET_CORPORATE_DETAIL = "GET_CORPORATE_DETAIL";
export const GET_CORPORATE_STUDENTS = "GET_CORPORATE_STUDENTS";
export const GET_ALL_CORPORATE_REQUESTS = "GET_ALL_CORPORATE_REQUESTS";

interface GetAllCorporateRequest {
  type: typeof GET_ALL_CORPORATE_REQUESTS;
  payload: any[];
}
interface GetCorporateStudents {
  type: typeof GET_CORPORATE_STUDENTS;
  payload: StudentDetailType[];
}
interface GetCorporateDetail {
  type: typeof GET_CORPORATE_DETAIL;
  payload: CorporateDetail;
}

export type CorporateActionType =
  | GetCorporateDetail
  | GetCorporateStudents
  | GetAllCorporateRequest;
export interface CorporateReducerType {
  corporateDetail: CorporateDetail | null;
  corpStudents: StudentDetailType[];
  corpRequests: any[];
}

///////////////////////////////////////////////////...................................

export const UPDATE_TABLE_FILTERS = "UPDATE_TABLE_FILTERS",
  CLEAR_TABLE_FILTERS = "CLEAR_TABLE_FILTERS";

interface UpdateTableFiltersActionType {
  type: typeof UPDATE_TABLE_FILTERS;
  payload: {
    tableName: TableNamesTypes;
    filterData: TableFilterDataTypes;
  };
}
interface ClearTableFiltersActionType {
  type: typeof CLEAR_TABLE_FILTERS;
  payload: {
    tableName: TableNamesTypes;
  };
}

export type TablesFiltersActionType =
  | ClearTableFiltersActionType
  | UpdateTableFiltersActionType;

export type MaterialType = "junior" | "regular" | "all";
export interface TableFilterDataTypes {
  data: {
    category?: string;
    materialType?: MaterialType;
    startTime?: MaterialUiPickersDate;
    endTime?: MaterialUiPickersDate;
    classStatus?: string | null;
    studentType?: "all" | "b2b" | "b2c";
    sortType?: 1 | -1;
    sortField?: "registerTime" | "startTime" | "updatedTime";
    classDuration?: "20" | "40";
    semester?: {
      label: string;
      value: string;
    };
  };
}

export type TableNamesTypes = keyof TablesFiltersReducerType;
export interface TablesFiltersReducerType {
  [tableNames: string]: TableFilterDataTypes;
}
