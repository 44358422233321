import { MatchmakingDataType, TagType, UserBaseInfo } from "./common";
import { InterviewType } from "./interview";

export interface TutorType extends UserBaseInfo {
  phone_number: string;
  show: boolean;
  profile_status: string;
}

export interface TutorDetailType extends TutorType {
  date_of_birth: string;
  nationality: string;
  matchmakingData?: MatchmakingDataType;
  matchmakingAnswers?: {
    questionId: string;
    _id: string;
    userId: string;
    answer: {
      label: string;
      checked: boolean | null;
      _id: string;
    }[];
  }[];

  tags: TagType[];
  major: {
    id: string;
    text: string;
    _id: string;
  }[];
  university: {
    title: string;
    file: string | null;
  };
  portfolio?: {
    attachment: string;
    file_type: string;
    url: string;
  };
  contract: string | null;
  interview?: InterviewType;
  teachingCertificate: {
    attachment: string;
    file_type: string;
    name: string;
    _id: string;
  }[];
  updated_at: string;
  created_at: string;
}

export interface RateType {
  title: string;
  description: string;
  packageType: string;
  rate: number;
}
export interface TutorRateData {
  classes: number;
  reviewCount: number;
  averageRate: number;
  rates: RateType[];
}

export const tutorInitialState: TutorDetailType = {
  first_name: "",
  last_name: "",
  email: "",
  status: "active",
  avatar_url: null,
  registration_date: "",
  user_type: "tutor",
  _id: "",
  phone_number: "",
  date_of_birth: "",
  nationality: "",
  tags: [],
  major: [],
  university: {
    title: "",
    file: null,
  },
  portfolio: undefined,
  contract: null,
  teachingCertificate: [],
  updated_at: "",
  created_at: "",
  show: false,
  profile_status: "",
};
