import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import * as yup from "yup";

import {
  Button,
  FormikTextField,
  LottieLoading,
} from "../../Components/Common";
import { useTypedSelector } from "../../Redux/Reducers";
import { loginUser } from "./../../Redux/Actions/authActions";
import { Login_Payload } from "./../../Types/UserAndRoles";
import classes from "./LoginScreen.module.css";

const validationSchema = yup.object({
  username: yup.string().required("Email is required"),
  /*.email("Invalid Email Address"),*/
  password: yup.string().required("Please enter your password"),
});

export function LoginScreen() {
  const isAuth = useTypedSelector((state) => state.auth.isAuth);
  const isAuthenticating = useTypedSelector(
    (state) => state.auth.isAuthenticating
  );
  const [showPassword, togglePassword] = React.useState(false);
  const initialData: Login_Payload = {
    username: "",
    password: "",
  };
  const dispatch = useDispatch();
  const history = useHistory();

  if (isAuth) {
    return <Redirect to="/content/learningMaterials" />;
  }
  // if (isAuthenticating) {
  //   return <LottieLoading width="200px" height="200px" />;
  // }

  return (
    <div className={classes.Root}>
      <div className={classes.Header}></div>
      <div className={classes.PageContainer}>
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(loginUser(values, history));
          }}
        >
          <Form>
            <div className={classes.InputsContainer}>
              <div className={classes.LoginHeader}>
                <span>Login</span>
              </div>
              <div className={classes.formContainer}>
                <FormikTextField
                  placeholder={"Your Email Address"}
                  name="username"
                />
                <FormikTextField
                  placeholder={"Your Password"}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className={classes.PasswordInput}
                  endAdornment={
                    <Button
                      className={classes.EyeButton}
                      freeHeight
                      type="button"
                      variant="default"
                      onClick={() => togglePassword(!showPassword)}
                    >
                      {!showPassword ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  }
                />
                <div style={{ marginTop: "4.28rem" }}>
                  <Button type="submit">
                    <span>Login</span>
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
