import React from "react";
import classes from "./StudentBrief.module.css";

interface Props {
  title: string;
  subTitle: string;
  withImage?: string;
}

export const StudentDetail = (props: Props) => {
  const { title, subTitle, withImage } = props;

  return (
    <div className={classes.Part}>
      <p className={classes.Title}>{title}</p>
      {withImage ? (
        <img src={withImage} />
      ) : (
        <p className={classes.SubTitle}>{subTitle}</p>
      )}
    </div>
  );
};
