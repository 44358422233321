import React from 'react';
import classes from "./Separator.module.css";

interface Props{
    color?:"primary"|"secondary";
    className?:string;
}

export const Separator = ({color,className}:Props) => {
    return (
        <div className={className}>
            <div className={classes[`separator_${color}`]}/>
        </div>
    )
}

Separator.defaultProps={
    color:"primary"
}
