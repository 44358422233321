import moment, { Moment } from "moment-timezone";

export type AppTimeZones = "Asia/Seoul" | "local";

export class TimeMoment {
  dateObj: Moment = moment().zone("GMT");
  constructor(time?: string, targeTimeZone: AppTimeZones = "Asia/Seoul") {
    if (time) {
      var splitTime = time.split(/:/);
      this.dateObj = this.dateObj
        .clone()
        .tz(targeTimeZone)
        .hours(parseInt(splitTime[0]))
        .minutes(parseInt(splitTime[1]))
        .seconds(0)
        .milliseconds(0);
    } else {
      const now = new Date();
      this.dateObj = this.dateObj
        .clone()
        .tz(targeTimeZone)
        .hours(now.getHours())
        .minutes(now.getMinutes())
        .seconds(0)
        .milliseconds(0);
    }
  }

  setTime(time: string) {
    var splitTime = time.split(/:/);
    this.dateObj
      .hours(parseInt(splitTime[0]))
      .minutes(parseInt(splitTime[1]))
      .seconds(0)
      .milliseconds(0);
  }

  format = (f: string): string => {
    return this.dateObj.format(f);
  };

  getMoment = (): Moment => {
    return this.dateObj;
  };

  getLocalTimeZone = (): Moment => {
    return this.dateObj.clone().tz(moment.tz.guess());
  };
}
