import { MainRouteType } from "./../../../Types/ConfigTypes";
export const TutorsRoute: MainRouteType = {
  key: "Tutors",
  title: "Tutor",
  path: "/tutors/active",
  subRoutes: [
    {
      key: "Tutors",
      title: "Active Tutor",
      path: "/tutors/active",
    },
    {
      key: "Applications",
      title: "Application",
      path: "/tutors/applications",
    },
    {
      key: "Interviews",
      title: "Interview",
      path: "/tutors/interviews",
    },
  ],
};
