import { Chip, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ApplicationInterviewerSelect, ApplicationRejectionModal, ApplicationSetTimeModal, InterviewState } from "..";
import { closeGlobalDialog, openGlobalDialog } from "../../../Redux/Actions/appStatusActions";
import { TutorsActions } from "../../../Redux/Actions/tutorsAction";
import { useTypedSelector } from "../../../Redux/Reducers";
import profilePicTmpImage from "../../../Resources/Images/1.jpg";
import { InterviewStatusType, LanguageVariantType, Member, TutorDetailType, tutorInitialState } from "../../../Types";
import { Alert, Button, CheckboxWithLabel, FormikTextArea, FormikTextField } from "../../Common";
import { ConfirmationModal } from "../../menus";
import { ExpandMenu, IconGenerator, InputLabel, ProfileBaseInfo, TextInput } from "./../../Common";
import classes from "./ApplicationDetails.module.css";

const validationSchema = yup.object({});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  interviewers: Member[];
  tutorDetail: TutorDetailType | null;
}

export const ApplicationDetails = ({ interviewers, tutorDetail }: Props) => {
  const matchmakingQuestions = useTypedSelector(
    (state) => state.tutor.tutorMatchmakingQuestions
  );
  const [initialValues, setInitialValues] =
    useState<TutorDetailType>(tutorInitialState);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    dispatch(closeGlobalDialog());
  };

  const onRejectRequestPressed = (newStatus?: InterviewStatusType) => {
    if (tutorDetail && tutorDetail._id)
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <ApplicationRejectionModal
              onCancel={onCloseModal}
              tutorId={tutorDetail._id}
              onReject={(data) => {
                const dataToSend = newStatus
                  ? { ...data, status: newStatus }
                  : data;

                console.log(newStatus, "fff");
                dispatch(
                  TutorsActions.rejectTutorApplication(dataToSend, (isOk) => {
                    if (isOk) {
                      dispatch(closeGlobalDialog());
                    }
                  })
                );
              }}
            />
          ),
        })
      );
  };

  React.useEffect(() => {
    if (tutorDetail) {
      console.log(tutorDetail);
      setInitialValues(tutorDetail);
    }
  }, [tutorDetail]);

  const onAcceptRequestPressed = () => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <ConfirmationModal
            text={
              <span>
                Are you sure you want to Qualify this user as a Tutor?
              </span>
            }
            outlineBtnText="No"
            onOutlineButtonClicked={onCloseModal}
            containedButtonText="Yes"
          />
        ),
      })
    );
  };

  const onSetTimeClicked = () => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <ApplicationSetTimeModal
            onCancel={onCloseModal}
            tutorData={tutorDetail}
            onSubmitTime={(val) =>
              dispatch(
                TutorsActions.acceptTutorApplicationRequest(val, (isOk) => {
                  if (isOk) {
                    dispatch(closeGlobalDialog());
                  }
                })
              )
            }
          />
        ),
      })
    );
  };

  const onSelectInterviewer = () => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <ApplicationInterviewerSelect
            onCancel={onCloseModal}
            interviewers={interviewers}
          />
        ),
      })
    );
  };

  const onChangeInterviewStatus = (status: InterviewStatusType) => {
    if (tutorDetail)
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <ConfirmationModal
              text={
                <span style={{ fontSize: "2.56rem" }}>
                  Are you sure you want to qualify this tutor ?
                </span>
              }
              containedButtonText="Yes"
              outlineBtnText="No"
              onContainedButtonClicked={() => {
                if (tutorDetail._id)
                  dispatch(
                    TutorsActions.changeTutorInterviewStatus(
                      {
                        tutorId: tutorDetail._id,
                        status: status,
                        qualifiedTime: new Date().toISOString(),
                      },
                      (isOk) => {
                        if (isOk) {
                          dispatch(closeGlobalDialog());
                        }
                      }
                    )
                  );
              }}
              onOutlineButtonClicked={() => dispatch(closeGlobalDialog())}
            />
          ),
        })
      );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values }) => {
        return (
          <Form>
            <div>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {values.interview && (
                    <InterviewState
                      state={
                        values.interview ? values.interview.status : "PENDING"
                      }
                      onRejectRequest={() => onRejectRequestPressed()}
                      onAcceptRequest={onAcceptRequestPressed}
                      onSetTimeClicked={onSetTimeClicked}
                      onSelectInterviewer={onSelectInterviewer}
                      onQualifyTutor={() => onChangeInterviewStatus("QUALIFY")}
                      onRejectQualify={() =>
                        onRejectRequestPressed("NOT_QUALIFY")
                      }
                      onInterviewDone={() =>
                        onChangeInterviewStatus("CONFIRMED")
                      }
                      onRejectInterviewTime={() =>
                        onChangeInterviewStatus("PENDING")
                      }
                      interviewData={values.interview}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ProfileBaseInfo
                        profilePicture={values.avatar_url || profilePicTmpImage}
                        className={classes.inputFields}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Phone Number"
                        placeholder={"12345678910"}
                        className={classes.inputFields}
                        name="phone_number"
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Email address"
                        placeholder={"Noooooooo@gmail.com..."}
                        className={classes.inputFields}
                        name="email"
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextInput
                        label="Date of Birth"
                        placeholder={"Aug 29th, 1959"}
                        className={classes.inputFields}
                        value={moment(values.date_of_birth).format(
                          "YYYY-MM-DD"
                        )}
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Nationality"
                        placeholder={"Iranian"}
                        className={classes.inputFields}
                        name="nationality"
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextInput
                        label="Submission Date"
                        placeholder={"Oct 30th, 2019"}
                        className={classes.inputFields}
                        value={moment(values.updated_at).format("YYYY-MM-DD")}
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormikTextArea
                        label="Tutor Intro"
                        placeholder={"Maximum 500 characters"}
                        className={classes.inputFields}
                        name="selfDescription"
                        rows={3}
                        limit={500}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Intro Video</span>
                      </InputLabel>
                      <div className="d-flex">
                        <Button color="primary" variant="contained">
                          <div className="px-5 d-flex align-items-center">
                            <IconGenerator iconName="Video" />
                            <span>View Intro Video</span>
                          </div>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={classes.inputFields}>
                        <InputLabel>
                          <span>Matchmaking Data</span>
                        </InputLabel>
                        {matchmakingQuestions &&
                          matchmakingQuestions.length &&
                          matchmakingQuestions.map((question) => {
                            let answers =
                              tutorDetail &&
                              tutorDetail.matchmakingAnswers &&
                              tutorDetail.matchmakingAnswers.length
                                ? tutorDetail.matchmakingAnswers.filter(
                                    (answer) =>
                                      answer.questionId === question._id
                                  )[0].answer
                                : [];
                            return (
                              <ExpandMenu
                                className={classes.expandRoot}
                                header={<p>{question.title}</p>}
                                content={
                                  <div className={classes.expandContent}>
                                    {answers && answers.length ? (
                                      answers.map((ans: any) => (
                                        <CheckboxWithLabel
                                          checked={true}
                                          label={ans.label}
                                        />
                                      ))
                                    ) : (
                                      <Alert hideButton>
                                        <span>Not answered yet</span>
                                      </Alert>
                                    )}
                                  </div>
                                }
                              />
                            );
                          })}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={classes.inputFields}>
                        <InputLabel>
                          <span>Chosen Tags</span>
                        </InputLabel>
                        <div>
                          {values.tags.map((tag) => {
                            return (
                              <Chip
                                color="primary"
                                label={tag.name.en}
                                icon={<FaCheck />}
                                className="mr-2 mb-2"
                                key={tag._id}
                              />
                            );
                          })}
                        </div>
                        {values.tags.length < 4 && (
                          <Alert className="mt-5" hideButton>
                            <span>{`${
                              4 - values.tags.length
                            } Tag(s) Remaining`}</span>
                          </Alert>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Majors</span>
                      </InputLabel>
                      {values.major.map((major) => {
                        return (
                          <Chip
                            color="primary"
                            label={major.text}
                            icon={<FaCheck />}
                            className="mr-2 mb-2"
                            key={major._id}
                          />
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormikTextField
                        label="University"
                        placeholder={"Madrid University"}
                        name="university"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Alert hideButton>
                        <span>no file is uploaded yet</span>
                      </Alert>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>CV/Portfolio</span>
                      </InputLabel>
                      {values.portfolio &&
                        values.portfolio.attachment &&
                        values.portfolio.attachment !== "" && (
                          <a
                            href={values.portfolio.attachment}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Alert hideButton className="mb-5">
                              <span>Download</span>
                            </Alert>
                          </a>
                        )}
                      {values.portfolio &&
                        values.portfolio.url &&
                        values.portfolio.url !== "" && (
                          <a
                            href={values.portfolio.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Alert hideButton className="mb-5">
                              <span>View Link</span>
                            </Alert>
                          </a>
                        )}
                      {(!values.portfolio ||
                        !values.portfolio.url ||
                        !values.portfolio.attachment) && (
                        <Alert hideButton className="mb-5">
                          <span>no file is uploaded yet</span>
                        </Alert>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={classes.inputFields}>
                        <InputLabel>
                          <span>Teaching Certificates</span>
                        </InputLabel>
                        {values.teachingCertificate.map((cert) => {
                          return (
                            <ExpandMenu
                              className={classes.expandRoot}
                              header={<CheckboxWithLabel label={cert.name} />}
                              content={
                                <div
                                  className={
                                    "d-flex justify-content-between px-5 pb-4"
                                  }
                                >
                                  <a
                                    href={cert.attachment}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button variant="contained">
                                      <div className="d-flex align-items-center p-4">
                                        <IconGenerator iconName="Video" />
                                        <span>Download File</span>
                                      </div>
                                    </Button>
                                  </a>
                                  <div className="d-flex align-align-items-center">
                                    <Button variant="outlined" className="mr-3">
                                      <div className="d-flex align-items-center p-2">
                                        <span>Disapprove</span>
                                      </div>
                                    </Button>
                                    <Button variant="contained">
                                      <div className="d-flex align-items-center p-5">
                                        <span>Approve</span>
                                      </div>
                                    </Button>
                                  </div>
                                </div>
                              }
                            />
                          );
                        })}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Default Availability</span>
                      </InputLabel>
                      <Alert hideButton>
                        <span>Yet to be set</span>
                      </Alert>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            {/*<MaterialFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
            />*/}
          </Form>
        );
      }}
    </Formik>
  );
};
