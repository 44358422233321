import { MaterialType } from "../Redux/ActionTypes";

/*

?Payload to Send To Server

*/
export interface ExampleType_Payload {
  title: string;
  meaning__ko: string;
  audio: File | null | boolean | string;
}

export interface ExpressionType_Payload {
  title: string;
  meaning__ko: string;
  meaning__en: string;
  audio: File | null | boolean | string;
  tips: string;
  examples: ExampleType_Payload[];
}

export interface Question_Payload {
  img_url: File | null | boolean | string;
  question_title: string;
  question_subtitle: string;
}

export interface MediaType_Payload {
  url: string;
  title: string;
}

export interface LearningMaterialType_Payload {
  main_category: string;
  category: string;
  title: string;
  sub_title: string;
  audio: File | null | string;
  image: File | null | string;
  material_id?: string;
  insights: string | null;
  questions: Question_Payload[];
  expressions: ExpressionType_Payload[];
  media: MediaType_Payload[];
  level?:number;
  popular?: boolean;
  recommended?: boolean;
}

/*

?Material initial values

*/

export const exampleInitialState: ExampleType_Payload = {
  title: "",
  meaning__ko: "",
  audio: null,
};

export const expressionInitialState: ExpressionType_Payload = {
  title: "",
  meaning__ko: "",
  meaning__en: "",
  audio: null,
  tips: "",
  examples: [exampleInitialState],
};

export const questionInitialState: Question_Payload = {
  img_url: null,
  question_title: "",
  question_subtitle: "",
};

export const mediaInitialState: MediaType_Payload = {
  url: "",
  title: "",
};

export const learningMaterialInitialState: LearningMaterialType_Payload = {
  main_category: "",
  category: "",
  title: "",
  sub_title: "",
  audio: null,
  image: null,
  insights: "",
  questions: [questionInitialState],
  expressions: [expressionInitialState],
  media: [mediaInitialState],

};

/*

?Material Helper Types

*/

export interface LearningMaterialCategory {
  _id: string;
  title: {
    en: string;
    ko: string;
  };
  main_category: string;
  default: boolean;
}

export interface LearningMaterialMainCategory {
  _id: string;
  title: {
    en: string;
    ko: string;
  };
}

export interface learningMaterialFilters {
  search: string;
  category: string[];
  main_category: string[];
  type: MaterialType;
}

export interface learningMaterialPoints {
  total: number;
  preparation: number;
  attendance: number;
  task: number;
}

/*

?Main Learning Material Types

*/

export interface QuestionType {
  questionId: string;
  img_url: string;
  question_title: string;
  question_subtitle: string;
}

export interface ExampleType {
  title: string;
  meaning__ko: string;
  audio: string;
  transcript: string;
}
export interface ExpressionType {
  title: string;
  meaning__ko: string;
  meaning__en: string;
  audio: string;
  tips: string;
  examples: ExampleType[];
}

export type MediaType = {
  videos: MediaType_Payload[];
  articles: MediaType_Payload[];
};

export interface LearningMaterialType {
  category: string;
  main_category: string;
  title: string;
  sub_title: string;
  audio: string;
  image: string;
  insights: string;
  points: learningMaterialPoints;
  questions: QuestionType[];
  expressions: ExpressionType[];
  is_active: boolean;
  material_id?: string;
  media: MediaType;
  _id: string;
  home_screen_display: boolean;
}
