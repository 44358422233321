import { ClassType } from ".";

// TODO Type Checking
export const classDetailInitialState: ClassType = {
  student: null,
  disabled: false,
  reviewedByStudent: {
    classRate: 0,
    tutorRate: 0,
    description: "",
    haveProblem: false,
    learningMaterialRate: 0,
    problem: "",
    problemDescription: "",
  },
  classPreferences: {
    skipped: false,
    introduction: "",
  },
  classCantCancel: false,
  classCantEdit: false,
  inCompleteReason: "string",
  requestedIssueReports: [
    {
      url: "",
      includeAttendance: false,
      includeExpense: false,
    },
  ],

  reviewedByTutor: {
    listening: {
      rate: 0,
      haveDescription: false,
      description: "",
    },
    pronunciation: {
      rate: 0,
      haveDescription: false,
      description: "",
    },
    speaking: {
      rate: 0,
      haveDescription: false,
      description: "",
    },
    vocabulary: {
      rate: 0,
      haveDescription: false,
      description: "",
    },
    grammar: {
      rate: 0,
      haveDescription: false,
      description: "",
    },
    problem: "",
    problemDescription: "",
    otherOpinion: "",
  },
  tutor: null,
  startTime: '',
  endTime: new Date(),

  learning_material: null,
  status: "request_pending",
  status_select: {
    label: "request_pending",
    value: "request_pending"
  },
  status_reason: "",
  class_duration_type: "20 minutes",
  subscription: null,
  docs: null,
  meeting: null,
  class_history: {
    tutor_socket: "",
    student_socket: "",
    has_tutor_joined: false,
    has_student_joined: false,
    tutor_join_timings: '',

    student_join_timings: '',
    student_connection_status: "not_initiated",
    tutor_connection_status: "not_initiated",
    t: "",
  },
  _id: "",
  created_at: ''
};
