import React from "react";
import DateMomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#272727",
    },
  },
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "2rem",
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: "2.28rem",
        padding: "0 2.85rem",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "130%",
        color: "#858585",
      },
      notchedOutline: {
        border: "none",
      },
      root: {
        background: "#fff",
        height: "8rem",
        borderRadius: "5px",
        border:" 1px solid #858585",
        "& hover": {
          curser: "pointer",
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiButtonBase: {
      root: {
        fontSize: "4rem",
      },
    },
    MuiButton: {
      root: {
        fontSize: "2.85rem",

        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiIconButton: {
      root: {
        "& svg": {
          fontSize: "4rem",
        },
        "&:focus": {
          outline: "none",
        },
      },
    },

    MuiTypography: {
      h5: {
        fontSize: "5rem",
        fontWeight: "bolder",
      },
      body1: {
        fontSize: "2.85rem",
      },
      body2: {
        fontSize: "2.85rem",
      },
      h4: {
        fontSize: "4rem",
      },
      subtitle1: {
        fontSize: "3rem",
      },
      caption: {
        fontSize: "2rem",
      },
    },
  },
});

interface Props {
  selectedDate: ParsableDate;
  onChange: (date: MaterialUiPickersDate) => void;
  clearable?: boolean;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  label?: string;
  inputVariant?: "standard" | "filled" | "outlined";
  fullWidth?: boolean;
  placeholder?: string;
  className?: string;
  views?: any;
  format?: string;
  error?: boolean;
  helperText?: string;
  onFocus?: any;
}

export function DatePicker({
  minDate,
  placeholder,
  maxDate,
  selectedDate,
  onChange,
  className,
  views,
  format,
  error,
  helperText,
  onFocus,
}: Props) {
  return (
    <div className={className}>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <KeyboardDatePicker
            error={error}
            helperText={helperText}
            margin="normal"
            inputVariant="outlined"
            views={views}
            format={format || "MM/DD/yyyy"}
            value={selectedDate}
            onChange={onChange}
            placeholder={placeholder}
            fullWidth
            minDate={minDate}
            maxDate={maxDate}
            onFocus={onFocus}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}
