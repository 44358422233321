import React from "react";
import { FaChevronDown } from "react-icons/fa";
import classes from "./ExpandMenu.module.css";
import { ReactNode } from "react";

interface Props {
  header: ReactNode;
  content: ReactNode;
  className?: string;
}

export const ExpandMenu = (props: Props) => {
  const [isExpanded, toggleExpanded] = React.useState(false);
  return (
    <div className={props.className}>
      <div
        onClick={() => toggleExpanded(!isExpanded)}
        className={
          isExpanded
            ? [classes.Root, classes.Root_open].join(" ")
            : classes.Root
        }
      >
        {props.header}
        <FaChevronDown
          className={
            isExpanded
              ? [classes.chevronIcon, classes.chevronIcon_open].join(" ")
              : classes.chevronIcon
          }
        />
      </div>
      {isExpanded && <div className={classes.ContentRoot}>{props.content}</div>}
    </div>
  );
};
