import React from "react";
import { CardModal } from "../../menus";
import classes from "./ApplicationInterviewerSelect.module.css";
import { useFormik } from "formik";
import { BorderedRadio } from "../../Common/BorderedRadio/BorderedRadio";
import { ChooseInterviewer_Payload, Member } from "../../../Types";

interface Props {
  onSubmitInterviewer?: (val: ChooseInterviewer_Payload) => void;
  onCancel?: () => void;
  interviewers: Member[];
}

export const ApplicationInterviewerSelect = (props: Props) => {
  const { onSubmitInterviewer, onCancel, interviewers } = props;
  const formik = useFormik<{ selectedInterviewer: Member | null }>({
    initialValues: {
      selectedInterviewer: null,
    },
    onSubmit: (values) => {
      if (onSubmitInterviewer && values.selectedInterviewer)
        onSubmitInterviewer({
          acceptBy: {
            name: `${values.selectedInterviewer.first_name} ${values.selectedInterviewer.last_name}`,
            interviewerId: values.selectedInterviewer._id,
          },
        });
    },
  });
  const { values, setFieldValue } = formik;
  return (
    <CardModal
      head={<span>Available Interviewers</span>}
      haveFromButtons
      className={classes.Root}
      outlineBtnText="Cancel"
      containedButtonText="Confirm"
      onContainedButtonClicked={formik.handleSubmit}
      onOutlineButtonClicked={onCancel}
      onClose={onCancel}
    >
      <div className="mb-5">
        {interviewers &&
          interviewers.map((interviewer) => {
            const isActive =
              values.selectedInterviewer !== null &&
              values.selectedInterviewer._id === interviewer._id;
            return (
              <BorderedRadio
                key={interviewer._id}
                className="mb-4"
                isSelected={isActive}
                checked={isActive}
                onClick={() =>
                  setFieldValue("selectedInterviewer", interviewer)
                }
                label={
                  <div className={classes.InterviewItemRoot}>
                    <h3>{`${interviewer.first_name} ${interviewer.last_name}`}</h3>
                    <h4>15 Total</h4>
                    <h5>2 This Week</h5>
                  </div>
                }
              />
            );
          })}
      </div>
    </CardModal>
  );
};
