export type AccountState = "active" | "ban";
export type UserTypes = "student" | "tutor" | "other";

export interface UserBaseInfo {
  first_name: string;
  last_name: string;
  email: string;
  status: AccountState | string;
  avatar_url: string | null;
  banData?: {
    total: number;
    remain: number;
  };
  registration_date: string;
  user_type: UserTypes | string;
  _id: string;
}

export const userBaseInfoInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  status: "active",
  avatar_url: "",
  banData: {
    total: 0,
    remain: 0,
  },
  registration_date: "",
  user_type: "student",
  _id: "",
};

export interface QuestionOptionsType {
  checked: boolean;
  label: string;
  _id: string;
}

export interface MatchmakingDataType {
  iconName?: string;
  options: QuestionOptionsType[];
  order: number;
  question_type: string;
  title: string;
  _id: string;
}

export interface TagType_Payload {
  titleEn: string;
  titleKo: string;
  matchingTags: string[];
}

export interface TagType extends TagType_Payload {
  _id: string;
  name: {
    en: string;
    ko: string;
  };
  user_type: UserTypes;
}

export interface Gallery_Payload {
  title: string;
  image: undefined | File | string;
}
