export interface Package_Payload {
  illustration: string | null | File;
  type: string;
  class_duration_number: number;
  titleEn: string;
  titleKo: string;
  classes_count: number;
  validityDuration: number;
  validity_date: string;
  packageMonthlyPrice: number;
  discount?: number;
  classes_remaining: number;
  package_name?: {
    en: string;
    ko: string;
  } & string;
  name?: {
    en: string;
    ko: string;
  } & string;
  total_classes: number;
  is_single_class: boolean
}

export const packageInitialState: Package_Payload = {
  illustration: null,
  type: "",
  class_duration_number: 20,
  titleEn: "",
  titleKo: "",
  classes_count: 0,
  validityDuration: 0,
  packageMonthlyPrice: 0,
  validity_date: "",
  classes_remaining: 0,
  total_classes: 0,
  is_single_class: false,
};

export interface PackageType extends Package_Payload {
  _id: string;
}
