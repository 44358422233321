import { InterviewAvailability } from "./interview";

export interface Login_Payload {
  username: string;
  password: string;
}

export interface Role {
  _id: string;
  title: string;
  permissions: {
    add_super_admin: boolean;
    ban_users: boolean;
    content: true;
    edit_members: boolean;
    edit_tutors: boolean;
    response_to_complaints: boolean;
  };
}

export interface IDs {
  id_type: string;
  file: string;
}

export interface Role_Payload {
  _id: string;
  title: string;
  permissions: {};
}
export interface IDs_Payload {
  file: null | string | File;
  id_types: string;
}

export interface Member_Payload {
  profile_image: string | File | undefined;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  email: string;
  username: string;
  address: string;
  dob: string;
  gender: string;
  employment_type: string;
  nationality: string;
  role: string;
  contract: string | File | undefined;
  ids: IDs_Payload[];
  account_status: "active";
  corporate_code: string;
}

export const memberInitialState: Member_Payload = {
  profile_image: undefined,
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  username: "",
  address: "",
  dob: new Date().toISOString(),
  gender: "",
  employment_type: "",
  nationality: "",
  role: "",
  contract: "",
  ids: [{ id_types: "", file: null }],
  account_status: "active",
  password: "",
  corporate_code: ""
};

export interface Member extends Omit<Member_Payload, "role"> {
  _id: string;
  role: Role;
}

export interface Interviewer extends Member {
  availabilities: InterviewAvailability[];
}

export interface BanUser_Payload {
  userId: string;
  banned_for_value: number;
  banned_for_duration: "d";
}
