import { MainRouteType } from "./../../../Types/ConfigTypes";

export const ProgramRoute: MainRouteType = {
  key: "Programs",
  title: "Program",
  path: "/b2b/programs",
  subRoutes: [
    {
      key: "Programs",
      title: "Program",
      path: "/b2b/programs",
    },
  ],
};
