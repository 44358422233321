import React from "react";
import { ReviewPartType } from "../../../Types";
import { IconGenerator, TextInput } from "../../Common";
import Rating from "@material-ui/lab/Rating";
import classes from "./TutorReviewItem.module.css";
import { Disclaimer } from "../../Common/Disclaimer/Disclaimer";

interface Props {
  titleChildren: string | JSX.Element;
  itemValue: ReviewPartType;
}

export const TutorReviewItem = ({ titleChildren, itemValue }: Props) => {
  const { rate, description } = itemValue;

  return (
    <div>
      <div className={classes.RateContainer}>
        {titleChildren && titleChildren}
        <Rating
          readOnly
          icon={<IconGenerator iconName="star" className={classes.FullStar} />}
          emptyIcon={
            <IconGenerator iconName="star" className={classes.EmptyStar} />
          }
          value={rate}
        />
      </div>
      {description && <Disclaimer label="Description" content={description} />}
    </div>
  );
};
