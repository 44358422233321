import React from "react";
import DateMomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  TimePicker as MaterialDatePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const materialTheme = () =>
  createMuiTheme({
    palette: {
      primary: {
        main: "#272727",
      },
    },
    overrides: {
      MuiFormControl: {
        marginNormal: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      MuiOutlinedInput: {
        input: {
          fontSize: "2.28rem",
          padding: "0 2.85rem",
          fontStyle: "normal",
          fontWeight: "normal",
          lineHeight: "130%",

          color: "#858585",
        },
        notchedOutline: {
          border: " 1px solid #858585",
        },
        root: {
          height: "8rem",
          borderRadius: "5px",
          //border:" 1px solid #858585"
          "& hover": {
            curser: "pointer",
          },
        },
        adornedEnd: {
          paddingRight: 0,
        },
      },
      MuiButtonBase: {
        root: {
          fontSize: "4rem",
        },
      },
      MuiButton: {
        root: {
          fontSize: "2.85rem",

          "&:focus": {
            outline: "none",
          },
        },
      },
      MuiIconButton: {
        root: {
          "& svg": {
            fontSize: "4rem",
          },
          "&:focus": {
            outline: "none",
          },
        },
      },
      MuiTypography: {
        root: {
          fontSize: "2.85rem",
        },
        body1: {
          fontSize: "2.85rem",
        },
        body2: {
          fontSize: "2.85rem",
        },
        h2: {
          fontSize: "8rem",
        },
        h4: {
          fontSize: "4rem",
        },
        subtitle1: {
          fontSize: "3rem",
        },
      },
      MuiDialogActions: {
        root: {
          "& button": {
            fontSize: "2.85rem",
          },
        },
      },
    },
  });

interface Props {
  selectedDate: ParsableDate;
  handleDateChange: (date: MaterialUiPickersDate) => void;
  clearable?: boolean;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  label?: string;
  ampm?: boolean;
  inputVariant?: "standard" | "filled" | "outlined";
  placeholder?: string;
  className?: string;
}

const TimePicker = ({
  selectedDate,
  handleDateChange,
  clearable,
  label,
  ampm,
  inputVariant,
  placeholder,
  className,
}: Props) => {
  return (
    <div className={className}>
      <ThemeProvider theme={materialTheme()}>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <MaterialDatePicker
            fullWidth
            clearable={clearable}
            ampm={ampm}
            label={label}
            value={selectedDate}
            onChange={handleDateChange}
            inputVariant={inputVariant}
            placeholder={placeholder}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

export default TimePicker;
