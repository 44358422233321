import { CheckboxProps, Checkbox as MaterialCheckbox, withStyles } from "@material-ui/core";
import React from "react";

const CustomCheckbox = withStyles({
  root: {
    color: "#272727",
    "&$checked": {
      color: "#272727",
    },
    "& svg": {
      width: "4rem",
      height: "4rem",
    },
  },
  checked: {},
})((props: CheckboxProps) => <MaterialCheckbox color="default" {...props} />);

export const Checkbox = (props: CheckboxProps) => {
  return <CustomCheckbox {...props} />;
};
