import BaseApi from "../BaseAPI";
import { Login_Payload } from "./../../../Types";

class AuthService extends BaseApi {
  constructor() {
    super({ suffix: "admin/login" });
  }
  loginUser = (data: Login_Payload) => {
    return this.httpService.post("", data);
  };
}

const AuthServiceInstance = new AuthService();
export default AuthServiceInstance;
