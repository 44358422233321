import BaseApi from "../BaseAPI";
import { Role_Payload } from "./../../../Types";

class RoleServices extends BaseApi {
  constructor() {
    super({ suffix: "admin/roles" });
  }
  getRoles = () => {
    return this.httpService.get("");
  };
  addNewRole = (data: Role_Payload) => {
    return this.httpService.post("", data);
  };
  editRole = (id: string, newData: Role_Payload) => {
    return this.httpService.patch(`${id}`, newData);
  };
}

const RoleServicesInstance = new RoleServices();
export default RoleServicesInstance;
