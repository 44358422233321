import {
  CommonState,
  commonActionTypes,
  GET_GALLERY_DATA,
} from "../ActionTypes";

const initialState: CommonState = {
  gallery: [],
};

export default function commonReducer(
  state = initialState,
  action: commonActionTypes
): CommonState {
  switch (action.type) {
    case GET_GALLERY_DATA:
      return {
        ...state,
        gallery: action.payload,
      };

    default:
      return state;
  }
}
