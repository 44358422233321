import { MainRouteType } from "./../../../Types/ConfigTypes";
export const OwnerRoute: MainRouteType = {
  key: "Owner",
  title: "Owner",
  path: "/owner/members",
  subRoutes: [
    {
      key: "Members",
      title: "Member",
      path: "/owner/members",
    },
    {
      key: "Roles",
      title: "Role",
      path: "/owner/roles",
    },
    {
      key: "Interviewers",
      title: "Interviewer",
      path: "/owner/interviewer",
    },
  ],
};
