import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { getAllCorporateRequests } from "../../Redux/Actions/corporateActions";
import { useTypedSelector } from "../../Redux/Reducers";
import { CorporateRequestsScreen } from "./CorpoateRequestsScreen";

export const CorporateRequestsScreenContainer = () => {
  const [hovered, setHovered] = useState(false);

  const showTooltip = (id: string, value: boolean) => {
    const node = document.getElementById(id);
    if (node && value) {
      node.style.overflow = "auto";
      node.style.textOverflow = "unset";
      node.style.lineHeight = "unset";
      node.style.height = `${node.scrollHeight}px`;
    }
    if (node && !value) {
      node.style.overflow = "hidden";
      node.style.textOverflow = "ellipsis";
      node.style.lineHeight = "2rem";
      node.style.height = "2rem";
    }

  };

  const columns: ColumnDescription[] = [
    {
      dataField: "_id",
      text: "Id",
    },
    {
      dataField: "created_at",
      text: "Date/Time",
      formatter: (_, row) => (
        <p
          style={{
            margin: 0,
          }}
        >
          {moment(row && row.created_at).format("YYYY.MM.DD HH:mm A")}
        </p>
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "consid",
      text: "Consideration",
      formatter: (_, row) => (
        <p
          onMouseEnter={() => showTooltip(row._id, true)}
          onMouseLeave={() => showTooltip(row._id, false)}
          id={row._id}
          style={{
            margin: 0,
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "2rem",
            height: "2rem",
            transition: 'height 1s, line-height 1s'
          }}
        >
          {row.consideration}
        </p>
      ),
    },
  ];

  const dispatch = useDispatch();
  const requests = useTypedSelector((state) => state.corporate.corpRequests);

  const [filteredRequests, setFilteredRequests] = useState<any[]>([]);
  const [startTime, setStartTime] = useState<MaterialUiPickersDate>(moment());
  const [endTime, setEndTime] = useState<MaterialUiPickersDate>(moment());

  useEffect(() => {
    setFilteredRequests(requests);
  }, [requests]);

  useEffect(() => {
    const filtered = requests.filter(
      (r) =>
        moment(r.created_at).isSameOrAfter(startTime) &&
        moment(r.created_at).isSameOrBefore(endTime)
    );
    setFilteredRequests(filtered);
  }, [startTime, endTime]);

  useEffect(() => {
    dispatch(getAllCorporateRequests());
  }, []);

  const onChangeTime = (
    startDate?: MaterialUiPickersDate,
    endDate?: MaterialUiPickersDate
  ) => {
    if (startDate) setStartTime(startDate);
    if (endDate) setEndTime(endDate);
  };

  return (
    <CorporateRequestsScreen
      onChangeTime={onChangeTime}
      startTime={startTime}
      endTime={endTime}
      requests={filteredRequests}
      columns={columns}
    />
  );
};
