import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";

import { DatePicker, SelectInput, TextInput } from "../../Common";
import classes from "./Filters.module.css";

interface Props {
  searchText: string;
  onSearch: (val: string) => void;
  onSetTimeFilters?: (
    start?: MaterialUiPickersDate,
    end?: MaterialUiPickersDate
  ) => void;
  startTime?: MaterialUiPickersDate;
  endTime?: MaterialUiPickersDate;
  semesterOptions: {
    label: string,
    value: string
  }[];
  setSortTypeHandler: (value: string | unknown) => void;
  sortTypeValue: string;
}


export const Filters = (props: Props) => {
  const { onSetTimeFilters, startTime, endTime, semesterOptions, sortTypeValue, setSortTypeHandler } = props;

  return (
    <div className={classes.Container}>
      <TextInput {...props} placeholder="Search" />
      <div className={classes.RightFiltersContainer}>
        {/* <DatePicker
          placeholder="Start Date"
          onChange={(newDate) =>
            onSetTimeFilters && onSetTimeFilters(newDate)
          }
          selectedDate={startTime}
        />
        <div style={{ width: "1rem" }} />
        <DatePicker
          placeholder="End Date"
          onChange={(newDate) =>
            onSetTimeFilters && onSetTimeFilters(undefined, newDate)
          }
          selectedDate={endTime}
        /> */}
        <SelectInput
          className={classes.Select}
          placeholder="Semester"
          variant="outlined"
          label="Semester"
          handleChange={(e) => setSortTypeHandler(e.target.value)}
          options={semesterOptions}
          value={sortTypeValue}
        />
      </div>
      {/* <SelectInput
        className={classes.Select}
        placeholder="Class State"
        variant="outlined"
        label="Order"
        handleChange={() => {}}
        options={[{ value: "Latest", label: "Latest" }]}
      /> */}
    </div>
  );
};
