import React, { ReactNode } from "react";

import classes from "./InputLabel.module.css";

interface Props {
  children: ReactNode;
  className?: string;
  style?: any;
  labelButton?: ReactNode;
}
export const InputLabel = ({
  children,
  className,
  style,
  labelButton,
}: Props) => {
  return (
    <div className={classes.Container}>
      <p
        style={style}
        className={[classes.inputLabel, className ? className : ""].join(" ")}
      >
        {children}
      </p>
      {labelButton}
    </div>
  );
};
