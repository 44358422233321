import enMessages from '../locals/en_Us';
import {LanguageType} from "../Types"

const EnLang:LanguageType = {
    messages: {
        ...enMessages
    },
    locale: 'en',
    // data: appLocaleData
};
export default EnLang;