import React, { ReactNode } from "react";
import Select, { GroupBase, SingleValue, StylesConfig } from "react-select";

import { InputLabel } from "../InputLabel/InputLabel";
import TextInput from "../TextInput/TextInput";
import classes from "./SearchableInput.module.css";

type Option = { label: string; value: string; email: string };
type IsMulti = false;

interface Props {
  data: Option[];
  label?: string;
  value: SingleValue<Option>;
  placeholder?: string;
  isSearchable?: boolean;
  onChange?: (newValue: SingleValue<Option>) => void;
}

export const SearchableInput = ({
  data,
  label,
  placeholder,
  value,
  isSearchable,
  onChange,
}: Props) => {
  const customStyles = (): StylesConfig<
    Option,
    IsMulti,
    GroupBase<Option>
  > => ({
    singleValue: (provided, state) => ({
      fontSize: "2.58rem",
      position: "absolute",
      paddingLeft: "2.58rem",
    }),
    container: (provided) => ({
      ...provided,
      height: "8rem"
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "8rem",
      height: "8rem"
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "8rem"
    }),

    input: () => ({
      fontSize: "2.58rem",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "2.58rem",
      padding: "2.58rem",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      scrollbarWidth: "none",
    }),
  });
  return (
    <div className={classes.Container}>
      {label && <InputLabel className={classes.inputLabel}>{label}</InputLabel>}
      <Select
        styles={customStyles()}
        options={data}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
