import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { CouponDetail } from "../../Components/Coupons/CouponDetail/CouponDetail";
import { CouponModal } from "../../Components/Coupons/CouponModal/CouponModal";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { closeGlobalDialog, openGlobalDialog, openModal } from "../../Redux/Actions/appStatusActions";
import { PackagesActions } from "../../Redux/Actions/packagesActions";
import { useTypedSelector } from "../../Redux/Reducers";

const tableColumns: ColumnDescription[] = [
  {
    text: "Id",
    dataField: "_id",
  },
  {
    text: "Coupon Code",
    dataField: "voucher_code",
  },
  {
    text: "Discount Rage",
    dataField: "discount_rate_2_3",
    formatter: (cell, row) => <p>{row.group ? row.discount_rate_2_3 : row.discount_individual}%</p>,
  },
  {
    text: "User Limit",
    dataField: "limit",
  },
  {
    text: "Expiration Date",
    dataField: "expire",
    formatter: (cell, row) => <p>{moment(row.expire).format("YYYY.MM.DD")}</p>,
  },
  {
    text: "Created Date",
    dataField: "created_at",
    formatter: (cell, row) => (
      <p>{moment(row.created_at).format("YYYY.MM.DD")}</p>
    ),
  },
  {
    text: "Updated Date",
    dataField: "updated_at",
    formatter: (cell, row) => (
      <p>{moment(row.updated_at).format("YYYY.MM.DD")}</p>
    ),
  },
];

export const CouponsScreenContainer = () => {
  const dispatch = useDispatch();
  const [{ pageState }, { onChangePageState }] = useDashboardPageLocalData();

  const data = useTypedSelector((state) => state.packages.coupons);

  useEffect(() => {
    dispatch(PackagesActions.getAllCoupons());
  }, []);

  const onClose = () => {
    dispatch(closeGlobalDialog());
  };

  const showCouponDetailModal = () => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: <CouponModal onClose={onClose} />,
      })
    );
  };

  const onEditCell = (row: any) => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: <CouponModal data={row} onClose={onClose} />,
      })
    );
  };


  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      tableColumns={tableColumns}
      data={data}
      addButtonAction={showCouponDetailModal}
      onEditCell={onEditCell}
    />
  );
};
