import React from "react";

import { CorporateDetail } from "../../../Types/corporate";
import classes from "./StudentBrief.module.css";
import { StudentDetail } from "./StudentDetail";
import { StudentDetailType } from "../../../Types";
import { useTypedSelector } from "../../../Redux/Reducers";

interface Props {
  classesLength: number;
  completedClassesLength: number;
  numberOfClasses: number;
}
export const StudentBrief = (props: Props) => {
  const { classesLength, completedClassesLength, numberOfClasses } = props;

  

  console.log( (completedClassesLength / numberOfClasses) * 100 , "props");

  const corporateDetail = useTypedSelector(
    (state) => state.corporate.corporateDetail
  );

  const studentDetail = useTypedSelector(
    (state) => state.student.studentDetails
  );

  return (
    <div className={classes.StudentBrief}>
      <p>Student Brief</p>
      <div className={classes.StudentDetailsContainer}>
        <StudentDetail
          title="Student Name"
          subTitle={
            studentDetail
              ? studentDetail.first_name + " " + studentDetail.last_name
              : ""
          }
        />
        <StudentDetail
          title="Number of classes"
          subTitle={numberOfClasses + " Classes"}
        />
        <StudentDetail
          title="Program Code"
          subTitle={corporateDetail ? corporateDetail.code : ""}
        />
        <StudentDetail
          title="Progress of completed classes"
          subTitle={
            completedClassesLength / classesLength === 0
              ? "-"
              : isNaN(completedClassesLength / classesLength)
              ? "-"
              : `${Math.round(
                  (completedClassesLength / numberOfClasses) * 100
                )}%`
          }
        />
      </div>
    </div>
  );
};
