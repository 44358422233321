import { Button } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { AccountStatusConfirmModal } from "../../Components/Classes/AccountStatusConfirmModal/AccountStatusConfirmModal";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { useTableFilters } from "../../Components/Hooks/useTableFilters";
import UserMenuButtons from "../../Components/menus/UserMenuButtons/UserMenuButtons";
import {
  closeGlobalDialog,
  openGlobalDialog,
} from "../../Redux/Actions/appStatusActions";
import { PackagesActions } from "../../Redux/Actions/packagesActions";
import { StudentActions } from "../../Redux/Actions/studentActions";
import { useTypedSelector } from "../../Redux/Reducers";
import { StudentDetailType, StudentType } from "../../Types";
import { UsersScreen } from "./UsersScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "_id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "Full Name",
    text: "Full Name",
    formatter: (cell, row, index) => (
      <p style={{ margin: 0 }}>{`${row.first_name} ${row.last_name}`}</p>
    ),
    filterValue: (cell, row) => `${row.first_name} ${row.last_name}`,
  },
  {
    dataField: "email",
    text: "Email/ID",
  },
  {
    dataField: "verified",
    text: "Verified",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {row.verified ? "TRUE" : "FALSE"}
        </p>
      );
    },
  },
  {
    dataField: "signup_via",
    text: "Quick Sign Up",
  },
  {
    dataField: "account_status",
    text: "Status",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.account_status === "active" ? "#56c795" : "#D70000",
          }}
        >
          {row.account_status === "active" ? "Active" : "Banned"}
        </p>
      );
    },
  },
  {
    dataField: "corporate",
    text: "B2B",
    formatter: (cell, row) => {
      return (
        <p style={{ margin: 0, color: row.corporate ? "#56c795" : "#D70000" }}>
          {row.corporate ? "TRUE" : "FALSE"}
        </p>
      );
    },
  },
  {
    dataField: "created_at",
    text: "Registration Date",
    formatter: (cell, row) => {
      return <p>{moment(row.created_at).format("MMM Do YYYY")}</p>;
    },
  },
];

export const UsersScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const [{ filters }, { updateFiltersDispatch }] =
    useTableFilters("studentTable");

  const students = useTypedSelector((state) => state.student.allStudents);

  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);

  const studentDetail = useTypedSelector(
    (state) => state.student.studentDetails
  );
  const [filteredStudents, setFilteredStudents] = useState<StudentDetailType[]>(
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSupervisor) {
      dispatch(StudentActions.getAllStudents());
      dispatch(PackagesActions.getFreePackages());
      dispatch(PackagesActions.getUserPackages());
      dispatch(StudentActions.getStudentMatchmakingQuestions());
    }
  }, []);

  useEffect(() => {
    filterStudentBasedOnType();
  }, [filters.data.studentType, students]);

  const filterStudentBasedOnType = () => {
    if (filters.data.studentType === "all")
      return setFilteredStudents(students);
    if (filters.data.studentType === "b2b")
      return setFilteredStudents(students.filter((st) => st.corporate));
    if (filters.data.studentType === "b2c")
      return setFilteredStudents(students.filter((st) => !st.corporate));
  };

  const onViewCellClick = (studentDetails: StudentType) => {
    onChangePageState("edit", studentDetails._id);
    dispatch(StudentActions.getStudentDetails(studentDetails._id));
  };

  const onCancelClicked = () => {
    dispatch(closeGlobalDialog());
  };

  const banUserHandler = () => {
    if (studentDetail && studentDetail._id) {
      dispatch(StudentActions.changeUserStatus(studentDetail._id, "ban"));
      onCancelClicked();
    }
  };

  const onUnBanUserHandler = () => {
    if (studentDetail?._id) {
      dispatch(StudentActions.changeUserStatus(studentDetail._id, "active"));
      onCancelClicked();
    }
  };

  const onUnBanUserClicked = () => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <AccountStatusConfirmModal
            title="Are you sure you want to un ban this student?"
            onChangeStatus={onUnBanUserHandler}
            onCancelClicked={onCancelClicked}
          />
        ),
      })
    );
  };

  const onBanUserClicked = () => {
    dispatch(
      openGlobalDialog({
        children: (
          <AccountStatusConfirmModal
            title="Are you sure you want to ban this student?"
            onChangeStatus={banUserHandler}
            onCancelClicked={onCancelClicked}
          />
        ),
        open: true,
      })
    );
  };

  return (
    <UsersScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      data={filteredStudents}
      onViewCellClick={onViewCellClick}
      EditPageHeaderComponent={
        isSupervisor
          ? undefined
          : () => (
              <UserMenuButtons
                onBanUserClicked={onBanUserClicked}
                onUnBanUserClicked={onUnBanUserClicked}
                status={
                  studentDetail?.account_status === "ban" ? "ban" : "active"
                }
              />
            )
      }
      studentType={filters.data.studentType}
      setStudentType={(val) =>
        updateFiltersDispatch({
          data: {
            studentType: val,
          },
        })
      }
    />
  );
};
