import React from "react";
import { SubRouteListObjectType } from "../../../Types";
import classes from "./SubRoutesNavigationCreator.module.css";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

interface Props {
  subRouteObject: SubRouteListObjectType;
  activeSubRoute: string;
  containerClassName?: string;
}

export const SubRoutesNavigationCreator = (props: Props) => {
  const { subRouteObject, containerClassName, activeSubRoute } = props;
  return (
    <div className={containerClassName}>
      {subRouteObject.map((item, index) => {
        const isActive = activeSubRoute === item.key;
        const ContainerClassName = isActive
          ? [classes.SubRouteContainer, classes.SubRouteContainer_Active]
          : [classes.SubRouteContainer];
        return (
          <Link
            to={item.path}
            key={index}
            className={ContainerClassName.join(" ")}
          >
            <h1>{item.title}</h1>
            <FaChevronRight />
          </Link>
        );
      })}
    </div>
  );
};

export default SubRoutesNavigationCreator;
