import { MainRouteType } from "../../../Types";

export const NotificationRoute: MainRouteType = {
  path: "/notifications",
  key: "Notifications",
  title: "Notification",
  subRoutes: [
    {
      key: "Notifications",
      title: "Notification",
      path: "/notifications",
    },
    {
      key: "Announcements",
      title: "Announcement",
      path: "/notifications/announcements",
    },
  ],
};
