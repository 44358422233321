import React from "react";
import { FaTimes } from "react-icons/fa";
import { Button } from "../../Common";
import classes from "./CardModal.module.css";

interface Props {
  className?: string;
  head?: React.ReactNode;
  children?: React.ReactNode;
  haveFromButtons?: boolean;
  outlineBtnText?: string;
  containedButtonText?: string;
  onOutlineButtonClicked?: () => void;
  onContainedButtonClicked?: () => void;
  onClose?: () => void;
}

export const CardModal = (props: Props) => {
  const {
    className,
    children,
    haveFromButtons,
    containedButtonText,
    outlineBtnText,
    onContainedButtonClicked,
    onOutlineButtonClicked,
    onClose,
    head
  } = props;
  return (
    <div className={className}>
      <div className={classes.Root}>
        <div className={classes.HeaderRoot}>
          <div>
            {head}
          </div>
          <Button
            onClick={onClose}
            variant="default"
            freeHeight
            className={classes.CloseButton}
          >
            <FaTimes />
          </Button>
        </div>
        <div className={classes.Content}>
          {children}{" "}
          {haveFromButtons && (
            <div className="d-flex align-items-center">
              <Button
                className="flex-grow-1 mr-4"
                onClick={onOutlineButtonClicked}
                variant="outlined"
              >
                <span>{outlineBtnText}</span>
              </Button>
              <Button
                className="flex-grow-1"
                onClick={onContainedButtonClicked}
                variant="contained"
              >
                <span>{containedButtonText}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
