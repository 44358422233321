import { styled, Switch, Theme } from "@material-ui/core";

interface SwitchProps {
  checked?: boolean;
  onChange: (e?: any, value?: boolean) => void;
  width?: string;
  height?: string;
}

interface f extends Theme {
  width?: string;
  height?: string;
}

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))<f>(({ theme }) => ({
  width: theme.width || '6rem',
  height: theme.height || '3.30rem',
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: '0.28rem',
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(2.7rem)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#2ECA45",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: '2.7rem',
    height: '2.7rem',
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
