import { ADD_LEARNING_MATERIAL_BY_MATERIALID, GET_ALL_LEARNING_MATERIALS, GET_ALL_LEARNING_MATERIALS_BY_MATERIALID, GET_LEARNING_MATERIALS_CATEGORIES, GET_LEARNING_MATERIALS_MAIN_CATEGORIES, learningMaterialActionTypes, LearningMaterialState, TOGGLE_LM_DISPLAY_ON_HOME_SCREEN } from "../ActionTypes";

const initialState: LearningMaterialState = {
  categories: [],
  mainCategories: [],
  allLearningMaterials: [],
  learningMaterialToEdit: {},
};

export default function learningMaterialReducer(
  state = initialState,
  action: learningMaterialActionTypes
): LearningMaterialState {
  switch (action.type) {
    case GET_ALL_LEARNING_MATERIALS:
      return {
        ...state,
        allLearningMaterials: action.payload,
      };
    case GET_LEARNING_MATERIALS_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case GET_LEARNING_MATERIALS_MAIN_CATEGORIES:
      return {
        ...state,
        mainCategories: action.payload,
      };
    case GET_ALL_LEARNING_MATERIALS_BY_MATERIALID:
      return {
        ...state,
        learningMaterialToEdit: action.payload,
      };
    case ADD_LEARNING_MATERIAL_BY_MATERIALID:
      return {
        ...state,
        learningMaterialToEdit: {
          ...state.learningMaterialToEdit,
          ...action.payload,
        },
      };
    case TOGGLE_LM_DISPLAY_ON_HOME_SCREEN:
      return {
        ...state,
        allLearningMaterials: state.allLearningMaterials.map((lm) => {
          if (lm.material_id === action.payload) {
            return {
              ...lm,
              home_screen_display: !lm.home_screen_display,
            };
          } else {
            return lm;
          }
        }),
      };

    default:
      return state;
  }
}
