import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PackagesActions } from "../../../Redux/Actions/packagesActions";

import { Button, DatePicker, FormikTextField, InputLabel } from "../../Common";
import { CardModal } from "../../menus";
import classes from "./CouponModal.module.css";

interface Props {
  onClose: () => void;
  data?: any;
}

export const CouponModal = ({ onClose, data }: Props) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    if (data)
      setInitialValues({
        ...data,
        expire: moment(data.expire).format("YYYY.MM.DD"),
      });
  }, [data]);

  return (
    <CardModal head={<span>Coupon Generator</span>} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          let updatedValues = {
            ...values,
          };

          if (values.group) {
            updatedValues.discount_rate_2_3 = parseInt(
              updatedValues.discount_rate_2_3
            );
          } else {
            updatedValues.discount_individual = parseInt(
              updatedValues.discount_individual
            );
          }

          updatedValues.limit = parseInt(values.limit);

          delete updatedValues._id;

          if (data) {
            dispatch(PackagesActions.updateCoupon(data._id, updatedValues));
          } else {
            dispatch(PackagesActions.addCoupon(updatedValues));
          }

          onClose()
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className={classes.Root}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <FormikTextField
                      name="voucher_code"
                      label="Coupon Code"
                      placeholder={"Coupon Code"}
                      className={classes.inputFields}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <FormikTextField
                      name={
                        values.group
                          ? "discount_rate_2_3"
                          : "discount_individual"
                      }
                      label="Discount Rate"
                      placeholder={"Discount Rate"}
                      className={classes.inputFields}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <FormikTextField
                      name="limit"
                      label="User Limit"
                      placeholder={"Users Limits"}
                      className={classes.inputFields}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputLabel>
                      <span>Expiration Date</span>
                    </InputLabel>
                    <DatePicker
                      minDate={moment()}
                      selectedDate={values.expire}
                      onChange={(date) => {
                        setFieldValue("expire", date);
                      }}
                      format="YYYY.MM.DD"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button variant="contained" type="submit">
                      <span>{data ? "Update" : "Save"}</span>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CardModal>
  );
};
