import React from "react";

import { Button } from "../";
import sprite from "../../../Resources/Images/SVG/sprite.svg";
import classes from "./PageHeader.module.css";

const BackButtonIcon = () => (
  <svg>
    <use href={`${sprite}#icon-arrow-left-box`} />
  </svg>
);

export const BackButton = ({ onClick }: any) => (
  <Button
    onClick={onClick}
    variant="default"
    freeHeight
    className={classes.BackButton}
  >
    <BackButtonIcon />
  </Button>
);

interface Props {
  haveBack: boolean;
  onBackClick?: () => void;
  title: string;
  className?: string;
  children?: React.ReactNode;
}

export const PageHeader = (props: Props) => {
  return (
    <div className={props.className}>
      <div className={classes.Root}>
        <div className={classes.TitleContainer}>
          {props.haveBack && <BackButton onClick={props.onBackClick} />}
          <h1 style={{ marginLeft: props.haveBack ? "4.28rem" : undefined }}>
            {props.title}
          </h1>
        </div>
        <div className={classes.ContentContainer}>{props.children}</div>
      </div>
    </div>
  );
};

export default PageHeader;
