import React, { ChangeEvent, useRef } from "react";
import { Button, IconGenerator, InputLabel, Alert } from "..";
import { openSnackBar } from "../../../Redux/Actions/appStatusActions";
import classes from "./FileSelector.module.css";
import { useDispatch } from "react-redux";

interface Props {
  limitSize?: number;
  className?: string;
  value?: File | null | string;
  onBigFileError?: () => void;
  onFileChoose?: (file: File | null) => void;
  onClearClick?: () => void;

  accept?: string;
  btnText: string;
  label?: string;
}

export const FileSelector = (props: Props) => {
  const {
    limitSize,
    className,
    value,
    onBigFileError,
    onFileChoose,
    onClearClick,
    accept,
    label,
    btnText,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  const onInputButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const _onFileChoose = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (files[0]) {
        if (limitSize && files[0].size > limitSize) {
          dispatch(
            openSnackBar({
              open: true,
              message: "Your File is bigger than limitation",
              variant: "error",
            })
          );
          if (onBigFileError) onBigFileError();
        } else {
          if (onFileChoose) {
            onFileChoose(files[0]);
          }
        }
      }
    } else {
      e.target.files = null;
      if (onFileChoose) {
        onFileChoose(null);
      }
    }
    e.target.value = "";
  };

  return (
    <div className={className}>
      <input
        type="file"
        ref={inputRef}
        className={classes.fileInputClassName}
        accept={accept}
        onChange={_onFileChoose}
      />
      <div>
        {label && <InputLabel>{label}</InputLabel>}
        <div className={classes.InputsContainer}>
          <Button
            onClick={onInputButtonClick}
            className={classes.ButtonClassName}
          >
            <IconGenerator iconName="plus-box" />
            <span>{btnText}</span>
          </Button>
          {value && value !== "null" && (
            <Alert
              onCloseClick={onClearClick}
              className={classes.AlertClassName}
            >
              {typeof value === "string" && (
                <a href={value} target="_blank" rel="noreferrer">
                  <span>{value.slice(0, 30) + "..."}</span>
                </a>
              )}
              {typeof value !== "string" && (
                <a
                  href={URL.createObjectURL(value)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{value.name.slice(0, 30) + "..."}</span>
                </a>
              )}
            </Alert>
          )}
        </div>
      </div>
      {limitSize && (
        <span className={classes.limitSizeText}>{`*Maximum file size is ${
          limitSize / 1000000
        } MBs.`}</span>
      )}
    </div>
  );
};
