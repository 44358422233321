import React from "react";
import { Button } from "../../Common";
import classes from "./TutorRates.module.css";
import Rating from "@material-ui/lab/Rating";
import { useDispatch } from "react-redux";
import { openGlobalDialog } from "../../../Redux/Actions/appStatusActions";
import { TutorRateDetails } from "../";

export const TutorRates = () => {
  const dispatch = useDispatch();
  const onOpenRates = () => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: <TutorRateDetails />,
        scroll:"body"
      })
    );
  };

  return (
    <div className={classes.Root}>
      <div className={classes.Ratings}>
        <p>Average Rating</p>
        <Rating readOnly classes={{ iconEmpty: classes.emptyIcon }} />
      </div>
      <Button onClick={onOpenRates} variant="contained">
        <span className="p-5">Details</span>
      </Button>
    </div>
  );
};
