import React from "react";
import { Button } from "../../Common";
import classes from "./ConfirmationModal.module.css";
interface Props {
  text: React.ReactNode;
  outlineBtnText?: string;
  containedButtonText?: string;
  onOutlineButtonClicked?: () => void;
  onContainedButtonClicked?: () => void;
}

export const ConfirmationModal = (props: Props) => {
  const {
    text,
    onContainedButtonClicked,
    onOutlineButtonClicked,
    outlineBtnText,
    containedButtonText,
  } = props;

  return (
    <div className={classes.Root}>
      <div className={classes.Text}>{text}</div>
      <div className={classes.ButtonsContainer}>
        <Button
          onClick={onOutlineButtonClicked}
          variant="outlined"
          color="primary"
          className="mr-4"
        >
          <span >{outlineBtnText}</span>
        </Button>
        <Button
          onClick={onContainedButtonClicked}
          variant="contained"
          color="primary"
        >
          <span className="p-5">{containedButtonText}</span>
        </Button>
      </div>
    </div>
  );
};
