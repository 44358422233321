import { EventType } from "@testing-library/react";

import EventHttpService from "../../Services/Http/Endpoints/EventServices";
import { EventType_Payload, LanguageVariantType } from "../../Types";
import { AppThunk, GET_ALL_EVENTS, GET_EVENT_DETAIL } from "../ActionTypes";
import { openSnackBar, setUploadProgress, toggleReduxLoading } from "./appStatusActions";
import { Common_ACTIONS } from "./commonActions";

const getAllEvents = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return EventHttpService.getAllEvents()
    .then((res) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_ALL_EVENTS,
        payload: res.data.response,
      });
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
    });
};

const getEventDetail =
  (eventId: string): AppThunk =>
  (dispatch) => {
    dispatch({
      type: GET_EVENT_DETAIL,
      payload: eventId,
    });
  };

const editEventAPI =
  (
    newData: EventType_Payload,
    eventId: string,
    language: LanguageVariantType
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return EventHttpService.updateEvent(newData, eventId, (e: any) => {
      dispatch(setUploadProgress((e.loaded * 100) / e.total));
    })
      .then((result) => {
        dispatch(toggleReduxLoading(false));
        dispatch(
          openSnackBar({
            open: true,
            message: "Event Edited Successfully",
            variant: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const addEventAPI =
  (
    newData: EventType_Payload,
    language: LanguageVariantType,
    onFinish?: (isOk: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return EventHttpService.addeEvent(newData, (e: any) => {
      dispatch(setUploadProgress((e.loaded * 100) / e.total));
    })
      .then((result) => {
        dispatch(toggleReduxLoading(false));
        if (onFinish) onFinish(true);
        dispatch(
          openSnackBar({
            open: true,
            message: "Event added Successfully",
            variant: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        if (onFinish) onFinish(false);
      });
  };
const editEventAction =
  (
    newData: EventType_Payload,
    eventId: string,
    language: LanguageVariantType,
    onFinish?: () => void
  ): AppThunk =>
  (dispatch) => {
    const { image } = newData;
    if (image === null) {
      dispatch(
        editEventAPI(
          {
            ...newData,
            image: "",
          },
          eventId,
          language
        )
      );
    } else if (image && typeof image === "string") {
      dispatch(editEventAPI(newData, eventId, language));
    } else {
      dispatch(
        Common_ACTIONS.uploadFile(image as File, (isOk, url: any) => {
          if (isOk) {
            dispatch(
              editEventAPI(
                {
                  ...newData,
                  image: url[0].location,
                },
                eventId,
                language
              )
            );
          }
        })
      );
    }
    onFinish && onFinish();
  };

const addEventAction =
  (
    newData: EventType_Payload,
    language: LanguageVariantType,
    onFinish?: (isOk: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    const { image } = newData;
    if (image === null) {
      dispatch(
        addEventAPI(
          {
            ...newData,
            image: "",
          },
          language
        )
      );
    } else if (image && typeof image === "string") {
      dispatch(addEventAPI(newData, language, onFinish));
    } else {
      dispatch(
        Common_ACTIONS.uploadFile(image as File, (isOk, url: any) => {
          if (isOk) {
            dispatch(
              addEventAPI(
                {
                  ...newData,
                  image: url[0].location,
                },

                language,
                onFinish
              )
            );
          }
        })
      );
    }
  };

const deleteEvent =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleReduxLoading(true));
      const res = await EventHttpService.deleteEvent(id);
      if (res.data.ok) {
        dispatch(
          openSnackBar({
            message: "Event has been deactivated",
            variant: "success",
            open: true,
          })
        );
        dispatch(getAllEvents());
      }
      dispatch(toggleReduxLoading(false));
    } catch (err) {
      dispatch(toggleReduxLoading(false));
      console.log(err);
    }
  };

export const displayEventAsActive =
  (data: any, eventId: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return EventHttpService.displayEventAsActive(data, eventId)
      .then((result) => {
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const EVENT_ACTIONS = {
  getAllEvents,
  getEventDetail,
  editEventAPI,
  editEventAction,
  addEventAction,
  deleteEvent,
  displayEventAsActive,
};
