import {
  GET_ALL_EVENTS,
  EventState,
  eventsActionTypes,
  GET_EVENT_DETAIL,
} from "../ActionTypes";

const initialState: EventState = {
  events: [],
  eventToEdit: {},
};

export default function learningMaterialReducer(
  state = initialState,
  action: eventsActionTypes
): EventState {
  switch (action.type) {
    case GET_ALL_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case GET_EVENT_DETAIL:
      const eventToEdit = state.events.find((ev) => ev._id === action.payload);
      if (eventToEdit)
        return {
          ...state,
          eventToEdit: {
            en: eventToEdit,
          },
        };
      else {
        return {
          ...state,
          eventToEdit: {},
        };
      }

    default:
      return state;
  }
}
