import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
import { BootstrapTableCellRenderer } from "../../Components/Common";
//import { BootstrapTableCellRenderer } from "../../Components/Common";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { GalleryEditorModal } from "../../Components/Gallery";
import {
  closeGlobalDialog,
  openGlobalDialog,
  openSnackBar,
} from "../../Redux/Actions/appStatusActions";
import { GalleryScreen } from "./GalleryScreen";
import { DashboardPageState } from "../../Types";
import { Common_ACTIONS } from "../../Redux/Actions/commonActions";
import { useTypedSelector } from "../../Redux/Reducers/index";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "url",
    text: "Image",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="image" />
    ),
  },
  {
    dataField: "title",
    text: "Title",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer
        value={cell.slice(0, 10) + "..."}
        type="text"
      />
    ),
  },
  {
    dataField: "url",
    text: "Url",
  },
];

export const GalleryScreenContainer = () => {
  const [{ pageState, selectedLanguage }, { setSelectedLanguage }] =
    useDashboardPageLocalData();

  const dispatch = useDispatch();

  const data = useTypedSelector((state) => state.common.gallery);

  const onShowDetails = (rowData: any, _rowIndex: number) => {
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <GalleryEditorModal
            selectedGallery={rowData}
            onClose={() => dispatch(closeGlobalDialog())}
            onSendImage={() => {}}
          />
        ),
      })
    );
  };

  const _onChangePageState = (state: DashboardPageState) => {
    if (state === "add") {
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <GalleryEditorModal
              selectedGallery={null}
              onClose={() => dispatch(closeGlobalDialog())}
              onSendImage={(val) => {
                dispatch(
                  Common_ACTIONS.uploadToGallery(val, (isOk) => {
                    if (isOk) {
                      dispatch(closeGlobalDialog());
                    }
                  })
                );
              }}
            />
          ),
        })
      );
    }
  };
  const onCopyClicked = (row: any) => {
    if (navigator) {
      navigator.clipboard.writeText(row.url);
      dispatch(
        openSnackBar({
          open: true,
          message: "Url copied to clipboard",
          variant: "success",
        })
      );
    }
  };

  React.useEffect(() => {
    dispatch(Common_ACTIONS.getGalleryData());
  }, [dispatch]);

  return (
    <GalleryScreen
      onChangePageState={_onChangePageState}
      onShowDetails={onShowDetails}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      data={data}
      onCopyClicked={onCopyClicked}
    />
  );
};
