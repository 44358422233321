import { Chip, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { TutorRates } from "../";
import { TutorsActions } from "../../../Redux/Actions/tutorsAction";
import { useTypedSelector } from "../../../Redux/Reducers";
import profilePicTmpImage from "../../../Resources/Images/1.jpg";
import { LanguageVariantType, TutorDetailType, tutorInitialState } from "../../../Types";
import { Alert, Button, CheckboxWithLabel, FormikTextArea, FormikTextField, TextInput } from "../../Common";
import { IOSSwitch } from "../../Common/IOSSwitch/IOSSwitch";
import { ExpandMenu, IconGenerator, InputLabel, ProfileBaseInfo } from "./../../Common";
import classes from "./TutorDetails.module.css";

const validationSchema = yup.object({});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  tutorDetail: TutorDetailType;
}

export const TutorDetails = ({
  lang,
  setSelectedLanguage,
  tutorDetail,
}: Props) => {
  const dispatch = useDispatch();
  const matchmakingQuestions = useTypedSelector(
    (state) => state.tutor.tutorMatchmakingQuestions
  );
  const [initialValues, setInitialValues] =
    useState<TutorDetailType>(tutorInitialState);

  React.useEffect(() => {
    if (tutorDetail) setInitialValues(tutorDetail);
  }, [tutorDetail]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        const changeKeys: string[] = [];
        Object.keys(initialValues).forEach((inkey) => {
          if (
            values[inkey as keyof TutorDetailType] !==
              initialValues[inkey as keyof TutorDetailType] &&
            values[inkey as keyof TutorDetailType]
          ) {
            changeKeys.push(inkey);
          }
        });
        let dataToUpdate: Partial<TutorDetailType> = {};
        changeKeys.forEach((ke) => {
          dataToUpdate = {
            ...dataToUpdate,
            [ke]: values[ke as keyof TutorDetailType],
          };
        });
        if (Object.keys(dataToUpdate).length) {
          dispatch(TutorsActions.updateTutorDetail(values._id, dataToUpdate));
        }
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <div>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ProfileBaseInfo
                        profilePicture={values.avatar_url || profilePicTmpImage}
                        className={classes.inputFields}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Phone Number"
                        placeholder={"12345678910"}
                        className={classes.inputFields}
                        name="phone_number"
                        onChange={(e) =>
                          setFieldValue("phone_number", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Email address"
                        placeholder={"Noooooooo@gmail.com..."}
                        className={classes.inputFields}
                        name="email"
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextInput
                        label="Date of Birth"
                        placeholder={"Aug 29th, 1959"}
                        className={classes.inputFields}
                        value={moment(values.date_of_birth).format(
                          "YYYY-MM-DD"
                        )}
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Nationality"
                        placeholder={"Iranian"}
                        className={classes.inputFields}
                        name="nationality"
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextInput
                        label="Date of joining Tooti"
                        placeholder={"Oct 30th, 2019"}
                        className={classes.inputFields}
                        value={moment(values.created_at).format("YYYY-MM-DD")}
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Tutor ID"
                        placeholder={"123456"}
                        className={classes.inputFields}
                        name="_id"
                        readonly={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="mb-4">
                        <TutorRates />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormikTextArea
                        label="Tutor Intro"
                        placeholder={"Maximum 500 characters"}
                        className={classes.inputFields}
                        name="selfDescription"
                        readonly={true}
                        rows={3}
                        limit={500}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Intro Video</span>
                      </InputLabel>
                      <div className="d-flex">
                        <Button color="primary" variant="contained">
                          <div className="px-5 d-flex align-items-center">
                            <IconGenerator iconName="Video" />
                            <span>View Intro Video</span>
                          </div>
                        </Button>
                      </div>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{ marginBottom: "2rem" }}
                        className={classes.inputFields}
                      >
                        <InputLabel>
                          <span>Matchmaking Data</span>
                        </InputLabel>
                        {matchmakingQuestions &&
                          matchmakingQuestions.length &&
                          matchmakingQuestions.map((question) => {
                            let answers =
                              tutorDetail &&
                              tutorDetail.matchmakingAnswers &&
                              tutorDetail.matchmakingAnswers.length
                                ? tutorDetail.matchmakingAnswers.filter(
                                    (answer) =>
                                      answer.questionId === question._id
                                  )[0].answer
                                : [];
                            return (
                              <ExpandMenu
                                className={classes.expandRoot}
                                header={<p>{question.title}</p>}
                                content={
                                  <div className={classes.expandContent}>
                                    {answers && answers.length ? (
                                      answers.map((ans: any) => (
                                        <CheckboxWithLabel
                                          checked={true}
                                          label={ans.label}
                                        />
                                      ))
                                    ) : (
                                      <Alert hideButton>
                                        <span>Not answered yet</span>
                                      </Alert>
                                    )}
                                  </div>
                                }
                              />
                            );
                          })}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={classes.inputFields}>
                        <InputLabel>
                          <span>Chosen Tags</span>
                        </InputLabel>
                        <div>
                          {values.tags.map((tag) => {
                            return (
                              <Chip
                                color="primary"
                                label={tag.name.en}
                                icon={<FaCheck />}
                                className="mr-4 mb-4"
                                key={tag._id}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Major"
                        placeholder={"Computer Engineering"}
                        name="major[0].text"
                        readonly={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="University"
                        placeholder={"Madrid University"}
                        name="university"
                        readonly={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>CV/Portfolio</span>
                      </InputLabel>
                      {values.portfolio &&
                        values.portfolio.attachment &&
                        values.portfolio.attachment !== "" && (
                          <a
                            href={values.portfolio.attachment}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Alert hideButton className="mb-4">
                              <span>Download</span>
                            </Alert>
                          </a>
                        )}
                      {values.portfolio &&
                        values.portfolio.url &&
                        values.portfolio.url !== "" && (
                          <a
                            href={values.portfolio.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Alert hideButton className="mb-4">
                              <span>View Link</span>
                            </Alert>
                          </a>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Show Tutor</span>
                      </InputLabel>
                      <IOSSwitch
                        checked={values.show}
                        onChange={() => {
                          setFieldValue("show", !values.show);
                          dispatch(TutorsActions.toggleTutor(values._id));
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{ marginBottom: "1rem" }}
                        className={classes.inputFields}
                      >
                        <InputLabel>
                          <span>Teaching Certificates</span>
                        </InputLabel>
                        {values.teachingCertificate.map((cert) => {
                          return (
                            <ExpandMenu
                              className={classes.expandRoot}
                              header={<CheckboxWithLabel label={cert.name} />}
                              content={
                                <div
                                  className={
                                    "d-flex justify-content-between px-5 pb-4"
                                  }
                                >
                                  <a
                                    href={cert.attachment}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button type="button" variant="contained">
                                      <div className="d-flex align-items-center p-4">
                                        <IconGenerator iconName="Video" />
                                        <span>Download File</span>
                                      </div>
                                    </Button>
                                  </a>
                                  <div className="d-flex align-align-items-center">
                                    <Button
                                      type="button"
                                      variant="outlined"
                                      className="mr-3"
                                    >
                                      <div className="d-flex align-items-center p-2">
                                        <span>Disapprove</span>
                                      </div>
                                    </Button>
                                    <Button type="button" variant="contained">
                                      <div className="d-flex align-items-center p-5">
                                        <span>Approve</span>
                                      </div>
                                    </Button>
                                  </div>
                                </div>
                              }
                            />
                          );
                        })}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Default Availability</span>
                      </InputLabel>
                      <ExpandMenu
                        className={classes.expandRoot}
                        header={
                          <CheckboxWithLabel label="TESOL" checked={true} />
                        }
                        content={
                          <div
                            className={
                              "d-flex justify-content-between px-5 pb-4"
                            }
                          >
                            <Button type="button" variant="contained">
                              <div className="d-flex align-items-center p-4">
                                <IconGenerator iconName="Video" />
                                <span>Download File</span>
                              </div>
                            </Button>
                          </div>
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    <div className="d-flex align-items-center p-4">
                      <span>Update tutor</span>
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </div>

            {/*<MaterialFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
            />*/}
          </Form>
        );
      }}
    </Formik>
  );
};
