import React from "react";

import { ColumnDescription } from "react-bootstrap-table-next";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { TutorDetails } from "../../Components/Tutors";
import {
  DashboardPageState,
  LanguageVariantType,
  TutorDetailType,
  TutorType,
} from "../../Types";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  tutorList: TutorType[];
  onTableDetailClick: (val: TutorType) => void;
  tutorDetail: TutorDetailType | null;
}

export const TutorScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    tutorList,
    onTableDetailClick,
    tutorDetail,
  } = props;

  const RenderDetailFlow = () => {
    if (tutorDetail)
      return (
        <>
          <TutorDetails
            lang={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            tutorDetail={tutorDetail}
          />
        </>
      );
    else {
      return null;
    }
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a Event";
      case "edit":
        return "Tutor Detail";
      default:
        return "Manage Tutor";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      EditFlowContent={() => RenderDetailFlow()}
      haveAddButton={false}
      onViewCellClick={onTableDetailClick}
      data={tutorList}
    />
  );
};
