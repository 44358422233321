import React from "react";
//import classes from "./LearningMaterialScreen.module.css";
import { ColumnDescription } from "react-bootstrap-table-next";
import {
  LanguageVariantType,
  DashboardPageState,
  Member,
  Role,
  Interviewer,
} from "../../Types";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
//import AudioSelector from "./../../Components/Common/AudioSelector/AudioSelector";
import {
  InterviewAvailabilities,
  InterviewAvailabilityItemModal,
} from "../../Components/Members";
import {
  closeGlobalDialog,
  openGlobalDialog,
} from "../../Redux/Actions/appStatusActions";
import { useDispatch } from "react-redux";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { Moment } from "moment-timezone";
import { InterviewAvailability_Payload } from "../../Types/interview";
import moment from "moment";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  onEditCell: (cell: Member) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  userList: Member[];
  roleList: Role[];
  currentDate: ParsableDate;
  setCurrentDate: (date: ParsableDate) => void;
  onSubmitAvailability: (data: InterviewAvailability_Payload[]) => void;
  interviewerToEdit: Interviewer | null;
}

export const InterviewerScreen = (props: Props) => {
  const dispatch = useDispatch();
  const {
    onChangePageState,
    pageState,
    tableColumns,
    //selectedLanguage,
    //setSelectedLanguage,
    userList,
    currentDate,
    setCurrentDate,
    onEditCell,
    onSubmitAvailability,
    //roleList,
    interviewerToEdit,
  } = props;

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a New Member";
      case "edit":
        return "Interview Availability";
      default:
        return "Interviewers of Tooti";
    }
  };

  const closeAvailabilityModal = () => {
    dispatch(closeGlobalDialog());
  };

  const onEditAvailabilityItemClicked = (date: Moment) => {
    const currentDateAvailabilities = interviewerToEdit
      ? interviewerToEdit.availabilities.find(
          (av) =>
            moment(av.day).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
        )
      : undefined;
    return dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <InterviewAvailabilityItemModal
            selectedDate={date}
            closeAvailabilityModal={closeAvailabilityModal}
            onSubmit={onSubmitAvailability}
            currentDateAvailabilities={currentDateAvailabilities}
          />
        ),
      })
    );
  };

  const RenderAddFlow = () => {
    return (
      <>
        <InterviewAvailabilities
          data={interviewerToEdit ? interviewerToEdit.availabilities : []}
          currentDate={currentDate}
          onDateSelect={setCurrentDate}
          onEditAvailabilityItemClicked={onEditAvailabilityItemClicked}
        />
      </>
    );
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      EditFlowContent={() => RenderAddFlow()}
      onEditCell={onEditCell}
      data={userList}
    />
  );
};
