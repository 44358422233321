import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";

import { AddEventBanner } from "../../Components/EventBanners";
import { CustomEventTableButtons } from "../../Components/EventBanners/CustomEventTableButtons/CustomEventTableButtons";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { DashboardPageState, LanguageVariantType } from "../../Types";
import { EventType, EventType_Payload } from "./../../Types/events";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  onTableEditClick: (rowData: EventType, rowIndex: number) => void;
  onSubmitForm: (materialData: EventType_Payload) => void;
  allEvents: EventType[];
  eventToEdit: {
    [key: string]: EventType;
  };
  onDeleteCell?: (row: EventType, rowIndex: number) => void;
  updateEvent: (row: EventType, active?: boolean) => void;
}

export const EventScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    onTableEditClick,
    allEvents,
    eventToEdit,
    onSubmitForm,
    onDeleteCell,
    updateEvent,
  } = props;

  const RenderAddFlow = () => {
    return (
      <>
        <AddEventBanner
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          eventToEdit={eventToEdit}
          onSubmitForm={onSubmitForm}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a Event";
      case "edit":
        return "Edit a Event";
      default:
        return "Event Banner Content Table";
    }
  };

  const customButtons = (row: any) => (
    <CustomEventTableButtons
      row={row}
      updateEvent={(active) => {
        updateEvent(row, active);
      }}
    />
  );

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      EditFlowContent={() => RenderAddFlow()}
      data={allEvents}
      onEditCell={onTableEditClick}
      onDeleteCell={onDeleteCell}
      customButtons={customButtons}
    />
  );
};
