import BaseApi from "../BaseAPI";
import { InterviewAvailability_Payload } from "../../../Types/interview";

class InterviewerServices extends BaseApi {
  constructor() {
    super({ suffix: "" });
  }

  getInterviewer = (interviewerId: string) => {
    return this.httpService.get(`admin/get_interviewers/${interviewerId}`);
  };
  createInterviewerAvailability = (
    interviewerId: string,
    availabilities: InterviewAvailability_Payload[]
  ) => {
    return this.httpService.patch("admin/create_interviewer", {
      interviewerId,
      availabilities,
    });
  };
}

const InterviewerServicesInstance = new InterviewerServices();
export default InterviewerServicesInstance;
