import React, { useState } from "react";

import { InputLabel } from "../../Common";
import { IOSSwitch } from "../../Common/IOSSwitch/IOSSwitch";
import classes from "./CustomEventTableButtons.module.css";

interface Props {
  row: any;
  updateEvent: (active?: boolean) => void;
}

export const CustomEventTableButtons = ({ row, updateEvent }: Props) => {
  const [active, setActive] = useState(row.active);
  return (
    <div className={classes.Root}>
      <div className={classes.SwitchButton}>
        <InputLabel>Active</InputLabel>
        <IOSSwitch
          checked={active}
          onChange={(e, value) => {
            setActive(value);
            updateEvent(value);
          }}
        />
      </div>
    </div>
  );
};
