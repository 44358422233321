import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { ApplicationScreen } from "./ApplicationsScreen";
//import { BootstrapTableCellRenderer } from "./../../Components/Common/BootstrapTableCellRenderer/BootstrapTableCellRenderer";
import { useTypedSelector } from "../../Redux/Reducers";
import { useDispatch } from "react-redux";
import { getAllMembers } from "../../Redux/Actions/userAndRoleActions";
import { TutorsActions } from "../../Redux/Actions/tutorsAction";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "bookedBy.name",
    text: "Full Name",
  },
  {
    dataField: "bookedBy.email",
    text: "Email Address",
  },
  {
    dataField: "status",
    text: "Account State",
  },
  {
    dataField: "preferredDate",
    text: "Interview Date",
  },
  {
    dataField: "interviewer.email",
    text: "Interviewer Email",
  },
  {
    dataField: "bookedBy.signupDate",
    text: "Sign Up Date",
  },
];

export const ApplicationScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();
  const dispatch = useDispatch();

  const interviewers = useTypedSelector((state) =>
    state.userAndRoles.members.filter(
      (m) => m.role._id === "612daeb2090bed10a39edd6c"
    )
  );

  const interviewList = useTypedSelector(
    (state) => state.tutor.tutorApplications
  );

  React.useEffect(() => {
    dispatch(TutorsActions.getTutorMatchmakingQuestions());
  }, [dispatch]);

  React.useEffect(() => {
    if (pageState === "main") {
      dispatch(TutorsActions.getTutorInterviewApplications());
    } else if (pageState === "add" || pageState === "edit") {
      dispatch(getAllMembers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  return (
    <ApplicationScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      interviewers={interviewers}
      interviewList={interviewList}
    />
  );
};
