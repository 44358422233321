export const EmploymentTypeOptions = [
  {
    label: "Full-time",
    value: "full-time",
  },
  {
    label: "Part-time",
    value: "part-time",
  },
  {
    label: "Other",
    value: "other",
  },
];
