export interface FeatureBannerType_Payload {
  image: null | string | File;
  firstOnTheQueue: boolean;
  title: string;
  description: string;
  type: string;
  haveMoreDetails: boolean;
  detailHtml?: string;
  linkUrl?: string;
}

export interface FeatureBannerType extends FeatureBannerType_Payload {
  _id: string;
}

export interface TypeOfFeatureBanners {
  title: string;
  id: string;
}


export const featureBannerPayloadInitialState: FeatureBannerType_Payload = {
  image: null,
  firstOnTheQueue: false,
  title: "",
  description: "",
  type: "",
  haveMoreDetails: false,
  detailHtml: "",
  linkUrl: "",
};
