const messages={
  "login.tabTitle": "Login",
  "login.username": "mobile",
  "login.password": "password",
  "login.btnTitle": "Login",
  "login.forgetPassText": "Forget Password?",
  "signup.tabTitle": "Register",
  "signup.name": "Name",
  "signup.family": "Family",
  "signup.mobile": "Mobile",
  "signup.email": "Email",
  "signup.job": "Job Title",
  "signup.password": "Password",
  "signup.confirmPassword": "Confirm Password",
  "signup.btnTitle": "Register",

}

export default messages;