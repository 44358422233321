import React from "react";
import classes from "./BorderedRadio.module.css";
import { Radio } from "@material-ui/core";

interface Props {
  className?: string;
  isSelected?: boolean;
  checked?: boolean;
  label?: string | React.ReactNode;
  onClick?: () => void;
}

export const BorderedRadio = (props: Props) => {
  const { className, isSelected, checked, label, onClick } = props;

  return (
    <div className={className}>
      <div
        onClick={onClick}
        className={
          isSelected
            ? [classes.Root, classes.Root_active].join(" ")
            : classes.Root
        }
      >
        <Radio checked={checked} />
        <span>{label}</span>
      </div>
    </div>
  );
};
