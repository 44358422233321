import React from "react";
import classes from "./YouTubeAdder.module.css";
import { Grid } from "@material-ui/core";
import {
  FormikTextField,
  Button,
  Separator,
  InputLabel,
  IconGenerator,
} from "../../Common";
import { mediaInitialState, MediaType_Payload } from "../../../Types";

interface Props {
  className?: string;
  values: MediaType_Payload[];
  onAddMedia: (value: MediaType_Payload) => void;
  onRemoveMedia: (index: number) => boolean | undefined;
}

export const YoutubeAdder = (props: Props) => {
  const { className, onAddMedia, onRemoveMedia, values } = props;
  return (
    <div className={className}>
      <div className={classes.Root}>
        <InputLabel>
          <span>*Youtube links are automatically titled.</span>
        </InputLabel>
        {values &&
          typeof values !=="string"&&
          values.length > 0 &&
          values.map((_, mediaIndex) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    onClick={() => onRemoveMedia(mediaIndex)}
                    freeHeight
                    variant="default"
                    className={classes.removeButton}
                  >
                    <IconGenerator iconName="close-box" />
                  </Button>
                  <FormikTextField
                    name={`media.${mediaIndex}.url`}
                    label="Link Url"
                    placeholder="Url..."
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormikTextField
                    label="Link Title"
                    placeholder="Title..."
                    name={`media.${mediaIndex}.title`}
                  />
                </Grid>
              </Grid>
              <Separator color="secondary" className={classes.SeparatorRoot} />
            </>
          ))}
        <Button variant="link" onClick={() => onAddMedia(mediaInitialState)}>
          <span>+ Add Another Link ...</span>
        </Button>
      </div>
    </div>
  );
};
