import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { useTableFilters } from "../../Components/Hooks/useTableFilters";
import { getCorporateDetail, getCorporateStudents } from "../../Redux/Actions/corporateActions";
import { useTypedSelector } from "../../Redux/Reducers";
import { StudentDetailType } from "../../Types";
import { ProgramScreen } from "./ProgramScreen";

const columns: ColumnDescription[] = [
  {
    dataField: "_id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index + 1}</p>,
  },
  {
    dataField: "Student Name",
    text: "Student",
    formatter: (cell, row, index) => (
      <p style={{ margin: 0 }}>{`${row.first_name} ${row.last_name}`}</p>
    ),
    filterValue: (cell, row) => {
      return `${row.first_name} ${row.last_name}`;
    },
  },
  {
    dataField: "email",
    text: "Email/ID",
  },
  {
    dataField: "Package",
    text: "Package",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {row.user_packages[0] && row.user_packages[0].package_name}
        </p>
      );
    },
  },
  {
    dataField: "Semester",
    text: "Semester",
    formatter: (cell, row) => (
      <p
        style={{
          margin: 0,
        }}
      >
        {row && row.corporateSemester && row.corporateSemester[0]}
      </p>
    ),
  },
  {
    dataField: "Duration",
    text: "Duration",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {row.user_packages[0] && row.user_packages[0].class_duration_number}
          -min
        </p>
      );
    },
  },

  {
    dataField: "Class Remaining",
    text: "Credit",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {row.user_packages[0] &&
            row.user_packages[0].classes_remaining +
              "/" +
              row.user_packages[0].total_classes}
        </p>
      );
    },
  },

  {
    dataField: "Progress",
    text: "Progress",
    formatter: (cell, row) => {
      const percent =
        (row.user_packages[0] &&
          row.user_packages[0].classes_remaining /
            row.user_packages[0].total_classes) * 100;

      return (
        <p
          style={{
            margin: 0,
            textAlign: "left",
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {100 - (percent * 100) / 100 === 0
            ? "-"
            : isNaN(percent)
            ? "-"
            : `${Math.round(100 - (percent * 100) / 100)}%`}
        </p>
      );
    },
  },

  {
    dataField: "Level Test",
    text: "Pre-Test",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {row.level_test ? row.level_test : 0}
        </p>
      );
    },
  },
  {
    dataField: "Level Test",
    text: "Post-Test",
    formatter: (cell, row) => {
      return (
        <p
          style={{
            margin: 0,
            color: row.verified ? "#56c795" : "#D70000",
          }}
        >
          {row.post_level ? row.post_level : 0}
        </p>
      );
    },
  },
  // {
  //   dataField: "signup_via",
  //   text: "Signed up with",
  // },
  // {
  //   dataField: "account_status",
  //   text: "Account State",
  //   formatter: (cell, row) => {
  //     return (
  //       <p
  //         style={{
  //           margin: 0,
  //           color: row.account_status === "active" ? "#56c795" : "#D70000",
  //         }}
  //       >
  //         {row.account_status === "active" ? "Active" : "Banned"}
  //       </p>
  //     );
  //   },
  //   filterValue: (cell, row) => {
  //     return row.account_status === "active" ? "Active" : "Banned";
  //   },
  // },
  // {
  //   dataField: "Performance",
  //   text: "performance",
  //   formatter: (cell, row) => {
  //     console.log(row, "rowrow");
  //     return (
  //       <>
  //         <p>Speaking: {row.current_result_average.speaking}</p>
  //         <p>Pronunciation: {row.current_result_average.pronunciation}</p>
  //         <p>Listening: {row.current_result_average.listening}</p>
  //         <p>Vocabulary: {row.current_result_average.vocabulary}</p>
  //         <p>Grammar: {row.current_result_average.grammar}</p>
  //       </>
  //     );
  //   },
  //   filterValue: (cell, row) => {
  //     return row.account_status === "active" ? "Active" : "Banned";
  //   },
  // },

  {
    dataField: "created_at",
    text: "Registration Date",
    formatter: (cell, row) => {
      return <p>{moment(row.created_at).format("MMM Do YYYY")}</p>;
    },
    filterValue: (cell, row) => {
      return moment(row.created_at).format("MMM Do YYYY");
    },
  },
];

export const ProgramScreenContainer = () => {
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.auth.user);

  const corporateDetail = useTypedSelector(
    (state) => state.corporate.corporateDetail
  );
  const students = useTypedSelector((state) => state.corporate.corpStudents);

  const [{ filters }, { updateFiltersDispatch }] = useTableFilters(
    "studentProgramTable"
  );
  const [startTime, setStartTime] = useState<
    MaterialUiPickersDate | undefined
  >();
  const [endTime, setEndTime] = useState<MaterialUiPickersDate | undefined>();
  const [semesterOptions, setSemesterOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [filteredStudents, setFilteredStudents] = useState<StudentDetailType[]>(
    []
  );

  useEffect(() => {
    if (user && !corporateDetail) {
      dispatch(getCorporateDetail(user.corporate_code));
    }
  }, [user]);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  useEffect(() => {
    if (filters.data.semester && filters.data.semester.value === "all")
      return setFilteredStudents(students);

    const studentsWithSemester: StudentDetailType[] = [];
    students.forEach((student) => {
      if (
        student.corporateSemester?.includes(
          (filters.data.semester && filters.data.semester.value) || ""
        )
      ) {
        studentsWithSemester.push(student);
      }
    });

    setFilteredStudents(studentsWithSemester);
  }, [filters.data.semester]);

  useEffect(() => {
    if (corporateDetail) {
      const semesterList = [
        {
          label: "All",
          value: "all",
        },
      ];

      corporateDetail.semseter &&
        corporateDetail.semseter.students &&
        corporateDetail.semseter.students.forEach((s) => {
          semesterList.push({
            label: `Semester No.${s.semester}`,
            value: s.semester,
          });
        });

      setSemesterOptions(semesterList);
    }
  }, [corporateDetail]);

  const onSetTimeFilters = (
    start?: MaterialUiPickersDate,
    end?: MaterialUiPickersDate
  ) => {
    if (start) setStartTime(start);
    if (end) setEndTime(end);
  };

  const setSortTypeHandler = (value: string | unknown) => {
    updateFiltersDispatch({
      data: {
        semester: semesterOptions.filter((op) => op.value === value)[0],
      },
    });
  };

  useEffect(() => {
    if (corporateDetail?._id && startTime && endTime)
      dispatch(getCorporateStudents(corporateDetail?._id, startTime, endTime));
  }, [startTime, endTime]);

  if (!user) {
    return null;
  }

  return (
    <ProgramScreen
      startTime={startTime}
      endTime={endTime}
      students={filteredStudents}
      corporateDetail={corporateDetail}
      columns={columns}
      onSetTimeFilters={onSetTimeFilters}
      semesterOptions={semesterOptions}
      sortTypeValue={
        (filters.data.semester && filters.data.semester.value) || ""
      }
      setSortTypeHandler={setSortTypeHandler}
    />
  );
};
