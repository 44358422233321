import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { create } from "jss";
import React from "react";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  HashRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { ENV } from "../src/env/base";
import { CustomizedSnackbar, ProtectedRoute } from "./Components/Common";
import { GlobalDialog } from "./Components/Common/GlobalDialog/GlobalDialog";
import { LottieLoading } from "./Components/Common/LottieLoading";
import DashboardLayout from "./Components/HOC/Layout/DashboardLayout/DashboardLayout";
import { allAppRoutes } from "./Config/Constants/AppRoutes";
import AppLocal from "./Config/Language";
import getTheme from "./Config/ThemeCreator";
import { getSupervisorDetail } from "./Redux/Actions/corporateActions";
import { getUserDetail } from "./Redux/Actions/userAndRoleActions";
import { TOGGLE_IS_AUTHENTICATING } from "./Redux/ActionTypes";
import { useTypedSelector } from "./Redux/Reducers";
import { LoginScreen, ScreenFactory } from "./Screens";

const { TOKEN_STORAGE_KEY } = ENV;
//Routes

// Configure JSS

function AppContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuth, isAuthenticating } = useTypedSelector((state) => state.auth);
  const language = useTypedSelector((state) => state.status.lang);
  const history = useHistory();

  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);

  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  useEffect(() => {
    const search = history.location;
    if (search.hash === "#/login/corporate" || (isSupervisor && token)) {
      dispatch(getSupervisorDetail(history));
    }
    if (token) {
      dispatch(getUserDetail(history));
    }
  }, []);

  useEffect(() => {
    if (!token) {
      window.location.href ='/#/login'
    }
  }, [token]);

  const jss = create({ plugins: [...jssPreset().plugins] });

  const direction = "ltr";
  const currentAppLocal = AppLocal[language];

  const _renderDashboardFlow = () => {
    return !isAuthenticating ? (
      <DashboardLayout>
        <Switch>
          {allAppRoutes.map((route) => {
            const subRoute = route.subRoutes;
            if (
              isSupervisor &&
              (route.key === "Programs" || route.key === "Classes") &&
              subRoute
            ) {
              return subRoute.map((sb, index) => {
                return (
                  <ProtectedRoute
                    exact
                    key={index}
                    isAuth={isAuth}
                    isAuthenticating={isAuthenticating}
                    path={sb.path}
                    ComponentToRender={() => <ScreenFactory variant={sb.key} />}
                  />
                );
              });
            } else if (subRoute) {
              return subRoute.map((sb, index) => {
                return (
                  <ProtectedRoute
                    exact
                    key={index}
                    isAuth={isAuth}
                    isAuthenticating={isAuthenticating}
                    path={sb.path}
                    ComponentToRender={() => <ScreenFactory variant={sb.key} />}
                  />
                );
              });
            }
          })}
          <Route
            render={() => (
              <Redirect
                to={
                  isSupervisor ? "/b2b/programs" : "/content/learningMaterials"
                }
              />
            )}
          />
        </Switch>
      </DashboardLayout>
    ) : (
      <LottieLoading width={"200px"} height="200px" />
    );
  };

  return (
    <IntlProvider
      locale={currentAppLocal.locale}
      messages={currentAppLocal.messages}
    >
      <div className={"leftToRightText"}>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={getTheme(direction)}>
            <CustomizedSnackbar />
            <LottieLoading isOverlay={true} />
            <GlobalDialog />
            <Router>
              <Switch>
                <Route path="/login/corporate" component={LoginScreen} />
                <Route path="/login" component={LoginScreen} />
                {_renderDashboardFlow()}
                <Route component={() => <p>404 page</p>} />
              </Switch>
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </div>
    </IntlProvider>
  );
}

export default AppContainer;
