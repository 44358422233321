//import { ParsableDate } from "../../HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { Moment } from "moment-timezone";
import React from "react";
//import classes from "./LearningMaterialScreen.module.css";
import { ColumnDescription } from "react-bootstrap-table-next";

import { InterviewAvailability } from "../../../Types";
//import { CustomBootstrapTable, DatePicker } from "../../../Types";
import { CustomBootstrapTable, DatePicker } from "../../Common";
import { BootstrapTableCellRenderer } from "../../Common/BootstrapTableCellRenderer/BootstrapTableCellRenderer";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index + 1}</p>,
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => <p>{cell.format("MMM Do,YYYY")}</p>,
  },
  {
    dataField: "available",
    text: "Available",
    formatter: (cell) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
  {
    dataField: "booked",
    text: "Booked",
    formatter: (cell) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
];

interface Props {
  data: InterviewAvailability[];
  onEditAvailabilityItemClicked: (date: Moment) => void;
  currentDate: ParsableDate;
  onDateSelect: (date: MaterialUiPickersDate) => void;
}

export const InterviewAvailabilities = (props: Props) => {
  const { data, onEditAvailabilityItemClicked, currentDate, onDateSelect } =
    props;

  const _onEditAvailabilityClicked = (date: Moment) => {
    onEditAvailabilityItemClicked(date);
  };
  const [allDaysOfMonth, setAllDaysOfMonth] = React.useState<any[]>([]);
  console.log(data);

  React.useEffect(() => {
    if (currentDate) {
      const date = new Date(currentDate.toString());
      const allDays = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      if (currentDate)
        setAllDaysOfMonth(
          [...new Array(allDays)].map((_, index) => {
            const date = moment(new Date(currentDate as Date).toUTCString())
              .date(index + 2)
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .utc();
            const dateInData = data.find((d) => {
              console.log(moment(d.day).format("YYYY-MM-DD"), "yep");
              return (
                moment(d.day).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
              );
            });
            return {
              date,
              booked: dateInData
                ? dateInData.timeSlots.reduce((isBooked, tm) => {
                    if (isBooked) {
                      return true;
                    } else {
                      return tm.booked;
                    }
                  }, false)
                : false,
              available: dateInData !== undefined,
            };
          })
        );
    }
  }, [currentDate, data]);

  return (
    <div>
      <div className="d-flex">
        <DatePicker
          views={["year", "month"]}
          inputVariant="outlined"
          format={"MMMM, YYYY"}
          selectedDate={currentDate}
          onChange={onDateSelect}
          className="mb-3"
        />
      </div>
      <CustomBootstrapTable
        keyField="id"
        data={allDaysOfMonth}
        columns={tableColumns}
        // onEditCell={(cellData) => _onEditAvailabilityClicked(cellData.date)}
      />
    </div>
  );
};
