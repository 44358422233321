import { MainRouteType } from "./../../../Types/ConfigTypes";

export const CorporateRequestRoute: MainRouteType = {
  key: "Requests",
  title: "Corporate Request",
  path: "/corporate/requests",
  subRoutes: [
    {
      key: "Requests",
      title: "Corporate Request",
      path: "/corporate/requests",
    },
  ],
};
