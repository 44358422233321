import React from "react";
import classes from "./QuestionAdder.module.css";

import { Grid } from "@material-ui/core";
import {
  FormikTextArea,
  Button,
  Separator,
  ImagePicker,
  IconGenerator,
} from "../../Common";
import { Field, FieldProps } from "formik";
import { Question_Payload } from "../../../Types";

const imageLimitSize = 200000;

interface Props {
  className?: string;
  values?: Question_Payload[];
  onAddQuestion?: () => void;
  onRemoveQuestion: (index: number) => void;
}

const QUESTION_LIMIT = 20;
const QuestionAdder = (props: Props) => {
  const { className, values, onAddQuestion, onRemoveQuestion } = props;

  return (
    <div className={className}>
      <div className={classes.Root}>
        {values &&
          values.map((_, index) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {index !== 0 && (
                    <Button
                      onClick={() => onRemoveQuestion(index)}
                      freeHeight
                      variant="default"
                      className={classes.removeButton}
                    >
                      <IconGenerator iconName="close-box" />
                    </Button>
                  )}

                  <FormikTextArea
                    label="Question_Payload*"
                    placeholder="In English..."
                    rows={3}
                    limit={300}
                    name={`questions.${index}.question_title`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Field name={`questions.${index}.img_url`}>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }: FieldProps) => (
                      <ImagePicker
                        imageSize="14rem"
                        fileSizeLimit={imageLimitSize}
                        value={
                          field.value &&
                          field.value !== "" &&
                          field.value !== "false" &&
                          field.value !== "true"
                            ? field.value
                            : null
                        }
                        onFileChoose={(file) =>
                          setFieldValue(`questions.${index}.img_url`, file)
                        }
                        onCleanFile={() =>
                          setFieldValue(`questions.${index}.img_url`, null)
                        }
                        onBigFileError={() => console.log("big file")}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Separator color="secondary" className={classes.SeparatorRoot} />
            </>
          ))}

        <Button
          onClick={
            values && values.length < QUESTION_LIMIT ? onAddQuestion : undefined
          }
          variant="link"
        >
          <span>{`+ Add Another Question_Payload (${values?.length}/${QUESTION_LIMIT})`}</span>
        </Button>
      </div>
    </div>
  );
};

export default QuestionAdder;
