import { idText } from "typescript";

import CommonHttpService from "../../Services/Http/Endpoints/CommonServices";
import StudentServices from "../../Services/Http/Endpoints/StudentServices";
import { AccountState, StudentDetailType, StudentType } from "../../Types";
import {
  AppThunk,
  GET_ALL_STUDENTS,
  GET_STUDENT_DETAIL,
  GET_STUDENT_MATCHMAKING_QUESTIONS,
} from "../ActionTypes";
import { openSnackBar, toggleReduxLoading } from "./appStatusActions";

const getAllStudents = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return StudentServices.getAllStudents()
    .then((res) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_ALL_STUDENTS,
        payload: res.data.data,
      });
    })
    .catch(() => {
      dispatch(toggleReduxLoading(false));
    });
};

const getStudentDetails =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return StudentServices.getStudentDetails(id)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        return dispatch(storeStudentDetail(res.data.data));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        console.log(err);
      });
  };

const storeStudentDetail = (data: StudentDetailType | null) => ({
  type: GET_STUDENT_DETAIL,
  payload: data,
});

// const getStudentMatchmakingQuestions = (): AppThunk => (dispatch) => {
//   dispatch(toggleReduxLoading(true));
//   CommonHttpService.getMatchmakingQuestions("tutor")
//     .then((res) => {
//       dispatch({
//         type: GET_TUTOR_MATCHMAKING_QUESTIONS,
//         payload: res.data.questions,
//       });
//       dispatch(toggleReduxLoading(false));
//     })
//     .catch((err) => {
//       dispatch(toggleReduxLoading(false));
//     });
// };

const changeUserStatus =
  (id: string, status: AccountState): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return StudentServices.changeUserStatus(id, status)
      .then((res) => {
        if (res.data.ok) {
          dispatch(
            openSnackBar({
              open: true,
              message: "User Account Status Changed",
              variant: "success",
            })
          );
        }
        dispatch(getStudentDetails(id));
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const updateStudent =
  (id: string, data: Partial<StudentDetailType>): AppThunk =>
  async (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return StudentServices.updateStudent(id, data)
      .then((res) => {
        if (res.data.ok) {
          dispatch(getStudentDetails(id));
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(toggleReduxLoading(false));
      });
  };

const unBanStudent =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return StudentServices.unBanUser(id)
      .then(() => {
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const getStudentMatchmakingQuestions = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  CommonHttpService.getMatchmakingQuestions("student")
    .then((res) => {
      dispatch({
        type: GET_STUDENT_MATCHMAKING_QUESTIONS,
        payload: res.data.questions,
      });
      dispatch(toggleReduxLoading(false));
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
    });
};

const unlockFreePackage =
  (id: string, email: string, studentId: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return StudentServices.unlockFreePackage(id, email)
      .then((res) => {
        if (res.data.ok) {
          dispatch(
            openSnackBar({
              open: true,
              message: "A free package is added the student",
              variant: "success",
            })
          );
          dispatch(getStudentDetails(studentId));
        }
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleReduxLoading(false));
      });
  };

const updatePackage =
  (
    id: string,
    email: string,
    studentId: string,
    classes_remaining: number,
    validity_date: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return StudentServices.updatePackage(
      id,
      email,
      classes_remaining,
      validity_date
    )
      .then((res) => {
        if (res.data.ok) {
          dispatch(
            openSnackBar({
              open: true,
              message: "A package was updated",
              variant: "success",
            })
          );
          dispatch(getStudentDetails(studentId));
        }
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleReduxLoading(false));
      });
  };

export const StudentActions = {
  getAllStudents,
  getStudentDetails,
  unBanStudent,
  changeUserStatus,
  getStudentMatchmakingQuestions,
  unlockFreePackage,
  updateStudent,
  storeStudentDetail,
  updatePackage,
};
