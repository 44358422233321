import React from "react";
import { useDispatch } from "react-redux";

import { closeGlobalDialog, openGlobalDialog } from "../../../Redux/Actions/appStatusActions";
import { AccountState } from "../../../Types";
import { Button, InputLabel } from "../../Common";
import SelectInput from "../../Common/SelectInput";
import classes from "./UserMenuButtons.module.css";

interface Props {
  status?: AccountState;
  onBanUserClicked?: () => void;
  onUnBanUserClicked?: () => void;
  removeUserHandler?: (id: string) => void;
}

const UserMenuButtons = (props: Props) => {
  const { status, onBanUserClicked, removeUserHandler, onUnBanUserClicked } =
    props;

  return (
    <div className={classes.Root}>
      <div className={classes.FormContainer}>
        <InputLabel>
          <span>Account State</span>
        </InputLabel>
        <div className={classes.ButtonsContainer}>
          <div className={classes.UserStatus}>
            <span>{status === "ban" ? "Banned" : "Active"}</span>
          </div>
          <Button
            variant="contained"
            color="danger"
            className={classes.BanUserButton}
            onClick={status === "ban" ? onUnBanUserClicked : onBanUserClicked}
          >
            <span>{status === "active" ? "Ban" : "Un ban"} User</span>
          </Button>
          {/* <Button
            onClick={removeUserHandler}
            variant="outlined"
            className={classes.RemoveButton}
          >
            <span>Remove User</span>
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default UserMenuButtons;
