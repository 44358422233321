import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ClassesRoute, ContentRoute, NotificationRoute, OwnerRoute, PackagesRoute, ProgramRoute, ReviewsRoute, TutorsRoute, UsersRoute } from "../../../../Config/Constants/AppRoutes";
import { CorporateRequestRoute } from "../../../../Config/Constants/AppRoutes/CorporateRequestRoute";
import { ENV } from "../../../../env/base";
import { logoutUser } from "../../../../Redux/Actions/authActions";
import { useTypedSelector } from "../../../../Redux/Reducers";
import tmpProfileUrl from "../../../../Resources/Images/profile.png";
import { DashboardNavigationItemListType, DashboardTabProps, SideNavigationCreator, SubRoutesNavigationCreator } from "../../../Common";
import { MainRouteType } from "./../../../../Types/ConfigTypes";
import { Button } from "./../../../Common/Button/Button";
import classes from "./DashboardLayout.module.css";

const { TOKEN_STORAGE_KEY } = ENV;

interface Props {
  children: React.ReactNode;
}

const DashboardLayout = (props: Props) => {
  const [activeMainRoute, setActiveMainRoute] =
    React.useState<MainRouteType | null>(null);
  const [activeSubRoute, setActiveSubRoute] = React.useState("");
  const [showSubRoutes, toggleSubRoutes] = React.useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);

  const { children } = props;

  const top: Array<DashboardTabProps> = !isSupervisor
    ? [
        {
          routeData: {
            key: "Home",
            title: "",
            path: "/",
          },
          iconLink: `icon-tooti-logo`,
          type: "navLink",
          iconType: "sprite",
          hideOnMobile: true,
        },
        {
          routeData: OwnerRoute,
          iconType: "img",
          iconLink: tmpProfileUrl,
          type: "navLink",
        },
        {
          routeData: ContentRoute,
          iconLink: `icon-edit-box`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: ClassesRoute,
          iconLink: `icon-Video`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: UsersRoute,
          iconLink: `icon-user-group`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: TutorsRoute,
          iconLink: `icon-Work`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: ReviewsRoute,
          iconLink: `icon-Chat`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: NotificationRoute,
          iconLink: `icon-Notification`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: PackagesRoute,
          iconLink: `icon-Category`,
          type: "navLink",
          iconType: "sprite",
        },
        {
          routeData: CorporateRequestRoute,
          iconLink: `icon-b2b-request`,
          type: "navLink",
          iconType: "sprite",
        },
      ]
    : [
        {
          routeData: {
            key: "Home",
            title: "",
            path: "/",
          },
          iconLink: `icon-tooti-logo`,
          type: "navLink",
          iconType: "sprite",
          hideOnMobile: true,
        },
        {
          routeData: ProgramRoute,
          iconLink: `icon-Category`,
          type: "navLink",
          iconType: "sprite",
        },
      ];

  const navigationItemList: DashboardNavigationItemListType = {
    top: [...top],
    bottom: [
      {
        routeData: {
          key: "NavButton",
          title: "Logout",
          path: "null",
        },
        iconLink: `icon-logout`,
        type: "navButton",
        iconType: "sprite",
        onClickButton: () => {
          dispatch(logoutUser(history));
          history.push("./login");
        },
      },
    ],
  };

  React.useEffect(() => {
    const pathName = history.location.pathname;
    navigationItemList.top.forEach((navItem) => {
      if (pathName.includes(navItem.routeData.key.toLocaleLowerCase())) {
        setActiveMainRoute(navItem.routeData);
        const subRoutes = navItem.routeData.subRoutes;
        if (subRoutes) {
          subRoutes.forEach((sb) => {
            if (pathName.includes(sb.path)) {
              setActiveSubRoute(sb.key);
            }
          });
        }
      }
    });
  }, [history.location.pathname, navigationItemList.top]);

  const haveSubRoute =
    activeMainRoute?.subRoutes && activeMainRoute.subRoutes.length > 1;

  return (
    <div className={classes.Root}>
      <div className={classes.NavigationRoot}>
        <SideNavigationCreator
          navigationItems={navigationItemList}
          activeTabName={activeMainRoute ? activeMainRoute.key : ""}
        />
      </div>

      <div
        className={
          haveSubRoute && showSubRoutes
            ? classes.SubNavigationRoot
            : [
                classes.SubNavigationRoot_disable,
                classes.SubNavigationRoot,
              ].join(" ")
        }
      >
        {haveSubRoute && (
          <SubRoutesNavigationCreator
            containerClassName={classes.SubRoutesContainer}
            subRouteObject={
              activeMainRoute && activeMainRoute.subRoutes
                ? activeMainRoute.subRoutes
                : []
            }
            activeSubRoute={activeSubRoute}
          />
        )}
      </div>

      <div
        className={
          haveSubRoute && showSubRoutes
            ? classes.PageRoot
            : [classes.PageRoot, classes.PageRoot_FullScreen].join(" ")
        }
      >
        {children}
        {haveSubRoute && (
          <Button
            onClick={() => toggleSubRoutes(!showSubRoutes)}
            className={
              showSubRoutes
                ? classes.SubRootCollapseButton
                : [
                    classes.SubRootCollapseButton,
                    classes.SubRootCollapseButton_closed,
                  ].join(" ")
            }
          >
            <FaChevronLeft />
          </Button>
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
