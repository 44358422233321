import React from "react";
import { RolesScreen } from "./RolesScreen";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { useDispatch } from "react-redux";
import { getAllRoles } from "../../Redux/Actions/userAndRoleActions";
import { useTypedSelector } from "../../Redux/Reducers/index";
import { BootstrapTableCellRenderer } from "../../Components/Common";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "title",
    text: "Title",
  },
  {
    dataField: "permissions.edit_members",
    text: "Edit Members",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
  {
    dataField: "permissions.edit_tutors",
    text: "Edit Tutors",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
  {
    dataField: "permissions.content",
    text: "Add/Edit Content",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
  {
    dataField: "permissions.add_super_admin",
    text: "Add Super Admin",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
  {
    dataField: "permissions.ban_users",
    text: "Ban/UnBan Users",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
  {
    dataField: "permissions.response_to_complaints",
    text: "Response to Complaints",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="boolean" value={cell} />
    ),
  },
];

export const RolesScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const dispatch = useDispatch();
  const roles = useTypedSelector((state) => state.userAndRoles.roles);

  React.useEffect(() => {
    dispatch(getAllRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RolesScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      data={roles}
    />
  );
};
