import React from "react";
import { LanguageVariantType } from "../../Types";

interface Props<T> {
  itemToEdit: {
    [key: string]: T;
  };
  defaultValues: T;
  lang: LanguageVariantType;
}

type ReturnType<T> = [initialValues: T, onResetForm: () => void];
const useFormAdderTools = <T>(props: Props<T>):ReturnType<T> => {
  const { defaultValues, itemToEdit, lang } = props;
  const [initialValues, setInitialValues] = React.useState<T>(defaultValues);

  React.useEffect(() => {
    if (itemToEdit[lang] !== undefined) {
      setInitialValues(itemToEdit[lang]);
    } else {
      const otherLang = itemToEdit[lang === "en" ? "ko" : "en"];
      if (otherLang !== undefined) {
        setInitialValues(otherLang);
      } else {
        setInitialValues(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const onResetForm = () => {
    setInitialValues(initialValues);
  };

  return [initialValues, onResetForm];
};

export default useFormAdderTools;
