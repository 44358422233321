import React from "react";
import { IconGenerator, iconVariant } from "..";
import classes from "./SimpleCard.module.css";

interface Props {
  title: string;
  onClick?: () => void;
  withIcon?: boolean;
  noRightSection?: boolean;
  iconName?: iconVariant;
  noViewDetails?: boolean;
  style?: any;
}

export const SimpleCard = (props: Props) => {
  const { title, onClick, withIcon, noRightSection, iconName, noViewDetails, style } =
    props;
  return (
    <div style={style} className={classes.Container}>
      <div className={classes.Left}>
        <p>{title}</p>
      </div>
      {!noRightSection && (
        <div onClick={onClick} className={classes.Right}>
          {withIcon && (
            <IconGenerator iconName={iconName ? iconName : "star"} />
          )}
          {!noViewDetails && <p>View Details</p>}
        </div>
      )}
    </div>
  );
};
