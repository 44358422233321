import React from "react";
import { InterviewerScreen } from "./InterviewerScreen";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useTypedSelector } from "../../Redux/Reducers/index";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { useDispatch } from "react-redux";
import { getAllMembers } from "../../Redux/Actions/userAndRoleActions";
import { getAllRoles } from "../../Redux/Actions/userAndRoleActions";
import { DashboardPageState, Member } from "../../Types";
import { useHistory } from "react-router-dom";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { BootstrapTableCellRenderer } from "../../Components/Common";
import { InterviewersActions } from "../../Redux/Actions/interviewerActions";
import { InterviewAvailability_Payload } from "../../Types/interview";
import { closeGlobalDialog } from "../../Redux/Actions/appStatusActions";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "profile_image",
    text: "Image",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="image" value={cell} />
    ),
  },
  {
    dataField: "first_name+last_name",
    text: "Full Name",
    formatter: (_, cellData, __) => (
      <BootstrapTableCellRenderer
        type="text"
        value={cellData.first_name + " " + cellData.last_name}
      />
    ),
  },
  {
    dataField: "role.title",
    text: "Role",
  },
  {
    dataField: "account_status",
    text: "Account State",
  },
  {
    dataField: "uploadData",
    text: "Tooti Age",
  },
  {
    dataField: "uploadData",
    text: "Interviews",
  },
];

export const InterviewerScreenContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const users = useTypedSelector((state) =>
    state.userAndRoles.members.filter(
      (m) => m.role._id === "612daeb2090bed10a39edd6c"
    )
  );
  const roles = useTypedSelector((state) => state.userAndRoles.roles);
  const interviewerToEdit = useTypedSelector(
    (state) => state.interviewers.interviewer
  );
  const [currentDate, setCurrentDate] = React.useState<ParsableDate>(
    new Date()
  );

  const onEditCellClicked = (cell: Member) => {
    onChangePageState("edit", cell._id);
  };

  const onSubmitAvailability = (data: InterviewAvailability_Payload[]) => {
    if (idToEdit)
      dispatch(
        InterviewersActions.createInterviewerAvailability(
          idToEdit,
          data,
          (isOk) => {
            if (isOk) {
              dispatch(closeGlobalDialog());
              dispatch(InterviewersActions.getInterviewer(idToEdit));
            }
          }
        )
      );
  };

  React.useEffect(() => {
    if (pageState === "main") {
      dispatch(getAllMembers());
    } else if (pageState === "add") {
      dispatch(getAllRoles());
    } else if (pageState === "edit" && idToEdit) {
      setCurrentDate(new Date());
      dispatch(InterviewersActions.getInterviewer(idToEdit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, idToEdit]);

  const _onChangePageState = (state: DashboardPageState) => {
    if (state === "add") {
      history.push("/owner/members?mode=add");
    } else {
      onChangePageState(state);
    }
  };

  return (
    <InterviewerScreen
      onChangePageState={_onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      userList={users}
      roleList={roles}
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
      onEditCell={onEditCellClicked}
      onSubmitAvailability={onSubmitAvailability}
      interviewerToEdit={interviewerToEdit}
    />
  );
};
