import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FaClipboard } from "react-icons/fa";

import { StudentDetailType, StudentType } from "../../../Types";
import { CorporateDetail } from "../../../Types/corporate";
import { Button, CustomBootstrapTable, IconGenerator } from "../../Common";
import { Filters } from "../Filters/Filters";
import { ProgramBrief } from "../ProgramBrief/ProgramBrief";
import Data from "./data.json";
import classes from "./Program.module.css";

interface Props {
  columns: ColumnDescription[];
  corporateDetail: CorporateDetail;
  students: StudentDetailType[];
  onOpenClasses?: (row: StudentDetailType, rowIndex?: number) => void;
  onOpenStudentDetails?: (row: StudentType, rowIndex?: number) => void;
  onSetTimeFilters?: (start?: MaterialUiPickersDate, end?: MaterialUiPickersDate) => void;
  startTime?: MaterialUiPickersDate;
  endTime?: MaterialUiPickersDate;
  semesterOptions: {
    label: string,
    value: string
  }[];
  setSortTypeHandler: (value: string | unknown) => void;
  sortTypeValue: string;
}

export const Program = (props: Props) => {
  const {
    columns,
    corporateDetail,
    students,
    onOpenClasses,
    onOpenStudentDetails,
    onSetTimeFilters,
    startTime,
    endTime,
    semesterOptions,
    sortTypeValue,
    setSortTypeHandler
  } = props;

  const TableButtonFormatter = (cell: any, row: any, rowIndex: number) => {
    return (
      <div className={classes.TableButtonsContainer}>
        {onOpenClasses && (
          <Button
            onClick={() => onOpenClasses(row, rowIndex)}
            freeHeight
            variant="default"
            className={classes.TableButtons}
          >
            <IconGenerator iconName="detail" />
          </Button>
        )}
        {onOpenStudentDetails && (
          <Button
            onClick={() => onOpenStudentDetails(row, rowIndex)}
            freeHeight
            variant="default"
            className={classes.TableButtons}
          >
            <IconGenerator iconName="edit-box" />
          </Button>
        )}
      </div>
    );
  };

  return (
    <div>
      <ProgramBrief
        numberOfStudent={students.length}
        corporateDetail={corporateDetail}
      />
      <ToolkitProvider
        data={students ? students : []}
        columns={[
          ...columns,
          {
            dataField: "bgd",
            text: "",
            formatter: TableButtonFormatter,
          },
        ]}
        keyField="id"
        search
      >
        {(props) => (
          <div className={classes.Root}>
            <p className={classes.Title}>Enrolled Students</p>
            <div className={classes.TableContainer}>
              <Filters
                {...props.searchProps}
                startTime={startTime}
                endTime={endTime}
                onSetTimeFilters={onSetTimeFilters}
                semesterOptions={semesterOptions}
                sortTypeValue={sortTypeValue}
                setSortTypeHandler={setSortTypeHandler}
              />
              <CustomBootstrapTable
                rowClassName={classes.RowClasses}
                headerClassName={classes.TableHeader}
                className={classes.TableRoot}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};
