import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { BootstrapTableCellRenderer } from "../../Components/Common";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { TutorsActions } from "../../Redux/Actions/tutorsAction";
import { useTypedSelector } from "../../Redux/Reducers/index";
import { TutorType } from "../../Types";
import { TutorScreen } from "./TutorScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "avatar_url",
    text: "Image",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="image" />
    ),
  },
  {
    dataField: "first_name",
    text: "Full Name",
    formatter: (_, data, __) => (
      <BootstrapTableCellRenderer
        value={data.first_name + " " + data.last_name}
        type="text"
      />
    ),
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "ratings",
    text: "Rating",
  },
  {
    dataField: "profile_status",
    text: "Account State",
  },
  {
    dataField: "_id",
    text: "Tooti ID",
  },
  {
    dataField: "age",
    text: "Tooti age",
  },
  {
    dataField: "show",
    text: "Should Show",
    formatter: (cell, row) => {
      return <p style={{
        color: row.show ? "#56c795" : "#d70000"
      }}>{row.show ? "TRUE" : "FALSE"}</p>;
    },
  },
];

export const TutorScreenContainer = () => {
  const dispatch = useDispatch();
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const tutorList = useTypedSelector((state) => state.tutor.allTutors);

  const tutorDetail = useTypedSelector((state) => state.tutor.tutorDetails);

  const onTableDetailClick = (rowData: TutorType) => {
    if (rowData._id) {
      onChangePageState("edit", rowData._id);
    }
  };

  React.useEffect(() => {
    dispatch(TutorsActions.getTutorMatchmakingQuestions());
  }, [dispatch]);

  React.useEffect(() => {
    if (pageState === "main") {
      dispatch(TutorsActions.getAllTutors());
    } else if (pageState === "add" || pageState === "edit") {
      if (idToEdit) dispatch(TutorsActions.getTutorDetails(idToEdit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, idToEdit]);
  return (
    <TutorScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      tutorList={tutorList}
      tutorDetail={tutorDetail}
      onTableDetailClick={onTableDetailClick}
    />
  );
};
