import React from "react";
import { Button } from "../../Common";
import classes from "./AccountStatusConfirmModal.module.css";

interface Props {
  onCancelClicked: () => void;
  onChangeStatus: () => void;
  title: string;
}

export const AccountStatusConfirmModal = (props: Props) => {
  const { onCancelClicked, onChangeStatus, title } = props;
  return (
    <div className={classes.BanUserRoot}>
      <p>{title}</p>
      <div className={classes.BanUserButtonsContainer}>
        <Button
          className={classes.BanUserCancel}
          variant="outlined"
          color="primary"
          onClick={onCancelClicked}
        >
          <span>Cancel</span>
        </Button>
        <Button
          className={classes.BanUserConfirm}
          variant="contained"
          color="primary"
          onClick={onChangeStatus}
        >
          <span>Yes</span>
        </Button>
      </div>
    </div>
  );
};
