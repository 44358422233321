import { MainRouteType } from "./../../../Types/ConfigTypes";

export const ClassesRoute: MainRouteType = {
  key: "Classes",
  title: "Class",
  path: "/classes",
  subRoutes: [
    {
      key: "Classes",
      title: "Class",
      path: "/classes",
    },
  ],
};
