import { Grid } from "@material-ui/core";
import React from "react";
import { LanguageVariantType } from "../../../Types";
import {
  InputLabel,
  TextInput,
  TextAreaInput,
  CheckboxWithLabel,
} from "../../Common";
import classes from "./TestimonialDetails.module.css";
import { Rating } from "@material-ui/lab";

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
}

export const TestimonialDetails = (props: Props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div>
            <TextInput label="Full Name" />
            <div className={classes.AnonymityRoot}>
              <p>This user asked for anonymity</p>
            </div>
          </div>
          <div className={[classes.Ratings, "mt-5"].join(" ")}>
            <InputLabel>
              <span>Rating</span>
            </InputLabel>
            <Rating />
          </div>

          <TextInput label="Testimonial Title" className="mt-5" />

          <TextAreaInput
            label="Testimonial Description"
            className="mt-5"
            rows={3}
          />

          <div className={[classes.ShowOnHomePage, "mt-5"].join(" ")}>
            <CheckboxWithLabel label="Show on Homepage" labelPlacement="end" />
            <span>{`${11}/15 Testimonials Shown`}</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
