import React, { ReactNode } from "react";
import classes from "./TextAreaInput.module.css";
import { InputLabel } from "../";
import { ErrorMessage } from "formik";

export interface TextAreaProps {
  className?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  id?: string;
  name?: string;
  limit?: number;
  multiLine?: boolean;
  rows?: number;
  type?: "email" | "number" | "password" | "tel" | "text" | "url";
  disabled?: boolean;
  notFormikInput?: boolean;
  readonly?: boolean;
}

export const TextAreaInput = (props: TextAreaProps) => {
  const {
    className,
    label,
    startAdornment,
    endAdornment,
    placeholder,
    helperText,
    error,
    onChange,
    onBlur,
    onFocus,
    value,
    id,
    name,
    limit,
    rows,
    disabled,
    notFormikInput,
    readonly,
  } = props;

  const _onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (limit) {
      if (e.target.value.length <= limit) {
        if (onChange) onChange(e);
      }
    } else {
      if (onChange) onChange(e);
    }
  };

  const InputRootClassName = error
    ? [classes.inputRoot, classes.inputRoot_Error].join(" ")
    : classes.inputRoot;
  return (
    <div className={className}>
      <div
        className={
          disabled
            ? [classes.Root, classes.Root_disabled].join(" ")
            : classes.Root
        }
      >
        {(limit !== undefined || label !== undefined) && (
          <div className={classes.topInput}>
            {label && (
              <InputLabel className={classes.inputLabel}>{label}</InputLabel>
            )}
            {limit && value && (
              <p className={classes.LimitText}>
                {value.toString().length}/{limit}
              </p>
            )}
          </div>
        )}
        <div
          style={{ height: `${(rows ? rows : 1) * 8}rem` }}
          className={InputRootClassName}
        >
          {startAdornment && (
            <div className={classes.inputAdornment}>{startAdornment}</div>
          )}
          <textarea
            disabled={disabled}
            id={id}
            name={name}
            value={value}
            onChange={_onInputChange}
            onBlur={onBlur}
            onFocus={onFocus}
            className={classes.inputTag}
            placeholder={placeholder}
            rows={rows}
            readOnly={readonly && readonly}
          />
          {endAdornment && (
            <div className={classes.inputAdornment}>{endAdornment}</div>
          )}
        </div>
        {name && !notFormikInput && (
          <ErrorMessage name={name}>
            {(msg) => (
              <span
                className={
                  name !== undefined
                    ? [
                        classes.inputHelperText,
                        classes.inputHelperText_error,
                      ].join(" ")
                    : classes.inputHelperText
                }
              >
                {msg}
              </span>
            )}
          </ErrorMessage>
        )}
        {helperText && (
          <span
            className={
              error
                ? [classes.inputHelperText, classes.inputHelperText_error].join(
                    " "
                  )
                : classes.inputHelperText
            }
          >
            {helperText}
          </span>
        )}
      </div>
    </div>
  );
};
