import { CheckboxProps, FormControlLabel } from "@material-ui/core";
import React from "react";
import { Checkbox } from "../";
import classes from "./CheckboxWithLabel.module.css"

interface Props {
  label?: string | React.ReactNode;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  className?: string;
}

export const CheckboxWithLabel = ({
  label,
  labelPlacement,
  className,
  ...props
}: CheckboxProps & Props) => {
  return (
    <FormControlLabel
      classes={{
        label:classes.Label
      }}
      control={<Checkbox {...props} />}
      label={label}
      labelPlacement={labelPlacement}
      className={className}
    />
  );
};
