import {
  TutorReducerType,
  TutorActionType,
  GET_ALL_TUTORS,
  GET_TUTOR_DETAIL,
  GET_APPLICATION_REJECTION_REASONS,
  GET_TUTOR_APPLICATIONS,
  GET_TUTOR_APPLICATION_DETAILS,
  GET_TUTOR_RATES,
  GET_TUTOR_MATCHMAKING_QUESTIONS
} from "../ActionTypes";

const initialState: TutorReducerType = {
  allTutors: [],
  tutorDetails: null,
  tutorApplications: [],
  applicationDetails: null,
  applicationRejectionReasons: [],
  tutorRates: null,
  tutorMatchmakingQuestions: []
};

export default function ClassReducer(
  state = initialState,
  action: TutorActionType
): TutorReducerType {
  switch (action.type) {
    case GET_TUTOR_MATCHMAKING_QUESTIONS:
      return {
        ...state,
        tutorMatchmakingQuestions: action.payload
      }
    case GET_TUTOR_APPLICATIONS:
      return {
        ...state,
        tutorApplications: action.payload,
      };
    case GET_APPLICATION_REJECTION_REASONS:
      return {
        ...state,
        applicationRejectionReasons: action.payload,
      };
    case GET_TUTOR_APPLICATION_DETAILS:
      return {
        ...state,
        applicationDetails: action.payload,
      };
    case GET_ALL_TUTORS:
      return {
        ...state,
        allTutors: action.payload,
      };
    case GET_TUTOR_DETAIL:
      return {
        ...state,
        tutorDetails: action.payload,
      };
    case GET_TUTOR_RATES:
      return {
        ...state,
        tutorRates: action.payload,
      };
    default:
      return state;
  }
}
