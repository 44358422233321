import { boolean } from "yup";

import { getMemberFormData } from "../../Services/DataCreators/getMemberFormData";
import RoleServices from "../../Services/Http/Endpoints/RoleServices";
import UserServices from "../../Services/Http/Endpoints/UserServices";
import { Member, Member_Payload } from "../../Types/UserAndRoles";
import { AppThunk, GET_ALL_MEMBERS, GET_ALL_ROLES, GET_MEMBER, TOGGLE_IS_AUTHENTICATING, TOGGLE_SUPERVISOR, USER_FOUND } from "../ActionTypes";
import { toggleReduxLoading } from "./appStatusActions";

export const getAllRoles = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return RoleServices.getRoles()
    .then((res) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_ALL_ROLES,
        payload: res.data.roles,
      });
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_ALL_ROLES,
        payload: [],
      });
    });
};

export const getAllMembers = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return UserServices.getUsers()
    .then((res) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_ALL_MEMBERS,
        payload: res.data.users,
      });
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_ALL_MEMBERS,
        payload: [],
      });
    });
};

export const postMember =
  (data: Member_Payload, onFinish: (isOk: boolean) => void): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return UserServices.addNewUser(getMemberFormData(data))
      .then(() => {
        dispatch(toggleReduxLoading(false));
        dispatch(getAllMembers());
        onFinish(true);
      })
      .catch(() => {
        onFinish(false);
        dispatch(toggleReduxLoading(false));
      });
  };

export const editMember =
  (
    id: string,
    data: Member_Payload,
    onFinish: (isOk: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return UserServices.editUser(id, getMemberFormData(data))
      .then(() => {
        dispatch(toggleReduxLoading(false));
        dispatch(getAllMembers());
        onFinish(true);
      })
      .catch(() => {
        onFinish(false);
        dispatch(toggleReduxLoading(false));
      });
  };

export const getUserDetail =
  (history?: any, token?: string): AppThunk =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_IS_AUTHENTICATING,
      payload: true,
    });
    return UserServices.getUserDetail()
      .then((res) => {
        if (res.data.ok) {
          if (
            res.data.data.role.title === "supervisor" &&
            res.data.data.role.permissions.supervisor
          ) {
            dispatch(toggleSupervisor(true));
          }
          return dispatch({
            type: USER_FOUND,
            payload: res.data.data,
          });
        }
        history.push("/#/login");
      })
      .catch((err) => {
        dispatch({
          type: TOGGLE_IS_AUTHENTICATING,
          payload: false,
        });
        history.push("/#/login");
      });
  };

export const toggleSupervisor = (value: boolean) => {
  return {
    type: TOGGLE_SUPERVISOR,
    payload: value,
  };
};

export const getMember =
  (user: Member | null): AppThunk =>
  (dispatch) => {
    dispatch({
      type: GET_MEMBER,
      payload: user,
    });
  };
