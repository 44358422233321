import {
  FeatureBannerState,
  featureBannerActionTypes,
  GET_ALL_FEATURE_BANNERS,
  GET_FEATURE_BANNER_TYPES,
  SET_FEATURE_BANNER_TO_EDIT,
} from "../ActionTypes";

const initialState: FeatureBannerState = {
  featureBannerTypes: [],
  featureBanners: [],
  featureBannerToEdit: {},
};

export default function learningMaterialReducer(
  state = initialState,
  action: featureBannerActionTypes
): FeatureBannerState {
  switch (action.type) {
    case GET_ALL_FEATURE_BANNERS:
      return {
        ...state,
        featureBanners: action.payload,
      };
    case GET_FEATURE_BANNER_TYPES:
      return {
        ...state,
        featureBannerTypes: action.payload,
      };
    case SET_FEATURE_BANNER_TO_EDIT:
      return {
        ...state,
        featureBannerToEdit: {
          ...state.featureBannerToEdit,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
