import { learningMaterialFilters } from "../../../Types";
import BaseApi from "../BaseAPI";

class LearningMaterialServices extends BaseApi {
  constructor() {
    super({ suffix: "admin/learning_materials" });
  }

  postLearningMaterial = (
    materialData: FormData,
    onUploadProgress?: (e: any) => void
  ) => {
    return this.httpService.post("", materialData, {
      onUploadProgress: onUploadProgress,
    });
  };

  patchLearningMaterial = (
    idToEdit: string,
    newData: FormData,
    onUploadProgress?: (e: any) => void
  ) => {
    return this.httpService.patch(`${idToEdit}`, newData, {
      onUploadProgress: onUploadProgress,
    });
  };

  getAllLearningMaterials = (
    filters: learningMaterialFilters,
    query: { skip: number; limit: number } = { skip: 0, limit: 300 }
  ) => {
    return this.httpService.post(
      `getall?skip=${query.skip}&limit=${query.limit}`,
      filters
    );
  };

  getAllLearningMaterialsByMaterialId = (materialId: string) => {
    return this.httpService.get(`${materialId}`);
  };

  displayLearningMaterialOnHomeScreen = (dataToggle: {
    material_id: string;
    home_screen_display: boolean;
  }) => {
    return this.httpService.patch("home_screen_display", dataToggle);
  };

  getMainCategories = () => {
    return this.httpService.get("main_categories");
  };
  getCategories = () => {
    return this.httpService.get("categories");
  };

  displayMaterialAsPopularRecommended = (data: any, materialId: string) =>
    this.httpService.patch(`${materialId}/material_popular_recommended`, data);
}

const LearningMaterialServicesInstance = new LearningMaterialServices();
export default LearningMaterialServicesInstance;
