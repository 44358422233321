import React, { useState } from "react";

import { InputLabel } from "../../Common";
import { IOSSwitch } from "../../Common/IOSSwitch/IOSSwitch";
import classes from "./CustomTableButtons.module.css";

interface Props {
  row: any;
  updateMaterial: (popular?: boolean, recommended?: boolean) => void;
}

export const CustomTableButtons = ({ row, updateMaterial }: Props) => {
  const [popularChecked, setPopularChecked] = useState(row.popular);
  const [recommendedChecked, setRecommendedChecked] = useState(row.recommended);
  return (
    <div className={classes.Root}>
      <div className={classes.SwitchButton}>
        <InputLabel>Popular</InputLabel>
        <IOSSwitch
          checked={popularChecked}
          onChange={(e, value) => {
            setPopularChecked(value);
            updateMaterial(value, undefined);
          }}
        />
      </div>
      <div className={classes.SwitchButton}>
        <InputLabel>Recommended</InputLabel>
        <IOSSwitch
          checked={recommendedChecked}
          onChange={(e, value) => {
            setRecommendedChecked(value);
            updateMaterial(undefined, value);
          }}
        />
      </div>
    </div>
  );
};
