import { Grid, GridSize } from "@material-ui/core";
import React, { SetStateAction } from "react";
import { Dispatch } from "react";

import { BorderedRadio } from "..";

export interface BorderedRadioGroupItemsType {
  label?: string;
  isSelected?: boolean;
  checked?: boolean;
  index?: number;
}

interface Props {
  label?: string;
  items: BorderedRadioGroupItemsType[];
  classNameRadio?: string;
  className?: string;
  onClick?: (item: BorderedRadioGroupItemsType) => void;
  setItems?: Dispatch<SetStateAction<BorderedRadioGroupItemsType[]>>;
  gridSize?: GridSize;
}

export const BorderedRadioGroup = (props: Props) => {
  const { label, items, className, onClick, classNameRadio, setItems, gridSize } = props;

  const onClickRadioGroups = () => {
    let updatedItems = [...items];
    const checkedItem = updatedItems.filter((item) => item.checked);
    const unCheckedItem = updatedItems.filter((item) => !item.checked);

    updatedItems = updatedItems.map((item) => {
      if (item.label === checkedItem[0].label) {
        return {
          ...item,
          checked: false,
        };
      } else if (item.label === unCheckedItem[0].label) {
        return {
          ...item,
          checked: true,
        };
      } else {
        return {
          ...item,
        };
      }
    });

    setItems && setItems(updatedItems);
  };

  return (
    <div className={className}>
      {label && <p>{label}</p>}
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
        {items.map((item) => (
          <Grid item xs={12} sm={12} md={gridSize} lg={gridSize} xl={gridSize}>
            <BorderedRadio
              isSelected={item.isSelected}
              checked={item.checked}
              className={classNameRadio}
              onClick={() => onClick && onClick(item) || onClickRadioGroups}
              label={item.label}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
