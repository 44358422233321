import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { DashboardPageState, FeatureBannerType, FeatureBannerType_Payload, LanguageVariantType } from "../../Types";
import { AddFeatureBanner } from "../../Components/FeatureBanner";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  onSubmitForm:(values:FeatureBannerType_Payload)=>void;
  featureBannerToEdit:{
    [key:string]:FeatureBannerType
  }
}

export const FeatureBannerScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    onSubmitForm,
    featureBannerToEdit
  } = props;

  const RenderAddFlow = () => {
    return (
      <>
        <AddFeatureBanner
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          onSubmitForm={onSubmitForm}
          featureBannerToEdit={featureBannerToEdit}
          pageState={pageState}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a New Feature";
      case "edit":
        return "Edit Feature";
      default:
        return "Feature Banner Content Table";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      data={[]}
    />
  );
};
