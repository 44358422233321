import {
  LearningMaterialType_Payload,
  LanguageVariantType,
} from "./../../Types";

export const getLearningMaterialFormData = (
  data: LearningMaterialType_Payload,
  language: LanguageVariantType,
  materialId?: string
): FormData => {
  const formData = new FormData();
  formData.append("audio", data.audio as Blob);
  formData.append("image", data.image as Blob);
  if (data.insights) formData.append("insight", data.insights);
  data.questions.forEach((q) => {
    if (q.img_url !== null && typeof q.img_url !== "string") {
      formData.append("question_images", q.img_url as Blob);
    }
  });

  data.expressions.forEach((exp) => {
    if (exp.audio !== null && typeof exp.audio !== "string") {
      formData.append("key_expression_audios", exp.audio as Blob);
    }
    if (exp.examples.length > 0) {
      exp.examples.forEach((example) => {
        formData.append("key_expression_example_audios", example.audio as Blob);
      });
    }
  });

  formData.append("category", data.category);
  formData.append("main_category", data.main_category);
  formData.append("lang", language);
  formData.append("title", data.title);
  formData.append("sub_title", data.sub_title);
  formData.append(
    "questions",
    JSON.stringify(
      data.questions.map((q) => ({
        ...q,
        img_url:
          typeof q.img_url === "string"
            ? q.img_url !== "false" && q.img_url !== "true"
              ? q.img_url
              : null
            : q.img_url !== null?true:null,
      }))
    )
  );
  if (materialId) {
    formData.append("material_id", materialId);
  }
  formData.append(
    "media",
    JSON.stringify({ videos: data.media, articles: [] })
  );
  formData.append(
    "expressions",
    JSON.stringify(
      data.expressions.map((exp) => ({
        ...exp,
        audio:
          typeof exp.audio === "string"
            ? exp.audio !== "false" && exp.audio !== "true"
              ? exp.audio
              : null
            : exp.audio !== null?true:null,
        examples: exp.examples.map((example) => ({
          ...example,
          audio:
            typeof example.audio === "string"
              ? example.audio !== "false" && example.audio !== "true"
                ? example.audio
                : null
              : example.audio !== null?true:null,
        })),
      }))
    )
  );
  formData.append(
    "points",
    JSON.stringify(`${{ total: 0, preparation: 0, attendance: 0, task: 0 }}`)
  );

  return formData;
};
