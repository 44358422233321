import React from "react";
import classes from "./BorderedCheckbox.module.css";
import { Checkbox } from "../";

interface Props {
  className?: string;
  isSelected?: boolean;
  checked?: boolean;
  label?: string;
  onClick?: () => void;
}

export const BorderedCheckbox = (props: Props) => {
  const { className, isSelected, checked, label, onClick } = props;

  return (
    <div className={className}>
      <div
        onClick={onClick}
        className={
          isSelected
            ? [classes.Root, classes.Root_active].join(" ")
            : classes.Root
        }
      >
        <span>{label}</span>
        <Checkbox checked={checked} />
      </div>
    </div>
  );
};
