import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { NotificationScreen } from "./NotificationScreen";
import { useDispatch } from "react-redux";
import { EVENT_ACTIONS } from "../../Redux/Actions/eventActions";
import { useTypedSelector } from "../../Redux/Reducers/index";
import { BootstrapTableCellRenderer } from "../../Components/Common";
import { EventType, EventType_Payload } from "../../Types/events";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "image",
    text: "Image",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="image" />
    ),
  },
  {
    dataField: "eventTitle",
    text: "Title",
  },
  {
    dataField: "eventSubtitle",
    text: "Description",
  },
  {
    dataField: "category",
    text: "Upload Date",
  },
  {
    dataField: "showOnHomepage",
    text: "Homepage",
    formatter: (cell, _, __) => (
      <p style={{ color: cell ? "green" : "#d70000" }}>
        {cell ? "SHOW" : "HIDDEN"}
      </p>
    ),
  },
];

export const NotificationScreenContainer = () => {
  const dispatch = useDispatch();
  const allEvents = useTypedSelector((state) => state.platformEvent.events);
  const eventToEdit = useTypedSelector(
    (state) => state.platformEvent.eventToEdit
  );
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const onTableEditClick = (rowData: EventType, rowIndex: number) => {
    if (rowData._id) {
      onChangePageState("edit", rowData._id);
    }
  };

  const onSubmitForm = (materialData: EventType_Payload) => {
    if (pageState === "edit" && eventToEdit[selectedLanguage] !== undefined) {
      onEditData(materialData);
    } else {
      onAddEvent(materialData);
    }
  };

  const onEditData = (materialData: EventType_Payload) => {
    dispatch(
      EVENT_ACTIONS.editEventAction(
        materialData,
        eventToEdit[selectedLanguage]._id,
        selectedLanguage
      )
    );
  };
  const onAddEvent = (materialData: EventType_Payload) => {
    dispatch(
      EVENT_ACTIONS.addEventAction(materialData, selectedLanguage, () => {
        onChangePageState("main");
      })
    );

    console.log(materialData, "materialData");
  };
  React.useEffect(() => {
    if (pageState === "add") {
      dispatch(EVENT_ACTIONS.getEventDetail("-1"));
    } else if (pageState === "main") {
      dispatch(EVENT_ACTIONS.getAllEvents());
    } else if (pageState === "edit") {
      if (idToEdit) {
        dispatch(EVENT_ACTIONS.getEventDetail(idToEdit));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageState]);
  return (
    <NotificationScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      allEvents={allEvents}
      onTableEditClick={onTableEditClick}
      eventToEdit={eventToEdit}
      onSubmitForm={onSubmitForm}
    />
  );
};
