import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
//import { BootstrapTableCellRenderer } from "../../Components/Common";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import {
  TutorIssueDetails,
  TutorIssueResponse,
} from "../../Components/Reviews";
import {
  closeGlobalDialog,
  openGlobalDialog,
} from "../../Redux/Actions/appStatusActions";
import { StudentReportsScreen } from "./StudentReportsScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "Full Name",
    text: "Full Name",
  },
  {
    dataField: "rage",
    text: "Responded",
  },
  {
    dataField: "state",
    text: "Class ID",
  },
  {
    dataField: "Active Packages",
    text: "Submission Date",
  },
  {
    dataField: "age",
    text: "Issue(s) Reported",
  },
];

export const StudentReportsScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const dispatch = useDispatch();

  const [selectedReport, setSelectedReport] = React.useState<any | null>();

  const onResponseClicked = () => {
    dispatch(closeGlobalDialog());
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <TutorIssueResponse
            report={selectedReport}
            onClose={() => dispatch(closeGlobalDialog())}
            onSendResponse={() => {}}
          />
        ),
      })
    );
  };

  const onEditClicked = (rowData: any, _rowIndex: number) => {
    setSelectedReport(rowData);
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <TutorIssueDetails
            report={rowData}
            onClose={() => dispatch(closeGlobalDialog())}
            onResponseClicked={onResponseClicked}
          />
        ),
      })
    );
  };

  return (
    <StudentReportsScreen
      onChangePageState={onChangePageState}
      onEditClicked={onEditClicked}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
    />
  );
};
