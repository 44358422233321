import { EditClassData, SearchClassType } from "../../../Types";
import BaseApi from "../BaseAPI";

class ClassServices extends BaseApi {
  constructor() {
    super({ suffix: "admin" });
  }

  getAllClasses = (data: SearchClassType) => {
    const {
      sort_based_start_time,
      search_term,
      register_time,
      type,
      startTime,
      endTime,
      sort_based_update_time,
      duration,
    } = data;

    console.log(register_time);

    return this.httpService.get(
      `class_admin?${
        sort_based_update_time !== undefined
          ? `sort_based_update_time=${sort_based_update_time}&`
          : ""
      }${
        sort_based_start_time !== undefined
          ? `sort_based_start_time=${sort_based_start_time}&`
          : ""
      }${
        register_time !== undefined ? `register_time=${register_time}&` : ""
      }type=${type}&search_term=${search_term}${
        startTime ? `&startTime=${startTime}` : ""
      }${endTime ? `&endTime=${endTime}` : ""}${
        duration ? `&duration=${duration}` : ""
      }`
    );
  };

  getClassDetail = (id: string) => {
    return this.httpService.get(`class_admin/${id}`);
  };

  getAllClassesByStudent = (
    studentId: string,
    type?: string,
    startTime?: string,
    endTime?: string
  ) => {
    return this.httpService.get(
      `class_admin/student/${studentId}?type=${type || "all"}${
        startTime ? `&startTime=${startTime}` : ""
      }${endTime ? `&endTime=${endTime}` : ""}`
    );
  };

  editClassSchedule(data: EditClassData) {
    return this.httpService.patch(`edit_class`, data);
  }
}

const ClassServicesInstance = new ClassServices();
export default ClassServicesInstance;
