import React from "react";

import { CorporateDetail } from "../../../Types/corporate";
import classes from "./ProgramBrief.module.css";
import { ProgramDetail } from "./ProgramDetail";

interface Props {
  corporateDetail: CorporateDetail;
  numberOfStudent: number
}
export const ProgramBrief = (props: Props) => {

  const { corporateDetail ,numberOfStudent} = props

  console.log(corporateDetail,"corporateDetail")
  
  return (
    <div className={classes.ProgramBrief}>
      <p>Program Brief</p>
      <div className={classes.ProgramDetailsContainer}>
        <ProgramDetail title="Program Name" subTitle={corporateDetail.name} />
        <ProgramDetail title="Users" subTitle={numberOfStudent.toString() + " Student"} />
        <ProgramDetail title="Program Code" subTitle={corporateDetail.code} />
        <ProgramDetail title="Corporate Logo" subTitle="Exclusive B2B Program" withImage={corporateDetail.logo} />
      </div>
    </div>
  );
};
