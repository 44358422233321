import React from "react";

import { ColumnDescription } from "react-bootstrap-table-next";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { ApplicationDetails } from "../../Components/Application";
import {
  DashboardPageState,
  InterviewType,
  LanguageVariantType,
  Member,
  TutorDetailType,
} from "../../Types";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  interviewers: Member[];
  interviewList: InterviewType[];
  onTableDetailClick: (data: InterviewType) => void;
  tutorDetail: TutorDetailType | null;
}

export const InterviewScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    interviewers,
    interviewList,
    onTableDetailClick,
    tutorDetail,
  } = props;

  const RenderDetailFlow = () => {
    return (
      <>
        <ApplicationDetails
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          interviewers={interviewers}
          tutorDetail={tutorDetail}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a Interview";
      case "edit":
        return "Interview Detail";
      default:
        return "Interview";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      EditFlowContent={() => RenderDetailFlow()}
      haveAddButton={false}
      onViewCellClick={onTableDetailClick}
      data={interviewList}
    />
  );
};
