import { Grid } from "@material-ui/core";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  FormikValues,
} from "formik";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createRef } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SingleValue } from "react-select";
import SelectSearch, { SelectedOptionValue } from "react-select-search";
import * as yup from "yup";

import { getClassStatus } from "..";
import { classState } from "../../../Config/Constants/SelectValues";
import {
  closeGlobalDialog,
  openGlobalDialog,
} from "../../../Redux/Actions/appStatusActions";
import { classesActions } from "../../../Redux/Actions/classActions";
import { getClassDetailsCorporate } from "../../../Redux/Actions/corporateActions";
import { StudentActions } from "../../../Redux/Actions/studentActions";
import { TutorsActions } from "../../../Redux/Actions/tutorsAction";
import { GET_ALL_TUTORS } from "../../../Redux/ActionTypes";
import { useTypedSelector } from "../../../Redux/Reducers";
import {
  classDetailInitialState,
  ClassType,
  EditClassData,
  LanguageVariantType,
  learningMaterialInitialState,
  LearningMaterialType_Payload,
} from "../../../Types";
import {
  Button,
  FormikTextField,
  LottieLoading,
  SelectInput,
} from "../../Common";
import {
  BorderedRadioGroup,
  BorderedRadioGroupItemsType,
} from "../../Common/BorderedRadioGroup/BorderedRadioGroup";
import { DateTimePicker } from "../../Common/DateTimePicker/DateTimePicker";
import { Disclaimer } from "../../Common/Disclaimer/Disclaimer";
import { SearchableInput } from "../../Common/SearchableInput/SearchableInput";
import { SimpleCard } from "../../Common/SimpleCard/SimpleCard";
import { useDashboardPageLocalData } from "../../Hooks";
import { StudentReviewCard } from "../StudentReviewCard/StudentReviewCard";
import { TutorReviewCard } from "../TutorReviewCard/TutorReviewCard";
import GoToButton from "./../../Common/GoToButton/GoToButton";
import { InputLabel } from "./../../Common/InputLabel/InputLabel";
import classes from "./ClassesDetails.module.css";

// TODO Validating Rules
const validationSchema = yup.object({
  title: yup.string().required("title is required."),
  sub_title: yup.string().required("subtitle is required."),
  insights: yup.string().required("insight is required"),
  questions: yup.array().of(
    yup.object().shape({
      question_title: yup
        .string()
        .required("title of the question is required"),
    })
  ),
  expressions: yup.array().of(
    yup.object().shape({
      title: yup.string().required("title of the expression is required"),
      meaning__ko: yup.string().required("korean meaning is required"),
      meaning__en: yup.string(),
      tip: yup.string(),
      examples: yup.array().of(
        yup.object().shape({
          title: yup.string(),
          meaning__ko: yup.string(),
        })
      ),
    })
  ),
  media: yup.array().of(
    yup.object().shape({
      url: yup
        .string()
        .required("url is required")
        .url("please provide a valid url"),
      title: yup.string().required("title is required"),
    })
  ),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
}

export const ClassDetails = ({ lang, setSelectedLanguage }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [{ idToEdit }] = useDashboardPageLocalData();
  const classDetails = useTypedSelector((state) => state.class.classDetails);
  const allTutors = useTypedSelector((state) => state.tutor.allTutors);
  const ref = createRef<FormikProps<ClassType>>();

  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);

  const [initialValues, setInitialValues] = useState<ClassType>(
    classDetailInitialState
  );

  const [newTutor, setNewTutor] = useState<
    SingleValue<{ label: string; value: string; email: string }>
  >({ label: "", value: "", email: "" });

  const [introRadioItems, setIntroRadioItems] = useState<
    BorderedRadioGroupItemsType[]
  >([
    {
      label: "3 Minutes at The Beginning",
      checked: true,
    },
    {
      label: "Skip Live Introduction",
      checked: false,
    },
  ]);

  const [cancellationRadioItems, setCancellationIntroRadioItems] = useState<
    BorderedRadioGroupItemsType[]
  >([
    {
      label: "Restore Subscription",
      checked: true,
    },
    {
      label: "Replace Tutor",
      checked: false,
    },
  ]);

  useEffect(() => {
    if (!allTutors.length) {
      dispatch(TutorsActions.getAllTutors());
    }
  }, []);

  useEffect(() => {
    if (isSupervisor && idToEdit) {
      dispatch(getClassDetailsCorporate(idToEdit));
    } else if (idToEdit && !classDetails) {
      dispatch(classesActions.getClassDetails(idToEdit));
    }
  }, [idToEdit]);

  useEffect(() => {
    if (
      classDetails &&
      classDetails.tutor &&
      classDetails.student &&
      classDetails.class_history
    ) {
      setInitialValues({
        ...classDetails,
        status_select: {
          label: getClassStatus(classDetails.status).text,
          value: classDetails.status,
        },
        tutor: {
          ...classDetails.tutor,
          first_name: `${classDetails.tutor.first_name} ${classDetails.tutor.last_name}`,
        },
        student: {
          ...classDetails.student,
          first_name: `${classDetails.student.first_name} ${classDetails.student.last_name}`,
        },
        // startTime: moment(classDetails.startTime).format("MMM Do YYYY hh:mm A"),
        created_at: moment(classDetails.created_at).format("MMM Do YYYY"),
        class_history: {
          ...classDetails.class_history,
          tutor_join_timings: classDetails.class_history.has_tutor_joined
            ? moment(classDetails.class_history.tutor_join_timings).format(
                "MMM Do YYYY hh:mm A"
              )
            : null,
          student_join_timings: classDetails.class_history.has_student_joined
            ? moment(classDetails.class_history.student_join_timings).format(
                "MMM Do YYYY hh:mm A"
              )
            : null,
        },
      });
      setNewTutor({
        label: `${classDetails.tutor.first_name} ${classDetails.tutor.last_name}`,
        value: classDetails.tutor._id,
        email: classDetails.tutor.email,
      });
    }
  }, [classDetails]);

  const onReviewCardClick = (type: string) => {
    if (type === "student") {
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <StudentReviewCard
              onClose={() => {
                dispatch(closeGlobalDialog());
              }}
              classData={initialValues}
            />
          ),
        })
      );
    }
    if (type === "tutor") {
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <TutorReviewCard
              onClose={() => {
                dispatch(closeGlobalDialog());
              }}
              student={initialValues.student}
              classData={initialValues}
            />
          ),
        })
      );
    }
  };

  const redirectionHandler = (place: string) => {
    if (place === "tutor") {
      history.push(
        `/tutors/active?mode=edit&id=${
          ref.current && ref.current.values.tutor?._id
        }`
      );
    }
    if (place === "student") {
      dispatch(StudentActions.storeStudentDetail(null));
      history.push(
        `/users/students?mode=edit&id=${
          initialValues && initialValues.student && initialValues.student._id
        }`
      );
    }
    if (place === "learningMaterial") {
      history.push(
        `/content/learningMaterials?mode=edit&id=${
          initialValues &&
          initialValues.learning_material &&
          initialValues.learning_material.material_id
        }`
      );
    }
  };

  const editClassHandler = () => {
    if (
      ref.current &&
      classDetails &&
      classDetails.student &&
      ref.current.values.tutor?._id &&
      classDetails.learning_material
    ) {
      const data: EditClassData = {
        tutorId: ref.current.values.tutor?._id,
        class_id: classDetails._id,
        studentId: classDetails.student._id,
        class_time: new Date(ref.current.values.startTime),
        learningMaterial: classDetails.learning_material._id,
        status: ref.current.values.status,
        in_completion_reason:
          ref.current.values.status === "in_complete"
            ? ref.current.values.inCompleteReason
            : undefined,
      };
      dispatch(classesActions.editClass(data));
    }
  };

  return classDetails && classDetails.learning_material ? (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={ref}
      onSubmit={() => {}}
    >
      {({ values, handleChange, setFieldValue }) => {
        console.log(values, "values");
        return (
          <Form>
            <div>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormikTextField
                        label="Class Subject"
                        placeholder={"Title..."}
                        className={classes.inputFields}
                        name="learning_material.title"
                        readonly
                        endAdornment={
                          !isSupervisor ? (
                            <GoToButton
                              onClick={() =>
                                redirectionHandler("learningMaterial")
                              }
                            />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    {values.status && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Field name="status">
                          {({ field }: FieldProps) => {
                            return (
                              <SelectInput
                                disabled={!isSupervisor ? false : true}
                                label="Class State"
                                placeholder="Class State"
                                handleChange={(e) => {
                                  setFieldValue("status", e.target.value);
                                }}
                                options={classState}
                                variant="outlined"
                                value={field.value}
                                style={{
                                  marginBottom: "5.71em",
                                  marginTop: "-2.85rem",
                                }}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                    )}
                    {values.status === "in_complete" && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Field name="status">
                          {({ field }: FieldProps) => {
                            return (
                              <FormikTextField
                                label="In completion reason"
                                placeholder={"In completion reason"}
                                className={classes.inputFields}
                                name="inCompleteReason"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.student?.first_name && (
                        <FormikTextField
                          label="Student Full Name"
                          placeholder={"Ross Geller"}
                          className={classes.inputFields}
                          name="student.first_name"
                          readonly
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.student?._id && (
                        <FormikTextField
                          label="Student ID"
                          placeholder={"9M06d9KBd"}
                          className={classes.inputFields}
                          name="student._id"
                          readonly
                          endAdornment={
                            <GoToButton
                              onClick={() => redirectionHandler("student")}
                            />
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Field name="tutor._id">
                        {({ field }: FieldProps) => {
                          return (
                            <SearchableInput
                              data={allTutors
                                .filter(
                                  (tutor) =>
                                    tutor.show &&
                                    tutor.profile_status === "approved"
                                )
                                .map((tutor) => ({
                                  label: `${tutor.first_name} ${tutor.last_name}`,
                                  value: tutor._id,
                                  email: tutor.email,
                                }))}
                              placeholder="Tutor"
                              label="Tutor"
                              value={newTutor}
                              isSearchable
                              onChange={(newValue) => {
                                setFieldValue("tutor._id", newValue?.value);
                                setNewTutor(newValue);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.tutor?._id && (
                        <FormikTextField
                          label="Tutor ID"
                          placeholder={"Tutor ID"}
                          className={classes.inputFields}
                          name="tutor._id"
                          readonly
                          endAdornment={
                            !isSupervisor ? (
                              <GoToButton
                                onClick={() => redirectionHandler("tutor")}
                              />
                            ) : (
                              ""
                            )
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.class_duration_type && (
                        <FormikTextField
                          label="Duration"
                          placeholder={"Duration"}
                          className={classes.inputFields}
                          readonly
                          name="class_duration_type"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values._id && (
                        <FormikTextField
                          label="Class Id"
                          placeholder={"Duration"}
                          className={classes.inputFields}
                          readonly
                          name="_id"
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel className={classes.inputLabel}>
                        Class Start Time
                      </InputLabel>
                      {values.startTime && (
                        <DateTimePicker
                          disabled={!isSupervisor ? false : true}
                          value={values.startTime}
                          setFieldValue={(date) => {
                            !isSupervisor && setFieldValue("startTime", date);
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.created_at && (
                        <FormikTextField
                          label="Class Booked Time"
                          placeholder={"Class Booked Time"}
                          className={classes.inputFields}
                          name="created_at"
                          readonly
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.company_name && (
                        <FormikTextField
                          label="Company Name"
                          placeholder={"Company Name"}
                          className={classes.inputFields}
                          name="company_name"
                          readonly
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {values.company_code && (
                        <FormikTextField
                          label="Company Code"
                          placeholder={"Company Code"}
                          className={classes.inputFields}
                          name="company_code"
                          readonly
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Tutor Entry Time"
                        placeholder="Tutor Entry Time"
                        className={classes.inputFields}
                        name="class_history.tutor_join_timings"
                        readonly
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormikTextField
                        label="Student Entry Time"
                        placeholder="Student Entry Time"
                        className={classes.inputFields}
                        name="class_history.student_join_timings"
                        readonly
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel
                        style={{
                          marginTop: "-2.85rem",
                        }}
                        className={classes.inputLabel}
                      >
                        Class Preference
                      </InputLabel>
                      <BorderedRadioGroup
                        label="Introduction"
                        className={classes.BorderedRadioGroup}
                        items={[
                          {
                            label: "3 Minutes at The Beginning",
                            checked: !values.classPreferences?.skipped,
                          },
                          {
                            label: "Skip Live Introduction",
                            checked: values.classPreferences?.skipped,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {values.classPreferences?.introduction && (
                        <Disclaimer
                          label="Student Intro"
                          content={values.classPreferences?.introduction}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <BorderedRadioGroup
                        label="In Event of Cancellation"
                        className={classes.BorderedRadioGroup}
                        items={cancellationRadioItems}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {values.reviewedByStudent && (
                        <SimpleCard
                          style={{ marginTop: "2.85rem" }}
                          title="Review Given By Student"
                          onClick={() => onReviewCardClick("student")}
                        />
                      )}
                      {values.reviewedByTutor && (
                        <SimpleCard
                          title="Review Given By Tutor"
                          onClick={() => onReviewCardClick("tutor")}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {values.docs && (
                        <SimpleCard
                          title="Google Doc"
                          onClick={() => {
                            window.open(values.docs ? values.docs : undefined);
                          }}
                          withIcon={true}
                          iconName="goTo"
                          noViewDetails={true}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {!isSupervisor ? (
                  <Grid
                    justify="flex-end"
                    container
                    spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Button
                      className={classes.EditButton}
                      onClick={editClassHandler}
                      variant="contained"
                    >
                      <span>Edit Class</span>
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};
