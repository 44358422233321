import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { PackageScreen } from "./PackageScreen";
import { useDispatch } from "react-redux";
import { EVENT_ACTIONS } from "../../Redux/Actions/eventActions";
import { useTypedSelector } from "../../Redux/Reducers/index";
//import { BootstrapTableCellRenderer } from "../../Components/Common";
import { PackageType, Package_Payload } from "../../Types";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "type",
    text: "Type",
  },
  {
    dataField: "startDate",
    text: "Start Date",
  },
  {
    dataField: "endDate",
    text: "End Date",
  },
  {
    dataField: "title",
    text: "Title",
  },
  {
    dataField: "Target Users",
    text: "targetUsers",
  },
];

export const PackageScreenContainer = () => {
  const dispatch = useDispatch();
  //const allEvents = useTypedSelector((state) => state.platformEvent.events);
  const eventToEdit = useTypedSelector(
    (state) => state.platformEvent.eventToEdit
  );
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const onTableEditClick = (rowData: PackageType, rowIndex: number) => {
    if (rowData._id) {
      onChangePageState("edit", rowData._id);
    }
  };

  const onSubmitForm = (materialData: Package_Payload) => {
    if (pageState === "edit" && eventToEdit[selectedLanguage] !== undefined) {
      onEditData(materialData);
    } else {
      onAddEvent(materialData);
    }
  };

  const onEditData = (_materialData: Package_Payload) => {};
  const onAddEvent = (_materialData: Package_Payload) => {
    onChangePageState("main");
  };
  React.useEffect(() => {
    if (pageState === "add") {
      dispatch(EVENT_ACTIONS.getEventDetail("-1"));
    } else if (pageState === "main") {
      dispatch(EVENT_ACTIONS.getAllEvents());
    } else if (pageState === "edit") {
      if (idToEdit) {
        dispatch(EVENT_ACTIONS.getEventDetail(idToEdit));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageState]);
  return (
    <PackageScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      allEvents={[]}
      onTableEditClick={onTableEditClick}
      eventToEdit={{}}
      onSubmitForm={onSubmitForm}
    />
  );
};
