import React from "react";
import classes from "./ProfileBaseInfo.module.css";
import { FormikTextField } from "./../FormikTextField/FormikTextField";

interface Props {
  className?: string;
  profilePicture?: string;
  isSupervisor? : boolean;
}

export const ProfileBaseInfo = (props: Props) => {
  const { profilePicture, className, isSupervisor } = props;
  return (
    <div className={className}>
      <div className={classes.Root}>
        <img src={profilePicture} alt={"tooti profile"} />
        <div className={classes.inputsContainer}>
          <FormikTextField label={"First Name"} name="first_name" readonly={isSupervisor} />
          <FormikTextField label={"Last Name"} name="last_name" readonly={isSupervisor} />
        </div>
      </div>
    </div>
  );
};
