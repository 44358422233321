import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { clearFilters, updateFilters } from "../../Redux/Actions/tableFilterActions";
import { TableFilterDataTypes, TableNamesTypes } from "../../Redux/ActionTypes";
import { useTypedSelector } from "../../Redux/Reducers";

type ReturnType = [
  { filters: TableFilterDataTypes },
  {
    updateFiltersDispatch: (data: TableFilterDataTypes) => void;
    clearFiltersDispatch: () => void;
  }
];

export const useTableFilters = (tableName: TableNamesTypes): ReturnType => {
  const filters = useTypedSelector((state) => state.tableFilters[tableName]);
  const dispatch = useDispatch();

  const updateFiltersDispatch = (data: TableFilterDataTypes) => {
    dispatch(updateFilters(tableName, data));
  };

  const clearFiltersDispatch = () => {
    dispatch(clearFilters(tableName));
  };

  return [{ filters }, { updateFiltersDispatch, clearFiltersDispatch }];
};
