export const IdTypes = [
  {
    label: "License",
    value: "license",
  },
  {
    label: "Other",
    value: "other",
  },
];
