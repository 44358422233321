import { useEffect } from "react";
import { LevelTestType } from "../../../Types";
import { ExpandMenu, TextAreaInput, TextInput } from "../../Common";

import classes from "./ScoresLayout.module.css";

interface Props {
  editMode: boolean;
  data?: LevelTestType;
  setFieldValue: (field: string, value: any) => void;
  title: string;
  secondTitle: string;
}

interface ScoreItemProps {
  title: string;
  value?: number | string;
  edit?: boolean;
  onChangeScore?: (value: string) => void;
}

type ScoresType = {
  label: string;
  field: keyof LevelTestType;
};

const ScoreInfoItem = ({
  title,
  value,
  edit,
  onChangeScore,
}: ScoreItemProps) => {
  return (
    <div className={classes.ScoresInfoItem}>
      <p>{title}</p>
      {edit && onChangeScore ? (
        <TextInput
          className={classes.ScoreInput}
          value={value || 0}
          onChange={(e) => onChangeScore(e.target.value)}
        />
      ) : (
        <h4>{value || 0}</h4>
      )}
    </div>
  );
};

const scoresFields: ScoresType[] = [
  {
    label: "Fluency",
    field: "fluency",
  },
  {
    label: "Vocabulary",
    field: "lexical",
  },
  {
    label: "Pronunciation",
    field: "pronunciation",
  },
  {
    label: "Grammar",
    field: "grammatical",
  },
  {
    label: "Listening",
    field: "listening",
  },
];

export const ScoresLayout = ({
  editMode,
  data,
  setFieldValue,
  title,
  secondTitle,
}: Props) => {
  const updateAverage = (newData: LevelTestType) => {
    let sum = 0;
    scoresFields.forEach((score) => {
      if (newData && newData[score.field]) {
        sum += parseInt(newData[score.field].toString());
      }
    });

    setFieldValue("averageScore", sum / 5);
  };

  const onChangeScore = (value: string, fieldName: string) => {
    let valueAsInteger = parseInt(value);

    if (value === "") valueAsInteger = 0;

    if (valueAsInteger <= 9) {
      setFieldValue(fieldName, valueAsInteger);
    }
  };

  useEffect(() => {
    if (data) {
      updateAverage(data);
    }
  }, [data]);

  return (
    <div className={classes.ScoresRoot}>
      <div className={classes.ScoresHeader}>
        <h2>{title}</h2>
        <h2>{secondTitle}</h2>
      </div>
      <div className={classes.ScoresInfoContainer}>
        {scoresFields.map((score) => (
          <ScoreInfoItem
            value={data ? data[score.field] : 0}
            title={score.label}
            edit={editMode}
            onChangeScore={(value) => onChangeScore(value, score.field)}
          />
        ))}
        <ScoreInfoItem value={data?.averageScore || 0} title="Average" />
      </div>
      <ExpandMenu
        className={classes.CommentSection}
        header={
          <div className={classes.Comment}>
            <h2>Tutor Assessment</h2>
          </div>
        }
        content={
          <div className={classes.Comment}>
            {editMode ? (
              <TextAreaInput
                rows={2}
                value={data?.tutorComments || ""}
                onChange={(e) => {
                  setFieldValue("tutorComments", e.target.value);
                }}
              />
            ) : (
              <p>{data?.tutorComments || ""}</p>
            )}
          </div>
        }
      />
    </div>
  );
};
