import { AccountState, StudentDetailType } from "../../../Types";
import BaseApi from "../BaseAPI";

class StudentServices extends BaseApi {
  constructor() {
    super({ suffix: "admin" });
  }

  getAllStudents = () => {
    return this.httpService.get("/students");
  };
  getStudentDetails = (id: string) => {
    return this.httpService.get(`/students/${id}`);
  };

  changeUserStatus = (id: string, status: AccountState) => {
    return this.httpService.patch(`/students/status/${id}`, { status });
  };

  unBanUser = (id: string) => {
    return this.httpService.patch(`/students/un_ban/${id}`);
  };

  unlockFreePackage = (id: string, email: string) => {
    return this.httpService.get(`/unblock_package?id=${id}&email=${email}`);
  };
  updatePackage = (
    id: string,
    email: string,
    classes_remaining: number,
    validity_date: string
  ) => {
    return this.httpService.post(`/update_package?id=${id}&email=${email}`, {
      classes_remaining,
      validity_date,
    });
  };

  updateStudent = (id: string, data: Partial<StudentDetailType>) => {
    return this.httpService.patch(`/students/edit/${id}`, data);
  };
}

const StudentServicesInstance = new StudentServices();
export default StudentServicesInstance;
