import React, { Dispatch, SetStateAction } from "react";
import { ComponentType } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { UsersDetail } from "../../Components/Users";
import { UsersTableFilter } from "../../Components/Users/UsersTableFilter/UsersTableFilter";
import { DashboardPageState, LanguageVariantType, StudentType } from "../../Types";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  onViewCellClick?: ((cellData: any, cellIndex: number) => void) | undefined;
  data: StudentType[];
  EditPageHeaderComponent?: ComponentType<any>;
  studentType?: string,
  setStudentType: (val: 'all' | 'b2b' | 'b2c') => void
}

export const UsersScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    data,
    onViewCellClick,
    EditPageHeaderComponent,
    studentType,
    setStudentType
  } = props;

  const RenderDetailFlow = () => {
    return (
      <>
        <UsersDetail
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a Event";
      case "edit":
        return "User Detail";
      default:
        return "Manage User";
    }
  };

  const RenderUsersFilter = () => (
    <UsersTableFilter studentType={studentType} setStudentType={setStudentType}  />
  )

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      EditFlowContent={() => RenderDetailFlow()}
      haveAddButton={false}
      onViewCellClick={onViewCellClick}
      EditPageHeaderComponent={EditPageHeaderComponent}
      data={data.length ? data : [{ t: "1" }]}
      ExtraTableFilters={RenderUsersFilter}
      filterStyle={{
        minWidth: "100rem"
      }}
    />
  );
};
