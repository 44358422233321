import { Moment } from "moment";
import React from "react";
import { APP_INTERVIEW_TIME_STAMPS } from "../../../Config/AppConfig";
import { Alert, CheckboxWithLabel, InputLabel } from "../../Common";
import { CardModal } from "../../menus";
import { useFormik } from "formik";
import {
  InterviewAvailability,
  InterviewAvailabilityTimeSlot_Payload,
  InterviewAvailability_Payload,
} from "../../../Types/interview";

interface Props {
  closeAvailabilityModal: () => void;
  selectedDate: Moment;
  onSubmit: (value: InterviewAvailability_Payload[]) => void;
  currentDateAvailabilities?: InterviewAvailability;
}

interface FormikTimeSlot extends InterviewAvailabilityTimeSlot_Payload {
  isSelected: boolean;
  index: number;
  isDisable: boolean;
}

type FormikType = {
  availabilities: FormikTimeSlot[];
};

export const InterviewAvailabilityItemModal = (props: Props) => {
  const {
    closeAvailabilityModal,
    selectedDate,
    onSubmit,
    currentDateAvailabilities,
  } = props;
  const formik = useFormik<FormikType>({
    initialValues: {
      availabilities: APP_INTERVIEW_TIME_STAMPS.map((tm, index) => {
        return {
          startTime: tm.startTime,
          endTime: tm.endTime,
          isSelected: currentDateAvailabilities
            ? currentDateAvailabilities.timeSlots.reduce(
                (isSelect: boolean, item) => {
                  if (isSelect) {
                    return true;
                  } else {
                    return (
                      item.startTime === tm.startTime &&
                      item.endTime === tm.endTime
                    );
                  }
                },
                false
              )
            : true,
          index,
          isDisable: currentDateAvailabilities
            ? currentDateAvailabilities.timeSlots.reduce(
                (isBooked: boolean, item) => {
                  if (isBooked) {
                    return true;
                  } else {
                    return (
                      item.startTime === tm.startTime &&
                      item.endTime === tm.endTime &&
                      (item.booked || item.requested)
                    );
                  }
                },
                false
              )
            : false,
        };
      }),
    },
    onSubmit: (values) => {
      const { availabilities } = values;
      const selectedTimeSlots: InterviewAvailabilityTimeSlot_Payload[] =
        availabilities
          .filter((item) => {
            return item.isSelected;
          })
          .map((item) => {
            return {
              startTime: item.startTime,
              endTime: item.endTime,
            };
          });
      if (selectedDate)
        onSubmit([
          {
            day: selectedDate
              .hour(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .format(),
            timeSlots: selectedTimeSlots,
          },
        ]);
    },
  });

  const { values, setFieldValue } = formik;
  return (
    <CardModal
      onClose={closeAvailabilityModal}
      haveFromButtons
      containedButtonText="Save Changes"
      outlineBtnText="Cancel"
      onContainedButtonClicked={formik.handleSubmit}
      onOutlineButtonClicked={closeAvailabilityModal}
    >
      <InputLabel>
        <span style={{ fontWeight: "normal" }}>
          Set availability for{" "}
          <span style={{ fontWeight: "bold" }}>
            {selectedDate.format("MMM Do,YYYY")}
          </span>
          .
        </span>
      </InputLabel>
      <div>
        {values.availabilities.map((item, index) => {
          return (
            <CheckboxWithLabel
              key={index}
              checked={item.isSelected}
              label={`${item.startTime} - ${item.endTime}`}
              disabled={item.isDisable}
              onChange={(e) =>
                setFieldValue(
                  "availabilities",
                  values.availabilities.map((tm, index) => {
                    if (index === item.index) {
                      return {
                        ...tm,
                        isSelected: !tm.isSelected,
                      };
                    } else {
                      return tm;
                    }
                  })
                )
              }
            />
          );
        })}
      </div>
      <Alert hideButton className="mb-4">
        <span>Disabled checkboxes are already booked.</span>
      </Alert>
    </CardModal>
  );
};
