import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { MatchmakingScreen } from "./MatchmakingScreen";
import { useDispatch } from "react-redux";
import { EVENT_ACTIONS } from "../../Redux/Actions/eventActions";
import { BootstrapTableCellRenderer } from "../../Components/Common";

import {
  matchmakingQuestionInitialState,
  MatchmakingQuestionType_Payload,
} from "../../Types";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "image",
    text: "Image",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="image" />
    ),
  },
  {
    dataField: "eventTitle",
    text: "Title",
  },
  {
    dataField: "eventSubtitle",
    text: "Description",
  },
  {
    dataField: "category",
    text: "Upload Date",
  },
  {
    dataField: "showOnHomepage",
    text: "Homepage",
    formatter: (cell, _, __) => (
      <p style={{ color: cell ? "green" : "#d70000" }}>
        {cell ? "SHOW" : "HIDDEN"}
      </p>
    ),
  },
];

export const MatchmakingScreenContainer = () => {
  const dispatch = useDispatch();

  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const onTableEditClick = (
    rowData: MatchmakingQuestionType_Payload,
    rowIndex: number
  ) => {};

  const onSubmitForm = (questionData: MatchmakingQuestionType_Payload) => {
    if (pageState === "edit") {
      onEditData(questionData);
    } else {
      onAddEvent(questionData);
    }
  };

  const onEditData = (questionData: MatchmakingQuestionType_Payload) => {};
  const onAddEvent = (questionData: MatchmakingQuestionType_Payload) => {};
  React.useEffect(() => {
    if (pageState === "add") {
      dispatch(EVENT_ACTIONS.getEventDetail("-1"));
    } else if (pageState === "main") {
      dispatch(EVENT_ACTIONS.getAllEvents());
    } else if (pageState === "edit") {
      if (idToEdit) {
        dispatch(EVENT_ACTIONS.getEventDetail(idToEdit));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageState]);

  return (
    <MatchmakingScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      allEvents={[matchmakingQuestionInitialState]}
      onTableEditClick={onTableEditClick}
      eventToEdit={{}}
      onSubmitForm={onSubmitForm}
    />
  );
};
