import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import {
  AiOutlineWarning as WarningIcon,
  AiFillCheckCircle as SuccessIcon,
  AiOutlineInfo as InfoIcon,
  AiOutlineCloseCircle as CloseIcon
} from "react-icons/ai";
import{
  IconType
} from "react-icons";
import {
  MdErrorOutline as ErrorIcon
} from "react-icons/md";


import { useDispatch } from 'react-redux';
import {
  useTypedSelector
} from "../../Redux/Reducers";
import {closeSnackBar} from "../../Redux/Actions/appStatusActions";

interface varianIconsType{
  [key:string]:IconType
}

const variantIcon:varianIconsType = {
  "success": SuccessIcon,
  "warning": WarningIcon,
  "error": ErrorIcon,
  "info": InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface MySnackbarProps{
  message:string,
  variant:"error"|"info"|"warning"|"success"
  onClose?:()=>void
}

export function MySnackbarContentWrapper(props:MySnackbarProps) {
  const classes = useStyles1();
  const {
    message,
    variant,
    onClose,
    ...other
  }=props;
  const Icon:IconType = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant])}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          <p style={{fontSize:"2.28rem",margin:0}}>{message}</p>
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};


export function CustomizedSnackbar () {

  const {
    message,
    variant,
    onClose,
    open
  }=useTypedSelector(state=>state.status.snackBar);

  const dispatch=useDispatch();
  
    const _closeSnackBar=()=>{
        dispatch(closeSnackBar()) ;
        if(onClose){
            onClose();
        }
    }
    return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={_closeSnackBar}
          >
            <MySnackbarContentWrapper
              onClose={_closeSnackBar}
              variant={variant}
              message={message}
            />
          </Snackbar>
        </div>
      );



}




