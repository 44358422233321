import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { LanguageVariantType, Package_Payload, packageInitialState, PackageType } from "../../../Types";
import { BorderedRadio, CheckboxWithLabel, FileSelector, FormikTextField, InputLabel, LanguageFormControls, SelectInput, Separator } from "../../Common";
import { ExpandMenu } from "../../Common/ExpandMenu/ExpandMenu";
//import { useDispatch } from "react-redux";
import useFormAdderTools from "../../Hooks/useFormAdderTools";
import classes from "./AddPackage.module.css";

const validationSchema = yup.object({
  illustration: yup.mixed().required("Illustration is Require"),
  type: yup.string().required("Type is Required"),
  titleEn: yup.string().required("English title is Required"),
  titleKo: yup.string().required("Korean title is Required"),
  classCount: yup.string().required("Class count is Required"),
  validityDuration: yup.string().required("This field is Required"),
  monthlyPrice: yup.string().required("This filed is Required"),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  eventToEdit: {
    [key: string]: PackageType;
  };
  onSubmitForm: (eventData: Package_Payload) => void;
}

export const AddPackage = (props: Props) => {
  const { lang, setSelectedLanguage, eventToEdit, onSubmitForm } = props;
  //const dispatch = useDispatch();
  const [initialValues, onResetForm] = useFormAdderTools<Package_Payload>({
    itemToEdit: eventToEdit,
    lang,
    defaultValues: packageInitialState as Package_Payload,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        onSubmitForm(values);
      }}
    >
      {({ values, setFieldValue, errors }) => {
        const addButtonDisabled =
          Object.keys(errors).length > 0 ||
          JSON.stringify(values) === JSON.stringify(eventToEdit[lang]);

        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={"mb-5"}>
                    <InputLabel>
                      <span>Illustration*</span>
                    </InputLabel>
                    <div className="mb-4">
                      <FileSelector
                        limitSize={500000}
                        btnText="Add a File"
                        accept=".svg"
                        value={values.illustration}
                        onClearClick={() => setFieldValue("illustration", null)}
                        onFileChoose={(f) => setFieldValue("illustration", f)}
                      />
                    </div>
                  </div>
                  <div className="mb-5">
                    <InputLabel>
                      <span>Type*</span>
                    </InputLabel>
                    <SelectInput
                      variant="outlined"
                      placeholder="Select type"
                      options={[]}
                      handleChange={(e) =>
                        setFieldValue("type", e.target.value)
                      }
                      value={values.type}
                    />
                  </div>
                  <div className="mb-5">
                    <InputLabel>
                      <span>Class Duration*</span>
                    </InputLabel>
                    <div className="d-flex">
                      <BorderedRadio
                        className="flex-grow-1 mr-4"
                        label="20 Minutes"
                        isSelected={values.class_duration_number === 20}
                        checked={values.class_duration_number === 20}
                        onClick={() => setFieldValue("classDuration", 20)}
                      />
                      <BorderedRadio
                        className="flex-grow-1"
                        label="40 Minutes"
                        isSelected={values.class_duration_number === 40}
                        checked={values.class_duration_number === 40}
                        onClick={() => setFieldValue("classDuration", 40)}
                      />
                    </div>
                  </div>

                  <div className="d-flex mb-5">
                    <FormikTextField
                      label="Package Title (English)*"
                      placeholder={"Title..."}
                      className={"flex-grow-1 mr-4"}
                      name="titleEn"
                    />
                    <FormikTextField
                      label="Package Title (Korean)*"
                      placeholder={"Title..."}
                      className={"flex-grow-1"}
                      name="titleKo"
                    />
                  </div>

                  <div className="d-flex mb-5">
                    <FormikTextField
                      label="Class Count*"
                      placeholder={"Number of Classes..."}
                      className={"flex-grow-1 mr-4"}
                      name="classCount"
                    />
                    <FormikTextField
                      label="Validity Duration(Days)*"
                      placeholder={"Ex. 30..."}
                      className={"flex-grow-1"}
                      name={"validityDuration"}
                    />
                  </div>

                  <FormikTextField
                    label="Package Monthly Price*"
                    placeholder={"won/Month..."}
                    type="number"
                    className={classes.inputFields}
                    name={"packageMonthlyPrice"}
                  />

                  <ExpandMenu
                    header={
                      <CheckboxWithLabel
                        checked={values.discount !== undefined}
                        onChange={(e) =>
                          setFieldValue(
                            "discount",
                            e.target.checked ? "" : undefined
                          )
                        }
                        label="Discount"
                      />
                    }
                    className="mb-5"
                    content={
                      values.discount !== undefined && (
                        <div className="px-5">
                          <InputLabel>Percentage*</InputLabel>
                          <div className="d-flex">
                            <FormikTextField
                              className="flex-grow-1 mr-4"
                              placeholder="%"
                              name="discount"
                              type="number"
                            />
                            <div>
                              <InputLabel>Discounted Price</InputLabel>
                              <InputLabel>-</InputLabel>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <Separator />
            <LanguageFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              notMultiLang
              englishChecked={eventToEdit["en"] !== undefined}
              koreanChecked={eventToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                eventToEdit[lang] !== undefined ? "Edit Package" : "Add Package"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
