import CommonHttpService from "../../Services/Http/Endpoints/CommonServices";
import TutorServicesInstance from "../../Services/Http/Endpoints/TutorsServices";
import TutorsServices from "../../Services/Http/Endpoints/TutorsServices";
import { AcceptInterview_Payload, ChooseInterviewer_Payload, InterviewRejection_Payload, QualifyTutor_Payload, TutorDetailType } from "../../Types";
import { AppThunk, GET_ALL_TUTORS, GET_APPLICATION_REJECTION_REASONS, GET_TUTOR_APPLICATION_DETAILS, GET_TUTOR_APPLICATIONS, GET_TUTOR_DETAIL, GET_TUTOR_MATCHMAKING_QUESTIONS } from "../ActionTypes";
import { openSnackBar, toggleReduxLoading } from "./appStatusActions";

const getAllTutors = (): AppThunk => (dispatch) => {
  return TutorsServices.getAllTutors()
    .then((res) => {
      return dispatch({
        type: GET_ALL_TUTORS,
        payload: res.data.tutors,
      });
    })
    .catch(() => {});
};

const toggleTutor =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleReduxLoading(true));
      const res = await TutorsServices.toggleTutor(id);
      if (res.data.ok) {
        dispatch(
          openSnackBar({
            open: true,
            message: "Toggling the tutor was successful",
            variant: "success",
          })
        );
        dispatch(getTutorDetails(id));
      }
      dispatch(toggleReduxLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(toggleReduxLoading(false));
    }
  };

const getTutorDetails =
  (id: string): AppThunk =>
  (dispatch) => {
    console.log(id, "dsfsdf");
    return TutorsServices.getTutorDetails(id)
      .then((res) => {
        return dispatch({
          type: GET_TUTOR_DETAIL,
          payload: res.data.tutors,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

const banTutor =
  (id: string, duration: number): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.banUser(id, duration)
      .then(() => {
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const unBanTutor =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.unBanUser(id)
      .then(() => {
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const getTutorInterviewApplications = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return TutorsServices.getTutorInterviewApplications()
    .then((res) => {
      dispatch(toggleReduxLoading(false));
      dispatch({
        type: GET_TUTOR_APPLICATIONS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
      console.log(err);
    });
};

const getApplicationDetail =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.getTutorApplicationDetail(id)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        return dispatch({
          type: GET_TUTOR_APPLICATION_DETAILS,
          payload: res.data.tutor,
        });
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const getApplicationRejectionReasons = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return TutorsServices.getRejectionReasons()
    .then((res) => {
      dispatch(toggleReduxLoading(false));
      return dispatch({
        type: GET_APPLICATION_REJECTION_REASONS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
      console.log(err);
    });
};

const rejectTutorApplication =
  (
    data: InterviewRejection_Payload,
    onFinish: (isOk: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.rejectTutorApplication(data)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        dispatch(TutorsActions.getTutorDetails(data.tutorId));
        if (onFinish) {
          onFinish(true);
        }
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        if (onFinish) {
          onFinish(false);
        }
      });
  };

const acceptTutorApplicationRequest =
  (
    data: AcceptInterview_Payload,
    onFinish: (isOk: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.acceptTutorApplicationRequest(data)
      .then((res) => {
        dispatch(TutorsActions.getTutorDetails(data.tutorId));
        dispatch(toggleReduxLoading(false));
        if (onFinish) {
          onFinish(true);
        }
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        if (onFinish) {
          onFinish(false);
        }
      });
  };

const chooseApplicationInterviewer =
  (id: string, data: ChooseInterviewer_Payload): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.chooseApplicationInterviewer(id, data)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        console.log(err);
      });
  };

const changeTutorInterviewStatus =
  (data: QualifyTutor_Payload, onFinish: (isOk: boolean) => void): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return TutorsServices.changeTutorInterviewStatus(data)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        dispatch(TutorsActions.getTutorDetails(data.tutorId));
        if (onFinish) {
          onFinish(true);
        }
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        if (onFinish) {
          onFinish(false);
        }
      });
  };

const getTutorRates =
  (tutorId: string | null): AppThunk =>
  (dispatch) => {
    if (tutorId === null) {
      return dispatch({
        type: GET_TUTOR_DETAIL,
        payload: null,
      });
    }
    return TutorsServices.getTutorDetails(tutorId)
      .then((res) => {
        dispatch({
          type: GET_TUTOR_DETAIL,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

const getTutorMatchmakingQuestions = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  CommonHttpService.getMatchmakingQuestions("tutor")
    .then((res) => {
      dispatch({
        type: GET_TUTOR_MATCHMAKING_QUESTIONS,
        payload: res.data.questions,
      });
      dispatch(toggleReduxLoading(false));
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
    });
};

const updateTutorDetail =
  (id: string, dataToUpdate: Partial<TutorDetailType>): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    TutorServicesInstance.updateTutorDetail(id, dataToUpdate)
      .then((res) => {
        dispatch(getTutorDetails(id));
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        console.log(err);
      });
  };

export const TutorsActions = {
  getAllTutors,
  getTutorDetails,
  banTutor,
  unBanTutor,
  getTutorInterviewApplications,
  getApplicationRejectionReasons,
  getApplicationDetail,
  changeTutorInterviewStatus,
  acceptTutorApplicationRequest,
  rejectTutorApplication,
  chooseApplicationInterviewer,
  getTutorRates,
  getTutorMatchmakingQuestions,
  toggleTutor,
  updateTutorDetail
};
