import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";

import { DatePicker } from "../Common";
import classes from "./RequestFilters.module.css";

interface Props {
  startTime: MaterialUiPickersDate;
  endTime: MaterialUiPickersDate;
  onChangeTime: (
    startDate?: MaterialUiPickersDate,
    endDate?: MaterialUiPickersDate
  ) => void;

}

export const RequestFilters = (props: Props) => {
  const { startTime, endTime, onChangeTime } = props;
  return (
    <div className={classes.Container}>
      <DatePicker
        placeholder="Start Date"
        onChange={(newDate) => onChangeTime && onChangeTime(newDate)}
        selectedDate={startTime}
      />
      <div style={{ width: "1rem" }} />
      <DatePicker
        placeholder="End Date"
        onChange={(newDate) =>
            onChangeTime && onChangeTime(undefined, newDate)
        }
        selectedDate={endTime}
      />
    </div>
  );
};
