// eslint-disable-next-line
import { createStore, compose, applyMiddleware } from "redux";
//import {} from "react-redux";
import thunk from "redux-thunk";

import rootReducer from "./Reducers";

const initailState = {};

const middleWares = [thunk];

const store =
  process.env.NODE_ENV !== "development"
    ? createStore(rootReducer, initailState, applyMiddleware(...middleWares))
    : createStore(
        rootReducer,
        initailState,
        compose(applyMiddleware(...middleWares))
      );

export default store;
