import ClassServices from "../../Services/Http/Endpoints/ClassServices";
import { ClassType, EditClassData, SearchClassType } from "../../Types";
import { AppThunk, GET_ALL_CLASSES, GET_CLASS_DETAILS, GET_STUDENT_CLASSES } from "../ActionTypes";
import { openSnackBar, toggleReduxLoading } from "./appStatusActions";

const getAllClasses =
  (data: SearchClassType): AppThunk =>
  (dispatch) => {
    return ClassServices.getAllClasses(data)
      .then((res) => {
        return dispatch({
          type: GET_ALL_CLASSES,
          payload: res.data.data,
        });
      })
      .catch(() => {});
  };

const getClassDetails =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return ClassServices.getClassDetail(id)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        return dispatch(storeClassDetail(res.data.classDetails));
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleReduxLoading(false));
      });
  };

export const getAllClassesByStudent =
  (
    studentId: string,
    type?: string,
    startTime?: string,
    endTime?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleReduxLoading(true));
      const res = await ClassServices.getAllClassesByStudent(studentId, type, startTime,endTime);

      if (res.data.ok) {
        dispatch({
          type: GET_STUDENT_CLASSES,
          payload: res.data.data,
        });
      }
      dispatch(toggleReduxLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(toggleReduxLoading(false));
    }
  };

export const storeClassDetail = (data: ClassType | null) => {
  return {
    type: GET_CLASS_DETAILS,
    payload: data,
  };
};

const editClass =
  (data: EditClassData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleReduxLoading(true));
      const response = await ClassServices.editClassSchedule(data);
      if (response && response.data && response.data.ok) {
        dispatch(
          openSnackBar({
            open: true,
            message: "Class Edited successfully",
            variant: "success",
          })
        );
        dispatch(getClassDetails(data.class_id));
      }
    } catch (e) {
      console.log(e);
      dispatch(toggleReduxLoading(false));
    }
  };

export const classesActions = {
  getAllClasses,
  getClassDetails,
  editClass,
  getAllClassesByStudent,
};
