import { AppThunk, GET_GALLERY_DATA } from "../ActionTypes";

import CommonHttpService from "../../Services/Http/Endpoints/CommonServices";
//import { toggleReduxLoading } from "./appStatusActions";

//import { toggleReduxLoading } from "./appStatusActions";
import { Gallery_Payload } from "../../Types/common";
import { toggleReduxLoading } from "./appStatusActions";

const uploadFile =
  (file: File, onFinish: (isOk: boolean, url: string) => void): AppThunk =>
  () => {
    const formData = new FormData();
    formData.append("upload", file);
    CommonHttpService.uploadFormData(formData)
      .then((result) => {
        onFinish(true, result.data);
      })
      .catch((err) => {
        onFinish(false, "");
      });
  };

const getGalleryData = (): AppThunk => (dispatch) => {
  return CommonHttpService.getGalleryData()
    .then((res) => {
      dispatch({
        type: GET_GALLERY_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

const uploadToGallery =
  (
    data: Gallery_Payload,
    onFinish: (isOk: boolean, url: string) => void
  ): AppThunk =>
  (dispatch) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("upload", data.image as File);
    dispatch(toggleReduxLoading(true));
    CommonHttpService.uploadFormData(formData)
      .then((result) => {
        dispatch(toggleReduxLoading(false));
        dispatch(getGalleryData());
        onFinish(true, result.data);
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        onFinish(false, "");
      });
  };

export const Common_ACTIONS = {
  uploadFile,
  uploadToGallery,
  getGalleryData,
};
