import { MatchmakingDataType, PackageType, TagType, UserBaseInfo } from "./";
import { AccountState, userBaseInfoInitialValues } from "./common";

export interface StudentType extends UserBaseInfo {}

export interface StudentDetailType extends StudentType {
  phone_number: string;
  matchmakingData?: MatchmakingDataType;
  ideal_tutor_tags: TagType[];
  currentPackages: PackageType[];
  backup_email: string;
  matchmakingAnswers?: {
    questionId: string;
    _id: string;
    userId: string;
    answer: {
      label: string;
      checked: boolean | null;
      _id: string;
    }[];
  }[];
  ageGroup: string;
  job_position: string;
  company_name: string;
  company_code: string;
  tierAndPoints?: any;
  perv_result_average?: {
    speaking: number;
    pronunciation: number;
    vocabulary: number;
    grammar: number;
    listening: number;
  };
  current_result_average?: {
    speaking: number;
    pronunciation: number;
    vocabulary: number;
    grammar: number;
    listening: number;
  };
  classCount?: number;
  invite_friends?: {
    friend_invitation_count_signup?: number;
    friend_invitation_count_paid?: number;
    friend_invitation_count_overall?: number;
    created_invitation?: string[];
    friends_signup?: string[];
    friends_paid?: string[];
  };
  additional_data?: {
    back_up_email?: string;
    company_name?: string;
    company_code?: string;
    job_position?: string;
  };
  created_at?: string;
  account_status?: AccountState | string;
  level_test?: string;
  post_level?: string;
  corporate?: {};
  corporateSemester?: string[];
  level_test_detail?: LevelTestType;
  post_level_test_detail?: LevelTestType;

  corporatePackages: PackageType[];
  level_test_status?: string;
  post_level_test_status?: string;
}

export interface LevelTestType {
  fluency: number;
  lexical: number;
  grammatical: number;
  listening: number;
  pronunciation: number;
  averageScore: number;
  problem: string;
  problemDescription: string;
  tutorComments: string;
}

export const studentInitialValues = {
  ...userBaseInfoInitialValues,
  matchmakingData: {
    iconName: "",
    options: [],
    order: 0,
    question_type: "",
    title: "",
    _id: "",
  },
  ideal_tutor_tags: [],
  currentPackages: [],
  backup_email: "",
  matchmakingAnswers: [],
  ageGroup: "",
  job_position: "",
  company_name: "",
  company_code: "",
  tierAndPoints: [],
  perv_result_average: {
    speaking: 0,
    pronunciation: 0,
    vocabulary: 0,
    grammar: 0,
    listening: 0,
  },
  current_result_average: {
    speaking: 0,
    pronunciation: 0,
    vocabulary: 0,
    grammar: 0,
    listening: 0,
  },
  classCount: 0,
  invite_friends: {
    friend_invitation_count_signup: 0,
    friend_invitation_count_paid: 0,
    friend_invitation_count_overall: 0,
    created_invitation: [],
    friends_signup: [],
    friends_paid: [],
  },
  additional_data: {
    back_up_email: "",
  },
  created_at: "",
  account_status: "active",
  phone_number: "",
  corporatePackages: [],
};
