import { ErrorMessage } from "formik";
import React, { ReactNode, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { InputLabel } from "../";
import { useChangeURl, useQueryString } from "../../Hooks";
import classes from "./TextInput.module.css";

export interface TextInputProps {
  className?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  limit?: number;
  freeHeight?: boolean;
  type?: "email" | "number" | "password" | "tel" | "text" | "url";
  disabled?: boolean;
  notFormikInput?: boolean;
  readonly?: boolean;
  searchText?: string;
  onSearch?: (val: string) => void;
  onClear?: () => void;
  getSearchValueOnChange?: (value: string) => void;
  data?: any[];
}

export const TextInput = (props: TextInputProps) => {
  const {
    className,
    label,
    startAdornment,
    endAdornment,
    placeholder,
    helperText,
    error,
    onChange,
    onBlur,
    onFocus,
    value,
    id,
    name,
    limit,
    freeHeight,
    disabled,
    type,
    notFormikInput,
    readonly,
    searchText,
    onSearch,
    data,
  } = props;

  const RootClassNames = disabled
    ? [classes.Root, classes.Root_disabled].join(" ")
    : classes.Root;
  const InputRootClassName = error
    ? [classes.inputRoot, classes.inputRoot_Error].join(" ")
    : classes.inputRoot;

  const query = useQueryString();

  const updateUrlNoRefresh = useChangeURl();

  const _onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (limit) {
      if (e.target.value.length <= limit) {
        if (onSearch) {
          onSearch(e.target.value);
        } else if (onChange) onChange(e);
      }
    } else {
      if (onSearch) {
        onSearch(e.target.value);
      } else if (onChange) onChange(e);
    }
  };

  useEffect(() => {
    if (onSearch && query.get("search")) {
      onSearch(`${query.get("search")}`);
    }
  }, [data]);

  useEffect(() => {
    const interval = setTimeout(() => {
      updateUrlNoRefresh([
        {
          nameQuery: "search",
          data: searchText,
          delete: searchText === ""
        },
      ]);
    }, 1000);

    if (interval) {
      return () => clearTimeout(interval);
    }
  }, [searchText]);

  return (
    <div className={className}>
      <div
        className={
          freeHeight
            ? [RootClassNames, classes.Root_freeHeight].join(" ")
            : RootClassNames
        }
      >
        {(limit !== undefined || label !== undefined) && (
          <div className={classes.topInput}>
            {label && (
              <InputLabel className={classes.inputLabel}>{label}</InputLabel>
            )}
            {limit && value && (
              <p className={classes.LimitText}>
                {value.toString().length}/{limit}
              </p>
            )}
          </div>
        )}
        <div
          className={
            freeHeight
              ? [InputRootClassName, classes.inputRoot_freeHeight].join(" ")
              : InputRootClassName
          }
        >
          {startAdornment && (
            <div className={classes.inputAdornment}>{startAdornment}</div>
          )}
          <input
            disabled={disabled}
            id={id}
            name={name}
            value={searchText || value}
            onChange={_onInputChange}
            onBlur={onBlur}
            onFocus={onFocus}
            className={classes.inputTag}
            placeholder={placeholder}
            type={type}
            readOnly={readonly}
          />
          {endAdornment && (
            <div className={classes.inputAdornment}>{endAdornment}</div>
          )}
        </div>
        {name && !notFormikInput && (
          <ErrorMessage name={name}>
            {(msg) => (
              <span
                className={
                  name !== undefined
                    ? [
                        classes.inputHelperText,
                        classes.inputHelperText_error,
                      ].join(" ")
                    : classes.inputHelperText
                }
              >
                {msg}
              </span>
            )}
          </ErrorMessage>
        )}
        {helperText && (
          <span
            className={
              error
                ? [classes.inputHelperText, classes.inputHelperText_error].join(
                    " "
                  )
                : classes.inputHelperText
            }
          >
            {helperText}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextInput;
