import { UserTypes } from "./common";

export interface MatchmakingAnswerType {
  titleEn: string;
  titleKo: string;
}

export interface MatchmakingQuestionType_Payload {
  questionEn: string;
  questionKo: string;
  targetUser: UserTypes;
  questionNumber: number;
  answerType: string | null;
  answers: MatchmakingAnswerType[];
}

export const matchmakingQuestionInitialState: MatchmakingQuestionType_Payload =
  {
    questionEn: "",
    questionKo: "",
    targetUser: "student",
    questionNumber: 1,
    answerType: null,
    answers: [
      {
        titleEn: "",
        titleKo: "",
      },
    ],
  };
