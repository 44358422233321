import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { BootstrapTableCellRenderer } from "../../Components/Common";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { openSnackBar } from "../../Redux/Actions/appStatusActions";
import { displayEventAsActive, EVENT_ACTIONS } from "./../../Redux/Actions/eventActions";
import { useTypedSelector } from "./../../Redux/Reducers/index";
import { EventType, EventType_Payload } from "./../../Types/events";
import { EventScreen } from "./EventScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "image",
    text: "Image",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="image" />
    ),
  },
  {
    dataField: "eventTitle",
    text: "Title",
  },
  {
    dataField: "active",
    text: "Active",
    formatter: (cell, row) => (
      <p
        style={{
          margin: 0,
          color: row.active ? "#56c795" : "#D70000",
        }}
      >
        {row.active ? "TRUE" : "FALSE"}
      </p>
    ),
  },
  {
    dataField: "eventSubtitle",
    text: "Description",
  },
  {
    dataField: "rew",
    text: "Enroll",
    formatter: (cell, row) => (
      <p>
        {row.remaining}/{row.limit}
      </p>
    ),
  },
  {
    dataField: "startDate",
    text: "Start Date",
    formatter: (cell, row) => {
      return (
        <p>
          {moment(row.startDate)
            .hours(row.startTime.split(":")[0])
            .minutes(row.startTime.split(":")[1])
            .format("YYYY.MM.DD HH:mm A")}
        </p>
      );
    },
  },
  {
    dataField: "endDate",
    text: "End Date",
    formatter: (cell, row) => {
      return (
        <p>
          {moment(row.endDate)
            .hours(row.endTime.split(":")[0])
            .minutes(row.endTime.split(":")[1])
            .format("YYYY.MM.DD HH:mm A")}
        </p>
      );
    },
  },
];

export const EventScreenContainer = () => {
  const dispatch = useDispatch();
  const allEvents = useTypedSelector((state) => state.platformEvent.events);
  const eventToEdit = useTypedSelector(
    (state) => state.platformEvent.eventToEdit
  );
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const [filteredEvents, setFilteredEvents] = useState<EventType[]>([]);

  useEffect(() => {
    // const filtered = allEvents.filter((eve) => eve.active);
    setFilteredEvents(allEvents);
  }, [allEvents]);

  const onTableEditClick = (rowData: EventType, rowIndex: number) => {
    if (rowData._id) {
      onChangePageState("edit", rowData._id);
    }
  };

  const onSubmitForm = (materialData: EventType_Payload) => {
    if (pageState === "edit" && eventToEdit[selectedLanguage] !== undefined) {
      onEditData(materialData);
    } else {
      onAddEvent(materialData);
    }
  };

  const onEditData = (materialData: EventType_Payload) => {
    if (!materialData.image)
      return dispatch(
        openSnackBar({
          open: true,
          message: "Image is missing",
          variant: "error",
        })
      );
    dispatch(
      EVENT_ACTIONS.editEventAction(
        materialData,
        eventToEdit[selectedLanguage]._id,
        selectedLanguage,
        () => {
          onChangePageState("main");
        }
      )
    );
  };
  const onAddEvent = (materialData: EventType_Payload) => {
    if (!materialData.image)
      return dispatch(
        openSnackBar({
          open: true,
          message: "Image is missing",
          variant: "error",
        })
      );
    dispatch(
      EVENT_ACTIONS.addEventAction(materialData, selectedLanguage, () => {
        onChangePageState("main");
      })
    );

    console.log(materialData, "materialData");
  };
  React.useEffect(() => {
    if (pageState === "add") {
      dispatch(EVENT_ACTIONS.getEventDetail("-1"));
    } else if (pageState === "main") {
      dispatch(EVENT_ACTIONS.getAllEvents());
    } else if (pageState === "edit") {
      if (idToEdit) {
        dispatch(EVENT_ACTIONS.getEventDetail(idToEdit));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageState, idToEdit]);

  const onDeleteDelete = (event: EventType) => {
    dispatch(EVENT_ACTIONS.deleteEvent(event.eventID));
  };

  const updateEvent = (event: EventType, isActive?: boolean) => {
    dispatch(displayEventAsActive({ isActive: isActive }, event._id));
  };

  return (
    <EventScreen
      updateEvent={updateEvent}
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      allEvents={filteredEvents}
      onTableEditClick={onTableEditClick}
      eventToEdit={eventToEdit}
      onSubmitForm={onSubmitForm}
      onDeleteCell={onDeleteDelete}
    />
  );
};
