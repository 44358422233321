import { IssueResponse_Payload } from "../../../Types";
import BaseApi from "../BaseAPI";

class PackagesServices extends BaseApi {
  constructor() {
    super({ suffix: "admin" });
  }

  getFreePackages = () => {
    return this.httpService.get("/free_packages");
  };

  getUserPackages = (sort?: string) => {
    return this.httpService.get("/unfree_packages");
  };
}

const PackagesInstance = new PackagesServices();
export default PackagesInstance;
