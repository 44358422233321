import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";

import { RequestFilters } from "../../Components/Coporates/ReqestFilters";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { useDashboardPageLocalData } from "../../Components/Hooks";

interface Props {
  columns: ColumnDescription[];
  requests: any[];
  startTime: MaterialUiPickersDate;
  endTime: MaterialUiPickersDate;
  onChangeTime: (
    startDate?: MaterialUiPickersDate,
    endDate?: MaterialUiPickersDate
  ) => void;

}

export const CorporateRequestsScreen = (props: Props) => {
  const { columns, requests, startTime, endTime, onChangeTime } = props;

  const [{ pageState }, { onChangePageState }] = useDashboardPageLocalData();



  const RenderExtraFilters = () => (
    <RequestFilters
      startTime={startTime}
      endTime={endTime}
      onChangeTime={onChangeTime}
    />
  );

  return (
    <DashboardPageContentLayout
      onChangePageState={onChangePageState}
      tableColumns={columns}
      data={requests}
      pageState={pageState}
      pageTitle="Manage Corporate Requests"
      ExtraTableFilters={RenderExtraFilters}
      haveAddButton={false}
      // filterStyle={{
      //   minWidth: "100rem",
      // }}
    />
  );
};
