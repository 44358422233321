import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { BorderedRadioGroup, BorderedRadioGroupItemsType } from "../../Common/BorderedRadioGroup/BorderedRadioGroup";
import classes from "./UsersTableFilter.module.css";

interface Props {
  studentType?: string;
  setStudentType: (val: "all" | "b2b" | "b2c") => void;
}

export const UsersTableFilter = (props: Props) => {
  const { studentType, setStudentType } = props;

  const onClick = (item: BorderedRadioGroupItemsType) => {
    if (item.index === 0) setStudentType("b2b");
    if (item.index === 1) setStudentType("b2c");
    if (item.index === 2) setStudentType("all");
  };
  return (
    <div>
      <BorderedRadioGroup
        className={classes.BorderedRadioGroup}
        onClick={onClick}
        gridSize={4}
        items={[
          {
            index: 0,
            label: "B2B Only",
            checked: studentType === "b2b",
          },
          {
            index: 1,
            label: "B2C Only",
            checked: studentType === "b2c",
          },
          { index: 2, label: "All", checked: studentType === "all" },
        ]}
      />
    </div>
  );
};
