import React from "react";
import classes from "./TutorRateDetails.module.css";
import { IconGenerator, TextInput } from "../../Common";
import { CardModal } from "../../menus";
import { Rating } from "@material-ui/lab";

export const TutorRateDetails = () => {
  return (
    <CardModal head={<span>Rating Details</span>}>
      <div className={classes.Root}>
        <div className={classes.AverageRootContainer}>
          <div className={classes.Ratings}>
              <p>Average Rating</p>
              <Rating readOnly classes={{ iconEmpty: classes.emptyIcon }}/>
          </div>
          <div>
            <p>Classes</p>
            <h5>365</h5>
          </div>
          <div>
            <p>Reviews</p>
            <h5>365</h5>
          </div>
        </div>
        <TextInput
          className={classes.SearchInput}
          startAdornment={<IconGenerator iconName="search" />}
          placeholder="Search..."
        />
        <div className={classes.RateList}>
          <div className={classes.RateItemRoot}>
              <div className={classes.RateItemText}>
                  <p>Effective Communication</p>
                  <h4>Writing, Design, and Presentation</h4>
              </div>
              <div className={classes.RateItemClassType}>
                  <p>20</p>
                  <h4>Min</h4>
              </div>
              <Rating/>
          </div>
          <div className={classes.RateItemRoot}>
              <div className={classes.RateItemText}>
                  <p>Effective Communication</p>
                  <h4>Writing, Design, and Presentation</h4>
              </div>
              <div className={classes.RateItemClassType}>
                  <p>20</p>
                  <h4>Min</h4>
              </div>
              <Rating/>
          </div>
          <div className={classes.RateItemRoot}>
              <div className={classes.RateItemText}>
                  <p>Effective Communication</p>
                  <h4>Writing, Design, and Presentation</h4>
              </div>
              <div className={classes.RateItemClassType}>
                  <p>20</p>
                  <h4>Min</h4>
              </div>
              <Rating/>
          </div>
          <div className={classes.RateItemRoot}>
              <div className={classes.RateItemText}>
                  <p>Effective Communication</p>
                  <h4>Writing, Design, and Presentation</h4>
              </div>
              <div className={classes.RateItemClassType}>
                  <p>20</p>
                  <h4>Min</h4>
              </div>
              <Rating/>
          </div>
          <div className={classes.RateItemRoot}>
              <div className={classes.RateItemText}>
                  <p>Effective Communication</p>
                  <h4>Writing, Design, and Presentation</h4>
              </div>
              <div className={classes.RateItemClassType}>
                  <p>20</p>
                  <h4>Min</h4>
              </div>
              <Rating/>
          </div>
          <div className={classes.RateItemRoot}>
              <div className={classes.RateItemText}>
                  <p>Effective Communication</p>
                  <h4>Writing, Design, and Presentation</h4>
              </div>
              <div className={classes.RateItemClassType}>
                  <p>20</p>
                  <h4>Min</h4>
              </div>
              <Rating/>
          </div>
        </div>
      </div>
    </CardModal>
  );
};
