import { ENV } from "../../env/base";
import AuthUser from "../../Services/Http/Endpoints/AuthUserService";
import { Login_Payload } from "../../Types";
import { USER_FOUND, USER_NOT_FOUND } from "../ActionTypes";
import { AppThunk } from "../ActionTypes";
import { toggleReduxLoading } from "./appStatusActions";
import { toggleSupervisor } from "./userAndRoleActions";

const { TOKEN_STORAGE_KEY } = ENV;

export const loginUser =
  (loginData: Login_Payload, history?: any): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));

    return AuthUser.loginUser(loginData)

      .then((res: any) => {
        dispatch(toggleReduxLoading(false));
        const { token } = res.data;

        if (res.data.roles && res.data.roles.supervisor) {
          dispatch(toggleSupervisor(true));
        }
        dispatch({
          type: USER_FOUND,
          payload: res.data.adminDetails,
        });
        localStorage.setItem(TOKEN_STORAGE_KEY, token);

        return history.push(
          res.data.roles && res.data.roles.supervisor
            ? "/b2b/programs"
            : "/content/learningMaterials"
        );
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const logoutUser =
  (history: any): AppThunk =>
  (dispatch) => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    dispatch({
      type: USER_NOT_FOUND,
    });
    dispatch(toggleSupervisor(false));
    history.push("/#/login");
  };
