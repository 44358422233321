import BaseApi from "../BaseAPI";
import { BanUser_Payload } from "../../../Types";

class UserServices extends BaseApi {
  constructor() {
    super({ suffix: "admin/users" });
  }
  getUsers = () => {
    return this.httpService.get("");
  };
  addNewUser = (data: FormData) => {
    return this.httpService.post("", data);
  };
  editUser = (id: string, newData: FormData) => {
    return this.httpService.patch(`${id}`, newData);
  };

  banUser = (data: BanUser_Payload) => {
    return this.httpService.post("", data);
  };

  getUserDetail = () => {
    return this.httpService.get('/detail');
  };

  unBanUser = (userId: string) => {
    return this.httpService.post(`${userId}`);
  };
}

const UserServicesInstance = new UserServices();
export default UserServicesInstance;
