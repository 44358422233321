import React from "react";
import { MembersScreen } from "./MemberScreen";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useTypedSelector } from "./../../Redux/Reducers/index";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { useDispatch } from "react-redux";
import {
  editMember,
  getAllMembers,
  getMember,
  postMember,
} from "../../Redux/Actions/userAndRoleActions";
import { getAllRoles } from "./../../Redux/Actions/userAndRoleActions";
import { Member, Member_Payload } from "../../Types/UserAndRoles";
import { BootstrapTableCellRenderer } from "../../Components/Common";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "profile_image",
    text: "Image",
    formatter: (cell, __) => (
      <BootstrapTableCellRenderer type="image" value={cell} />
    ),
  },
  {
    dataField: "first_name+last_name",
    text: "Full Name",
    formatter: (_, cellData, __) => (
      <BootstrapTableCellRenderer
        type="text"
        value={cellData.first_name + " " + cellData.last_name}
      />
    ),
  },
  {
    dataField: "role.title",
    text: "Role",
  },
  {
    dataField: "account_status",
    text: "Account State",
  },
];

export const MemberScreenContainer = () => {
  const dispatch = useDispatch();
  const [{ pageState, idToEdit }, { onChangePageState }] =
    useDashboardPageLocalData();

  const members = useTypedSelector((state) => state.userAndRoles.members);
  const roles = useTypedSelector((state) => state.userAndRoles.roles);
  const memberToEdit = useTypedSelector((state) => state.userAndRoles.member);

  const onPostMember = (data: Member_Payload) => {
    dispatch(
      postMember(data, (isOk) => {
        if (isOk) onChangePageState("main");
      })
    );
  };

  const onEditMember = (data: Member_Payload) => {
    if (idToEdit)
      dispatch(
        editMember(idToEdit, data, (isOk) => {
          if (isOk) onChangePageState("main");
        })
      );
  };

  const onSubmit = (data: Member_Payload) => {
    if (pageState === "add") {
      onPostMember(data);
    } else if (pageState === "edit") {
      onEditMember(data);
    }
  };

  const onEditMemberClicked = (member: Member) => {
    onChangePageState("edit", member._id);
    dispatch(getMember(member));
  };

  React.useEffect(() => {
    if (pageState === "main") {
      dispatch(getAllMembers());
      dispatch(getMember(null));
    } else if (pageState === "add") {
      dispatch(getAllRoles());
      dispatch(getMember(null));
    } else if (pageState === "edit") {
      dispatch(getAllRoles());
    }
  }, [pageState, dispatch]);

  return (
    <MembersScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      memberList={members}
      roleList={roles}
      onSubmit={onSubmit}
      memberToEdit={memberToEdit}
      onEditMemberClicked={onEditMemberClicked}
    />
  );
};
