import React from "react";
import {  Route, RouteProps } from "react-router-dom";

import { LottieLoading } from ".";

interface Props {
  isAuth: boolean;
  isAuthenticating: boolean;
  AuthenticateRoute?: string;
  //SplashRoute?: string ;
  ComponentToRender: any;
}

export function ProtectedRoute({
  isAuth,
  isAuthenticating,
  AuthenticateRoute,
  //SplashRoute,
  ComponentToRender,
  ...rest
}: Props & RouteProps) {
  if (isAuthenticating) {
    return (
      <Route
        {...rest}
        render={({ location, ...rest }) => (
          <LottieLoading isOverlay={true} showOverLay={isAuthenticating} />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={({ location, ...rest }) => {
          if (isAuth) {
            return <ComponentToRender {...rest} />;
          }
        }}
      />
    );
  }
}

export default ProtectedRoute;
