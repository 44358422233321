// import { addLocaleData } from 'react-intl';
import enLang from './entries/en_us';
import koLang from './entries/ko';

import {AppLocalType} from "./Types"



const AppLocale:AppLocalType = {
    "en": enLang,
    "ko": koLang 
};




export default AppLocale;
