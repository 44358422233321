import { AuthActionTypes, AuthState, LOGOUT_USER, TOGGLE_IS_AUTHENTICATING, TOGGLE_SUPERVISOR, USER_FOUND, USER_NOT_FOUND } from "../ActionTypes";

const initialState: AuthState = {
  isAuth: false,
  isAuthenticating: true,
  isSupervisor: false,
};

export default function AuthReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case TOGGLE_SUPERVISOR:

      return {
        ...state,
        isSupervisor: action.payload,
        isAuthenticating: false
      };
    case USER_FOUND:
      return {
        ...state,
        isAuth: true,
        isAuthenticating: false,
        user: action.payload,
      };
    case USER_NOT_FOUND:
      return {
        ...state,
        isAuth: false,
        isAuthenticating: false,
        user: initialState.user,
      };
    case LOGOUT_USER:
      return {
        ...initialState,
        isAuthenticating: false,
      };

    case TOGGLE_IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.payload,
      };
    default:
      return state;
  }
}
