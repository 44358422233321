import {
  AppStatusActionTypes,
  GET_ERRORS,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  CHANGE_LANGUAGE,
  AppStatusState,
  TOGGLE_REDUX_LOADING,
  SET_UPLOAD_PROGRESS,
  OPEN_GLOBAL_DIALOG,
  CLOSE_GLOBAL_DIALOG,
} from "../ActionTypes";

//import config from "../../Config/AppConfig";
import { ENV } from "../../env/base";
const { LANGUAGE_STORAGE_KEY } = ENV;

const initialState: AppStatusState = {
  errors: {},
  lang: localStorage.getItem(LANGUAGE_STORAGE_KEY) || "ko",
  modal: {
    visible: false,
    title: "",
    description: "",
    Icon: undefined,
    btnText: "",
    onBtnClick: () => {},
    closeOnClick: true,
    secondaryBtnText: undefined,
    onSecondaryBtnClick: undefined,
  },
  snackBar: {
    open: false,
    message: "",
    variant: "info",
    onClose: undefined,
  },
  reduxLoading: false,
  uploadProgress: 0,
  dialog: {
    open: false,
    children: undefined,
    onClose: undefined,
  },
};

export default function appStatusReducer(
  state = initialState,
  action: AppStatusActionTypes
): AppStatusState {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: {
          ...initialState.modal,
          visible: false,
        },
      };

    case OPEN_SNACKBAR:
      return {
        ...state,
        snackBar: {
          open: true,
          message: action.payload.message,
          variant: action.payload.variant,
          onClose: action.payload.onClose,
        },
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackBar: initialState.snackBar,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    case TOGGLE_REDUX_LOADING:
      return {
        ...state,
        reduxLoading: action.payload,
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };

    case OPEN_GLOBAL_DIALOG:
      return {
        ...state,
        dialog: action.payload,
      };
    case CLOSE_GLOBAL_DIALOG:
      return {
        ...state,
        dialog: initialState.dialog,
      };
    default:
      return state;
  }
}
