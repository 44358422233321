import { Field, FieldProps } from "formik";
import React from "react";

import { getClassStatus } from "../../Classes";
import TextInput, { TextInputProps } from "./../TextInput/TextInput";

interface Props {
  name: string;
}

export const FormikTextField = ({ name, ...props }: TextInputProps & Props) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        return (
          <TextInput
            error={errors[name] !== undefined && touched[name] === true}
            {...props}
            {...field}
            //error={touched && errors[name] !== undefined}
            //helperText={errors[name] ? (errors[name] as string) : ""}
          />
        );
      }}
    </Field>
  );
};
