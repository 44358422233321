export const classState = [
  { label: "All States", value: "all" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Accepted", value: "confirmed" },
  { label: "Auto Rejected", value: "auto_reject" },
  { label: "Cancelled By Student", value: "cancelled_by_student" },
  { label: "Cancelled By Admin", value: "cancelled_by_admin" },
  { label: "Confirmation Pending", value: "request_pending" },
  { label: "Incompleted", value: "in_complete" },
  { label: "Cancelled By Tutor", value: "cancelled_by_tutor" },
];

export const classDurations = [
  {
    label: "20 Minutes",
    value: "20",
  },
  {
    label: "40 Minutes",
    value: "40",
  },
];
