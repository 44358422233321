import { MainRouteType } from "./../../../Types/ConfigTypes";
export const ReviewsRoute: MainRouteType = {
  key: "Reviews",
  title: "Review",
  path: "/reviews/tutor",
  subRoutes: [
    {
      key: "TutorReports",
      title: "Tutor Report",
      path: "/reviews/tutor",
    },
    {
      key: "StudentReports",
      title: "Student Report",
      path: "/reviews/student",
    },
    {
      key: "Questions",
      title: "Question",
      path: "/reviews/questions",
    },
    {
      key: "Testimonials",
      title: "Testimonial",
      path: "/reviews/testimonials",
    },
    {
      key: "ManageFaq",
      title: "Manage FAQ",
      path: "/reviews/faq",
    },
  ],
};
