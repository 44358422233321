import moment from "moment";

import { TimeMoment } from "../utils";

export interface EventType {
  backgroundColor: string;
  endDate: string;
  endTime: string;
  eventID: string;
  eventName: string;
  eventSubtitle: string;
  eventTitle: string;
  googleMeetLink: string;
  image: string;
  limit: number;
  remaining: number;
  showOnHomepage: boolean;
  startDate: string;
  startTime: string;
  roomLink: string;
  _id: string;
  active?: boolean;
}

export interface EventType_Payload {
  backgroundColor: string;
  endDate: string;
  endTime: string;
  eventID?: string;
  eventName: string;
  eventSubtitle: string;
  eventTitle: string;
  googleMeetLink: string;
  image: string | File | null;
  limit: number;
  remaining: number;
  showOnHomepage: boolean;
  startDate: string;
  startTime: string;
  roomLink?: string;
  active?: boolean;
}

export const eventInitialState: EventType_Payload = {
  backgroundColor: "#56c795",
  endDate: new Date(moment().add(1, 'month').toDate())?.toISOString(),
  endTime:  new TimeMoment().format("HH:mm"),
  eventID: "",
  eventName: "",
  eventSubtitle: "",
  eventTitle: "",
  googleMeetLink: "",
  image: null,
  limit: 0,
  remaining: 0,
  showOnHomepage: false,
  startDate: new Date()?.toISOString(),
  startTime: new TimeMoment().format("HH:mm"),
  roomLink: "",
};
