import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
//import { BootstrapTableCellRenderer } from "../../Components/Common";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import {
  FaqModal,
  TutorIssueResponse,
} from "../../Components/Reviews";
import {
  closeGlobalDialog,
  openGlobalDialog,
} from "../../Redux/Actions/appStatusActions";
import { DashboardPageState } from "../../Types";
import { FaqScreen } from "./FaqScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "Question",
    text: "Question",
  },
  {
    dataField: "Answer",
    text: "Answer",
  },
];

export const FaqScreenContainer = () => {
  const [
    { pageState, selectedLanguage },
    {  setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const dispatch = useDispatch();

  const [selectedReport, setSelectedReport] = React.useState<any | null>();

  const onResponseClicked = () => {
    dispatch(closeGlobalDialog());
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <TutorIssueResponse
            report={selectedReport}
            onClose={() => dispatch(closeGlobalDialog())}
            onSendResponse={() => {}}
          />
        ),
      })
    );
  };

  const onChangePageState=(newState:DashboardPageState)=>{
    if(newState==="add"){
      dispatch(
        openGlobalDialog({
          open: true,
          children: (
            <FaqModal
              report={null}
              onClose={() => dispatch(closeGlobalDialog())}
              onResponseClicked={onResponseClicked}
            />
          ),
        })
      );
    }
  }

  const onEditClicked = (rowData: any, _rowIndex: number) => {
    setSelectedReport(rowData);
    dispatch(
      openGlobalDialog({
        open: true,
        children: (
          <FaqModal
            report={rowData}
            onClose={() => dispatch(closeGlobalDialog())}
            onResponseClicked={onResponseClicked}
          />
        ),
      })
    );
  };

  return (
    <FaqScreen
      onChangePageState={onChangePageState}
      onEditClicked={onEditClicked}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
    />
  );
};
