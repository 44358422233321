import React from "react";
import sprite from "../../../Resources/Images/SVG/sprite.svg";

export type iconVariant =
  | "arrow-left-box"
  | "close-box"
  | "Chat"
  | "Work"
  | "Video"
  | "tooti-logo"
  | "edit-box"
  | "logout"
  | "plus-box"
  | "user"
  | "user-group"
  | "search"
  | "detail"
  | "goTo"
  | "close-box"
  | "startReg"
  | "star"
  | "error";

interface Props {
  iconName: iconVariant;
  className?: string;
}

export function IconGenerator({ iconName, className }: Props) {
  return (
    <svg className={className}>
      <use href={`${sprite}#icon-${iconName}`} />
    </svg>
  );
}
