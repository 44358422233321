import {
  StudentReducerType,
  StudentActionType,
  GET_ALL_STUDENTS,
  GET_STUDENT_DETAIL,
  GET_STUDENT_MATCHMAKING_QUESTIONS,
} from "../ActionTypes";

const initialState: StudentReducerType = {
  allStudents: [],
  studentDetails: null,
  studentMatchmakingQuestions: []
};

export default function ClassReducer(
  state = initialState,
  action: StudentActionType
): StudentReducerType {
  switch (action.type) {
    case GET_STUDENT_MATCHMAKING_QUESTIONS:
      return {
        ...state,
        studentMatchmakingQuestions: action.payload
      }
    case GET_ALL_STUDENTS:
      return {
        ...state,
        allStudents: action.payload,
      };
    case GET_STUDENT_DETAIL:
      return {
        ...state,
        studentDetails: action.payload,
      };
    default:
      return state;
  }
}
