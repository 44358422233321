import { AppThunk, GET_INTERVIEWER } from "../ActionTypes";
import InterviewerServices from "../../Services/Http/Endpoints/InterviewerServices";
import { toggleReduxLoading } from "./appStatusActions";
import { InterviewAvailability_Payload } from "../../Types/interview";

const getInterviewer =
  (interviewerId: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return InterviewerServices.getInterviewer(interviewerId)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        return dispatch({
          type: GET_INTERVIEWER,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        return dispatch({
          type: GET_INTERVIEWER,
          payload: null,
        });
      });
  };

const createInterviewerAvailability =
  (
    interviewerId: string,
    availabilities: InterviewAvailability_Payload[],
    onFinish: (isOk: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return InterviewerServices.createInterviewerAvailability(
      interviewerId,
      availabilities
    )
      .then(() => {
        dispatch(toggleReduxLoading(false));

        onFinish(true);
      })
      .catch(() => {
        onFinish(false);
        dispatch(toggleReduxLoading(false));
      });
  };

export const InterviewersActions = {
  getInterviewer,
  createInterviewerAvailability,
};
