import React, { ChangeEvent } from "react";
//import classes from "./LearningMaterialScreen.module.css";
import { ColumnDescription, SortOrder } from "react-bootstrap-table-next";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { AddMaterialLearning } from "../../Components/MaterialLearnings";
import { CustomTableButtons } from "../../Components/MaterialLearnings/CustomTableButtons/CustomTableButtons";
import { MaterialFilters } from "../../Components/MaterialLearnings/MaterialFilters/MaterialFilters";
import { MaterialType } from "../../Redux/ActionTypes";
import {
  DashboardPageState,
  LanguageVariantType,
  LearningMaterialCategory,
  LearningMaterialMainCategory,
  LearningMaterialType_Payload,
} from "../../Types";
import SelectInput from "./../../Components/Common/SelectInput";
//import AudioSelector from "./../../Components/Common/AudioSelector/AudioSelector";
import { LearningMaterialType } from "./../../Types/materialLearning";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  categories: LearningMaterialCategory[];
  mainCategories: LearningMaterialMainCategory[];
  searchText: string;
  onChangeSearchText: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmitForm: (materialData: LearningMaterialType_Payload) => void;
  allLearningMaterials: LearningMaterialType[];
  onTableEditClick: (rowData: LearningMaterialType, rowIndex: number) => void;
  learningMaterialToEdit: {
    [key: string]: LearningMaterialType;
  };
  onDisplayToggleClick?: (cellData: any, cellIndex: number) => void;
  selectedCategory?: string;
  onChangeSelectedCategory: (val: string) => void;
  selectedMaterialType: MaterialType;
  onChangeMaterialType: (type: MaterialType) => void;
  defaultSorted?: [
    {
      dataField: any;
      order: SortOrder;
    }
  ];
  updateMaterial: (row: any, popular?: boolean, recommended?: boolean) => void;
}

export const LearningMaterialScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    categories,
    mainCategories,
    onSubmitForm,
    searchText,
    onChangeSearchText,
    allLearningMaterials,
    onTableEditClick,
    learningMaterialToEdit,
    onDisplayToggleClick,
    selectedCategory,
    onChangeSelectedCategory,
    defaultSorted,
    updateMaterial,
    onChangeMaterialType,
    selectedMaterialType,
  } = props;

  const RenderAddFlow = () => {
    return (
      <>
        <AddMaterialLearning
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          categories={categories}
          mainCategories={mainCategories}
          onSubmitForm={onSubmitForm}
          learningMaterialToEdit={learningMaterialToEdit}
          pageState={pageState}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a New Learning Material";
      case "edit":
        return "Edit Learning Material";
      default:
        return "Learning Materials";
    }
  };

  const materialFilters = () => (
    <MaterialFilters
      selectedMaterialType={selectedMaterialType}
      onChangeMaterialType={onChangeMaterialType}
      onChangeSelectedCategory={onChangeSelectedCategory}
      selectedCategory={selectedCategory}
      categories={categories}
    />
  );

  const renderCustomButton = (row: any) => (
    <CustomTableButtons
      row={row}
      updateMaterial={(popular, recommended) => {
        updateMaterial(row, popular, recommended);
      }}
    />
  );

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      ExtraTableFilters={() => materialFilters()}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      EditFlowContent={() => RenderAddFlow()}
      onEditCell={onTableEditClick}
      getToggleValue={(cellData) => cellData.home_screen_display}
      onDisplayToggleClick={onDisplayToggleClick}
      searchValue={searchText}
      onChangeSearch={onChangeSearchText}
      data={allLearningMaterials}
      defaultSorted={defaultSorted}
      customButtons={renderCustomButton}
    />
  );
};
