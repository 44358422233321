import moment from "moment";
import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { useDashboardPageLocalData } from "../../Components/Hooks";
import { TutorsActions } from "../../Redux/Actions/tutorsAction";
import { getAllMembers } from "../../Redux/Actions/userAndRoleActions";
//import { useTypedSelector } from "./../../Components/Common/BootstrapTableCellRenderer/BootstrapTableCellRenderer";
import { useTypedSelector } from "../../Redux/Reducers";
import { InterviewType } from "../../Types";
import { InterviewScreen } from "./InterviewScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "bookedBy.name",
    text: "Full Name",
  },
  {
    dataField: "bookedBy.email",
    text: "Email Address",
  },
  {
    dataField: "status",
    text: "Account State",
  },
  {
    dataField: "preferredDate",
    text: "Interview Date",
  },
  {
    dataField: "interviewer.email",
    text: "Interviewer Email",
  },
  {
    dataField: "bookedBy.signupDate",
    text: "Sign Up Date",
    formatter: (cell, row) => (
      <p>{moment(row.bookedBy.signupDate).format('YYYY.MM.DD HH:mm A')}</p>
    )
  },
];

export const InterviewScreenContainer = () => {
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();
  const dispatch = useDispatch();

  const interviewers = useTypedSelector((state) =>
    state.userAndRoles.members.filter(
      (m) => m.role._id === "612daeb2090bed10a39edd6c"
    )
  );

  const interviewList = useTypedSelector(
    (state) => state.tutor.tutorApplications
  );

  const tutorDetail = useTypedSelector((state) => state.tutor.tutorDetails);

  const onTableDetailClick = (rowData: InterviewType) => {
    if (rowData.bookedBy) {
      onChangePageState("edit", rowData.bookedBy.tutorId);
    }
  };

  React.useEffect(() => {
    dispatch(TutorsActions.getTutorMatchmakingQuestions());
  }, [dispatch]);

  React.useEffect(() => {
    if (pageState === "main") {
      dispatch(TutorsActions.getTutorInterviewApplications());
    } else if (pageState === "add" || pageState === "edit") {
      dispatch(getAllMembers());
      if (idToEdit) dispatch(TutorsActions.getTutorDetails(idToEdit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, idToEdit]);

  return (
    <InterviewScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      interviewers={interviewers}
      interviewList={interviewList}
      onTableDetailClick={onTableDetailClick}
      tutorDetail={tutorDetail}
    />
  );
};
