import React from "react";

//import { AnnouncementsScreenContainer, ApplicationScreenContainer, ClassesScreenContainer, EventScreenContainer, FaqScreenContainer, FeatureBannerScreenContainer, GalleryScreenContainer, InterviewerScreenContainer, InterviewScreenContainer, LearningMaterialsContainer, MatchmakingScreenContainer, MemberScreenContainer, NotificationScreenContainer, PackageScreenContainer, PreferredTagsScreenContainer, QuestionScreenContainer, RolesScreenContainer, StudentReportsScreenContainer, TestimonialsScreenContainer, TutorReportsScreenContainer, TutorScreenContainer, UsersScreenContainer } from './../../Config/Constants/AppRoutes/index';
import {
  //? Auth
  MemberScreenContainer,
  RolesScreenContainer,
  InterviewerScreenContainer,

  //? Content
  LearningMaterialsContainer,
  FeatureBannerScreenContainer,
  EventScreenContainer,
  ClassesScreenContainer,
  GalleryScreenContainer,

  //? Tutors
  ApplicationScreenContainer,
  TutorScreenContainer,
  InterviewScreenContainer,

  //? Reviews
  TutorReportsScreenContainer,
  StudentReportsScreenContainer,
  QuestionScreenContainer,
  FaqScreenContainer,
  TestimonialsScreenContainer,

  //?Users
  PreferredTagsScreenContainer,
  MatchmakingScreenContainer,
  UsersScreenContainer,

  //? Notification
  NotificationScreenContainer,
  AnnouncementsScreenContainer,

  //? Package
  PackageScreenContainer,
} from "../";
import { CorporateRequestsScreenContainer } from "../CorpoateRequestsScreen/CorpoateRequestsScreenContainer";
import { CouponsScreenContainer } from "../CouponsScreenContainer/CouponsScreenContainer";
import { ProgramScreenContainer } from "../ProgramScreen/ProgramScreenContainer";

interface Props {
  variant: string;
}
export const ScreenFactory = ({ variant }: Props) => {
  console.log(variant);
  switch (variant) {
    case "Programs":
      return <ProgramScreenContainer />;
    case "LearningMaterials":
      return <LearningMaterialsContainer />;
    case "Members":
      return <MemberScreenContainer />;
    case "Interviewers":
      return <InterviewerScreenContainer />;
    case "Roles":
      return <RolesScreenContainer />;
    case "FeatureBanner":
      return <FeatureBannerScreenContainer />;
    case "EventsBanner":
      return <EventScreenContainer />;
    case "Classes":
      return <ClassesScreenContainer />;
    case "Users":
      return <UsersScreenContainer />;
    case "Applications":
      return <ApplicationScreenContainer />;
    case "Interviews":
      return <InterviewScreenContainer />;
    case "Tutors":
      return <TutorScreenContainer />;
    case "TutorReports":
      return <TutorReportsScreenContainer />;

    case "StudentReports":
      return <StudentReportsScreenContainer />;
    case "Questions":
      return <QuestionScreenContainer />;
    case "ManageFaq":
      return <FaqScreenContainer />;
    case "Testimonials":
      return <TestimonialsScreenContainer />;
    case "PreferredTags":
      return <PreferredTagsScreenContainer />;
    case "MatchmakingQuestions":
      return <MatchmakingScreenContainer />;

    case "Notifications":
      return <NotificationScreenContainer />;
    case "Announcements":
      return <AnnouncementsScreenContainer />;

    case "Packages":
      return <PackageScreenContainer />;
    case "Coupons": 
    return <CouponsScreenContainer />  
    case "Gallery":
      return <GalleryScreenContainer />;
    case "Requests":
      return <CorporateRequestsScreenContainer />

    default:
      return <LearningMaterialsContainer />;
  }
};

export default ScreenFactory;
