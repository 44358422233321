import BaseApi from "../BaseAPI";
import { EventType, EventType_Payload } from "./../../../Types";

class EventServices extends BaseApi {
  constructor() {
    super({ suffix: "account" });
  }

  updateEvent = (
    newData: EventType_Payload,
    eventId: string,
    onUploadProgress?: (e: any) => void
  ) => {
    return this.httpService.patch("platform_event_admin", newData, {
      onUploadProgress: onUploadProgress,
    });
  };

  addeEvent = (
    newData: EventType_Payload,
    onUploadProgress?: (e: any) => void
  ) => {
    console.log(newData, "newDatanewData");

    return this.httpService.post("platform_event_admin", newData, {
      onUploadProgress: onUploadProgress,
    });
  };

  getAllEvents = () => {
    return this.httpService.get("platform_event_admin");
  };

  deleteEvent = (id: string) => {
    return this.httpService.delete(`platform_event_admin/${id}`);
  };

  displayEventAsActive = (data: any, eventId: string) => {
    return this.httpService.patch(
      `platform_event_admin/${eventId}/active`,
      data
    );
  };
}

const EventServicesInstance = new EventServices();
export default EventServicesInstance;
