import React from "react";
import classes from "./AddMatchmaking.module.css";
import { Grid } from "@material-ui/core";

import {
  FormikTextField,
  Separator,
  InputLabel,
  LanguageFormControls,
  BorderedRadio,
  SelectInput,
  Button,
  IconGenerator,
} from "../../Common";
import { Formik, Form, FieldArray } from "formik";
import {
  LanguageVariantType,
  MatchmakingQuestionType_Payload,
} from "../../../Types";
import * as yup from "yup";
import useFormAdderTools from "../../Hooks/useFormAdderTools";
import TextInput from "../../Common/TextInput/TextInput";
import { matchmakingQuestionInitialState } from "../../../Types/matchmaking";

const validationSchema = yup.object({
  roomLink: yup.string().url("Invalid URL"),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  itemToEdit: {
    [key: string]: MatchmakingQuestionType_Payload;
  };
  onSubmitForm: (eventData: MatchmakingQuestionType_Payload) => void;
}

export const AddMatchmaking = (props: Props) => {
  const { lang, setSelectedLanguage, itemToEdit, onSubmitForm } = props;
  const [initialValues, onResetForm] =
    useFormAdderTools<MatchmakingQuestionType_Payload>({
      itemToEdit: itemToEdit,
      lang,
      defaultValues:
        matchmakingQuestionInitialState as MatchmakingQuestionType_Payload,
    });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        onSubmitForm(values);
      }}
    >
      {({ values, setFieldValue, errors }) => {
        const addButtonDisabled =
          Object.keys(errors).length > 0 ||
          JSON.stringify(values) === JSON.stringify(itemToEdit[lang]);

        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField
                    label="Question (EngLish)*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="eventTitle"
                  />
                  <FormikTextField
                    label="Question (Korean)*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="roomLink"
                  />
                  <div>
                    <InputLabel>
                      <span>Target User*</span>
                    </InputLabel>
                    <div className="d-flex">
                      <BorderedRadio
                        label="Student"
                        className="flex-grow-1 mr-4"
                        isSelected={values.targetUser === "student"}
                        checked={values.targetUser === "student"}
                        onClick={() => setFieldValue("targetUser", "student")}
                      />
                      <BorderedRadio
                        label="Tutor"
                        className="flex-grow-1"
                        isSelected={values.targetUser === "tutor"}
                        checked={values.targetUser === "tutor"}
                        onClick={() => setFieldValue("targetUser", "tutor")}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <InputLabel>
                      <span>Questions Order*</span>
                      <h6 className={classes.matchmakingQuestionNumberHint}>
                        If a spot is already filled, the question will be moved
                        to the next spot.
                      </h6>
                    </InputLabel>
                    <div className="d-flex flex-wrap mt-5">
                      {["1", "2", "3"].map((item, index) => {
                        return (
                          <div
                            className={
                              values.questionNumber.toString() === item
                                ? [
                                    classes.QuestionNumberItem,
                                    classes.QuestionNumberItem_Active,
                                  ].join(" ")
                                : classes.QuestionNumberItem
                            }
                            key={index}
                            onClick={() =>
                              setFieldValue("questionNumber", parseInt(item))
                            }
                          >
                            <span>{item}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Answer Type* </span>
                      </InputLabel>
                      <SelectInput
                        variant="outlined"
                        options={[]}
                        handleChange={() => {}}
                        placeholder="test"
                        label="AnswerType"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.AnswersRoot}
                    >
                      <Grid container spacing={2}>
                        <FieldArray name="answers">
                          {({ insert, remove, push }) => {
                            return values.answers.map((_val, index) => {
                              return (
                                <>
                                  {index !== 0 && (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <Button
                                        freeHeight
                                        onClick={() => remove(index)}
                                        className={classes.removeButton}
                                        variant="default"
                                      >
                                        <IconGenerator iconName="close-box" />
                                      </Button>
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                  >
                                    <TextInput label="English" />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                  >
                                    <TextInput label="Korean" />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Separator />
                                  </Grid>
                                  {index === values.answers.length - 1 && (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <Button
                                        variant="link"
                                        onClick={() =>
                                          push(
                                            matchmakingQuestionInitialState
                                              .answers[0]
                                          )
                                        }
                                      >
                                        <span>+Add Another Answer</span>
                                      </Button>
                                    </Grid>
                                  )}
                                </>
                              );
                            });
                          }}
                        </FieldArray>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Separator />
            <LanguageFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              englishChecked={itemToEdit["en"] !== undefined}
              koreanChecked={itemToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                itemToEdit[lang] !== undefined ? "Edit Event" : "Add Event"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
