import React from "react";

import { ColumnDescription } from "react-bootstrap-table-next";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { DashboardPageState, LanguageVariantType } from "../../Types";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  onEditClicked: (rowDate: any, index: number) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
}

export const TutorReportsScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,

    onEditClicked,
  } = props;

  const RenderDetailFlow = () => {
    return <></>;
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Reported Issues";
      case "edit":
        return "Reported Issues";
      default:
        return "Reported Issues by Tutors";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      EditFlowContent={() => RenderDetailFlow()}
      haveAddButton={false}
      onEditCell={onEditClicked}
      onDeleteCell={() => {}}
      data={[{ t: "1" }]}
    />
  );
};
