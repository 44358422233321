import { useLocation } from "react-router-dom";

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const useQueryString = () => {
  const query = useQuery();
  return query;
};

export const useChangeURl = () => {
  const location = useLocation();

  const updateUrlNoRefresh = (
    updateQueryList: {
      nameQuery: string;
      data?: any;
      delete?: boolean;
    }[]
  ) => {
    const query = new URLSearchParams(location.search);

    updateQueryList.forEach((q) => {
      if (q.delete) {
        return query.delete(q.nameQuery);
      }
      if (q.data) query.set(q.nameQuery, q.data);
    });

    location.search = query.toString();
    window.history.replaceState(
      location.pathname,
      location.pathname,
      `/#${location.pathname}?${query.toString() || " "}`
    );
  };
  return updateUrlNoRefresh;
};
