import React from "react";
import classes from "./ExpressionAdder.module.css";
import { Grid } from "@material-ui/core";
import {
  Button,
  Separator,
  IconGenerator,
  FormikTextArea,
  FormikTextField,
  FileSelector,
  InputLabel,
} from "../../Common";
import {
  exampleInitialState,
  ExpressionType_Payload,
  expressionInitialState,
} from "../../../Types";
import { Field, FieldArray, FieldProps } from "formik";

interface Props {
  className?: string;
  values?: ExpressionType_Payload[];
  onAddExpression?: (value: ExpressionType_Payload) => void;
  onRemoveExpression?: (index: number) => boolean | undefined;
}

export const ExpressionAdder = (props: Props) => {
  const { className, values, onAddExpression, onRemoveExpression } = props;

  
  return (
    <div className={className}>
      <div className={classes.Root}>
        {values &&
          values.map((exp, index) => (
            <>
              <Grid container spacing={2}>
                {index !== 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      onClick={() => {
                        if (onRemoveExpression) onRemoveExpression(index);
                      }}
                      freeHeight
                      variant="default"
                      className={classes.removeButton}
                    >
                      <IconGenerator iconName="close-box" />
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField
                    label="ExpressionType_Payload*"
                    placeholder="In English..."
                    name={`expressions.${index}.title`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormikTextField
                    label="Translation*"
                    placeholder="In Korean..."
                    name={`expressions.${index}.meaning__ko`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormikTextField
                    label="Meaning*"
                    placeholder="In English..."
                    name={`expressions.${index}.meaning__en`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormikTextArea
                    rows={3}
                    label="Tip"
                    placeholder="Maximum 150 characters..."
                    limit={150}
                    name={`expressions.${index}.tips`}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputLabel>Expression Audio</InputLabel>
                  <Field name={`expressions.${index}.audio`}>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }: FieldProps) => (
                      <FileSelector
                        limitSize={2000000}
                        value={field.value}
                        btnText={field.value?"Replace the audio":"Choose an Audio"}
                        accept={"audio/*"}
                        onFileChoose={(file:any) =>
                          setFieldValue(`expressions.${index}.audio`, file)
                        }
                        onClearClick={() =>
                          setFieldValue(`expressions.${index}.audio`, null)
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <FieldArray name={`expressions.${index}.examples`}>
                  {({ insert, remove, push }) =>
                    exp.examples &&
                    exp.examples.map((_, exampleIndex) => (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          {exampleIndex !== 0 && (
                            <Button
                              onClick={() => {
                                if (remove) remove(exampleIndex);
                              }}
                              freeHeight
                              variant="default"
                              className={classes.removeButton}
                            >
                              <IconGenerator iconName="close-box" />
                            </Button>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormikTextArea
                            rows={2}
                            label="Example"
                            placeholder="In English..."
                            name={`expressions.${index}.examples.${exampleIndex}.title`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormikTextArea
                            rows={2}
                            label="Translation"
                            placeholder="In Korean..."
                            name={`expressions.${index}.examples.${exampleIndex}.meaning__ko`}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <InputLabel>Example Audio</InputLabel>
                          <Field name={`expressions.${index}.examples.${exampleIndex}.audio`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }: FieldProps) => (
                              <FileSelector
                                limitSize={2000000}
                                value={field.value}
                                btnText={field.value?"Replace the audio":"Choose an Audio"}
                                accept={"audio/*"}
                                onFileChoose={(file) =>
                                  setFieldValue(
                                    `expressions.${index}.examples.${exampleIndex}.audio`,
                                    file
                                  )
                                }
                                onClearClick={() =>
                                  setFieldValue(
                                    `expressions.${index}.examples.${exampleIndex}.audio`,
                                    null
                                  )
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                        {exampleIndex===exp.examples.length-1&&<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            onClick={() => push(exampleInitialState)}
                            variant="link"
                          >
                            <span>+Add Example...</span>
                          </Button>
                        </Grid>}
                      </>
                    ))
                  }
                </FieldArray>
              </Grid>
              <Separator color="secondary" className={classes.SeparatorRoot} />
            </>
          ))}
        <Button
          onClick={() => {
            if (onAddExpression) onAddExpression(expressionInitialState);
          }}
          variant="link"
        >
          <span>+ Add Another Expression</span>
        </Button>
      </div>
    </div>
  );
};
