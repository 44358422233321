import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { AddMatchmaking } from "../../Components/Users";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import {
  DashboardPageState,
  LanguageVariantType,
  MatchmakingQuestionType_Payload,
} from "../../Types";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  onTableEditClick: (
    rowData: MatchmakingQuestionType_Payload,
    rowIndex: number
  ) => void;
  onSubmitForm: (materialData: MatchmakingQuestionType_Payload) => void;
  allEvents: MatchmakingQuestionType_Payload[];
  eventToEdit: {
    [key: string]: MatchmakingQuestionType_Payload;
  };
}

export const MatchmakingScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    onTableEditClick,
    allEvents,
    eventToEdit,
    onSubmitForm,
  } = props;

  const RenderAddFlow = () => {
    return (
      <>
        <AddMatchmaking
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          itemToEdit={eventToEdit}
          onSubmitForm={onSubmitForm}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Adding a Matchmaking Question";
      case "edit":
        return "Editing a Matchmaking Question";
      default:
        return "Matchmaking Questions";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      EditFlowContent={() => RenderAddFlow()}
      data={allEvents}
      onEditCell={onTableEditClick}
    />
  );
};
