import React from "react";
import { MaterialType } from "../../../Redux/ActionTypes";
import { LearningMaterialCategory } from "../../../Types";
import { SelectInput } from "../../Common";
import classes from "./MaterialFilters.module.css";

const materialTypeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Junior",
    value: "junior",
  },
  {
    label: "Regular",
    value: "regular",
  },
];

interface Props {
  selectedCategory?: string;
  onChangeSelectedCategory: (val: string) => void;
  selectedMaterialType: MaterialType;
  onChangeMaterialType: (type: MaterialType) => void;
  categories: LearningMaterialCategory[];
}

export const MaterialFilters = (props: Props) => {
  const {
    selectedCategory,
    selectedMaterialType,
    onChangeSelectedCategory,
    onChangeMaterialType,
    categories,
  } = props;

  const selectOptions = [
    { title: { en: "All" }, _id: "ALL" },
    ...categories,
  ].map((ca) => {
    return {
      label: ca.title["en"],
      value: ca._id,
    };
  });

  return (
    <div className={classes.Container}>
      <SelectInput
        options={selectOptions}
        handleChange={(e) => {
          onChangeSelectedCategory(e.target.value as string);
        }}
        value={selectedCategory}
        variant="outlined"
        label="Category"
        className={classes.SelectInput}
      />
      <SelectInput
        options={materialTypeOptions}
        handleChange={(e) => {
          onChangeMaterialType(e.target.value as MaterialType);
        }}
        value={selectedMaterialType}
        variant="outlined"
        label="Material Type"
      />
    </div>
  );
};
