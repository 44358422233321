import React from "react";
import { BorderedRadio, InputLabel, TextAreaInput } from "../../Common";
import SelectInput, { OptionObj } from "../../Common/SelectInput";
import { CardModal } from "../../menus";
import classes from "./ApplicationRejectionModal.module.css";
import {
  InterviewRejection_Payload,
} from "../../../Types";
import { useFormik } from "formik";

const rejectionReasons: OptionObj[] = [
  {
    label: "Incomplete profile data",
    value: "Incomplete profile data",
  },
  {
    label: "Invalid certificates",
    value: "Invalid certificates",
  },
];

interface Props {
  onReject?: (val: InterviewRejection_Payload) => void;
  onCancel?: () => void;
  tutorId: string;
}

export const ApplicationRejectionModal = (props: Props) => {
  const { onReject, onCancel, tutorId } = props;
  const formik = useFormik<InterviewRejection_Payload>({
    initialValues: {
      rejectionReason: "",
      language: "ko",
      message: "",
      tutorId,
      status: "REJECTED",
    },
    onSubmit: (values) => {
      if (onReject) onReject(values);
    },
  });
  const { values, setFieldValue } = formik;
  return (
    <CardModal
      head={<span>Rejection Response</span>}
      haveFromButtons
      className={classes.Root}
      outlineBtnText="Cancel"
      containedButtonText="Reject Response"
      onContainedButtonClicked={formik.handleSubmit}
      onOutlineButtonClicked={onCancel}
      onClose={onCancel}
    >
      <InputLabel>
        <span>Rejection Reason*</span>
      </InputLabel>
      <SelectInput
        options={rejectionReasons}
        label=""
        handleChange={(e) => {
          setFieldValue("rejectionReason", e.target.value);
        }}
        variant="outlined"
        className="mb-4"
      ></SelectInput>

      <InputLabel>
        <span>Language</span>
      </InputLabel>
      <div className={"d-flex align-items-center mb-4"}>
        <BorderedRadio
          label="Korean"
          className="flex-grow-1 mr-4"
          isSelected={values.language === "ko"}
          checked={values.language === "ko"}
          onClick={() => setFieldValue("language", "ko")}
        />
        <BorderedRadio
          label="English"
          className="flex-grow-1"
          isSelected={values.language === "en"}
          checked={values.language === "en"}
          onClick={() => setFieldValue("language", "en")}
        />
      </div>

      <TextAreaInput
        label="The Message"
        limit={750}
        rows={3}
        className="mb-5"
        value={values.message}
        id="message"
        name="message"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        notFormikInput
      />
    </CardModal>
  );
};
