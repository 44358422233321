import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  setFieldValue?: (date: Date | null) => void;
  value?: string;
  disabled?: boolean;
  className?: string;
}

export const DateTimePicker = (props: Props) => {
  const { setFieldValue, value, disabled, className } = props;
  return (
    <DatePicker
      wrapperClassName={className}
      selected={value ? new Date(value) : new Date()}
      onChange={(date) => setFieldValue && setFieldValue(date)}
      showTimeSelect
      timeFormat="HH:mm"
      dateFormat="MMMM d, yyyy h:mm aa"
      disabled={disabled ? disabled : false}
    />
  );
};
