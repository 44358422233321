import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import sprite from "../../../Resources/Images/SVG/sprite.svg";
import { MainRouteType } from "./../../../Types/ConfigTypes";
import classes from "./SideNavigationCreator.module.css";

export interface DashboardTabProps {
  routeData: MainRouteType;
  iconLink: string;
  type: "navLink" | "navButton" | "settingButton";
  iconType: "sprite" | "img" | "test";
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  onClick?: (tabName: string) => void;
  onClickButton?: () => void
}

interface TabCreatorProps {
  navigationItem: DashboardTabProps;
  isActive: boolean;
  isDarkMode?: boolean;
}

export function TabCreator(props: TabCreatorProps) {
  const { navigationItem, isActive, isDarkMode } = props;
  const { iconLink, routeData, type, iconType, hideOnMobile, hideOnDesktop, onClickButton } =
    navigationItem;

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 600px)",
  });

  if (isMobileDevice && hideOnMobile) {
    return null;
  }
  if (!isMobileDevice && hideOnDesktop) {
    return null;
  }

  const renderIcon = () => {
    if (iconType === "sprite") {
      return (
        <svg
          className={
            routeData && routeData.key === "Home" ? classes.HomeIcon : undefined
          }
        >
          <use href={`${sprite}#${iconLink}`}></use>
        </svg>
      );
    } else if (iconType === "img") {
      return (
        <img src={iconLink} alt={"profile icon"} width="40px" height="40px" />
      );
    } else {
      return <h6>{iconLink}</h6>;
    }
  };

  const TabClassName = isDarkMode
    ? [classes.NormalTab, classes.NormalTab_Dark]
    : [classes.NormalTab];
  const ActiveTabClassName = isDarkMode
    ? [classes.ActiveTab, classes.ActiveTab_Dark]
    : [classes.ActiveTab];

  if (type === "navLink" && routeData) {
    return (
      <Link
        to={routeData.path}
        style={{
          justifyContent: routeData.key === "Home" ? "center" : undefined,
          height: routeData.key === "Home" ? "12rem" : undefined,
        }}
        className={
          isActive
            ? [...ActiveTabClassName, ...TabClassName].join(" ")
            : TabClassName.join(" ")
        }
      >
        {renderIcon()}
        <div className={classes.NormalTabTitle}>
          {routeData.title.split("\n").map((txt, index) => {
            return <p key={index}>{txt}</p>;
          })}
        </div>
      </Link>
    );
  } else if (type === "navButton") {
    return (
      <div onClick={onClickButton} className={TabClassName.join(" ")}>
        {renderIcon()}
        <div className={classes.NormalTabTitle}>
          {routeData.title.split("\n").map((txt, index) => {
            return <p key={index}>{txt}</p>;
          })}
        </div>
      </div>
    );
  } else {
    return (
      <button
        className={
          isDarkMode
            ? [classes.SettingButton, classes.SettingButton_Dark].join(" ")
            : classes.SettingButton
        }
      >
        {renderIcon()}
      </button>
    );
  }
}

export type DashboardNavigationItemListType = {
  top: Array<DashboardTabProps>;
  bottom: Array<DashboardTabProps>;
};
export interface SideNavigationProps {
  navigationItems: DashboardNavigationItemListType;
  activeTabName: string;
  //onNavItemClick: (tabName: string) => void;
  isDarkMode?: boolean;
}

export function SideNavigationCreator(props: SideNavigationProps) {
  const { navigationItems, activeTabName, isDarkMode } = props;

  return (
    <div
      className={
        isDarkMode
          ? [classes.AllTabsRoot, classes.AllTabsRoot_Dark].join(" ")
          : classes.AllTabsRoot
      }
    >
      <div className={classes.TopItems}>
        {navigationItems.top.map((tab, index) => {
          return (
            <Fragment key={index}>
              <TabCreator
                isActive={activeTabName === tab.routeData.key}
                navigationItem={tab}
                isDarkMode={isDarkMode}
              />
            </Fragment>
          );
        })}
      </div>
      <div className={classes.BottomItems}>
        {navigationItems.bottom.map((tab, index) => {
          return (
            <Fragment key={index}>
              <TabCreator
                isActive={activeTabName === tab.routeData.key}
                navigationItem={tab}
                isDarkMode={isDarkMode}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
    // </div>
  );
}

export default SideNavigationCreator;
