import React from "react";
import classes from "./TutorIssueResponse.module.css";
import { CardModal } from "../../menus";
import { Grid } from "@material-ui/core";
import {
  TextInput,
  InputLabel,
  TextAreaInput,
  BorderedRadio,
} from "../../Common";
import { useFormik } from "formik";
import { IssueResponse_Payload } from "../../../Types";

interface Props {
  report: any;
  onClose: () => void;
  onSendResponse: (val: IssueResponse_Payload) => void;
}
export const TutorIssueResponse = (_props: Props) => {
  const { onClose, onSendResponse } = _props;
  const formik = useFormik<IssueResponse_Payload>({
    initialValues: {
      subject: "",
      language: "ko",
      issueDescription: "",
    },
    onSubmit: (values) => {
      onSendResponse(values);
    },
  });
  return (
    <CardModal
      head={<span>Issue Report</span>}
      onClose={onClose}
      haveFromButtons
      containedButtonText="Send Response"
      outlineBtnText="Reset Template"
      onContainedButtonClicked={formik.handleSubmit}
      onOutlineButtonClicked={formik.resetForm}
    >
      <div className={classes.Root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextInput
              notFormikInput
              name="subject"
              id="subject"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.touched.subject ? formik.errors.subject : undefined
              }
              error={
                formik.touched.subject && formik.errors.subject !== undefined
              }
              label="Subject"
              placeholder="name"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputLabel>
              <span>Language</span>
            </InputLabel>
            <div className="d-flex align-items-center w-100">
              <BorderedRadio
                onClick={() => formik.setFieldValue("language", "ko")}
                checked={formik.values.language==="ko"}
                isSelected={formik.values.language==="ko"}
                label="Korean"
                className="flex-grow-1 mr-4"
              />
              <BorderedRadio
                onClick={() => formik.setFieldValue("language", "en")}
                label="English"
                className="flex-grow-1"
                checked={formik.values.language==="en"}
                isSelected={formik.values.language==="en"}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextAreaInput
              label="Customize The Message"
              limit={750}
              placeholder="name"
              rows={2}
              className="mb-5"
              notFormikInput
              name="issueDescription"
              id="issueDescription"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.touched.issueDescription
                  ? formik.errors.issueDescription
                  : undefined
              }
              error={
                formik.touched.issueDescription &&
                formik.errors.issueDescription !== undefined
              }
            />
          </Grid>
        </Grid>
      </div>
    </CardModal>
  );
};
