import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { openSnackBar } from "../../../Redux/Actions/appStatusActions";
import { eventInitialState, EventType, EventType_Payload, LanguageVariantType } from "../../../Types";
import { CheckboxWithLabel, DatePicker, FormikTextArea, FormikTextField, ImagePicker, InputLabel, LanguageFormControls, Separator } from "../../Common";
import { IOSSwitch } from "../../Common/IOSSwitch/IOSSwitch";
import useFormAdderTools from "../../Hooks/useFormAdderTools";
import { TimeMoment } from "./../../../utils";
import { ColorPicker } from "./../../Common/ColorPicker/ColorPicker";
import TimePicker from "./../../Common/TimePicker/TimePicker";
import classes from "./AddEventBanner.module.css";

const validationSchema = yup.object({
  roomLink: yup.string().url("Invalid URL"),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  eventToEdit: {
    [key: string]: EventType;
  };
  onSubmitForm: (eventData: EventType_Payload) => void;
}

export const AddEventBanner = (props: Props) => {
  const { lang, setSelectedLanguage, eventToEdit, onSubmitForm } = props;
  const dispatch = useDispatch();
  const [initialValues, onResetForm] = useFormAdderTools<EventType_Payload>({
    itemToEdit: eventToEdit,
    lang,
    defaultValues: eventInitialState as EventType_Payload,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        const startDate = moment(values.startDate);
        const endDate = moment(values.endDate);
        const startHour = values.startTime.split(":")[0];
        const startMinute = values.startTime.split(":")[1];
        const endHour = values.endTime.split(":")[0];
        const endMinute = values.endTime.split(":")[1];
        startDate.hours(parseInt(startHour)).minutes(parseInt(startMinute));
        endDate.hours(parseInt(endHour)).minutes(parseInt(endMinute));

        const ifOnTheSameDate = endDate.diff(startDate) <= 0;
        if (ifOnTheSameDate) {
          dispatch(
            openSnackBar({
              open: true,
              message: "invalid Start and End date",
              variant: "error",
            })
          );
        } else {
          onSubmitForm(values);
        }
      }}
    >
      {({ values, setFieldValue, errors }) => {
        console.log(values);
        const addButtonDisabled =
          Object.keys(errors).length > 0 ||
          JSON.stringify(values) === JSON.stringify(eventToEdit[lang]);

        const startTimeMoment =
          values.startTime && values.startTime !== ""
            ? new TimeMoment(values.startTime)
            : new TimeMoment();
        const endTimeMoment =
          values.endTime && values.endTime !== ""
            ? new TimeMoment(values.endTime)
            : new TimeMoment();
        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ImagePicker
                    hints={[
                      "* Image shouldn’t be larger than1000 x 1000 pixels.",
                      "* Make sure to use appropriate and relevant imagery",
                    ]}
                    className={classes.inputFields}
                    value={values.image || undefined}
                    fileSizeLimit={700000}
                    onFileChoose={(file) => setFieldValue("image", file)}
                    onCleanFile={() => setFieldValue("image", null)}
                  />

                  <div className={classes.checkboxRoot}>
                    <CheckboxWithLabel
                      label="Show on Homepage"
                      labelPlacement="end"
                      checked={values.showOnHomepage}
                      onChange={(e) =>
                        setFieldValue("showOnHomepage", e.target.checked)
                      }
                    />
                  </div>
                  <FormikTextField
                    label="Event Title*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="eventTitle"
                  />
                  <FormikTextField
                    label="Room Link*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="roomLink"
                  />
                  <FormikTextField
                    label="Limit*"
                    placeholder={"Limit..."}
                    className={classes.inputFields}
                    name="limit"
                    type="number"
                  />
                  <FormikTextArea
                    className={classes.inputFields}
                    label="Event Subtitle*"
                    limit={90}
                    placeholder={"Maximum 90 characters..."}
                    rows={3}
                    name="eventSubtitle"
                  />
                  <div>
                    <InputLabel>
                      <span>Background Color*</span>
                    </InputLabel>
                    <ColorPicker
                      className={classes.inputFields}
                      value={values.backgroundColor}
                      onChange={(newColor) =>
                        setFieldValue("backgroundColor", newColor)
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Start Date*</span>
                      </InputLabel>
                      <DatePicker
                        minDate={new Date()}
                        maxDate={
                          values.endDate ? new Date(values.endDate) : undefined
                        }
                        selectedDate={
                          values.startDate && values.startDate !== ""
                            ? new Date(values.startDate)
                            : new Date()
                        }
                        onChange={(newDate) => {
                          setFieldValue("startDate", newDate?.toISOString());
                        }}
                        className={classes.inputFields}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>End Date*</span>
                      </InputLabel>
                      <DatePicker
                        minDate={
                          values.startDate
                            ? new Date(values.startDate)
                            : new Date()
                        }
                        selectedDate={
                          values.endDate && values.endDate !== ""
                            ? new Date(values.endDate)
                            : new Date(moment().add(1, "month").toDate())
                        }
                        onChange={(newDate) => {
                          setFieldValue("endDate", newDate?.toISOString());
                        }}
                        className={classes.inputFields}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>Start Time*</span>
                      </InputLabel>
                      <TimePicker
                        selectedDate={startTimeMoment.getMoment()}
                        handleDateChange={(newDate) => {
                          setFieldValue(
                            "startTime",
                            new TimeMoment(
                              moment(newDate).format("HH:mm")
                            ).format("HH:mm")
                          );
                        }}
                        className={classes.inputFields}
                        inputVariant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <InputLabel>
                        <span>End Time*</span>
                      </InputLabel>
                      <TimePicker
                        selectedDate={endTimeMoment.getMoment()}
                        handleDateChange={(newDate) => {
                          setFieldValue(
                            "endTime",
                            new TimeMoment(
                              moment(newDate).format("HH:mm")
                            ).format("HH:mm")
                          );
                        }}
                        className={classes.inputFields}
                        inputVariant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel>
                        <span>Time is base on KST time Zone! </span>
                      </InputLabel>
                      <InputLabel>
                        <span>
                          {`the event time is between ${startTimeMoment
                            .getLocalTimeZone()
                            .format("HH:mm")} to ${endTimeMoment
                            .getLocalTimeZone()
                            .format("HH:mm")} base on your local time zone`}
                        </span>
                      </InputLabel>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Separator />
            <LanguageFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              englishChecked={eventToEdit["en"] !== undefined}
              koreanChecked={eventToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                eventToEdit[lang] !== undefined ? "Edit Event" : "Add Event"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
