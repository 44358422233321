import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
import { InputLabel } from "../../Components/Common";
import { IOSSwitch } from "../../Components/Common/IOSSwitch/IOSSwitch";

import { useDashboardPageLocalData } from "../../Components/Hooks";
import { useTableFilters } from "../../Components/Hooks/useTableFilters";
import { MaterialType } from "../../Redux/ActionTypes";
import { BootstrapTableCellRenderer } from "./../../Components/Common/BootstrapTableCellRenderer/BootstrapTableCellRenderer";
import {
  clearMaterialsToEdit,
  displayLearningMaterialOnHomeScreen,
  displayMaterialAsPopularRecommended,
  editLearningMaterial,
  getAllLearningMaterials,
  getAllLearningMaterialsByMaterialId,
  getLearningMaterialCategories,
  getLearningMaterialMainCategories,
  postLearningMaterial,
} from "./../../Redux/Actions/learningMaterialActions";
import { useTypedSelector } from "./../../Redux/Reducers/index";
import {
  LearningMaterialType,
  LearningMaterialType_Payload,
} from "./../../Types/materialLearning";
import { LearningMaterialScreen } from "./LearningMaterialScreen";

const tableColumns: ColumnDescription[] = [
  {
    dataField: "id",
    text: "#",
    formatter: (_, __, index: number) => <p>{index}</p>,
  },
  {
    dataField: "image",
    text: "Image",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="image" />
    ),
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
  },
  {
    dataField: "sub_title",
    text: "Subtitle",
    sort: true,
  },
  {
    dataField: "category",
    text: "Category",
  },
  {
    dataField: "uploaded_date",
    text: "Update",
    formatter: (cell, _, __) => (
      <BootstrapTableCellRenderer value={cell} type="date" />
    ),
    sort: true,
  },
];

//export type PageState = "main" | "edit" | "add";

export const LearningMaterialsContainer = () => {
  const dispatch = useDispatch();
  const [{ filters }, { updateFiltersDispatch }] =
    useTableFilters("materialTable");

  const [searchText, setSearchText] = React.useState("");

  const categories = useTypedSelector(
    (state) => state.learningMaterial.categories
  );
  const mainCategories = useTypedSelector(
    (state) => state.learningMaterial.mainCategories
  );
  const allLearningMaterials = useTypedSelector(
    (state) => state.learningMaterial.allLearningMaterials
  );
  const learningMaterialToEdit = useTypedSelector(
    (state) => state.learningMaterial.learningMaterialToEdit
  );
  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);
  const [
    { pageState, selectedLanguage, idToEdit },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const onSubmitForm = (materialData: LearningMaterialType_Payload) => {
    if (learningMaterialToEdit[selectedLanguage]) {
      onEditData({
        ...materialData,
        insights:
          learningMaterialToEdit[selectedLanguage].insights ===
          materialData.insights
            ? null
            : materialData.insights,
      });
    } else {
      onPostMaterialLearning(materialData);
    }
  };

  const onPostMaterialLearning = (
    materialData: LearningMaterialType_Payload
  ) => {
    if (learningMaterialToEdit[selectedLanguage]) {
      return;
    }
    const otherLang = selectedLanguage === "en" ? "ko" : "en";
    if (learningMaterialToEdit[otherLang]) {
      return dispatch(
        postLearningMaterial(
          materialData,
          selectedLanguage,
          learningMaterialToEdit[otherLang].material_id
        )
      );
    }

    dispatch(postLearningMaterial(materialData, selectedLanguage));
  };

  const onEditData = (newData: LearningMaterialType_Payload) => {
    if (learningMaterialToEdit[selectedLanguage]) {
      dispatch(
        editLearningMaterial(
          newData,
          learningMaterialToEdit[selectedLanguage]._id,
          selectedLanguage,
          learningMaterialToEdit[selectedLanguage].material_id
        )
      );
    }
  };

  const onTableEditClick = (
    rowData: LearningMaterialType,
    rowIndex: number
  ) => {
    if (rowData.material_id) {
      onChangePageState("edit", rowData.material_id);
    }
  };

  const onToggleDisplayOnHome = (
    rowData: LearningMaterialType,
    rowIndex: number
  ) => {
    dispatch(
      displayLearningMaterialOnHomeScreen(
        rowData.material_id as string,
        !rowData.home_screen_display
      )
    );
  };

  React.useEffect(() => {
    if (isSupervisor) return;
    if (pageState === "add") {
      dispatch(clearMaterialsToEdit());
      dispatch(getLearningMaterialCategories());
      dispatch(getLearningMaterialMainCategories());
    } else if (pageState === "main") {
      dispatch(clearMaterialsToEdit());
      dispatch(getLearningMaterialCategories());
      dispatch(
        getAllLearningMaterials({
          search: searchText,
          main_category: [],
          type: filters.data.materialType || "all",
          category:
            filters.data.category === "ALL" || filters.data.category === ""
              ? []
              : filters.data.category
              ? [filters.data.category]
              : [],
        })
      );
    } else if (pageState === "edit") {
      if (idToEdit) {
        dispatch(getAllLearningMaterialsByMaterialId(idToEdit));
        dispatch(getLearningMaterialCategories());
        dispatch(getLearningMaterialMainCategories());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageState, idToEdit]);

  React.useEffect(() => {
    if (pageState === "main") {
      dispatch(
        getAllLearningMaterials({
          search: searchText,
          main_category: [],
          type: filters.data.materialType || "all",
          category:
            filters.data.category === "ALL" || filters.data.category === ""
              ? []
              : filters.data.category
              ? [filters.data.category]
              : [],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filters.data.category, filters.data.materialType]);

  React.useEffect(() => {
    if (learningMaterialToEdit[selectedLanguage] !== undefined) {
      setSelectedLanguage(selectedLanguage === "en" ? "ko" : "en");
    }
    if (
      pageState === "add" &&
      learningMaterialToEdit["en"] &&
      learningMaterialToEdit["ko"]
    ) {
      onChangePageState("edit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningMaterialToEdit, pageState]);

  const updateMaterial = (
    row: any,
    popular?: boolean,
    recommended?: boolean
  ) => {
    let data: any = {};

    if (popular !== undefined) data.popular = popular;
    if (recommended !== undefined) data.recommended = recommended;

    dispatch(displayMaterialAsPopularRecommended(data, row._id));
  };

  const onChangeMaterialType = (type: MaterialType) => {
    updateFiltersDispatch({
      data: {
        materialType: type,
      },
    });
  };

  return (
    <LearningMaterialScreen
      selectedMaterialType={filters.data.materialType || "all"}
      onChangeMaterialType={onChangeMaterialType}
      updateMaterial={updateMaterial}
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={tableColumns}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      categories={categories}
      mainCategories={mainCategories}
      onSubmitForm={onSubmitForm}
      allLearningMaterials={allLearningMaterials}
      searchText={searchText}
      selectedCategory={filters.data.category}
      onChangeSelectedCategory={(val) => {
        updateFiltersDispatch({
          data: {
            category: val,
          },
        });
      }}
      onChangeSearchText={(e) => setSearchText(e.target.value)}
      onTableEditClick={onTableEditClick}
      learningMaterialToEdit={learningMaterialToEdit}
      onDisplayToggleClick={onToggleDisplayOnHome}
      defaultSorted={[
        {
          dataField: "uploaded_date",
          order: "desc",
        },
      ]}
    />
  );
};
