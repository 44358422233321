import { createSelectorHook } from "react-redux";

import { combineReducers } from "redux";

import appStatusReducer from "./appStatusReducer";
import authReducer from "./authReducer";
import classReducer from "./classReducer";
import commonReducer from "./commonReducer";
import corporateReducer from "./corporateReducer";
import eventReducer from "./eventReducer";
import featureBannerReducer from "./featureBannerReducer";
import interviewerReducer from "./interviewerReducer";
import learningMaterialReducer from "./learningMaterialReducer";
import packagesReducer from "./packagesReducer";
import studentReducer from "./studentReducer";
import tablesFiltersReducer from './tablesFilterReducer';
import tutorsReducer from "./tutorsReducer";
import userAndRoleReducer from "./userAndRoleReducer";

const rootReducer = combineReducers({
  status: appStatusReducer,
  auth: authReducer,
  learningMaterial: learningMaterialReducer,
  userAndRoles: userAndRoleReducer,
  platformEvent: eventReducer,
  featureBanner: featureBannerReducer,
  class: classReducer,
  tutor: tutorsReducer,
  student: studentReducer,
  common: commonReducer,
  interviewers: interviewerReducer,
  packages: packagesReducer,
  corporate: corporateReducer,
  tableFilters: tablesFiltersReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector = createSelectorHook<RootState>();
