// import appLocaleData from'react-intl/locale-data/fa' ;
import koMessages from '../locals/ko';
import {LanguageType} from "../Types"

const faLang:LanguageType = {
    messages: {
        ...koMessages
    },
    locale: 'ko',
    // data: appLocaleData
};
export default faLang;