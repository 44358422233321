import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import CorporateServiceInstance from "../../Services/Http/Endpoints/CorporateService";
import { ClassType, StudentType } from "../../Types";
import { CorporateDetail } from "../../Types/corporate";
import { AppThunk, GET_ALL_CORPORATE_REQUESTS, GET_CLASS_DETAILS, GET_CORPORATE_DETAIL, GET_CORPORATE_STUDENTS, GET_STUDENT_CLASSES, GET_STUDENT_DETAIL, TOGGLE_IS_AUTHENTICATING, TOGGLE_SUPERVISOR, USER_FOUND } from "../ActionTypes";
import { toggleReduxLoading } from "./appStatusActions";

export const getCorporateDetail =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleReduxLoading(true));
      const res = await CorporateServiceInstance.getCorporateDetails(id);
      if (res.data.ok) {
        dispatch(storeCorporateDetail(res.data.data.corporateDetail));
        dispatch(storeCorporateStudents(res.data.data.studentsByCorpId));
        dispatch(toggleReduxLoading(false));
      }
    } catch (err) {
      dispatch(toggleReduxLoading(false));
    }
  };

export const getCorporateStudentsById =
  (
    studentId: string,
    type?: string,
    startTime?: MaterialUiPickersDate,
    endTime?: MaterialUiPickersDate,
    class_time?: number,
    onFinish?: (classes: ClassType[]) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (studentId) {
        dispatch(toggleReduxLoading(true));
        const res = await CorporateServiceInstance.getCorporateStudent(
          studentId,
          type,
          startTime,
          endTime,
          class_time
        );
        if (res.data.ok) {
          if (onFinish && type === "all" && !startTime && !endTime)
            onFinish(res.data.data);
          dispatch(toggleReduxLoading(false));
          dispatch({
            type: GET_STUDENT_CLASSES,
            payload: res.data.data,
          });
        }
      }
    } catch (err) {
      dispatch(toggleReduxLoading(false));
    }
  };

export const getCorporateStudents =
  (
    id: string,
    startTime?: MaterialUiPickersDate,
    endTime?: MaterialUiPickersDate
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleReduxLoading(true));
      const res = await CorporateServiceInstance.getCorporateStudents(
        id,
        startTime,
        endTime
      );
      if (res.data.ok) {
        dispatch(storeCorporateStudents(res.data.data));
      }
      dispatch(toggleReduxLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(toggleReduxLoading(false));
    }
  };

export const getSupervisorDetail =
  (history?: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_IS_AUTHENTICATING,
      payload: true,
    });
    return CorporateServiceInstance.getSupervisorDetail()
      .then((res) => {
        if (res.data.ok) {
          if (
            res.data.data.role.title === "supervisor" &&
            res.data.data.role.permissions.supervisor
          ) {
            console.log("SSS");
            dispatch(toggleSupervisor(true));
          }
          return dispatch({
            type: USER_FOUND,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: TOGGLE_IS_AUTHENTICATING,
          payload: false,
        });
        history.push("/#/login");
      });
  };

export const getClassDetailsCorporate =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return CorporateServiceInstance.getClassDetailCorporate(id)
      .then((res) => {
        dispatch(toggleReduxLoading(false));
        return dispatch(storeClassDetail(res.data.classDetails));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
        console.log(err);
      });
  };

export const getStudentsDetailsById =
  (
    studentId: string,
    type?: string,
    startTime?: string,
    endTime?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (studentId) {
        dispatch(toggleReduxLoading(true));
        const res = await CorporateServiceInstance.getStudentDetailCorporate(
          studentId
        );

        if (res.data.ok) {
          dispatch(toggleReduxLoading(false));
          dispatch({
            type: GET_STUDENT_DETAIL,
            payload: res.data.data,
          });
        }
      }
    } catch (err) {
      dispatch(toggleReduxLoading(false));
    }
  };

export const getAllCorporateRequests = (): AppThunk => async (dispatch) => {
  try {
    dispatch(toggleReduxLoading(true));
    const res = await CorporateServiceInstance.getAllCorporateRequest();

    if (res.data.ok) {
      dispatch({
        type: GET_ALL_CORPORATE_REQUESTS,
        payload: res.data.result,
      });
    }
    dispatch(toggleReduxLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleReduxLoading(false));
  }
};
export const storeCorporateStudents = (data: StudentType) => ({
  type: GET_CORPORATE_STUDENTS,
  payload: data,
});
export const storeCorporateDetail = (data: CorporateDetail) => ({
  type: GET_CORPORATE_DETAIL,
  payload: data,
});

export const toggleSupervisor = (value: boolean) => {
  return {
    type: TOGGLE_SUPERVISOR,
    payload: value,
  };
};

export const storeClassDetail = (data: ClassType | null) => {
  return {
    type: GET_CLASS_DETAILS,
    payload: data,
  };
};
