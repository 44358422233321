import { GET_ALL_COUPONS, GET_FREE_PACKAGES, GET_USER_PACKAGES, PackagesActions, PackagesReducerType } from "../ActionTypes";

const initialState: PackagesReducerType = {
  freePackages: [],
  userPackages: [],
  coupons: [],
};

export default function PackagesReducer(
  state = initialState,
  action: PackagesActions
): PackagesReducerType {
  switch (action.type) {
    case GET_ALL_COUPONS:
      return {
        ...state,
        coupons: action.payload
      }
    case GET_FREE_PACKAGES:
      return {
        ...state,
        freePackages: action.payload,
      };
    case GET_USER_PACKAGES:
      return {
        ...state,
        userPackages: action.payload,
      };
    default:
      return state;
  }
}
