import { AppThunk, CLEAR_TABLE_FILTERS, TableFilterDataTypes, TableNamesTypes, UPDATE_TABLE_FILTERS } from "../ActionTypes";

export const updateFilters =
  (tableName: TableNamesTypes, filterData: TableFilterDataTypes): AppThunk =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TABLE_FILTERS,
      payload: {
        tableName,
        filterData,
      },
    });
  };

export const clearFilters =
  (tableName: TableNamesTypes): AppThunk =>
  (dispatch) => {
    dispatch({
      type: CLEAR_TABLE_FILTERS,
      payload: {
        tableName,
      },
    });
  };
