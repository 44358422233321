import moment from "moment";
import React, { ChangeEvent, ComponentType, ReactNode } from "react";
import { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  FaArrowDown,
  FaArrowUp,
  FaChevronDown,
  FaChevronUp,
  FaClipboard,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { JsxElement } from "typescript";

import { DashboardPageState } from "../../../../Types";
import {
  Button,
  CustomBootstrapTable,
  CustomSwitch,
  IconGenerator,
  InputLabel,
  PageHeader,
  TextInput,
} from "../../../Common";
import { IOSSwitch } from "../../../Common/IOSSwitch/IOSSwitch";
import classes from "./DashboardPageContentLayout.module.css";

//import AudioSelector from "./../../Components/Common/AudioSelector/AudioSelector";

interface Props {
  onChangePageState: (
    newState: DashboardPageState,
    idToEdit?: string,
    params?: string
  ) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  PageHeaderComponent?: ComponentType<any>;
  EditPageHeaderComponent?: ComponentType<any>;
  AddFlowContent?: ComponentType<any>;
  EditFlowContent?: ComponentType<any>;
  SupervisorMainContent?: ComponentType<any>;
  ExtraTableFilters?: ComponentType<any>;
  searchValue?: string;
  onChangeSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  data: any[];
  onDeleteCell?: ((cellData: any, cellIndex: number) => void) | undefined;
  onEditCell?: ((cellData: any, cellIndex: number) => void) | undefined;
  onViewCellClick?: ((cellData: any, cellIndex: number) => void) | undefined;
  onCopyClicked?: ((cellData: any, cellIndex: number) => void) | undefined;
  getToggleValue?: (cellData: any, cellIndex: number) => boolean;
  onDisplayToggleClick?: (cellData: any, cellIndex: number) => void;
  onSelectAll?: (isSelect: boolean, rows: any[], e?: any) => void | number[];
  params?: string;

  onSelectRow?: (
    row: any,
    isSelect: boolean,
    rowIndex: number,
    e?: any
  ) => boolean;
  pageTitle?: string;
  haveAddButton?: boolean;
  AddButtonText?: string;
  defaultSorted?: [
    {
      dataField: any;
      order: SortOrder;
    }
  ];
  getSearchValueOnChange?: (value: string) => void;
  filterStyle?: any;
  haveBackButton?: boolean;
  addButtonAction?: () => void;
  customButtons?: (row: any) => JSX.Element;
}

export const DashboardPageContentLayout = (props: Props) => {
  const history = useHistory();
  const {
    pageTitle,
    onChangePageState,
    pageState,
    tableColumns,
    PageHeaderComponent,
    AddFlowContent,
    ExtraTableFilters,
    onDeleteCell,
    onEditCell,
    getToggleValue,
    onDisplayToggleClick,
    data,
    haveAddButton,
    AddButtonText,
    onViewCellClick,
    EditFlowContent,
    defaultSorted,
    onCopyClicked,
    EditPageHeaderComponent,
    getSearchValueOnChange,
    SupervisorMainContent,
    filterStyle,
    haveBackButton,
    addButtonAction,
    customButtons,
  } = props;

  const updatedTableColumns = tableColumns.map((col) => {
    if (col.sortCaret) {
      return { ...col };
    }
    if (col.sort && !col.sortCaret) {
      return {
        ...col,
        sortCaret: (order: any, column: any) => {
          if (!order) return <span></span>;
          else if (order === "asc")
            return (
              <span>
                <FaChevronDown />
              </span>
            );
          else if (order === "desc")
            return (
              <span>
                <FaChevronUp />
              </span>
            );
          return null;
        },
      };
    }
    return { ...col };
  });

  // const TableHeaderFormatter = () => {
  //   return (
  //     <div>
  //       {customButtonsTitle}
  //     </div>
  //   )
  // }

  const TableButtonFormatter = (cell: any, row: any, rowIndex: number) => {
    return (
      <div className={classes.TableButtonsContainer}>
        <div className={classes.SwitchButtons}>
          {customButtons && customButtons(row)}
          {onDisplayToggleClick && (
            <div style={{ marginRight: "2.85rem" }}>
              <InputLabel>
                {getToggleValue && getToggleValue(row, rowIndex)
                  ? "On HomePage"
                  : "No Homepage"}
              </InputLabel>
              <IOSSwitch
                checked={getToggleValue ? getToggleValue(row, rowIndex) : false}
                onChange={() => onDisplayToggleClick(row, rowIndex)}
              />
            </div>
          )}
        </div>
        <div className={classes.Row}>
          {onCopyClicked && (
            <div
              onClick={() => onCopyClicked(row, rowIndex)}
              style={{ marginRight: "1.42rem", cursor: "pointer" }}
            >
              <FaClipboard fontSize="3rem" />
            </div>
          )}
          {onViewCellClick && (
            <Button
              onClick={() => onViewCellClick(row, rowIndex)}
              freeHeight
              variant="default"
              className={classes.TableButtons}
            >
              <IconGenerator iconName="detail" />
            </Button>
          )}
          {onEditCell && (
            <Button
              onClick={() => onEditCell(row, rowIndex)}
              freeHeight
              variant="default"
              className={classes.TableButtons}
            >
              <IconGenerator iconName="edit-box" />
            </Button>
          )}
          {onDeleteCell && (
            <Button
              onClick={() => onDeleteCell(row, rowIndex)}
              freeHeight
              variant="default"
              className={classes.TableButtons}
            >
              <IconGenerator iconName="close-box" />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const _renderMainFlow = () => {
    return (
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={[
          ...updatedTableColumns,
          {
            dataField: "id",
            text: "",
            formatter: TableButtonFormatter,
            // headerFormatter: TableHeaderFormatter
          },
        ]}
        search
      >
        {(props) => (
          <>
            <div className={classes.TableControlRoot}>
              {haveAddButton && (
                <Button
                  onClick={() =>
                    addButtonAction
                      ? addButtonAction()
                      : onChangePageState("add")
                  }
                  className={classes.AddButton}
                  variant="contained"
                >
                  <IconGenerator iconName="plus-box" />
                  <span>{AddButtonText || "Add a New Item"}</span>
                </Button>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextInput
                  startAdornment={<IconGenerator iconName="search" />}
                  className={classes.searchInput}
                  placeholder="Search"
                  getSearchValueOnChange={getSearchValueOnChange}
                  data={data}
                  {...props.searchProps}
                />
              </div>
              {ExtraTableFilters && (
                <div style={filterStyle} className={classes.extraFilterContent}>
                  <ExtraTableFilters />
                </div>
              )}
            </div>
            <CustomBootstrapTable
              defaultSorted={defaultSorted}
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    );
  };

  const _renderAddFlow = () => {
    if (AddFlowContent)
      return (
        <>
          <AddFlowContent />
        </>
      );
  };

  const _renderEditFlow = () => {
    if (EditFlowContent)
      return (
        <>
          <EditFlowContent />
        </>
      );
  };

  const _renderSupervisor = () => {
    if (SupervisorMainContent) {
      return (
        <>
          <SupervisorMainContent />
        </>
      );
    }
  };

  return (
    <div
      className={classes.Root}
      style={{ background: pageState === "supervisor" ? "#e5e5e5" : "#fff" }}
    >
      <PageHeader
        className={
          pageState === "main" || pageState === "supervisor"
            ? classes.PageHeader
            : [classes.PageHeader, classes.pageHeader_next].join(" ")
        }
        haveBack={pageState === "edit" || haveBackButton ? true : false}
        title={pageTitle || ""}
        onBackClick={() => history.goBack()}
      >
        {pageState === "edit" && EditPageHeaderComponent ? (
          <EditPageHeaderComponent />
        ) : (
          PageHeaderComponent && <PageHeaderComponent />
        )}
      </PageHeader>
      <div
        className={
          pageState === "main" || pageState === "supervisor"
            ? classes.PageContent
            : [classes.PageContent, classes.PageContent_next].join(" ")
        }
      >
        {pageState === "main" && _renderMainFlow()}
        {pageState === "supervisor" && _renderSupervisor()}
        {pageState === "add" && _renderAddFlow()}
        {pageState === "edit" && _renderEditFlow()}
      </div>
    </div>
  );
};

DashboardPageContentLayout.defaultProps = {
  haveAddButton: true,
};
