import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";

import { getClassStatus } from "../../Components/Classes";
import { useDashboardPageLocalData, useQueryString } from "../../Components/Hooks";
import { useTableFilters } from "../../Components/Hooks/useTableFilters";
import { openSnackBar } from "../../Redux/Actions/appStatusActions";
import { classesActions, storeClassDetail } from "../../Redux/Actions/classActions";
import { getCorporateStudentsById } from "../../Redux/Actions/corporateActions";
import { useTypedSelector } from "../../Redux/Reducers";
import { ClassType, SearchClassType } from "../../Types";
import { ClassesScreen } from "./ClassesScreen";

interface d extends ColumnDescription {
  sortValue?: (cell: any, row: any) => string;
}

const filterValueTutor = (cell: any, row: any) => {
  return row.tutor ? row.tutor.first_name + row.tutor.last_name : "";
};

const filterValueStudent = (cell: any, row: any) => {
  return row.student ? row.student.first_name + row.student.last_name : "";
};

const getColumns = (
  sortTimeHandler: (
    e: any,
    field: "registerTime" | "startTime" | "updatedTime"
  ) => void,
  sortType: number
): d[] => {
  return [
    {
      dataField: "Tutor",
      text: "#",
      formatter: (_, __, index: number) => <p>{index + 1}</p>,
    },
    {
      dataField: "Tutor",
      text: "Tutor",
      formatter: (_, row) => (
        <p>
          {row.tutor ? row.tutor.first_name : ""}{" "}
          {row.tutor ? row.tutor.last_name : ""}
        </p>
      ),
      filterValue: (cell, row) => filterValueTutor(cell, row),
      sort: true,
      sortValue: (_, row) => {
        return row.tutor ? row.tutor.first_name + row.tutor.last_name : "";
      },
    },
    {
      dataField: "class_duration_type",
      text: "Duration",
      formatter: (_, row) => <p>{row.class_duration_type.slice(0, 2)} Min</p>,
    },
    {
      dataField: "Student",
      text: "Student",
      formatter: (_, row) => (
        <p>
          {row.student ? row.student.first_name : ""}{" "}
          {row.student ? row.student.last_name : ""}
        </p>
      ),
      filterValue: (cell, row) => filterValueStudent(cell, row),
      sort: true,
      sortValue: (_, row) => {
        return row.student
          ? row.student.first_name + row.student.last_name
          : "";
      },
    },

    {
      dataField: "student.email",
      text: "Email/ID",
      sort: true,
    },
    {
      dataField: "class_history.student_used_mobile",
      text: "Platform",
      formatter: (cell, row) => (
        <p>
          {row.class_history && row.class_history.student_used_mobile
            ? "Mobile"
            : "Web"}
        </p>
      ),
    },
    // {
    //   dataField: "Tutor",
    //   text: "Tutor",
    //   formatter: (_, row) => (
    //     <p>
    //       {row.tutor ? row.tutor.first_name : ""}{" "}
    //       {row.tutor ? row.tutor.last_name : ""}
    //     </p>
    //   ),
    //   filterValue: (cell, row) => filterValueTutor(cell, row),
    // },
    // {
    //   dataField: "student.email",
    //   text: "Student Email",
    // },
    {
      dataField: "Class Status",
      text: "Status",
      formatter: (_, row) =>
        row.status && <p>{getClassStatus(row.status).text}</p>,
      sort: true,
      sortValue: (_, row) => {
        return row.status ? getClassStatus(row.status).text : "";
      },
    },
    {
      dataField: "learning_material.title",
      text: "Class Subject",
      formatter: (_, row) => (
        <p
          style={{
            width: "60rem",
          }}
        >
          {row.learning_material.title}
        </p>
      ),
    },
    {
      dataField: "_id",
      text: "Class Id",
    },

    {
      dataField: "student.corporate.name",
      text: "B2B",
    },

    {
      dataField: "Start Time",
      text: "Class Start Date",
      formatter: (_, row) =>
        row.startTime && (
          <p>{moment(row.startTime).format("YYYY.MM.DD HH:mm")}</p>
        ),
      sort: true,
      sortValue: (_, row) => {
        return moment(row.startTime).format("YYYY.MM.DD HH:mm");
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          if (moment(rowA.startTime).isAfter(moment(rowB.startTime))) return 1;
          return -1;
        }
        if (moment(rowA.startTime).isAfter(moment(rowB.startTime))) return -1;
        return 1;
      },
    },
    {
      dataField: "Updated Time",
      text: "Class Updated Time",
      sort: true,
      sortValue: (_, row) => {
        return moment(row.updated_at).format("YYYY.MM.DD HH:mm A");
      },
      formatter: (_, row) => {
        return (
          row.updated_at && (
            <p>{moment(row.updated_at).format("YYYY.MM.DD HH:mm A")}</p>
          )
        );
      },
    },
  ];
};

const getCorporateColumns = (
  sortTimeHandler: (
    e: any,
    field: "registerTime" | "startTime" | "updatedTime"
  ) => void,
  sortType: number
): d[] => {
  return [
    {
      dataField: "_id",
      text: "#",
      formatter: (_, __, index: number) => <p>{index + 1}</p>,
    },
    {
      dataField: "Class Time",
      text: "Class Start Date",
      formatter: (_, row) =>
        row.startTime && (
          <p>{moment(row.startTime).format("MMM Do, YYYY HH:mm")}</p>
        ),
      sort: true,
      sortValue: (_, row) => {
        return moment(row.startTime).format("YYYY.MM.DD HH:mm");
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          if (moment(rowA.startTime).isAfter(moment(rowB.startTime))) return 1;
          return -1;
        }
        if (moment(rowA.startTime).isAfter(moment(rowB.startTime))) return -1;
        return 1;
      },
    },
    {
      dataField: "Tutor",
      text: "Tutor",
      formatter: (_, row) => (
        <p>
          {row.tutor ? row.tutor.first_name : ""}{" "}
          {row.tutor ? row.tutor.last_name : ""}
        </p>
      ),
      filterValue: (cell, row) => filterValueTutor(cell, row),
      sort: true,
      sortValue: (_, row) => {
        return row.tutor ? row.tutor.first_name + row.tutor.last_name : "";
      },
    },
    {
      dataField: "class_duration_type",
      text: "Duration",
    },
    {
      dataField: "learning_material.title",
      text: "Class Subject",
    },
    {
      dataField: "Class State",
      text: "Class Status",
      formatter: (_, row) =>
        row.status && <p>{getClassStatus(row.status).text}</p>,
      sort: true,
      sortValue: (_, row) => {
        return row.status ? getClassStatus(row.status).text : "";
      },
    },
    {
      dataField: "class_history.student_used_mobile",
      text: "Platform",
      formatter: (cell, row) => (
        <p>
          {row.class_history && row.class_history.student_used_mobile
            ? "Mobile"
            : "Web"}
        </p>
      ),
    },

    {
      dataField: "_id",
      text: "Class ID",
    },
  ];
};

export const ClassesScreenContainer = () => {
  const dispatch = useDispatch();
  const query = useQueryString();
  const allClasses = useTypedSelector((state) => state.class.allClasses);
  const isSupervisor = useTypedSelector((state) => state.auth.isSupervisor);

  const [{ filters }, { updateFiltersDispatch, clearFiltersDispatch }] =
    useTableFilters("classTable");

  const studentClasses = useTypedSelector(
    (state) => state.class.studentClasses
  );
  const [
    { pageState, selectedLanguage, idToGetClasses },
    { onChangePageState, setSelectedLanguage },
  ] = useDashboardPageLocalData();

  const [studentId, setStudent] = useState<string | null>("");

  useEffect(() => {
    if (query.get("studentId")) setStudent(query.get("studentId"));
  }, [query.get("studentId")]);

  const [sortType, setSortType] = useState<number>(1);
  const [data, setData] = useState<ClassType[]>([]);
  const [classesWithoutFilter, setClassesWithoutFilter] = useState<ClassType[]>(
    []
  );

  useEffect(() => {
    let data: SearchClassType = {
      search_term: "",
      type: filters.data.classStatus || "all",
      startTime: filters.data.startTime,
      endTime: filters.data.endTime,
    };

    if (filters.data.sortField === "startTime")
      data.sort_based_start_time = filters.data.sortType;

    if (filters.data.sortField === "registerTime")
      data.register_time = filters.data.sortType;

    if (filters.data.sortField === "updatedTime")
      data.sort_based_update_time = filters.data.sortType;

    if (isSupervisor) {
      studentId &&
        dispatch(
          getCorporateStudentsById(
            studentId,
            data.type || "all",
            data.startTime,
            data.endTime,
            data.sort_based_update_time,
            (classes) => {
              setClassesWithoutFilter(classes);
            }
          )
        );
    } else {
      dispatch(classesActions.getAllClasses(data));
    }
  }, [studentId]);

  useEffect(() => {
    if (allClasses && allClasses.length) {
      setData(allClasses);
    }
  }, [allClasses]);

  const startSearch = (data: SearchClassType) => {
    setData([]);
    if (data && isSupervisor && studentId) {
      return dispatch(
        getCorporateStudentsById(
          studentId,
          data.type || "all",
          data.startTime,
          data.endTime,
          data.sort_based_update_time,
          (classes) => {
            setClassesWithoutFilter(classes);
          }
        )
      );
    }
    if (data) {
      dispatch(classesActions.getAllClasses(data));
    }
  };

  const changeDateSearchHandler = (
    type: string,
    newDate: MaterialUiPickersDate
  ) => {
    let time: {
      startTime?: MaterialUiPickersDate;
      endTime?: MaterialUiPickersDate;
    } = {};
    if (type === "startDate") {
      time = {
        startTime: newDate?.startOf("day"),
        endTime: filters.data.endTime?.hours(23).minutes(59).seconds(59),
      };
      updateFiltersDispatch({
        data: { startTime: newDate },
      });
    }

    if (type === "endDate") {
      time = {
        startTime: filters.data.startTime?.startOf("day"),
        endTime: newDate?.hours(23).minutes(59).seconds(59),
      };
      updateFiltersDispatch({
        data: { endTime: newDate },
      });
    }

    const data: SearchClassType = {
      search_term: "",
      type: filters.data.classStatus ? filters.data.classStatus : "all",
      ...time,
    };

    let condition;
    if (type === "startDate") condition = !time.endTime;

    if (type === "endDate") condition = !time.startTime;

    if (condition || moment(time.endTime).diff(moment(time.startTime)) > 0) {
      startSearch(data);
    } else {
      dispatch(
        openSnackBar({
          open: true,
          message: "End date should be after start date",
          variant: "error",
        })
      );
    }
  };

  const sortTimeHandler = (
    e: any,
    field: "registerTime" | "startTime" | "updatedTime"
  ) => {
    const data: SearchClassType = {
      search_term: "",
      type: filters.data.classStatus ? filters.data.classStatus : "all",
    };

    updateFiltersDispatch({
      data: {
        sortType: filters.data.sortType === 1 ? -1 : 1,
        sortField: field,
      },
    });

    if (field === "registerTime") {
      data.register_time = filters.data.sortType === 1 ? -1 : 1;
    }
    if (field === "startTime") {
      data.sort_based_start_time = filters.data.sortType === 1 ? -1 : 1;
    }
    if (field == "updatedTime") {
      data.sort_based_update_time = filters.data.sortType === 1 ? -1 : 1;
    }

    if (filters.data.startTime) {
      data.startTime = filters.data.startTime?.startOf("day");
    }
    if (filters.data.endTime) {
      data.endTime = filters.data.endTime?.hours(23).minutes(59).seconds(59);
    }

    startSearch(data);
  };

  const statusChangeHandler = (val: string) => {
    updateFiltersDispatch({
      data: { classStatus: val },
    });
    const data: SearchClassType = {
      type: val,
      search_term: "",
    };
    if (filters.data.startTime) {
      data.startTime = filters.data.startTime?.startOf("day");
    }
    if (filters.data.endTime) {
      data.endTime = filters.data.endTime?.hours(23).minutes(59).seconds(59);
    }

    startSearch(data);
  };

  const classDurationChangeHandler = (val: "20" | "40") => {
    console.log(val);
    updateFiltersDispatch({
      data: {
        classDuration: val,
      },
    });
    const data: SearchClassType = {
      type: filters.data.classStatus ? filters.data.classStatus : "all",
      search_term: "",
      duration: val,
    };
    if (filters.data.startTime) {
      data.startTime = filters.data.startTime?.startOf("day");
    }
    if (filters.data.endTime) {
      data.endTime = filters.data.endTime?.hours(23).minutes(59).seconds(59);
    }

    startSearch(data);
  };

  const onClearInputs = () => {
    const data: SearchClassType = {
      type: "all",
      search_term: "",
    };

    clearFiltersDispatch();
    startSearch(data);
  };

  const getClassesDetails = (classDetail: any) => {
    onChangePageState("edit", classDetail._id);

    if (!isSupervisor)
      dispatch(classesActions.getClassDetails(classDetail._id));
  };

  return (
    <ClassesScreen
      onChangePageState={onChangePageState}
      pageState={pageState}
      tableColumns={
        !isSupervisor
          ? getColumns(sortTimeHandler, sortType)
          : getCorporateColumns(sortTimeHandler, sortType)
      }
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      statusChangeHandler={statusChangeHandler}
      classDurationChangeHandler={classDurationChangeHandler}
      selectedDuration={
        filters.data.classDuration ? filters.data.classDuration : "20"
      }
      selectedClassState={
        filters.data.classStatus ? filters.data.classStatus : "all"
      }
      allClasses={idToGetClasses && studentClasses ? studentClasses : data}
      selectedStartDate={filters.data.startTime}
      selectedEndDate={filters.data.endTime}
      changeDateSearchHandler={changeDateSearchHandler}
      onClearInputs={onClearInputs}
      onViewCellClick={getClassesDetails}
      studentId={studentId}
      isSupervisor={isSupervisor}
      classesWithoutFilter={classesWithoutFilter}
    />
  );
};
