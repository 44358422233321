import React from "react";
//import classes from "./LearningMaterialScreen.module.css";
import { ColumnDescription } from "react-bootstrap-table-next";
import { DashboardPageState, LanguageVariantType, Role } from "../../Types";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
//import AudioSelector from "./../../Components/Common/AudioSelector/AudioSelector";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  data: Role[];
}

export const RolesScreen = (props: Props) => {
  const { onChangePageState, pageState, tableColumns, data } = props;

  const RenderAddFlow = () => {
    return <></>;
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a New Member";
      case "edit":
        return "Member Info";
      default:
        return "Roles and Permissions";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      data={data}
    />
  );
};
