import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";

import { classDurations, classState } from "../../../Config/Constants/SelectValues";
import { Button, DatePicker, SelectInput } from "../../Common";
import classes from "./ExtraTableFilters.module.css";

interface ComponentProps {
  statusChangeHandler: (value: string) => void;
  selectedClassState: string;
  selectedStartDate?: MaterialUiPickersDate;
  selectedEndDate?: MaterialUiPickersDate;
  classDurationChangeHandler: (val: "20" | "40") => void;
  selectedDuration: "20" | "40";
  changeDateSearchHandler: (
    type: string,
    newDate: MaterialUiPickersDate
  ) => void;
  onClearInputs: () => void;
}

const ExtraTableFilters = ({
  statusChangeHandler,
  selectedClassState,
  selectedStartDate,
  selectedEndDate,
  changeDateSearchHandler,
  onClearInputs,
  selectedDuration,
  classDurationChangeHandler
}: ComponentProps) => {

  return (
    <div className={classes.Container}>
      <div className={classes.LeftFiltersContainer}>
        <SelectInput
          value={selectedClassState}
          handleChange={(e) => statusChangeHandler(e.target.value as string)}
          options={classState}
          placeholder="Class State"
          variant="outlined"
          label="Class State"
        />
        <SelectInput
          value={selectedDuration}
          handleChange={(e) => classDurationChangeHandler(e.target.value as '20' | '40')}
          options={classDurations}
          placeholder="Class Duration"
          variant="outlined"
          label="Class Duration"
          style={{marginLeft: "1.28rem"}}
        />
      </div>
      <div className={classes.RightFiltersContainer}>
        <DatePicker
          placeholder="Start Date"
          onChange={(newDate) => changeDateSearchHandler("startDate", newDate)}
          selectedDate={selectedStartDate}
        />
        <div style={{ width: "1rem" }} />
        <DatePicker
          placeholder="End Date"
          onChange={(newDate) => changeDateSearchHandler("endDate", newDate)}
          selectedDate={selectedEndDate}
        />
      </div>
      <div>
        <Button onClick={onClearInputs} height="8rem" variant="contained">
          <div className="p-5">
            <span>Clear Inputs</span>
          </div>
        </Button>
      </div>
    </div>
  );
};


export default ExtraTableFilters;