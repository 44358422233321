import React from "react";

import { Button, IconGenerator } from "..";
import classes from "./GoToButton.module.css";

interface Props {
  onClick?: (place?: string) => void;
  className?: string;
}

const GoToButton = ({ className, onClick }: Props) => {
  return (
    <div className={className}>
      <Button
        onClick={onClick}
        variant="default"
        freeHeight
        className={classes.goToButton}
      >
        <IconGenerator iconName="goTo" />
      </Button>
    </div>
  );
};

export default GoToButton;
