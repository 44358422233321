import {
  GET_ALL_ROLES,
  GET_ALL_MEMBERS,
  GET_MEMBER,
  userAndRoleActionTypes,
  UserAndRoleState,
} from "../ActionTypes";

const initialState: UserAndRoleState = {
  members: [],
  member: null,
  roles: [],
};

export default function AuthReducer(
  state = initialState,
  action: userAndRoleActionTypes
): UserAndRoleState {
  switch (action.type) {
    case GET_ALL_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case GET_ALL_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_MEMBER:
      return {
        ...state,
        member: action.payload,
      };
    default:
      return state;
  }
}
