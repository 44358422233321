import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { AddAnnouncements } from "../../Components/Notification";
import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { DashboardPageState, LanguageVariantType } from "../../Types";
import { EventType, EventType_Payload } from "../../Types/events";

interface Props {
  onChangePageState: (newState: DashboardPageState) => void;
  pageState: DashboardPageState;
  tableColumns: ColumnDescription[];
  selectedLanguage: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  onTableEditClick: (rowData: EventType, rowIndex: number) => void;
  onSubmitForm: (materialData: EventType_Payload) => void;
  allEvents: EventType[];
  eventToEdit: {
    [key: string]: EventType;
  };
}

export const EventScreen = (props: Props) => {
  const {
    onChangePageState,
    pageState,
    tableColumns,
    selectedLanguage,
    setSelectedLanguage,
    onTableEditClick,
    allEvents,
    eventToEdit,
    onSubmitForm,
  } = props;

  const RenderAddFlow = () => {
    return (
      <>
        <AddAnnouncements
          lang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          eventToEdit={eventToEdit}
          onSubmitForm={onSubmitForm}
        />
      </>
    );
  };

  const getPageHeaderText = (): string => {
    switch (pageState) {
      case "add":
        return "Add a New Announcements";
      case "edit":
        return "Edit Announcements";
      default:
        return "Announcements";
    }
  };

  return (
    <DashboardPageContentLayout
      pageState={pageState}
      onChangePageState={onChangePageState}
      pageTitle={getPageHeaderText()}
      tableColumns={tableColumns}
      AddFlowContent={() => RenderAddFlow()}
      EditFlowContent={() => RenderAddFlow()}
      data={allEvents}
      onEditCell={onTableEditClick}
    />
  );
};
