import React from "react";
import classes from "./FaqModal.module.css";
import { CardModal } from "../../menus";
import { Grid } from "@material-ui/core";
import { TextInput, TextAreaInput } from "../../Common";

interface Props {
  report: any;
  onClose: () => void;
  onResponseClicked: () => void;
}
export const FaqModal = (_props: Props) => {
  const { onClose } = _props;
  return (
    <CardModal
      haveFromButtons
      containedButtonText="Submit"
      outlineBtnText="Cancel"
      onOutlineButtonClicked={onClose}
      head={<span>Inquiry Details</span>}
      onClose={onClose}
    >
      <div className={classes.Root}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextInput label="Question - English" placeholder="name" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextAreaInput
              label="Answer - English"
              placeholder="name"
              rows={2}
            />
          </Grid>

          <div className={classes.Divider} />

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextInput label="Question - Korean" placeholder="name" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextAreaInput
              label="Answer - Korean"
              placeholder="name"
              rows={2}
              className="mb-5"
            />
          </Grid>
        </Grid>
      </div>
    </CardModal>
  );
};
