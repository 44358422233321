import {
  GET_LEARNING_MATERIALS_MAIN_CATEGORIES,
  GET_LEARNING_MATERIALS_CATEGORIES,
  AppThunk,
  GET_ALL_LEARNING_MATERIALS,
  GET_ALL_LEARNING_MATERIALS_BY_MATERIALID,
  ADD_LEARNING_MATERIAL_BY_MATERIALID,
  TOGGLE_LM_DISPLAY_ON_HOME_SCREEN,
} from "../ActionTypes";

import LearningMaterialHttpService from "../../Services/Http/Endpoints/LearningMaterialServices";
//import { toggleReduxLoading } from "./appStatusActions";
import { LanguageVariantType, LearningMaterialType_Payload } from "../../Types";
import { learningMaterialFilters } from "./../../Types/materialLearning";
//import { toggleReduxLoading } from "./appStatusActions";
import { getLearningMaterialFormData } from "./../../Services/DataCreators/getLearningMaterialFormData";
import {
  openSnackBar,
  toggleReduxLoading,
  setUploadProgress,
} from "./appStatusActions";

export const postLearningMaterial =
  (
    data: LearningMaterialType_Payload,
    language: LanguageVariantType,
    materialId?: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return LearningMaterialHttpService.postLearningMaterial(
      getLearningMaterialFormData(data, language, materialId),
      (e: any) => {
        dispatch(setUploadProgress((e.loaded * 100) / e.total));
      }
    )
      .then((result) => {
        dispatch(toggleReduxLoading(false));

        dispatch({
          type: ADD_LEARNING_MATERIAL_BY_MATERIALID,
          payload: {
            [language]: result.data.data,
          },
        });

        dispatch(
          openSnackBar({
            open: true,
            variant: "success",
            message: "Learning Material Saved Successfully,(" + language + ")",
          })
        );
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const editLearningMaterial =
  (
    newData: LearningMaterialType_Payload,
    learningMaterialId: string,
    language: LanguageVariantType,
    materialId?: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return LearningMaterialHttpService.patchLearningMaterial(
      learningMaterialId,
      getLearningMaterialFormData(newData, language, materialId),
      (e: any) => {
        dispatch(setUploadProgress((e.loaded * 100) / e.total));
      }
    )
      .then((result) => {
        dispatch(toggleReduxLoading(false));
        dispatch(
          openSnackBar({
            open: true,
            message: "Learning Material Edited Successfully",
            variant: "success",
          })
        );
        dispatch({
          type: ADD_LEARNING_MATERIAL_BY_MATERIALID,
          payload: {
            [language]: result.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const getAllLearningMaterials =
  (filters: learningMaterialFilters): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return LearningMaterialHttpService.getAllLearningMaterials(filters)
      .then((result) => {
        dispatch(toggleReduxLoading(false));
        const { learning_materials } = result.data;
        dispatch({
          type: GET_ALL_LEARNING_MATERIALS,
          payload: learning_materials,
        });
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const clearMaterialsToEdit = () => ({
  type: GET_ALL_LEARNING_MATERIALS_BY_MATERIALID,
  payload: {},
});

export const getAllLearningMaterialsByMaterialId =
  (materialId: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return LearningMaterialHttpService.getAllLearningMaterialsByMaterialId(
      materialId
    )
      .then((res) => {
        const { learning_materials } = res.data;
        dispatch(toggleReduxLoading(false));
        dispatch({
          type: GET_ALL_LEARNING_MATERIALS_BY_MATERIALID,
          payload: learning_materials,
        });
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const displayMaterialAsPopularRecommended =
  (data: any, materialId: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return LearningMaterialHttpService.displayMaterialAsPopularRecommended(
      data,
      materialId
    )
      .then((result) => {
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const displayLearningMaterialOnHomeScreen =
  (id: string, isShow: boolean): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return LearningMaterialHttpService.displayLearningMaterialOnHomeScreen({
      material_id: id,
      home_screen_display: isShow,
    })
      .then((result) => {
        dispatch(toggleReduxLoading(false));
        dispatch({
          type: TOGGLE_LM_DISPLAY_ON_HOME_SCREEN,
          payload: id,
        });
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const getLearningMaterialCategories = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return LearningMaterialHttpService.getCategories()
    .then((result) => {
      dispatch(toggleReduxLoading(false));
      const { categories } = result.data;
      if (categories) {
        dispatch({
          type: GET_LEARNING_MATERIALS_CATEGORIES,
          payload: categories,
        });
      }
    })
    .catch((err) => {
      return dispatch(toggleReduxLoading(false));
    });
};

export const getLearningMaterialMainCategories = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return LearningMaterialHttpService.getMainCategories()
    .then((result) => {
      dispatch(toggleReduxLoading(false));
      const { main_categories } = result.data;
      if (main_categories) {
        dispatch({
          type: GET_LEARNING_MATERIALS_MAIN_CATEGORIES,
          payload: main_categories,
        });
      }
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
    });
};
