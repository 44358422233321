import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import moment from "moment-timezone";
import React from "react";

import { TutorDetailType } from "../../../Types";
import { AcceptInterview_Payload } from "../../../Types/interview";
import { DatePicker } from "../../Common";
import TimePicker from "../../Common/TimePicker/TimePicker";
import { CardModal } from "../../menus";
import classes from "./ApplicationSetTimeModal.module.css";

interface Props {
  onSubmitTime?: (val: AcceptInterview_Payload) => void;
  onCancel?: () => void;
  tutorData: TutorDetailType | null;
}

export const ApplicationSetTimeModal = (props: Props) => {
  const { onSubmitTime, onCancel, tutorData } = props;
  const formik = useFormik<AcceptInterview_Payload>({
    initialValues: {
      interviewDate: new Date().toISOString(),
      interviewTime: new Date().toISOString(),
      status: "CONFIRMED",
      message: "",
      tutorId: tutorData ? tutorData._id : "",
      time_zone: moment.tz.guess(),
    },
    onSubmit: (values) => {
      const valuesWithExactTime = {
        ...values,
        interviewExactTime: moment(values.interviewDate)
          .hours(moment(values.interviewTime).hours())
          .minutes(moment(values.interviewTime).minutes())
          .toISOString(),
      };

      if (onSubmitTime) onSubmitTime(valuesWithExactTime);
    },
  });
  const { values, setFieldValue } = formik;
  const interviewData = tutorData?.interview;
  const preferredTime = interviewData ? interviewData.preferredTime : "";
  const preferredDate = interviewData ? interviewData.preferredDate : "";
  return (
    <CardModal
      head={<span>Interview Date/ Time</span>}
      haveFromButtons
      className={classes.Root}
      outlineBtnText="Cancel"
      containedButtonText="Submit Date"
      onContainedButtonClicked={formik.handleSubmit}
      onOutlineButtonClicked={onCancel}
      onClose={onCancel}
    >
      <Grid container spacing={2} className="mb-5">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.PreferTimeRoot}>
            <p>Tutor Preferred Time:</p>
            <h4>
              {preferredDate}, {preferredTime}
            </h4>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <DatePicker
            selectedDate={values.interviewDate}
            onChange={(newDate) => setFieldValue("interviewDate", newDate)}
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TimePicker
            inputVariant="outlined"
            minDate={new Date()}
            selectedDate={values.interviewTime}
            handleDateChange={(newDate) => {
              console.log(newDate, " newda");
              setFieldValue("interviewTime", newDate);
            }}
          />
        </Grid>
      </Grid>
    </CardModal>
  );
};
