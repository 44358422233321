import React, { useEffect, useState } from "react";
import BootstrapTable, {
  ColumnDescription,
  PaginationOptions,
  SortOrder,
} from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import { FaClipboard } from "react-icons/fa";

import { useChangeURl, useQueryString } from "../../Hooks";
import { IconGenerator } from "../IconGenerator/IconGenerator";
import LottieLoading from "../LottieLoading";
import { Button } from "./../";
import { CustomSwitch } from "./../CustomSwitch/CustomSwitch";
import classes from "./CustomBootstrapTable.module.css";
import { PageListRenderer } from "./PageListRenderer";

const customTotal = (from: any, to: any, size: any) => (
  <span className={classes.PaginationTotalText}>
    Showing {from}-{to} of {size} Results
  </span>
);

interface Props {
  columns: ColumnDescription<any, any>[];
  data: any[];
  className?: string;
  defaultSorted?: [
    {
      dataField: any;
      order: SortOrder;
    }
  ];
  keyField: string;
  headerClassName?: string;
  rowClassName?: string;
  noPagination?: boolean;
}

export const CustomBootstrapTable = (props: Props) => {
  const { headerClassName, rowClassName, noPagination, data } = props;

  const updateUrlNoRefresh = useChangeURl();

  const paginationOptions: PaginationOptions = {
    paginationSize: 35,
    pageStartIndex: 1,
    hideSizePerPage: true,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    pageListRenderer: ({ pages, onPageChange }) => (
      <PageListRenderer pages={pages} onPageChange={onPageChange} data={data} />
    ),
    sizePerPageList: [
      {
        text: "35",
        value: 35,
      },
      {
        text: "35",
        value: 35,
      },
    ],
    onPageChange: (page) => {
      updateUrlNoRefresh([
        {
          nameQuery: "page",
          data: `${page}`,
        },
      ]);
    },
  };

  return (
    <div className={props.className}>
      <div className={classes.TableRoot}>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationTableProps }) => (
            <div>
              <BootstrapTable
                {...paginationTableProps}
                {...props}
                hover
                striped
                headerClasses={
                  headerClassName ? headerClassName : classes.TableHeader
                }
                rowClasses={rowClassName ? rowClassName : classes.RowClasses}
                defaultSorted={props.defaultSorted}
                noDataIndication={() => <h1>No Result Found</h1>}
              />
            </div>
          )}
        </PaginationProvider>
      </div>
    </div>
  );
};
