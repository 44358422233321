interface ReturnType {
  label: string;
  text: string;
}

export const getClassStatus = (status: string): ReturnType => {
  switch (status) {
    case "rejected":
      return { label: "rejected", text: "Rejected" };
    case "request_pending":
      return {
        label: "pending",
        text: "Confirmation Pending",
      };
    case "cancelled_by_tutor":
      return {
        label: "cancelled",
        text: "Cancelled By Tutor",
      };
    case "in_complete":
      return {
        label: "inComplete",
        text: "In Complete",
      };
    case "confirmed":
      return { label: "accepted", text: "Accepted" };
    case "cancelled_by_admin":
      return {
        label: "cancelled",
        text: "Cancelled By Admin",
      };
    case "cancelled_by_student":
      return {
        label: "cancelled",
        text: "Cancelled By Student",
      };
    case "absent":
      return {
        label: "absent",
        text: "Absent",
      };
    case "completed":
      return {
        label: "completed",
        text: "Completed",
      };

    case "auto_reject":
      return {
        label: "rejected",
        text: "Auto Rejected",
      };
    default:
      return {
        label: "pending",
        text: "Confirmation Pending",
      };
  }
};
