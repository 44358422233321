import CouponsInstance from "../../Services/Http/Endpoints/CouponsServices";
import PackagesInstance from "../../Services/Http/Endpoints/packagesService";
import {
  AppThunk,
  GET_ALL_COUPONS,
  GET_FREE_PACKAGES,
  GET_USER_PACKAGES,
} from "../ActionTypes";
import { toggleReduxLoading } from "./appStatusActions";

const getFreePackages = (): AppThunk => (dispatch) => {
  return PackagesInstance.getFreePackages()
    .then((res) => {
      if (res.data.ok) {
        dispatch({
          type: GET_FREE_PACKAGES,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

const getUserPackages =
  (sort?: string): AppThunk =>
  (dispatch) => {
    return PackagesInstance.getUserPackages(sort)
      .then((res) => {
        if (res.data.ok) {
          dispatch({
            type: GET_USER_PACKAGES,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

const getAllCoupons = (): AppThunk => (dispatch) => {
  dispatch(toggleReduxLoading(true));
  return CouponsInstance.getAllCoupons()
    .then((res) => {
      if (res.data.ok) {
        dispatch({
          type: GET_ALL_COUPONS,
          payload: res.data.data,
        });
      }
      dispatch(toggleReduxLoading(false));
    })
    .catch((err) => {
      dispatch(toggleReduxLoading(false));
    });
};

const updateCoupon =
  (id: string, data: any): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return CouponsInstance.updateCoupon(id, data)
      .then((res) => {
        if (res.data.ok) {
          dispatch(getAllCoupons());
        }
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const addCoupon =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return CouponsInstance.createCoupon(data)
      .then((res) => {
        if (res.data.ok) {
          dispatch(getAllCoupons());
        }
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

const deleteCoupon =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(toggleReduxLoading(true));
    return CouponsInstance.deleteCoupon(id)
      .then((res) => {
        if (res.data.ok) {
          dispatch(getAllCoupons());
        }
        dispatch(toggleReduxLoading(false));
      })
      .catch((err) => {
        dispatch(toggleReduxLoading(false));
      });
  };

export const PackagesActions = {
  getFreePackages,
  getUserPackages,
  getAllCoupons,
  deleteCoupon,
  updateCoupon,
  addCoupon,
};
