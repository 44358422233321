import { Grid } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import * as yup from "yup";

import {
  DashboardPageState,
  LanguageVariantType,
  LearningMaterialCategory,
  learningMaterialInitialState,
  LearningMaterialMainCategory,
  LearningMaterialType_Payload,
  questionInitialState,
} from "../../../Types";
import {
  FileSelector,
  FormikTextArea,
  FormikTextField,
  ImagePicker,
  Separator,
} from "../../Common";
import { InputLabel } from "../../Common/InputLabel/InputLabel";
import { IOSSwitch } from "../../Common/IOSSwitch/IOSSwitch";
import QuestionAdder from "../QuestionAdder/QuestionAdder";
import { ExpressionAdder, MaterialFormControls, YoutubeAdder } from "./../";
import { LearningMaterialType } from "./../../../Types/materialLearning";
import SelectInput, { OptionObj } from "./../../Common/SelectInput";
import classes from "./AddMaterialLearning.module.css";

const MaterialLevels: OptionObj[] = [
  {
    label: "1",
    value: "l1",
  },
  {
    label: "2",
    value: "l2",
  },
  {
    label: "3",
    value: "l3",
  },
  {
    label: "4",
    value: "l4",
  },
  {
    label: "5",
    value: "l5",
  },
  {
    label: "6",
    value: "l4",
  },
  {
    label: "6",
    value: "l4",
  },
  {
    label: "7",
    value: "l7",
  },
];

const validationSchema = yup.object({
  title: yup.string().required("title is required."),
  sub_title: yup.string(),
  insights: yup.string().nullable().required("insight is required"),
  category: yup.string().required("Please Select a Category"),
  main_category: yup.string().required("Please Select a Main Category"),
  questions: yup.array().of(
    yup.object().shape({
      question_title: yup
        .string()
        .required("title of the question is required"),
    })
  ),
  expressions: yup.array().of(
    yup.object().shape({
      title: yup.string().required("this field is required"),
      meaning__ko: yup.string().required("this field is required"),
      meaning__en: yup.string(),
      examples: yup.array().of(yup.object().shape({})),
    })
  ),
  media: yup.array().of(
    yup.object().shape({
      url: yup
        .string()
        .required("url is required")
        .url("please provide a valid url"),
      title: yup.string(),
    })
  ),
});

interface Props {
  lang: LanguageVariantType;
  setSelectedLanguage: (lang: LanguageVariantType) => void;
  categories: LearningMaterialCategory[];
  mainCategories: LearningMaterialMainCategory[];
  onSubmitForm: (materialData: LearningMaterialType_Payload) => void;
  learningMaterialToEdit: {
    [key: string]: LearningMaterialType;
  };
  pageState: DashboardPageState;
}

export const AddMaterialLearning = ({
  lang,
  setSelectedLanguage,
  categories,
  mainCategories,
  onSubmitForm,
  learningMaterialToEdit,
}: //pageState,
Props) => {
  const [initialValues, setInitialValues] =
    React.useState<LearningMaterialType_Payload>(learningMaterialInitialState);

  React.useEffect(() => {
    if (learningMaterialToEdit[lang] !== undefined) {
      setInitialValues({
        ...learningMaterialToEdit[lang],
        media: learningMaterialToEdit[lang].media
          ? learningMaterialToEdit[lang].media.videos
          : [],
      });
    } else {
      const otherLang = learningMaterialToEdit[lang === "en" ? "ko" : "en"];
      if (otherLang !== undefined) {
        setInitialValues({
          ...otherLang,
          media: otherLang.media ? otherLang.media.videos : [],
        });
      } else {
        setInitialValues(learningMaterialInitialState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const onResetForm = () => {
    setInitialValues(learningMaterialInitialState);
  };
  const htmlRef = React.useRef<any>();

  const onScrollToHtml = () => {
    if (htmlRef.current) {
      htmlRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        onSubmitForm(values);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        const addButtonDisabled =
          Object.keys(errors).length > 0 ||
          JSON.stringify(values) ===
            JSON.stringify(learningMaterialToEdit[lang]);
        return (
          <Form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ImagePicker
                    hints={[
                      "* Image shouldn’t be larger than1200 x 1200 pixels.",
                      "* Make sure to use appropriate and relevant imagery",
                    ]}
                    value={values.image || undefined}
                    fileSizeLimit={500000}
                    className={classes.inputFields}
                    onFileChoose={(file) => setFieldValue("image", file)}
                    onCleanFile={() => setFieldValue("image", null)}
                  />
                  <FormikTextField
                    label="Material Title*"
                    placeholder={"Title..."}
                    className={classes.inputFields}
                    name="title"
                  />
                  <FormikTextArea
                    className={classes.inputFields}
                    value=""
                    label="Material Subtitle*"
                    limit={150}
                    placeholder={"Maximum 150 characters..."}
                    rows={3}
                    name="sub_title"
                  />{" "}
                  <div className={classes.inputFields}>
                    <SelectInput
                      value={values.level}
                      variant={"outlined"}
                      handleChange={(e) =>
                        setFieldValue("level", e.target.value)
                      }
                      options={MaterialLevels}
                      label={"Level"}
                    />
                  </div>
                  <div className={classes.inputFields}>
                    <FileSelector
                      label="Audio"
                      btnText={
                        values.audio ? "Replace the audio" : "Add an audio"
                      }
                      limitSize={2000000}
                      accept="audio/*"
                      value={values.audio || undefined}
                      onFileChoose={(file) => setFieldValue("audio", file)}
                      onClearClick={() => setFieldValue("audio", null)}
                    />
                  </div>
                  <FieldArray name="expressions">
                    {({ insert, remove, push }) => (
                      <ExpressionAdder
                        values={values.expressions}
                        className={classes.inputFields}
                        onAddExpression={push}
                        onRemoveExpression={remove}
                      />
                    )}
                  </FieldArray>
                  <FieldArray name="media">
                    {({ insert, remove, push }) => (
                      <YoutubeAdder
                        values={values.media}
                        className={classes.inputFields}
                        onAddMedia={push}
                        onRemoveMedia={remove}
                      />
                    )}
                  </FieldArray>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={classes.inputFields}>
                    <SelectInput
                      value={values.main_category}
                      variant={"outlined"}
                      handleChange={(e) =>
                        setFieldValue("main_category", e.target.value)
                      }
                      options={mainCategories.map((item) => ({
                        label: item.title["en"],
                        value: item._id,
                      }))}
                      label={"Main Category"}
                    />
                  </div>
                  <div className={classes.inputFields}>
                    <SelectInput
                      value={values.category}
                      variant={"outlined"}
                      handleChange={(e) =>
                        setFieldValue("category", e.target.value)
                      }
                      options={categories.map((item) => ({
                        label: item.title["en"],
                        value: item._id,
                      }))}
                      className={classes.inputFields}
                      label={"Category"}
                    />
                  </div>
                  <div className={classes.inputFields}>
                    <FormikTextArea
                      value=""
                      label="Main Article HTML Tags*"
                      placeholder={"HTML Tags...."}
                      rows={10}
                      name="insights"
                    />
                    <button
                      onClick={onScrollToHtml}
                      type="button"
                      className={classes.PreviewButton}
                    >
                      <span>Preview</span>
                    </button>
                  </div>

                  <FieldArray name="questions">
                    {({ insert, remove, push }) => (
                      <QuestionAdder
                        values={values.questions}
                        onAddQuestion={() => push(questionInitialState)}
                        onRemoveQuestion={(index: number) => {
                          remove(index);
                        }}
                      />
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </div>
            <Separator />
            <MaterialFormControls
              selectedLanguage={lang}
              setSelectedLanguage={setSelectedLanguage}
              className={classes.FormControlRoot}
              englishChecked={learningMaterialToEdit["en"] !== undefined}
              koreanChecked={learningMaterialToEdit["ko"] !== undefined}
              addButtonDisabled={addButtonDisabled}
              submitButtonText={
                learningMaterialToEdit[lang] !== undefined
                  ? "Edit Learning Material"
                  : "Add Learning Material"
              }
              clearButtonText={"Clear"}
              onClearClick={onResetForm}
            />

            {values.insights && (
              <div ref={htmlRef} style={{ transition: ".5s", height: "100%" }}>
                <InputLabel>
                  <span>Insight Preview :</span>
                </InputLabel>
                {ReactHtmlParser(values.insights)}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
