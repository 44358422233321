import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import BaseApi from "../BaseAPI";

class CorporateService extends BaseApi {
  constructor() {
    super({ suffix: "admin" });
  }

  getCorporateDetails(id: string) {
    return this.httpService.get(`/supervisor/corporate_detail/${id}`);
  }

  getCorporateStudents(
    id: string,
    startTime?: MaterialUiPickersDate,
    endTime?: MaterialUiPickersDate
  ) {
    return this.httpService.get(
      `/supervisor/corporate_detail/${id}/students${
        startTime ? `?startTime=${startTime.utc()}` : ""
      } ${endTime ? `&endTime=${endTime.utc()}` : ""}`
    );
  }

  getCorporateStudent(
    studentId: string,
    type?: string,
    startTime?: MaterialUiPickersDate,
    endTime?: MaterialUiPickersDate,
    class_time?: number,
  ) {
    return this.httpService.get(
      `/supervisor/student/${studentId}?type=${type || "all"}${
        startTime ? `&startTime=${startTime}` : ""
      }${endTime ? `&endTime=${endTime}` : ""}${
        class_time !== undefined ? `&register_time=${class_time}` : ""
      }`
    );
  }

  getSupervisorDetail = () => {
    return this.httpService.get("/supervisor/corporate_detail");
  };

  getClassDetailCorporate = (id: string) => {
    return this.httpService.get(`/supervisor/class_detail/${id}`);
  };

  getStudentDetailCorporate = (id: string) => {
    return this.httpService.get(`/supervisor/students_detail/${id}`);
  };

  getAllCorporateRequest = () => {
    return this.httpService.get("/b2b_requests");
  };
}

const CorporateServiceInstance = new CorporateService();
export default CorporateServiceInstance;