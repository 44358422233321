import {
  GET_ERRORS,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  Errors,
  Modal,
  SnackBar,
  AppStatusActionTypes,
  CHANGE_LANGUAGE,
  TOGGLE_REDUX_LOADING,
  SET_UPLOAD_PROGRESS,
  AppThunk,
  OPEN_GLOBAL_DIALOG,
  CLOSE_GLOBAL_DIALOG,
} from "../ActionTypes";

//import config from "../../Config/AppConfig";
import { ENV } from "../../env/base";
import { DialogProps } from "@material-ui/core";

export const getErrors = (errors: Errors | {} = {}): AppStatusActionTypes => {
  return {
    type: GET_ERRORS,
    payload: errors,
  };
};

export const openModal = (modal: Modal): AppStatusActionTypes => {
  return {
    type: OPEN_MODAL,
    payload: modal,
  };
};

export const closeModal = (): AppStatusActionTypes => {
  return {
    type: CLOSE_MODAL,
  };
};

export const openSnackBar = (snackBar: SnackBar): AppStatusActionTypes => {
  return {
    type: OPEN_SNACKBAR,
    payload: snackBar,
  };
};

export const closeSnackBar = (): AppStatusActionTypes => {
  return {
    type: CLOSE_SNACKBAR,
  };
};

export const changeLang = (lang: "ko" | "en"): AppStatusActionTypes => {
  const { LANGUAGE_STORAGE_KEY } = ENV;
  localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
  return {
    type: CHANGE_LANGUAGE,
    payload: lang,
  };
};

export const toggleReduxLoading = (isLoading: boolean) => {
  return { type: TOGGLE_REDUX_LOADING, payload: isLoading };
};

export const setUploadProgress = (number: number) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: number,
});

export const openGlobalDialog =
  (dialogData: DialogProps): AppThunk =>
  (dispatch) => {
    dispatch({
      type: OPEN_GLOBAL_DIALOG,
      payload: {
        ...dialogData,
        onClose:
          dialogData.onClose !== undefined
            ? dialogData.onClose
            : () => dispatch(closeGlobalDialog()),
      },
    });
  };

export const closeGlobalDialog = () => {
  return {
    type: CLOSE_GLOBAL_DIALOG,
  };
};
/*export const handleAxiosCatch = (err) => {
  let errors = {};
  if (err) {
    if (!isEmpty(err.response)) {
      const { data } = err.response;
      if (isEmpty(data)) {
        errors.network = err.response.message;
      } else {
        errors = { ...data.data };
      }
    } else {
      errors.network = "مشکل اتصال اینترنت";
    }
  }

  return errors;
};
*/
