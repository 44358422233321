import React from "react";
import { useHistory } from "react-router-dom";

import { DashboardPageState, LanguageVariantType } from "../../Types";
import { useQueryString } from "./";

type ReturnType = [
  {
    pageState: DashboardPageState;
    selectedLanguage: LanguageVariantType;
    idToEdit: string | null;
    idToGetClasses: string | null;
  },
  {
    setSelectedLanguage: (lang: LanguageVariantType) => void;
    onChangePageState: (
      newState: DashboardPageState,
      idToEdit?: string,
      params?: string
    ) => void;
  }
];

export const useDashboardPageLocalData = (): ReturnType => {
  const [pageState, setPageState] = React.useState<DashboardPageState>("edit");
  const [idToEdit, setIdToEdit] = React.useState<string | null>(null);
  const [idToGetClasses, setIdToGetClasses] = React.useState<string | null>(
    null
  );
  const [selectedLang, setSelectedLang] =
    React.useState<LanguageVariantType>("en");

  const query = useQueryString();
  const history = useHistory();

  React.useEffect(() => {
    const pageMode = query.get("mode");
    const idClass = query.get("studentId") || query.get("tutorId");

    if (idClass) {
      setIdToGetClasses(idClass);
    }

    if (pageMode) {
      if (pageMode === "edit" || pageMode === "add" || pageMode === "main") {
        //history.push(history.location.pathname+`?mode=${pageMode}`)
        setIdToEdit(query.get("id"));
        setPageState(pageMode);
      } else {
        history.push(history.location.pathname + `?mode=main`);
      }
    } else {
      setPageState("main");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onChangePageState = (
    state: DashboardPageState,
    idToEdit?: string,
    params?: string
  ) => {
    let queryParams = `?mode=${state}${idToEdit ? "&id=" + idToEdit : ""}`;
    if (params) {
      queryParams = [queryParams, params].join("&");
    }

    history.push(history.location.pathname + queryParams);
  };

  return [
    { pageState, selectedLanguage: selectedLang, idToEdit, idToGetClasses },
    {
      setSelectedLanguage: setSelectedLang,
      onChangePageState,
    },
  ];
};
