import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { DashboardPageContentLayout } from "../../Components/HOC/Layout/DashboardPageContentLayout/DashboardPageContentLayout";
import { useDashboardPageLocalData } from "../../Components/Hooks";
import { Program } from "../../Components/Programs/Program/Program";
import { ProgramPageHeader } from "../../Components/Programs/ProgramPageHeader/ProgrmaPageHeader";
import { StudentActions } from "../../Redux/Actions/studentActions";
import { useTypedSelector } from "../../Redux/Reducers";
import { StudentDetailType, StudentType } from "../../Types";
import { CorporateDetail } from "../../Types/corporate";

interface Props {
  onEditRequestClick?: () => void;
  columns: ColumnDescription[];
  corporateDetail: CorporateDetail | null;
  students: StudentDetailType[];
  onSetTimeFilters?: (start?: MaterialUiPickersDate, end?: MaterialUiPickersDate) => void;
  startTime?: MaterialUiPickersDate;
  endTime?: MaterialUiPickersDate;
  semesterOptions: {
    label: string,
    value: string
  }[];
  setSortTypeHandler: (value: string | unknown) => void;
  sortTypeValue: string;
}

export const ProgramScreen = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const {
    onEditRequestClick,
    columns,
    corporateDetail,
    students,
    onSetTimeFilters,
    startTime,
    endTime,
    semesterOptions,
    sortTypeValue,
    setSortTypeHandler
  } = props;

  const classes = useTypedSelector((state) => state.class.allClasses);
  const [_, { onChangePageState }] = useDashboardPageLocalData();

  if (!corporateDetail) {
    return null;
  }

  const openClasses = (row: StudentDetailType) => {
    dispatch(StudentActions.storeStudentDetail(row))
    if (row && row._id) {
      history.push(
        `/classes?studentId=${row._id}&search=&startDate=&endDate=&status=`
      );
    }
  };

  return (
    <div>
      <DashboardPageContentLayout
        onChangePageState={onChangePageState}
        pageState={"supervisor"}
        tableColumns={[]}
        SupervisorMainContent={() => (
          <Program
            onOpenClasses={openClasses}
            corporateDetail={corporateDetail}
            students={students}
            columns={columns}
            onSetTimeFilters={onSetTimeFilters}
            startTime={startTime}
            endTime={endTime}
            semesterOptions={semesterOptions}
            sortTypeValue={sortTypeValue}
            setSortTypeHandler={setSortTypeHandler}
          />
        )}
        data={classes}
        pageTitle={`Manage B2B Program`}
        // PageHeaderComponent={() => (
        //   <ProgramPageHeader onClick={onEditRequestClick} />
        // )}
      />
    </div>
  );
};
