import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  StudentType,
  ClassType,
  TutorReviewType,
  tutorReviewInitialValues,
} from "../../../Types";
import { IconGenerator, TextInput } from "../../Common";
import { Disclaimer } from "../../Common/Disclaimer/Disclaimer";
import { TutorReviewItem } from "../TutorReviewItem/TutorReviewItem";

import classes from "./TutorReviewCard.module.css";

type ReviewKeysType =
  | "pronunciation"
  | "speaking"
  | "vocabulary"
  | "listening"
  | "grammar";

interface Props {
  open?: boolean;
  onClose: () => void;
  student?: StudentType | null;
  classData: ClassType;
}

const reviewKeys: ReviewKeysType[] = [
  "pronunciation",
  "speaking",
  "vocabulary",
  "listening",
  "grammar",
];

const ProblemList = [
  { label: "None", value: null },
  { label: "General", value: "General" },
  { label: "Network", value: "Network" },
];

export const TutorReviewCard = ({
  open,
  onClose,
  classData,
  student,
}: Props) => {
  const intl = useIntl();
  const [initialValue, setInitialValues] = useState<TutorReviewType>(
    tutorReviewInitialValues
  );

  const [haveProblem, setHaveProblem] = useState<boolean>(false);

  const onCloseTestimonial = () => {
    onClose();
  };

  const convertKeysToTitles = (key: string) => {
    if (!student) {
      return "";
    }
    switch (key) {
      case "pronunciation":
        return (
          <p>
            {`${student.first_name}’s`}&nbsp;
            <span>Pronunciation</span>
            &nbsp; Skill Review:
          </p>
        );
      case "speaking":
        return (
          <p>
            {`${student.first_name}’s`}&nbsp;
            <span> Speaking</span>
            &nbsp; Skill Review:
          </p>
        );
      case "vocabulary":
        return (
          <p>
            {`${student.first_name}’s`}&nbsp;
            <span> Vocabulary</span>
            &nbsp; Skill Review:
          </p>
        );
      case "grammar":
        return (
          <p>
            {`${student.first_name}’s`}&nbsp;
            <span> Grammar</span>
            &nbsp; Skill Review:
          </p>
        );
      case "listening":
        return (
          <p>
            {`Rate ${student.first_name}’s`}&nbsp;
            <span> Listening</span>
            &nbsp; Skill Review:
          </p>
        );

      default:
        return (
          <p>
            {`Rate ${student.first_name}’s`}&nbsp;
            <span> Listening</span>
            &nbsp; Skill Review:
          </p>
        );
    }
  };

  let formic = useFormik<TutorReviewType>({
    initialValues: { ...initialValue },
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    if (classData && classData.reviewedByTutor) {
      setInitialValues(classData.reviewedByTutor);
    }
  }, [classData]);

  const onLimitedInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof TutorReviewType
  ) => {
    formic.setFieldValue(key, e.target.value);
  };

  if (classData && !classData.reviewedByTutor) {
    return null;
  }

  return (
    <div className={classes.FormRoot}>
      <div className={classes.HeaderContainer}>
        <h2>Tutor Review</h2>

        <div className="d-flex align-center">
          <Button className={classes.ExitBtn} onClick={onCloseTestimonial}>
            <IconGenerator iconName="close-box" className={classes.CloseIcon} />
          </Button>
        </div>
      </div>
      <form onSubmit={formic.handleSubmit} className={classes.FormContainer}>
        <div className={classes.ReviewListContainer}>
          {reviewKeys.map((key: ReviewKeysType) => {
            if (formic.values[key]) {
              return (
                <div key={key}>
                  <TutorReviewItem
                    titleChildren={convertKeysToTitles(key)}
                    itemValue={formic.values[key]}
                  />
                  <div className={classes.Divider} />
                </div>
              );
            }
            return null;
          })}
          {formic.values.otherOpinion && (
            <>
              <TextInput
                label="Other"
                value={formic.values.otherOpinion}
                onChange={(e) => onLimitedInputChange(e, "otherOpinion")}
                readonly={true}
              />
              <div className={classes.Divider} />
            </>
          )}

          {formic.values.problem ? (
            <div style={{ marginTop: "4.28rem" }}>
              <div className={classes.IssueReport}>
                <IconGenerator iconName="error" />
                <p>{formic.values.problem}</p>
              </div>
              {formic.values.problemDescription ? (
                <Disclaimer
                  label="Problem"
                  content={formic.values.problemDescription}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};
