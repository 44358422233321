import {
  CLEAR_TABLE_FILTERS,
  TablesFiltersActionType,
  TablesFiltersReducerType,
  UPDATE_TABLE_FILTERS,
} from "../ActionTypes";

const initialState: TablesFiltersReducerType = {
  classTable: {
    data: {
      startTime: null,
      endTime: null,
      classStatus: "all",
      sortType: 1,
      classDuration: "20",
    },
  },
  materialTable: {
    data: { category: "ALL", materialType: "all" },
  },
  studentTable: {
    data: {
      studentType: "all",
    },
  },
  studentProgramTable: {
    data: {
      semester: {
        label: "All",
        value: "all",
      },
    },
  },
};

const tablesFiltersReducer = (
  state = initialState,
  action: TablesFiltersActionType
): TablesFiltersReducerType => {
  switch (action.type) {
    case UPDATE_TABLE_FILTERS:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          data: {
            ...state[action.payload.tableName].data,
            ...action.payload.filterData.data,
          },
        },
      };
    case CLEAR_TABLE_FILTERS:
      return {
        ...state,
        [action.payload.tableName]: {
          ...initialState[action.payload.tableName],
        },
      };
    default:
      return state;
  }
};

export default tablesFiltersReducer;
