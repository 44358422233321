import moment from "moment";
import { InterviewAvailabilityTimeSlot_Payload } from "../Types";

const Config = {
  SERVER_ADDRESS: "https://api.dev.tooti.co.kr/V1.0.0",
  BASE_API_URL: "https://api.dev.tooti.co.kr/V1.0.0",
  BASE_API_URL_DEV: "http://localhost:5000/V1.0.0",
  LANGUAGE_STORAGE_KEY: "@Tootie_Admin_LANGUAGE_STORAGE_KEY",
  TOKEN_STORAGE_KEY: "@Tootie_Admin_TOKEN_STORAGE_KEY",
};

export default Config;

export const APP_INTERVIEW_TIME_STAMPS: InterviewAvailabilityTimeSlot_Payload[] =
  [...new Array(14)].map((_, index) => {
    return {
      startTime: moment()
        .hour(index + 8)
        .minutes(0)
        .format("HH:mm"),
      endTime: moment()
        .hour(index + 9)
        .minutes(0)
        .format("HH:mm"),
    };
  });
