import React from "react";
import classes from "./TutorIssueDetails.module.css";
import { CardModal } from "../../menus";
import { Grid } from "@material-ui/core";
import {
  Button,
  TextInput,
  IconGenerator,
  InputLabel,
  TextAreaInput,
} from "../../Common";

interface Props {
  report: any;
  onClose: () => void;
  onResponseClicked: () => void;
}
export const TutorIssueDetails = (_props: Props) => {
  const { onClose, onResponseClicked } = _props;
  return (
    <CardModal head={<span>Issue Report</span>} onClose={onClose}>
      <div className={classes.Root}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput label="Full Name" placeholder="name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput label="Email Address" placeholder="name" />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              label="Student ID"
              placeholder="name"
              endAdornment={
                <Button variant="default">
                  <IconGenerator iconName="goTo" />
                </Button>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              label="Class ID"
              placeholder="name"
              endAdornment={
                <Button variant="default">
                  <IconGenerator iconName="goTo" />
                </Button>
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <InputLabel>
              <span>Issues Reported</span>
            </InputLabel>
            <div className={classes.IssuesList}>
              <div className={classes.IssueRoot}>
                <IconGenerator iconName="close-box" />
                <span>Video Quality</span>
              </div>
              <div className={classes.IssueRoot}>
                <IconGenerator iconName="close-box" />
                <span>Video Quality</span>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextAreaInput label="Email Address" placeholder="name" rows={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              onClick={onResponseClicked}
              variant="contained"
              className="mt-5"
            >
              <span>Write a Response</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </CardModal>
  );
};
