import { CorporateActionType, CorporateReducerType, GET_ALL_CORPORATE_REQUESTS, GET_CORPORATE_DETAIL, GET_CORPORATE_STUDENTS } from "../ActionTypes";

const initialState: CorporateReducerType = {
  corporateDetail: null,
  corpStudents: [],
  corpRequests: [],
};

export default function CorporateReducer(
  state = initialState,
  action: CorporateActionType
): CorporateReducerType {
  switch (action.type) {
    case GET_ALL_CORPORATE_REQUESTS:
      return {
        ...state,
        corpRequests: action.payload,
      };
    case GET_CORPORATE_DETAIL:
      return {
        ...state,
        corporateDetail: action.payload,
      };
    case GET_CORPORATE_STUDENTS:
      return {
        ...state,
        corpStudents: action.payload,
      };
    default:
      return state;
  }
}
