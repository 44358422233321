import React from "react";
import { IconGenerator, Button } from "../";
import classes from "./Alert.module.css";

interface Props {
  className?: string;
  children?: React.ReactNode;
  onCloseClick?: () => void;
  hideButton?: boolean;
}

export const Alert = (props: Props) => {
  const { className, children, onCloseClick, hideButton } = props;
  return (
    <div className={className}>
      <div className={classes.Root}>
        {children}
        {!hideButton && (
          <Button onClick={onCloseClick} variant="default">
            <IconGenerator iconName="close-box" className={classes.alertIcon} />
          </Button>
        )}
      </div>
    </div>
  );
};
