import { Field, FieldProps } from "formik";

import { TextAreaProps, TextAreaInput } from "../";

interface Props {
  name: string;
}

export const FormikTextArea = ({ name, ...props }: TextAreaProps & Props) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => (
        <TextAreaInput
          error={errors[name] !== undefined && touched[name] === true}
          {...props}
          {...field}
          //error={touched && errors[name] !== undefined}
          //helperText={errors[name] ? (errors[name] as string) : ""}
        />
      )}
    </Field>
  );
};
