import {
  GET_INTERVIEWER,
  InterviewersActionTypes,
  InterviewerReducerType,
} from "../ActionTypes";

const initialState: InterviewerReducerType = {
  interviewer: null,
};

export default function AuthReducer(
  state = initialState,
  action: InterviewersActionTypes
): InterviewerReducerType {
  switch (action.type) {
    case GET_INTERVIEWER:
      return {
        ...state,
        interviewer: action.payload,
      };
    default:
      return state;
  }
}
