import React from "react";
import classes from "./ColorPicker.module.css";

const defaultColors = [
  "#DE0000",
  "#72BDCD",
  "#56C795",
  "#FF7800",
  "#F2C94C",
  "#9B51E0",
  "#292929",
];
interface Props {
  value?: string;
  onChange?: (newColor: string) => void;
  pallets?: string[];
  className?: string;
}

export const ColorPicker = (props: Props) => {
  const { value, onChange, pallets, className } = props;

  const palletToRender = pallets !== undefined ? pallets : defaultColors;
  const defaultValue=value||"#DE0000";
  return (
    <div className={className}>
      <div className={classes.Root}>
        {palletToRender.map((pl, index) => (
          <div
            onClick={onChange ? () => onChange(pl) : undefined}
            className={
              pl === defaultValue
                ? [
                    classes.palletContainer,
                    classes.palletContainer_Active,
                  ].join(" ")
                : classes.palletContainer
            }
          >
            <div
              className={classes.palletColor}
              style={{ backgroundColor: pl }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};
