import React, { useEffect } from "react";
import { PageListRendererOptions } from "react-bootstrap-table-next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useQueryString } from "../../Hooks";

import classes from "./CustomBootstrapTable.module.css";

interface Props extends PageListRendererOptions {
  data: any[];
}

export const PageListRenderer = ({
  pages,
  onPageChange,
  data,
}: Props) => {
  const query = useQueryString();
  const renderPaginationText = (p: any) => {
    if (p.page === "Next") {
      return <FaChevronRight fontSize={"2.28rem"} />;
    } else if (p.page === "Back") {
      return <FaChevronLeft fontSize={"2.28rem"} />;
    } else {
      return <span className={classes.PaginationTotalText}>{p.page}</span>;
    }
  };

  useEffect(() => {
    if (!data || data.length === 0 || Number(query.get("page")) === 0) return;

    onPageChange(Number(query.get("page")), 5);
    
  }, [query.get("page"), data]);

  return (
    <div
      className={[
        classes.paginationRoot,
        "col-6 d-flex justify-content-end",
      ].join(" ")}
    >
      {pages.map((p: any) => {
        return (
          <button
            key={p.page}
            className={
              p.active
                ? [classes.PageButtonRoot, classes.PageButtonRoot_Active].join(
                    " "
                  )
                : classes.PageButtonRoot
            }
            onClick={() => onPageChange(p.page, 5)}
          >
            {renderPaginationText(p)}
          </button>
        );
      })}
    </div>
  );
};
